import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AccessCodeConnection = {
  __typename?: 'AccessCodeConnection';
  pageInfo: PageInfo;
  edges: Array<AccessCodeOutputEdge>;
  totalCount: Scalars['Int'];
};

/** An individual access code */
export type AccessCodeInput = {
  /** The access code */
  accessCode: Scalars['String'];
  /** The number of tickets that can be purchased with this access code, set null for no limit */
  quantity?: Maybe<Scalars['Float']>;
  /** The number of free tickets for this access code, set null for no limit */
  quantityFree?: Maybe<Scalars['Float']>;
  /** Whether this access code is enabled */
  isEnabled: Scalars['Boolean'];
};

/** Portable representation of an access code. */
export type AccessCodeOutput = {
  __typename?: 'AccessCodeOutput';
  /** The access code */
  accessCode: Scalars['String'];
  /** The number of tickets that can be purchased with this access code */
  quantity?: Maybe<Scalars['Float']>;
  /** The number of free tickets that can been redeemed with this access code */
  quantityFree?: Maybe<Scalars['Float']>;
  /** The number of tickets that have been purchased with this access code */
  quantityUsed: Scalars['Float'];
  /** the number of free tickets that have been redeemed with this access code */
  quantityFreeUsed: Scalars['Float'];
  /** The link to the access code */
  link: Scalars['String'];
  /** Is enabled */
  isEnabled: Scalars['Boolean'];
};

export type AccessCodeOutputEdge = {
  __typename?: 'AccessCodeOutputEdge';
  cursor: Scalars['String'];
  node: AccessCodeOutput;
};

/** How you can create or update an access code. */
export type AccessCodeReleaseInput = {
  /** The release id for the access code, set null to create new */
  releaseId?: Maybe<Scalars['String']>;
  /** The access codes for the release */
  accessCodes: Array<AccessCodeInput>;
  /** The tickets that can be purchased with this access code */
  tickets: Array<AccessCodeTicketInput>;
  /** The event id for the access code */
  eventId: Scalars['String'];
};

/** Portable representation of an access code release. */
export type AccessCodeReleaseOutput = {
  __typename?: 'AccessCodeReleaseOutput';
  /** The release id for the access code */
  releaseId: Scalars['String'];
  /** The access codes for the release */
  accessCodes: Array<AccessCodeOutput>;
  /** The tickets that can be purchased with this access code */
  tickets: Array<AccessCodeTicketOutput>;
  /** The event id for the access code */
  eventId: Scalars['String'];
};

/** Access code ticket input for the event. */
export type AccessCodeTicketInput = {
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
};

/** Access code ticket output for the event. */
export type AccessCodeTicketOutput = {
  __typename?: 'AccessCodeTicketOutput';
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
  /** The ticket name */
  name: Scalars['String'];
  /** The ticket zone name */
  zoneName?: Maybe<Scalars['String']>;
  /** The ticket quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** The ticket price */
  price?: Maybe<Scalars['Float']>;
  /** If ticket is hidden by the release */
  isHidden: Scalars['Boolean'];
};

export type AccessCodeWhereInput = {
  releaseId: Scalars['String'];
};

export type ActionEngagementRecordOutput = {
  __typename?: 'ActionEngagementRecordOutput';
  label: Scalars['String'];
  clickCount: Scalars['Float'];
};

export type ActiveCompetitionByTypeInput = {
  eventId: Scalars['String'];
  competitionType: CompetitionType;
};

export type Activity = {
  __typename?: 'Activity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The user that an activity belongs to */
  user: User;
  /** order:creation */
  type: Scalars['String'];
  /** Origin of activities */
  origin: ActivityOrigin;
  /** Detail is relevant to the action that cant be captured just by the refId and refType */
  detail: Scalars['JSON'];
  fingerPrint?: Maybe<Scalars['JSON']>;
  /** Target entity */
  refName?: Maybe<Scalars['String']>;
  /** Target entity id */
  refId?: Maybe<Scalars['String']>;
  /** human readable description of what has gone */
  description?: Maybe<Scalars['String']>;
  /** The admin that make this activity */
  admin?: Maybe<User>;
};

export type ActivityConnection = {
  __typename?: 'ActivityConnection';
  pageInfo: PageInfo;
  edges: Array<ActivityEdge>;
  totalCount: Scalars['Int'];
};

export type ActivityEdge = {
  __typename?: 'ActivityEdge';
  cursor: Scalars['String'];
  node: Activity;
};

/** Origin of activities */
export enum ActivityOrigin {
  User = 'User',
  System = 'System',
  Admin = 'Admin'
}

export type ActivityWhereInput = {
  userId: Scalars['String'];
};

export type AddGatesToEventAddonInput = {
  addonId: Scalars['String'];
  names: Array<Scalars['String']>;
};

export type AddGatesToZoneInput = {
  zoneId: Scalars['String'];
  names: Array<Scalars['String']>;
};

/** Represents additional info to be included with digital event or membership emails. */
export type AdditionalEmailInfoInput = {
  /** Additional info to be included with digital event or membership emails. */
  text?: Maybe<Scalars['String']>;
  /** The text that will be displayed on the action button. Required if buttonUrl is provided. */
  buttonText?: Maybe<Scalars['String']>;
  /** The url that the action button will link to. Required if buttonText is provided. */
  buttonUrl?: Maybe<Scalars['String']>;
};

export type AddonUnion = EventAddon | MembershipAddon;

export type AddPosTransactionInput = {
  /** Selected payment method (allowed: cash, eftpos, cc). */
  method: PaymentProvider;
  amount: Scalars['Float'];
  returned?: Maybe<Scalars['Float']>;
};

export type AddReminderToScoutCommunicationUserInput = {
  remindAt: Scalars['DateTime'];
  message?: Maybe<Scalars['String']>;
};

/** Address details. */
export type Address = {
  __typename?: 'Address';
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
};

/** Address details. */
export type AddressInput = {
  line1: Scalars['String'];
  line2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  formattedAddress?: Maybe<Scalars['String']>;
};

export type AddUnsubscribeReasonInput = {
  userId: Scalars['String'];
  unsubscribeReason: UnsubscribeReason;
  otherUnsubscribeReason?: Maybe<Scalars['String']>;
};

export type AdobeExperiencePlatformIntegration = {
  __typename?: 'AdobeExperiencePlatformIntegration';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  tenant: Scalars['String'];
  sandboxName: Scalars['String'];
  /** Also known as the API key */
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  imsOrgId: Scalars['String'];
  technicalAccountId: Scalars['String'];
  privateKeyBase64: Scalars['String'];
  customerEndpoint?: Maybe<Scalars['String']>;
  customerSchemaRefId?: Maybe<Scalars['String']>;
  customerDatasetId?: Maybe<Scalars['String']>;
  ticketEndpoint?: Maybe<Scalars['String']>;
  ticketSchemaRefId?: Maybe<Scalars['String']>;
  ticketDatasetId?: Maybe<Scalars['String']>;
  scanEndpoint?: Maybe<Scalars['String']>;
  scanSchemaRefId?: Maybe<Scalars['String']>;
  scanDatasetId?: Maybe<Scalars['String']>;
};

export type AdobeExperiencePlatformIntegrationInput = {
  tenant: Scalars['String'];
  sandboxName: Scalars['String'];
  /** Also known as the API key */
  clientId: Scalars['String'];
  clientSecret: Scalars['String'];
  imsOrgId: Scalars['String'];
  technicalAccountId: Scalars['String'];
  privateKeyBase64: Scalars['String'];
  customerEndpoint?: Maybe<Scalars['String']>;
  customerSchemaRefId?: Maybe<Scalars['String']>;
  customerDatasetId?: Maybe<Scalars['String']>;
  scanEndpoint?: Maybe<Scalars['String']>;
  scanSchemaRefId?: Maybe<Scalars['String']>;
  scanDatasetId?: Maybe<Scalars['String']>;
  ticketEndpoint?: Maybe<Scalars['String']>;
  ticketSchemaRefId?: Maybe<Scalars['String']>;
  ticketDatasetId?: Maybe<Scalars['String']>;
};

export type AgeBreakDownOutput = {
  __typename?: 'AgeBreakDownOutput';
  reportData: Array<AgeBreakDownSegment>;
  reportType: ReportType;
  shouldHide: Scalars['Boolean'];
};

export type AgeBreakDownSegment = {
  __typename?: 'AgeBreakDownSegment';
  ageRange: Scalars['String'];
  percentage: Scalars['Float'];
  total: Scalars['Float'];
};

export type ApplyPromoCode = {
  promoCode: Scalars['String'];
};

export type AppTicket = {
  __typename?: 'AppTicket';
  id: Scalars['String'];
  ticketNumber: Scalars['String'];
  parentTicketNumber?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  status: TicketStatus;
  gateIds: Array<Scalars['String']>;
  isHold: Scalars['Boolean'];
  ticketTypeName?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['String']>;
  seatZone?: Maybe<Scalars['String']>;
  buyerInformation?: Maybe<AppTicketUserDetails>;
  user?: Maybe<AppTicketUserDetails>;
  scans: Array<AppTicketScan>;
  isEventAddon: Scalars['Boolean'];
  eventAddonName?: Maybe<Scalars['String']>;
  ticketTypeKind?: Maybe<Scalars['String']>;
  isUsherDisabled: Scalars['Boolean'];
};

export type AppTicketScan = {
  __typename?: 'AppTicketScan';
  id: Scalars['String'];
  scannedAt: Scalars['DateTime'];
  direction: Scalars['String'];
  type: Scalars['String'];
};

export type AppTicketUserDetails = {
  __typename?: 'AppTicketUserDetails';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type AssignScoutCommunicationUsersToAgentInput = {
  scoutCommunicationId: Scalars['String'];
  agentUserId: Scalars['String'];
  scoutCommunicationUserIds: Array<Scalars['String']>;
};

export type AtrMembershipOrdersUpdatedBetweenInput = {
  /** The start date of the range to search provided in the organizations timezone. */
  startDate: Scalars['DateTime'];
  /** The end date of the range to search provided in the organizations timezone. */
  endDate: Scalars['DateTime'];
};

export type AtrMembershipOrdersUpdatedBetweenOutput = {
  __typename?: 'AtrMembershipOrdersUpdatedBetweenOutput';
  order: Order;
  arcMembershipId: Scalars['String'];
  arcContactId: Scalars['String'];
};

export type AtrUsersUpdatedBetweenOutput = {
  __typename?: 'ATRUsersUpdatedBetweenOutput';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  roles: Array<Role>;
  /** Permision set based on roles */
  permissions: Array<Permission>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  isTwoFactorAuthenticationEnabled: Scalars['Boolean'];
  isLoggedInWithLinkedAccount: Scalars['Boolean'];
  hasVerifiedEmail: Scalars['Boolean'];
  birthDate?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  /** Billing address associated with the user. */
  billingAddress?: Maybe<PartialAddress>;
  /** Shipping address associated with the user. */
  shippingAddress?: Maybe<PartialAddress>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress: Scalars['Boolean'];
  /** User credits balance */
  credits?: Maybe<Scalars['Float']>;
  /** User points balance */
  points: Scalars['Float'];
  usSegment?: Maybe<UsSegment>;
  /** unique uuid of a customer used to link to referral campaigns */
  referralId: Scalars['String'];
  /** User instagram username */
  instagramUsername?: Maybe<Scalars['String']>;
  /** The company name of the user. */
  companyName?: Maybe<Scalars['String']>;
  /** The tax number of the user. */
  taxNumber?: Maybe<Scalars['String']>;
  /** The region of the user, derived from the postal code of billing address. */
  region?: Maybe<Scalars['String']>;
  unsubscribeReason?: Maybe<UnsubscribeReason>;
  otherUnsubscribeReason?: Maybe<Scalars['String']>;
  lastTimeLoggedInAt?: Maybe<Scalars['DateTime']>;
  tags: Array<Tag>;
  scoutCommunications: Array<ScoutCommunication>;
  orders: OrderConnection;
  membershipTickets: Array<Ticket>;
  eventTickets: Array<Ticket>;
  hasMemberships: Scalars['Boolean'];
  hasTickets: Scalars['Boolean'];
  LTV: Scalars['Float'];
  history: HistoryConnection;
  /** The IDs of the events the user can access when their account is scoped for only certain events */
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  /** The IDs of the memberships the user can access when their account is scoped for only certain events */
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** The Shopify user entry for the user if they have used the organization's Shopify store */
  shopifyUser?: Maybe<ShopifyUser>;
  contactId: Scalars['String'];
};


export type AtrUsersUpdatedBetweenOutputOrdersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
};


export type AtrUsersUpdatedBetweenOutputHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};

export type AudienceCountInput = {
  transactionalType: BroadcastTransactionalType;
  audience?: Maybe<BroadcastAudience>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  zoneId?: Maybe<Array<Scalars['String']>>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  addonId?: Maybe<Array<Scalars['String']>>;
};

/** Id of the broadcast audience. One broadcast only has one id. */
export type AudienceData = {
  __typename?: 'AudienceData';
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  zoneId?: Maybe<Array<Scalars['String']>>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  addonId?: Maybe<Array<Scalars['String']>>;
};

/** Id of the broadcast audience. One broadcast only has one id. */
export type AudienceDataInput = {
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  zoneId?: Maybe<Array<Scalars['String']>>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  addonId?: Maybe<Array<Scalars['String']>>;
};

export type Automation = {
  __typename?: 'Automation';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  /** When to send the email */
  automationType: AutomationType;
  /** Id of Mailchimp template */
  templateId?: Maybe<Scalars['Float']>;
  /** Id of Mailchimp audience */
  listId?: Maybe<Scalars['String']>;
  /** Subject of email */
  subject?: Maybe<Scalars['String']>;
  /** Used to show what we are targeting */
  targetSource: ReportingFilterSource;
  /** Id of source */
  targetSourceId?: Maybe<Scalars['String']>;
  /** Release Id of the source */
  targetReleaseId?: Maybe<Scalars['String']>;
  /** Active status. */
  isActive: Scalars['Boolean'];
  /** send time for a birthday email, only hour and minute are used */
  timeOfDay?: Maybe<Scalars['DateTime']>;
  /** Postmark template name */
  templateName?: Maybe<Scalars['String']>;
};

export enum AutomationType {
  OrderAbandoned = 'orderAbandoned',
  Birthday = 'birthday'
}

export type BarchartOutput = {
  __typename?: 'BarchartOutput';
  range: Scalars['String'];
  value: Scalars['Float'];
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  cardLast4?: Maybe<Scalars['String']>;
  cardBrand?: Maybe<Scalars['String']>;
  cardExpiryMonth?: Maybe<Scalars['Float']>;
  cardExpiryYear?: Maybe<Scalars['Float']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  totalPaymentCount?: Maybe<Scalars['Float']>;
  completedPaymentCount?: Maybe<Scalars['Float']>;
  amountPaid?: Maybe<Scalars['Float']>;
  totalFeeAmount?: Maybe<Scalars['Float']>;
};

export type BrandingInput = {
  /** Organization logo. */
  logo?: Maybe<Scalars['Upload']>;
  /** Organization mobile logo. */
  mobileLogo?: Maybe<Scalars['Upload']>;
  /** Organization favicon. */
  favicon?: Maybe<Scalars['Upload']>;
  /** Organization background image. */
  background?: Maybe<Scalars['Upload']>;
  /** Organization background image. */
  fullBackground?: Maybe<Scalars['Upload']>;
  /** Primary color. */
  primaryColor: Scalars['String'];
  /** Secondary color. */
  secondaryColor: Scalars['String'];
  /** Grey scale option. */
  greyScale?: Maybe<GreyScale>;
};

export type BrandingOutputBase = {
  __typename?: 'BrandingOutputBase';
  /** Organization logo. */
  logo?: Maybe<ExtendedFile>;
  /** Organization mobile logo. */
  mobileLogo?: Maybe<ExtendedFile>;
  /** Organization favicon. */
  favicon?: Maybe<ExtendedFile>;
  /** Organization background image. */
  background?: Maybe<ExtendedFile>;
  /** Organization full background image. */
  fullBackground?: Maybe<ExtendedFile>;
  /** Primary color. */
  primaryColor: Scalars['String'];
  /** Secondary color. */
  secondaryColor: Scalars['String'];
  /** Grey scale option. */
  greyScale?: Maybe<GreyScale>;
};

export type Broadcast = {
  __typename?: 'Broadcast';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  subject: Scalars['String'];
  fromName: Scalars['String'];
  /** When to send the email */
  audienceType?: Maybe<BroadcastAudience>;
  /** The rich text object */
  message: Scalars['String'];
  event?: Maybe<Event>;
  membership?: Maybe<Membership>;
  scheduledAt: Scalars['DateTime'];
  status: BroadcastStatus;
  /** Array an object with the error responses from Postmark */
  errors?: Maybe<Scalars['JSON']>;
  count?: Maybe<Scalars['Float']>;
  audienceData: AudienceData;
  type: BroadcastType;
  marketingType?: Maybe<BroadcastMarketingType>;
  transactionalType?: Maybe<BroadcastTransactionalType>;
  marketingFilters?: Maybe<Array<BroadcastMarketingFilters>>;
  marketingFiltersV2?: Maybe<Array<BroadcastMarketingFiltersV2>>;
  style?: Maybe<BroadcastStyle>;
  /** The reporting version to decide how to query. */
  reportingVersion: Scalars['Float'];
};

/** Type of broadcast audience */
export enum BroadcastAudience {
  TicketBuyers = 'TICKET_BUYERS',
  RegistrationWaitlistCustomers = 'REGISTRATION_WAITLIST_CUSTOMERS',
  ResaleWaitlistCustomers = 'RESALE_WAITLIST_CUSTOMERS',
  TicketType = 'TICKET_TYPE',
  Zone = 'ZONE',
  Release = 'RELEASE',
  Addon = 'ADDON',
  ManualMembershipRenewal = 'MANUAL_MEMBERSHIP_RENEWAL',
  AutomaticMembershipRenewal = 'AUTOMATIC_MEMBERSHIP_RENEWAL',
  MembershipBuyers = 'MEMBERSHIP_BUYERS'
}

export type BroadcastConnection = {
  __typename?: 'BroadcastConnection';
  pageInfo: PageInfo;
  edges: Array<BroadcastOutputEdge>;
  totalCount: Scalars['Int'];
};

export type BroadcastFilterCountInput = {
  filters?: Maybe<Array<MarketingFiltersInput>>;
  filtersV2?: Maybe<Array<BroadcastMarketingFiltersV2Input>>;
};

export enum BroadcastFilterCriteria {
  Is = 'is',
  Not = 'not'
}

export enum BroadcastFilterStateType {
  Event = 'Event',
  TicketType = 'TicketType',
  Zone = 'Zone',
  Membership = 'Membership',
  Tag = 'Tag',
  Location = 'Location'
}

export enum BroadcastFilterType {
  Waitlist = 'waitlist',
  TicketBuyer = 'ticketBuyer',
  Tag = 'tag',
  Location = 'location',
  Region = 'region'
}

export type BroadcastLocationFilterOptions = {
  __typename?: 'BroadcastLocationFilterOptions';
  region: Array<ResponseLabelValue>;
  state: Array<ResponseLabelValue>;
  district: Array<ResponseLabelValue>;
  locality: Array<ResponseLabelValue>;
  country: Array<ResponseLabelValue>;
};

export type BroadcastMarketingFilters = {
  __typename?: 'BroadcastMarketingFilters';
  ids: Array<Scalars['String']>;
  criteria?: Maybe<BroadcastFilterCriteria>;
  filterType: BroadcastFilterType;
};

export type BroadcastMarketingFiltersV2 = {
  __typename?: 'BroadcastMarketingFiltersV2';
  args: Scalars['String'];
};

export type BroadcastMarketingFiltersV2Input = {
  args: Scalars['String'];
};

export enum BroadcastMarketingFiltersV2Kind {
  TicketType = 'TicketType',
  PurchaseHistory = 'PurchaseHistory',
  Zone = 'Zone',
  Membership = 'Membership',
  Tag = 'Tag',
  Location = 'Location',
  Comp = 'Comp'
}

export type BroadcastMarketingFiltersV2Output = {
  __typename?: 'BroadcastMarketingFiltersV2Output';
  args: BroadcastMarketingFiltersV2Union;
};

export type BroadcastMarketingFiltersV2Union = PurchaseHistoryArgs | TicketTypeArgs | LocationArgs | ZoneArgs | MembershipArgs | TagArgs | CompArgs;

export enum BroadcastMarketingType {
  OptIn = 'optIn',
  Custom = 'custom'
}

export type BroadcastMarketingV2StateInput = {
  filterV2: BroadcastMarketingFiltersV2Input;
};

export type BroadcastMarketingV2StateOutput = {
  __typename?: 'BroadcastMarketingV2StateOutput';
  type: BroadcastFilterStateType;
  id: Scalars['String'];
  label: Scalars['String'];
};

export type BroadcastOutput = {
  __typename?: 'BroadcastOutput';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  subject: Scalars['String'];
  fromName: Scalars['String'];
  /** When to send the email */
  audienceType?: Maybe<BroadcastAudience>;
  /** The rich text object */
  message: Scalars['String'];
  event?: Maybe<Event>;
  membership?: Maybe<Membership>;
  scheduledAt: Scalars['DateTime'];
  status: BroadcastStatus;
  /** Array an object with the error responses from Postmark */
  errors?: Maybe<Scalars['JSON']>;
  count?: Maybe<Scalars['Float']>;
  audienceData: AudienceData;
  type: BroadcastType;
  marketingType?: Maybe<BroadcastMarketingType>;
  transactionalType?: Maybe<BroadcastTransactionalType>;
  marketingFilters?: Maybe<Array<BroadcastMarketingFilters>>;
  marketingFiltersV2?: Maybe<Array<BroadcastMarketingFiltersV2Output>>;
  style?: Maybe<BroadcastStyle>;
  /** The reporting version to decide how to query. */
  reportingVersion: Scalars['Float'];
  /** The Broadcast email delivery stats from postmark */
  stats?: Maybe<PostmarkEmailStatsOverviewOutput>;
};

export type BroadcastOutputEdge = {
  __typename?: 'BroadcastOutputEdge';
  cursor: Scalars['String'];
  node: BroadcastOutput;
};

export type BroadcastReportingInputBase = {
  userId?: Maybe<Scalars['String']>;
  waitlistUserId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  broadcastId: Scalars['String'];
};

export type BroadcastReportingOrderInput = {
  userId?: Maybe<Scalars['String']>;
  waitlistUserId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  broadcastId: Scalars['String'];
  orderId: Scalars['String'];
};

export type BroadcastReportingOutput = {
  __typename?: 'BroadcastReportingOutput';
  clicksOpened: Scalars['Float'];
  revenue: Scalars['Float'];
  conversions: Scalars['Float'];
  spamReported: Scalars['Float'];
};

export type BroadcastReportingStatsInput = {
  broadcastId: Scalars['String'];
  type: BroadcastType;
};

export enum BroadcastStatus {
  Scheduled = 'SCHEDULED',
  Draft = 'DRAFT',
  Sent = 'SENT',
  Test = 'TEST'
}

export type BroadcastStyle = {
  __typename?: 'BroadcastStyle';
  backgroundColour: Scalars['String'];
  bodyColour: Scalars['String'];
  headingColour: Scalars['String'];
  paragraphColour: Scalars['String'];
  linkColour: Scalars['String'];
  buttonColour: Scalars['String'];
};

export type BroadcastStyleInput = {
  backgroundColour: Scalars['String'];
  bodyColour: Scalars['String'];
  headingColour: Scalars['String'];
  paragraphColour: Scalars['String'];
  linkColour: Scalars['String'];
  buttonColour: Scalars['String'];
};

export enum BroadcastTransactionalType {
  Event = 'Event',
  Membership = 'Membership'
}

export enum BroadcastType {
  Marketing = 'marketing',
  Transactional = 'transactional'
}

export type BroadcastWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  status?: Maybe<BroadcastStatus>;
};

/** Bulk orders item type */
export type BulkOrdersItem = {
  __typename?: 'BulkOrdersItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  processedAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** bulk orders item status. */
  status?: Maybe<BulkOrdersItemStatus>;
};

/** Bulk order items status */
export enum BulkOrdersItemStatus {
  Success = 'Success',
  Failed = 'Failed'
}

/** Buyer information. */
export type BuyerInformation = {
  __typename?: 'BuyerInformation';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  /** Postal code provided by the customer. Returns `null` if `UpdateOrderInput.deliveryMethod` is set to other than `eTicket`. */
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Tax number provided by the customer. */
  taxNumber?: Maybe<Scalars['String']>;
  /** Company name provided by the customer. */
  companyName?: Maybe<Scalars['String']>;
  travelDeals?: Maybe<Scalars['String']>;
};

/** Buyer information. */
export type BuyerInformationInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  /** Postal code provided by the customer. Pass `null` if `UpdateOrderInput.deliveryMethod` is set to other than `eTicket`. */
  postalCode?: Maybe<Scalars['String']>;
  /** Tax Number provided by the customer. */
  taxNumber?: Maybe<Scalars['String']>;
  /** Company name provided by the customer. */
  companyName?: Maybe<Scalars['String']>;
  travelDeals?: Maybe<Scalars['String']>;
};

export type ChargeLineItem = {
  __typename?: 'ChargeLineItem';
  createdAt: Scalars['DateTime'];
  orderId: Scalars['String'];
  lineItemCount: Scalars['Float'];
  lineItemTotalCents: Scalars['Float'];
  lineItemChargedCents: Scalars['Float'];
  lineItemUnchargedCents: Scalars['Float'];
};

export type ChargeMonthSummaryOutput = {
  __typename?: 'ChargeMonthSummaryOutput';
  lineItems: Array<ChargeLineItem>;
};

export type ChargeSummaryOutput = {
  __typename?: 'ChargeSummaryOutput';
  month: Scalars['DateTime'];
  totalCents: Scalars['Float'];
  totalChargedCents: Scalars['Float'];
  totalUnchargedCents: Scalars['Float'];
};

export type CodeReportItem = {
  __typename?: 'CodeReportItem';
  code: Scalars['String'];
  redeemed: Scalars['Float'];
  sold: Scalars['Float'];
  amount: Scalars['Float'];
};

export type CodeReportOutput = {
  __typename?: 'CodeReportOutput';
  counts: Scalars['Float'];
  items: Array<CodeReportItem>;
};

export type CompArgs = {
  __typename?: 'CompArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  eventId: Scalars['String'];
};

export type Competition = {
  __typename?: 'Competition';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  competitionType: CompetitionType;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  bannerImage?: Maybe<ExtendedFile>;
  isPublished: Scalars['Boolean'];
  prizes?: Maybe<Array<CompetitionPrize>>;
  promoter?: Maybe<Array<CompetitionReward>>;
  isCompetitionRunning: Scalars['Boolean'];
  competitionState: CompetitionState;
  referralRewardValue?: Maybe<Scalars['Int']>;
};

export type CompetitionCustomerReporting = {
  __typename?: 'CompetitionCustomerReporting';
  competitionEndDate: Scalars['DateTime'];
  competitionTitle: Scalars['String'];
  competitionEventTitle: Scalars['String'];
  competitionEventLogo?: Maybe<ExtendedFile>;
  competitionBannerImage?: Maybe<ExtendedFile>;
  competitionReferralLink?: Maybe<Scalars['String']>;
  competitionSharePageLink?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  competitionId: Scalars['String'];
  referralsMade: Scalars['Int'];
  entries: Scalars['Int'];
  isCompetitionRunning: Scalars['Boolean'];
};

export type CompetitionOnsaleOutput = {
  __typename?: 'CompetitionOnsaleOutput';
  totalEntries: Scalars['Float'];
  pageViews: Scalars['Float'];
  conversionRate: Scalars['Float'];
  revenue: Scalars['Float'];
  totalOrders: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  newCustomers: Scalars['Float'];
  hasSalesOutsideOfCompetition: Scalars['Boolean'];
};

export type CompetitionPrize = {
  __typename?: 'CompetitionPrize';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prizeOrder: Scalars['Float'];
  quantity: Scalars['Float'];
  image?: Maybe<ExtendedFile>;
};

export type CompetitionPrizeInput = {
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  prizeOrder?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['Upload']>;
};

export type CompetitionPromoterInput = {
  platform: Scalars['String'];
  actionType: Scalars['String'];
  value: Scalars['Int'];
  enabled: Scalars['Boolean'];
};

export type CompetitionRegistrationOutput = {
  __typename?: 'CompetitionRegistrationOutput';
  totalEntries: Scalars['Float'];
  pageViews: Scalars['Float'];
  /** Total page views for the event details page. */
  eventDetailsPageViews: Scalars['Float'];
  /** Total page views for the registration sign up form page. */
  registrationPageViews: Scalars['Float'];
  conversionRate: Scalars['Float'];
  eventPageConversionRate: Scalars['Float'];
  registrationPageConversionRate: Scalars['Float'];
  totalRegistrations: Scalars['Float'];
  newCustomers: Scalars['Float'];
};

export type CompetitionReportingInput = {
  competitionId: Scalars['String'];
  eventId: Scalars['String'];
};

export type CompetitionReportingOnsaleOutput = {
  __typename?: 'CompetitionReportingOnsaleOutput';
  competition: CompetitionOnsaleOutput;
  promoter: PromoterOnsaleOutput;
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
};

export type CompetitionReportingRegistrationOutput = {
  __typename?: 'CompetitionReportingRegistrationOutput';
  competition: CompetitionRegistrationOutput;
  promoter: PromoterRegistrationOutput;
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
};

export type CompetitionReward = {
  __typename?: 'CompetitionReward';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  platform: Scalars['String'];
  actionType: Scalars['String'];
  value: Scalars['Float'];
  socialLinkType?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export enum CompetitionState {
  Upcoming = 'UPCOMING',
  Running = 'RUNNING',
  Ended = 'ENDED'
}

/** Competition type. */
export enum CompetitionType {
  Registration = 'REGISTRATION',
  Onsale = 'ONSALE'
}

export type CompleteStripeOAuth = {
  __typename?: 'CompleteStripeOAuth';
  redirectUrl?: Maybe<Scalars['String']>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  /** Latitude */
  lat: Scalars['Float'];
  /** Longitude */
  lng: Scalars['Float'];
};

export type CoordinateInput = {
  /** Latitude */
  lat: Scalars['Float'];
  /** Longitude */
  lng: Scalars['Float'];
};

export type CreateAutomationInput = {
  name: Scalars['String'];
  automationType: AutomationType;
  templateId?: Maybe<Scalars['Float']>;
  subject?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  targetSource: ReportingFilterSource;
  targetSourceId?: Maybe<Scalars['String']>;
  targetReleaseId?: Maybe<Scalars['String']>;
  timeOfDay?: Maybe<Scalars['DateTime']>;
  templateName?: Maybe<Scalars['String']>;
};

export type CreateCompetitionInput = {
  competitionType: CompetitionType;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  referralRewardValue?: Maybe<Scalars['Int']>;
  bannerImage?: Maybe<Scalars['Upload']>;
  prizes?: Maybe<Array<CompetitionPrizeInput>>;
  promoter?: Maybe<Array<CompetitionPromoterInput>>;
};

/** Represents additional info required to host a digital event. */
export type CreateDigitalInfoInput = {
  /** The url that digital pass codes are appended to in order to access the digital event/membership. */
  url: Scalars['String'];
  /** The Postmark email template that is used to send digital pass codes. */
  emailTemplateId?: Maybe<Scalars['String']>;
  additionalEmailInfo?: Maybe<AdditionalEmailInfoInput>;
};

export type CreateEventAddonInput = {
  /** The event id that is associated with the addon. */
  eventId: Scalars['String'];
  /** The name of the addon. */
  name: Scalars['String'];
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Images for the ticket gallery */
  imageGallery?: Maybe<Array<ImageGalleryItemInput>>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  namingFields: Array<FormFieldInput>;
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
  insideFee?: Maybe<Scalars['Float']>;
  /** Whether hide QR code on ticket. */
  hideQRCode?: Maybe<Scalars['Boolean']>;
};

export type CreateEventDate = {
  id?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

/** Creates a new event. */
export type CreateEventInput = {
  /** The kind of event. */
  eventKind?: Maybe<EventKind>;
  /** Represents additional info required to host a digital event. */
  digitalInfo?: Maybe<CreateDigitalInfoInput>;
  /** Event season. */
  season?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  /** Event title. */
  title: Scalars['String'];
  logo?: Maybe<Scalars['Upload']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  hardCopyTicketBanner?: Maybe<Scalars['Upload']>;
  /** Event description. */
  description?: Maybe<Scalars['String']>;
  /** Event dates. */
  dates: Array<CreateEventDate>;
  /** Date at which tickets are viewable/printable from. */
  ticketsViewableFromDate?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Event featured status. */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Whether event is R-18. */
  isR18?: Maybe<Scalars['Boolean']>;
  /** Whether event is checking for vaccine passports on entry */
  checkForVaccinePass?: Maybe<Scalars['Boolean']>;
  /** Whether naming ticket is available. */
  canNameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether renaming ticket is available. */
  canRenameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether changing seats is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** Whether transfering ticket is available. */
  canTransferTicket?: Maybe<Scalars['Boolean']>;
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist?: Maybe<Scalars['Boolean']>;
  /** The start date of the presale registration. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the presale registration. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** Whether release codes are visible on the event information page */
  enableReleaseCodes?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  /** The payment gateways enabled for the event. */
  gateways?: Maybe<Array<Scalars['String']>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  importantNotice?: Maybe<Scalars['String']>;
  accessibility?: Maybe<Scalars['String']>;
  invoiceInformation?: Maybe<Scalars['String']>;
  presaleInformation?: Maybe<Scalars['String']>;
  emailNotification?: Maybe<Scalars['String']>;
  redirectURL?: Maybe<Scalars['String']>;
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGateInput>>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetailsInput>>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  gaVenue?: Maybe<CreateGaEventVenueInput>;
  ticketCover?: Maybe<TicketCoverInput>;
  listedReleaseOptions?: Maybe<ListedReleaseOptionsInput>;
  merchantReference?: Maybe<Scalars['String']>;
  pixelId?: Maybe<Scalars['String']>;
  hiddenFromPublic?: Maybe<Scalars['Boolean']>;
  enablePaymentPlans?: Maybe<Scalars['Boolean']>;
  paymentPlanScheduleOptions?: Maybe<Array<ScheduleOptionInput>>;
};

export type CreateEventMultiBuyPromotionInput = {
  name: Scalars['String'];
  buyQuantity: Scalars['Float'];
  getQuantity: Scalars['Float'];
  price: Scalars['Float'];
  /** The event id that is associated with the promotion. */
  eventId: Scalars['String'];
  buyTicketTypeId: Scalars['String'];
  getTicketTypeId: Scalars['String'];
};

export type CreateEventPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  eventId: Scalars['String'];
  type: EventPromoCodeType;
  ticketTypes?: Maybe<Array<Scalars['String']>>;
};

export type CreateGaEventVenueInput = {
  name?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  location?: Maybe<CoordinateInput>;
  timezone?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
};

export type CreateHistoryInput = {
  /** The reference name of the history item. */
  refName: HistoryReferenceType;
  /** The reference id of the history item. */
  refId: Scalars['String'];
  /** The description of the history item. */
  description: Scalars['String'];
};

export type CreateIntegrationInput = {
  type: IntegrationType;
  name: Scalars['String'];
  secretKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  wcPxPostSecretKey?: Maybe<Scalars['String']>;
  wcPxPostPublicKey?: Maybe<Scalars['String']>;
  webhookUrl?: Maybe<Scalars['String']>;
  transactionFeeType?: Maybe<FeeType>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType2?: Maybe<FeeType>;
  transactionFee2?: Maybe<Scalars['Float']>;
  paymentMethodType?: Maybe<Scalars['String']>;
  facebookTempToken?: Maybe<Scalars['String']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  createAccount?: Maybe<Scalars['Boolean']>;
};

export type CreateMembershipAddonInput = {
  /** The membership id that is associated with the addon. */
  membershipId: Scalars['String'];
  /** The name of the addon. */
  name: Scalars['String'];
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  namingFields: Array<FormFieldInput>;
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
};

export type CreateMembershipInput = {
  /** Name of the membership. */
  name: Scalars['String'];
  /** The kind of membership. */
  membershipKind: MembershipKind;
  /** Represents additional info required to host a digital membership. */
  digitalInfo?: Maybe<CreateDigitalInfoInput>;
  /** The previous memberships related to the membership. */
  previousMemberships?: Maybe<Array<Scalars['String']>>;
  /** The start date of the membership. */
  startDate: Scalars['DateTime'];
  /** The end date of the membership. */
  endDate: Scalars['DateTime'];
  /** The booking fee type of the membership. */
  bookingFeeType: FeeType;
  /** The booking fee for the membership. */
  bookingFee: Scalars['Float'];
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  /** The description of the membership. */
  description: Scalars['String'];
  thumbnail?: Maybe<Scalars['Upload']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  /** The payment gateways enabled for the membership. */
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Whether naming a membership is available. */
  canNameMembership?: Maybe<Scalars['Boolean']>;
  /** Whether renaming a membership is available. */
  canRenameMembership?: Maybe<Scalars['Boolean']>;
  /** Flag to determine if the membership is hidden from the public */
  hiddenFromPublic?: Maybe<Scalars['Boolean']>;
  /** Whether naming a membership is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the membership. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Whether the membership is ticketed. */
  isTicketed: Scalars['Boolean'];
  /** The active status of the membership. */
  isActive?: Maybe<Scalars['Boolean']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  importantNotice?: Maybe<Scalars['String']>;
  accessibility?: Maybe<Scalars['String']>;
  invoiceInformation?: Maybe<Scalars['String']>;
  showSchedule?: Maybe<Scalars['Boolean']>;
  emailNotification?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  enableAutoRenew?: Maybe<Scalars['Boolean']>;
  /** Whether individual event tickets can be transferred. */
  canTransferTickets?: Maybe<Scalars['Boolean']>;
  ticketCover?: Maybe<MembershipTicketCoverInput>;
  enablePaymentPlans?: Maybe<Scalars['Boolean']>;
  paymentPlanScheduleOptions?: Maybe<Array<ScheduleOptionInput>>;
};

export type CreateMembershipMultiBuyPromotionInput = {
  name: Scalars['String'];
  buyQuantity: Scalars['Float'];
  getQuantity: Scalars['Float'];
  price: Scalars['Float'];
  /** The membership id that is associated with the promotion. */
  membershipId: Scalars['String'];
  buyMembershipTypeId: Scalars['String'];
  getMembershipTypeId: Scalars['String'];
};

export type CreateMembershipPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  membershipId: Scalars['String'];
  type: MembershipPromoCodeType;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
};

export type CreateMembershipTypeInput = {
  /** The associated membership of the membership type. */
  membershipId: Scalars['String'];
  /** The kind of the membership type. */
  kind: MembershipTypeKind;
  /** Name of the membership type. */
  name: Scalars['String'];
  /** The ticket fee type. */
  ticketFeeType: FeeType;
  /** The number of membership tickets that must be purchased together in this set. */
  quantitySet?: Maybe<Scalars['Float']>;
  /** The ticket fee. */
  ticketFee: Scalars['Float'];
  /** Restrictions for the membership type. */
  description?: Maybe<Scalars['String']>;
  /** Restrictions for the membership type. */
  restrictions?: Maybe<Scalars['String']>;
  namingFields: Array<FormFieldInput>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  isGuestCard?: Maybe<Scalars['Boolean']>;
};

export type CreateOrderError = {
  message: Scalars['String'];
};

export type CreateOrderErrorUnion = TicketNotAvailableError;

export type CreateOrderInput = {
  /** Order type */
  orderType?: Maybe<OrderType>;
  /** Release id associated with the order. */
  releaseId?: Maybe<Scalars['String']>;
  /** Release password id associated with the order. */
  releasePasswordId?: Maybe<Scalars['String']>;
  /** Membership id associated with the order. */
  membershipId?: Maybe<Scalars['String']>;
  /** User id can be provided for back-office orders */
  userId?: Maybe<Scalars['String']>;
  /** List of the order's line items. */
  lineItems: Array<LineItemInput>;
  /** Seats.IO hold token. */
  holdToken?: Maybe<Scalars['String']>;
  /** Multi buy promotion id. */
  multiBuyId?: Maybe<Scalars['String']>;
  posId?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  referralCode?: Maybe<Scalars['String']>;
  transferFee?: Maybe<Scalars['Float']>;
  isCreatedFromRecoveredCart?: Maybe<Scalars['Boolean']>;
};

export type CreateOrganizationInput = {
  name: Scalars['String'];
  slug: Scalars['String'];
  address: AddressInput;
  email: Scalars['String'];
  phone: Scalars['String'];
  taxNumber?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  landingPage: LandingPage;
  showMemberships?: Maybe<Scalars['Boolean']>;
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  branding?: Maybe<BrandingInput>;
  marketing?: Maybe<MarketingInput>;
  helpLink?: Maybe<Scalars['String']>;
  customTaxRate?: Maybe<Scalars['Float']>;
  customTaxName?: Maybe<Scalars['String']>;
};

export type CreateOrganizationOutput = {
  __typename?: 'CreateOrganizationOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type CreatePaymentInput = {
  /** Payment gateway chosen by the customer (leave empty if paying with credits only). */
  gateway?: Maybe<Scalars['String']>;
  /** Whether the customer opts-in for marketing & promotions. */
  optInForMarketingPromotions: Scalars['Boolean'];
  /** Whether the customer receives a receipt by email. */
  skipOrderConfirmation?: Maybe<Scalars['Boolean']>;
  /** Whether the customer gets a hold order confirmation. */
  sendHoldOrderCustomerEmail?: Maybe<Scalars['Boolean']>;
  /** Whether the customer wants to initiate a reoccurring payment. */
  reoccurringPayment?: Maybe<Scalars['Boolean']>;
  pointRedemptionAmount?: Maybe<Scalars['Float']>;
  gatewayOptions?: Maybe<GatewayOptions>;
  customFields?: Maybe<Array<CustomFieldInput>>;
};

/** Represents the order creation result. */
export type CreatePaymentOutput = {
  __typename?: 'CreatePaymentOutput';
  /** The order. */
  order: Order;
  /** The payment creation result of the order. */
  result?: Maybe<PaymentResult>;
};

export type CreatePinPaymentCharge = {
  refId: Scalars['String'];
  cardToken: Scalars['String'];
};

export type CreatePointInput = {
  name?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type CreatePointItemInput = {
  pointId: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  isVisible: Scalars['Boolean'];
};

export type CreatePosInput = {
  eventId: Scalars['String'];
  releaseId: Scalars['String'];
  name: Scalars['String'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  paymentOptions: PosPaymentOptionsInput;
  deliveryOptions: Array<ShippingOption>;
  cashAmount?: Maybe<Scalars['Float']>;
  /** Whether receipts are turned on. */
  enableReceipts?: Maybe<Scalars['Boolean']>;
};

export type CreatePosUserInput = {
  eventId: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  accessCode: Scalars['String'];
};

export type CreatePurchaseRestrictionInput = {
  restrictedType: LineItemType;
  restrictedId: Scalars['String'];
  requiredEntities: Array<PurchaseRestrictionRequiredEntityInput>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  isAlwaysVisible?: Maybe<Scalars['Boolean']>;
  quantity: Scalars['Float'];
};

export type CreateRedirectUrlInput = {
  /** Type of entity this redirect is for. */
  refName: Scalars['String'];
  /** Reference entity ID. */
  refId: Scalars['String'];
  /** Full URL path. */
  redirectURL: Scalars['String'];
};

export type CreateReferralCampaignDto = {
  referralReleaseId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  referralOwnerRewardType: ReferralRewardType;
  referralOwnerRewardQuantityType: ReferralRewardQuantityType;
  referralOwnerRewardQuantity: Scalars['Float'];
  referralUserRewardType: ReferralRewardType;
  referralUserRewardQuantityType: ReferralRewardQuantityType;
  referralUserRewardQuantity: Scalars['Float'];
};

export type CreateReferralRewardsDto = {
  referralCampaignId: Scalars['String'];
  referralOwnerUserReferralId: Scalars['String'];
  referralUserId: Scalars['String'];
  orderId: Scalars['String'];
};

/** Creates a refund. */
export type CreateRefundInput = {
  /** Order id for which the refund will be created. */
  orderId: Scalars['String'];
  /** Desired amount of the refund. */
  amount: Scalars['Float'];
  /** Notes why the refund was created. */
  notes: Scalars['String'];
  /** Tickets to realease (will also release child tickets). */
  ticketIds?: Maybe<Array<Scalars['String']>>;
  /** Whether the refund is a manual one. */
  isManual?: Maybe<Scalars['Boolean']>;
  gateway?: Maybe<RefundGateway>;
  /** Whether to send a refund confirmation email. */
  sendEmail?: Maybe<Scalars['Boolean']>;
};

export type CreateReleaseInput = {
  event: Scalars['String'];
  /** Release name. */
  name: Scalars['String'];
  /** Release type. */
  type: ReleaseType;
  /** The slug for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  slug?: Maybe<Scalars['String']>;
  /** The password for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  password?: Maybe<Scalars['String']>;
  /** The start condition of the release. */
  startCondition: ReleaseStartCondition;
  /** The start date of the release. Pass null if the startCondition is set to other than `date`. */
  startConditionDate?: Maybe<Scalars['DateTime']>;
  /** The end condition of the release. */
  endCondition: ReleaseEndCondition;
  /** The quantity limit of the release. Pass null if the endCondition is set to other than `quantity`. */
  endConditionQuantity?: Maybe<Scalars['Float']>;
  /** The end date of the release. Pass null if the endCondition is set to other than `date`. */
  endConditionDate?: Maybe<Scalars['DateTime']>;
  /** The status of the release. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The zones of the release. */
  releaseZones: Array<ReleaseZoneInput>;
  /** The title of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleTitle?: Maybe<Scalars['String']>;
  presaleLogo?: Maybe<Scalars['Upload']>;
  presaleHeader?: Maybe<Scalars['Upload']>;
  /** The information of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleInformation?: Maybe<Scalars['String']>;
  /** The information that will be shown to customers on the ticketing page. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** Ask how did you heard about us during the purchasing flow */
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  /** Request purchasers  */
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  /** Are comps visible to public */
  allowPublicComp?: Maybe<Scalars['Boolean']>;
  /** Can this release use Windcaves Account2Account functionality */
  allowAccount2Account?: Maybe<Scalars['Boolean']>;
  /** Can customers create hold orders for this release */
  allowHoldOrders?: Maybe<Scalars['Boolean']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  releaseEventAddons?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<Array<Scalars['String']>>;
};

export type CreateReleaseZoneInput = {
  eventId: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Images for the ticket gallery */
  imageGallery?: Maybe<Array<ImageGalleryItemInput>>;
  capacity?: Maybe<Scalars['Int']>;
  admission: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};

export type CreateResaleTicketTypeInput = {
  /** The event id that is associated with the ticket type. */
  event: Scalars['String'];
  /** Ticket group name. */
  name: Scalars['String'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Price for v2 event */
  price?: Maybe<Scalars['Float']>;
  /** The ticket types that map to this resale ticket. */
  ticketTypes: Array<Scalars['String']>;
};

export type CreateScoutCommunicationInput = {
  name: Scalars['String'];
  purpose: ScoutPurpose;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  type: ScoutCommunicationType;
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  segmentId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  trackSource?: Maybe<ReportingFilterSource>;
  trackSourceId?: Maybe<Scalars['String']>;
  trackReleaseId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  shouldUpdate?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  subject?: Maybe<Scalars['String']>;
};

export type CreateScoutInput = {
  name: Scalars['String'];
  promoteTo: Array<FilterSelectionInput>;
  exclude?: Maybe<Array<FilterSelectionInput>>;
};

export type CreateSeasonInput = {
  /** Season name. */
  name: Scalars['String'];
  /** Season active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Season description. */
  description: Scalars['String'];
  thumbnail?: Maybe<Scalars['Upload']>;
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  displayOrder: Scalars['Float'];
};

export type CreateTagInput = {
  name: Scalars['String'];
};

export type CreateTemporaryPosUserInput = {
  eventId: Scalars['String'];
  fullName: Scalars['String'];
};

export type CreateTemporaryPosUserOutput = {
  __typename?: 'CreateTemporaryPosUserOutput';
  user: User;
  password: Scalars['String'];
  /** Access token to create a single temporary POS user */
  oneTimeAccessToken: Scalars['String'];
};

export type CreateTestEmailInput = {
  emails: Array<Scalars['String']>;
  templateId: Scalars['Float'];
  subject: Scalars['String'];
  communicationId: Scalars['String'];
};

export type CreateTicketTypeDate = {
  eventDateId: Scalars['String'];
  alternateEntryTime?: Maybe<Scalars['String']>;
};

export type CreateTicketTypeInput = {
  /** The event id that is associated with the ticket type. */
  event: Scalars['String'];
  /** The kind of the ticket type. */
  kind: TicketTypeKind;
  /** Whether the ticket type is grouped. */
  isGrouped?: Maybe<Scalars['Boolean']>;
  /** The amount of people in a group. Returns null if the ticket type is not grouped. */
  groupAmount?: Maybe<Scalars['Float']>;
  /** The number of tickets that must be purchased together in this set. */
  quantitySet?: Maybe<Scalars['Float']>;
  /** Ticket group name. */
  name: Scalars['String'];
  /** Event dates. */
  dates: Array<CreateTicketTypeDate>;
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Per ticket stadium levy ticket fee. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  /** Mark a ticket type as high demand */
  inHighDemand: Scalars['Boolean'];
  /** MMark a ticket type as sold out */
  soldOut: Scalars['Boolean'];
  /** Description of the ticket */
  description?: Maybe<Scalars['String']>;
  /** Restrictions for the ticket group. */
  restrictions?: Maybe<Scalars['String']>;
  /** Print at home information for the ticket group. */
  printAtHomeInformation?: Maybe<Scalars['String']>;
  namingFields: Array<FormFieldInput>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketBanner?: Maybe<Scalars['Upload']>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketImage?: Maybe<Scalars['Upload']>;
  /** Images for the ticket gallery */
  imageGallery?: Maybe<Array<ImageGalleryItemInput>>;
  quantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Price for v2 event */
  price?: Maybe<Scalars['Float']>;
  /** releaseZoneId for v2 event */
  releaseZoneId?: Maybe<Scalars['String']>;
  /** isActive for v2 event */
  isActive?: Maybe<Scalars['Boolean']>;
  /** isComplimentary for v2 event */
  isComplimentary?: Maybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  twoFactorAuthenticationSecret?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  /** Billing address provided by the user. */
  billingAddress?: Maybe<PartialAddressInput>;
  /** Shipping address provided by the user. */
  shippingAddress?: Maybe<PartialAddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  credits?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  instagramUsername?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Elevate users privileges from user to admin. */
  elevatePrivileges?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

export type CreateVenueInput = {
  seating: VenueSeating;
  /** Seats.IO chart id. */
  seatsChartKey?: Maybe<Scalars['String']>;
  accessibilityInfo?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  capacityUtilization?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  address: AddressInput;
  location?: Maybe<CoordinateInput>;
  zones?: Maybe<Array<ZoneInput>>;
  gates?: Maybe<Array<GateInput>>;
};

export type CurrentOrganizationOutput = {
  __typename?: 'CurrentOrganizationOutput';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Organization name. */
  name: Scalars['String'];
  /** Organization slug. */
  slug: Scalars['String'];
  /** Contact e-mail address. */
  email: Scalars['String'];
  /** Contact phone. */
  phone: Scalars['String'];
  /** Tax number */
  taxNumber?: Maybe<Scalars['String']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Address details. */
  address?: Maybe<Address>;
  /** Landing page settings. */
  landingPage: LandingPage;
  /** Show memberships in client navigation. */
  showMemberships: Scalars['Boolean'];
  /** Show memberships renewal banner. */
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  /** The support information of the organistion */
  supportInfo?: Maybe<Scalars['String']>;
  /** The privacy policy of the organistion */
  privacyPolicy?: Maybe<Scalars['String']>;
  /** The terms and conditions of the organistion */
  termsAndConditions?: Maybe<Scalars['String']>;
  /** Marketing settings. */
  marketing?: Maybe<Marketing>;
  /** Shipping labels. */
  shippingLabels?: Maybe<ShippingLabels>;
  /** Beta features. */
  features?: Maybe<Array<Scalars['String']>>;
  timezone: Scalars['String'];
  locale: Scalars['String'];
  helpLink?: Maybe<Scalars['String']>;
  ticketCoverPlatformFeePercentage?: Maybe<Scalars['Float']>;
  taxRate: Scalars['Float'];
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod: TaxCalculationMethod;
  defaultLanguage: Scalars['String'];
  customTaxRate?: Maybe<Scalars['Float']>;
  customTaxName?: Maybe<Scalars['String']>;
  platformChargeSettings?: Maybe<PlatformChargeSettings>;
  branding: OrganizationOutputBranding;
  singleEvent?: Maybe<Event>;
  shopify?: Maybe<ShopifyIntegration>;
  point?: Maybe<Point>;
  defaultI18nLanguage: Scalars['String'];
};

export type CustomerCompetitionReportingInput = {
  customerId: Scalars['String'];
};

export type CustomerReferralReportingInput = {
  customerId: Scalars['String'];
};

export type CustomerReportingOverviewArgs = {
  __typename?: 'CustomerReportingOverviewArgs';
  resold: PeReportStatistic;
  abandonCart: PeReportStatistic;
  abandonCartRecovered: PeReportStatistic;
  addons: PeReportStatistic;
  resaleMargin: PeReportStatistic;
  resaleFeeRevenue: PeReportStatistic;
  bookingFeeRevenue: PeReportStatistic;
  refund: PeReportStatistic;
  ticketProtectedNum: PeReportStatistic;
  ticketProtectedRevenue: PeReportStatistic;
  ordersPartPaid: PeReportStatistic;
  ticketsPartPaid: PeReportStatistic;
  addonsRevenue: PeReportStatistic;
  totalTicketsScanned: PeReportStatistic;
  totalTicketsScannedOut: PeReportStatistic;
  conversionRate: PeReportStatistic;
  newCustomers: PeReportStatistic;
  marketableAudience: PeReportStatistic;
  averageLTV: PeReportStatistic;
  eventVisitors: PeReportStatistic;
  totalCustomers: PeReportStatistic;
  repeatPurchasers: PeReportStatistic;
  returningCustomers: PeReportStatistic;
  essentialEmailsSent: PeReportStatistic;
  essentialEmailsSentRate: PeReportStatistic;
  totalRegistrations: PeReportStatistic;
  newCustomerRegistrations: PeReportStatistic;
  registrationRevenue: PeReportStatistic;
  registrationsNotConverted: PeReportStatistic;
  registrationCompetitionTotalRegistrations: PeReportStatistic;
  registrationCompetitionEventDetailsPageViews: PeReportStatistic;
  registrationCompetitionEventPageConversionRate: PeReportStatistic;
  registrationCompetitionRegistrationPageViews: PeReportStatistic;
  registrationCompetitionRegistrationPageConversionRate: PeReportStatistic;
  registrationCompetitionNewCustomers: PeReportStatistic;
  registrationCompetitionTotalEntries: PeReportStatistic;
  posSaleOverview: PosSaleOverview;
  posFinancialData: PosFinancialData;
  posFloatData: PosFloatData;
  onsaleCompetitionTotalRevenue: PeReportStatistic;
  onsaleCompetitionTotalCompetitionEntries: PeReportStatistic;
  onsaleCompetitionEventPageViews: PeReportStatistic;
  onsaleCompetitionEventPageConversionRate: PeReportStatistic;
  onsaleCompetitionTotalOrders: PeReportStatistic;
  onsaleCompetitionTicketsSold: PeReportStatistic;
  onsaleCompetitionNewCustomers: PeReportStatistic;
  registrationPromoterRegistrationsViaReferral: PeReportStatistic;
  registrationPromoterNewCustomers: PeReportStatistic;
  registrationPromoterReferralEntriesAwarded: PeReportStatistic;
  totalTicketsIssued: PeReportStatistic;
  totalTicketsSold: PeReportStatistic;
  totalCompsIssued: PeReportStatistic;
  totalOrders: PeReportStatistic;
  membershipsRenewed: PeReportStatistic;
  membershipSales: MembershipSales;
  membershipFinancial: MembershipFinancial;
  onsalePromoter: OnsalePromoter;
};

export type CustomField = {
  __typename?: 'CustomField';
  id?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type CustomFieldInput = {
  id?: Maybe<Scalars['String']>;
  fieldName: Scalars['String'];
  fieldValue: Scalars['String'];
};

export type CustomFieldOuput = {
  __typename?: 'CustomFieldOuput';
  id: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['Boolean'];
  /** Where the custom field input should appear. */
  targetArea: Array<Scalars['String']>;
};

export type CustomSaleOutput = {
  __typename?: 'CustomSaleOutput';
  data?: Maybe<Scalars['JSON']>;
};

export type DateRange = {
  __typename?: 'DateRange';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};


export type DefaultDateRange = {
  __typename?: 'DefaultDateRange';
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type DefaultDates = {
  __typename?: 'DefaultDates';
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
};

export type DeleteOneOutput = {
  __typename?: 'DeleteOneOutput';
  deletedId?: Maybe<Scalars['String']>;
};

export type DeleteScoutUserInput = {
  ids: Array<Scalars['String']>;
};

/** Represents additional info required to host a digital event/membership. */
export type DigitalInfo = {
  __typename?: 'DigitalInfo';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The url that digital pass codes are appended to in order to access the digital event/membership. */
  url: Scalars['String'];
  /** The Postmark email template that is used to send digital pass codes. */
  emailTemplateId?: Maybe<Scalars['String']>;
  /** Additional email info to be added in digital event/membership access emails. */
  additionalEmailInfoText?: Maybe<Scalars['String']>;
  /** Additional email info button URL to be added in digital event/membership access emails. */
  additionalEmailButtonUrl?: Maybe<Scalars['String']>;
  /** Additional email info button text to be added in digital event/membership access emails. */
  additionalEmailButtonText?: Maybe<Scalars['String']>;
  /** Additional email info HTML be added in digital event/membership access emails. */
  additionalEmailInfoHTML?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  membership?: Maybe<Membership>;
};

export type DigitalPassCodeStatusOutput = {
  __typename?: 'DigitalPassCodeStatusOutput';
  totalPassCodes: Scalars['Int'];
  remainingPassCodes: Scalars['Int'];
};

export enum DiscountType {
  Percentage = 'PERCENTAGE',
  Fixed = 'FIXED'
}

/** Duplicate an existing release. */
export type DuplicateSingleReleaseInput = {
  releaseId: Scalars['String'];
  newSlug: Scalars['String'];
  releaseType?: Maybe<Scalars['String']>;
};

export type EmailUsherComplimentaryInput = {
  ticketTypeId: Scalars['String'];
  ticketTypeName: Scalars['String'];
  releaseId: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The kind of event */
  eventKind: EventKind;
  /** Event season. */
  season?: Maybe<Season>;
  venue: Venue;
  releases: Array<Release>;
  /** Seats.io event key. */
  seatsEventKey?: Maybe<Scalars['String']>;
  /** Event title. */
  title: Scalars['String'];
  /** Event description. */
  description: Scalars['String'];
  /** Event dates. */
  dates: Array<EventDate>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Date at which tickets are viewable/printable after. */
  ticketsViewableFromDate?: Maybe<Scalars['DateTime']>;
  /** Event endDate has now lapsed, therefore it has ended. */
  hasEventEnded: Scalars['Boolean'];
  /** Event active status. */
  isActive: Scalars['Boolean'];
  /** Featured status. */
  isFeatured: Scalars['Boolean'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Whether event is R-18. */
  isR18: Scalars['Boolean'];
  /** Whether naming ticket is available. */
  canNameTicket: Scalars['Boolean'];
  /** Whether renaming ticket is available. */
  canRenameTicket: Scalars['Boolean'];
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist: Scalars['Boolean'];
  /** The date the presale registration will be visible on the event information page. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  /** The date the presale registration will no longer be visible on the event information page. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** Whether renaming ticket is available. */
  enableReleaseCodes: Scalars['Boolean'];
  /** Whether changing seats is available. */
  canChangeSeats: Scalars['Boolean'];
  /** Whether transfering ticket is available. */
  canTransferTicket: Scalars['Boolean'];
  /** Whether sell to other fans on the waitlist is enabled. */
  ticketResaleEnabled: Scalars['Boolean'];
  /** Whether to enable waitlist for tickets when sold out. */
  ticketResaleWaitlistEnabled: Scalars['Boolean'];
  /** The renaming fee for the event ticket. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptions>;
  thumbnail?: Maybe<ExtendedFile>;
  logo?: Maybe<ExtendedFile>;
  backgroundImage?: Maybe<ExtendedFile>;
  backgroundImageFullPage?: Maybe<ExtendedFile>;
  descriptionImage?: Maybe<ExtendedFile>;
  printBanner1?: Maybe<ExtendedFile>;
  printBanner2?: Maybe<ExtendedFile>;
  hardCopyTicketBanner?: Maybe<ExtendedFile>;
  /** The terms and conditions of the event */
  termsAndConditions?: Maybe<Scalars['String']>;
  /** Important notice section on the tickets */
  importantNotice?: Maybe<Scalars['String']>;
  /** The accessibility info of the event */
  accessibility?: Maybe<Scalars['String']>;
  /** The presale info of the event */
  presaleInformation?: Maybe<Scalars['String']>;
  /** The invoice info of the event */
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Allocated gates to venue zones */
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGate>>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetails>>;
  /** Featured status. */
  checkForVaccinePass: Scalars['Boolean'];
  /** Email address that gets sent a notifcation every time an order is completed */
  emailNotification?: Maybe<Scalars['String']>;
  /** Once a customer has finished purchasing they can be redirected back to another URL to continuing their purchsing journey */
  redirectURL?: Maybe<Scalars['String']>;
  /** The referral campaign associated with this event */
  referralCampaignId?: Maybe<Scalars['String']>;
  canUsePoints: Scalars['Boolean'];
  /** The ID of the Google Wallet Event Class for this event */
  googleWalletEventClassId?: Maybe<Scalars['String']>;
  lastTicketsViewableEmailsSentAt?: Maybe<Scalars['DateTime']>;
  ticketCover?: Maybe<EventTicketCover>;
  paymentPlanSettings?: Maybe<PaymentPlanSettings>;
  /** event level facebook pixel id */
  pixelId?: Maybe<Scalars['String']>;
  /** Whether to allow a ticket to be scanned in to multiple areas within a venue. */
  multiScanningEnabled: Scalars['Boolean'];
  hiddenFromPublic: Scalars['Boolean'];
  merchantReferenceResult?: Maybe<Scalars['String']>;
  hasPublicRelease: Scalars['Boolean'];
  venueAddress: Venue;
  ticketTypes: Array<TicketType>;
  gateways: Array<Integration>;
  addons: Array<EventAddon>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
  soldTicketsCountToday?: Maybe<Scalars['Float']>;
  soldTicketsCountYesterday?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  totalFaceValue?: Maybe<Scalars['Float']>;
  multiBuyPromotions: Array<EventMultiBuyPromotion>;
  tags: Array<Tag>;
  digitalPassCodesStatus?: Maybe<DigitalPassCodeStatusOutput>;
  digitalInfo?: Maybe<DigitalInfo>;
  defaultDateRange?: Maybe<DefaultDateRange>;
  hasPromoCodes: Scalars['Boolean'];
  isWaitlistAvailable: Scalars['Boolean'];
  listedRelease?: Maybe<Release>;
  ticketsIssued: Scalars['Int'];
  capacity?: Maybe<Scalars['Int']>;
  totalTicketsFaceValue: Scalars['Float'];
  taxRate: Scalars['Float'];
  customTaxRate: Scalars['Float'];
  taxCalculationMethod: TaxCalculationMethod;
  customFields: Array<CustomFieldOuput>;
};


export type EventTicketTypesArgs = {
  ticketTypeFilter?: Maybe<TicketTypeFilter>;
};

export enum EventAccessStatus {
  EventEnded = 'EVENT_ENDED',
  Denied = 'DENIED',
  Allowed = 'ALLOWED',
  AllowedAdminPreview = 'ALLOWED_ADMIN_PREVIEW'
}

export type EventAddon = {
  __typename?: 'EventAddon';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  namingFields: Array<FormField>;
  /** Image to be shown on the customer portal of the addon . Returns null if the not set. */
  addonImage?: Maybe<ExtendedFile>;
  /** Quantity allocated already */
  quantityAllocated: Scalars['Float'];
  maxPurchaseQuantity: Scalars['Float'];
  /** Quantity remaining via order created or canceled, does not take into account the order status */
  remaining: Scalars['Float'];
  /** Booking fee. */
  insideFee?: Maybe<Scalars['Float']>;
  /** Whether hide QR code on ticket. */
  hideQRCode: Scalars['Boolean'];
  scannableGateIds: Array<Scalars['String']>;
  /** Image gallery for ticket listing and sub-page. */
  imageGallery: Array<ImageGalleryItem>;
  purchaseRestrictions: Array<PurchaseRestriction>;
  purchaseRestrictionsHelpText: Array<Scalars['String']>;
  totalSold?: Maybe<Scalars['Int']>;
  addonAvailabilityStatus: TicketAvailabilityStatus;
};

export type EventChannelOutput = {
  __typename?: 'EventChannelOutput';
  id: Scalars['String'];
  label: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type EventConnection = {
  __typename?: 'EventConnection';
  pageInfo: PageInfo;
  edges: Array<EventEdge>;
  totalCount: Scalars['Int'];
};

/** Represents a date in an event. */
export type EventDate = {
  __typename?: 'EventDate';
  id: Scalars['ID'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor: Scalars['String'];
  node: Event;
};

export type EventForCompetitionReportingEntity = {
  __typename?: 'EventForCompetitionReportingEntity';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The kind of event */
  eventKind: EventKind;
  /** Event season. */
  season?: Maybe<Season>;
  venue: Venue;
  releases: Array<Release>;
  /** Seats.io event key. */
  seatsEventKey?: Maybe<Scalars['String']>;
  /** Event title. */
  title: Scalars['String'];
  /** Event description. */
  description: Scalars['String'];
  /** Event dates. */
  dates: Array<EventDate>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Date at which tickets are viewable/printable after. */
  ticketsViewableFromDate?: Maybe<Scalars['DateTime']>;
  /** Event endDate has now lapsed, therefore it has ended. */
  hasEventEnded: Scalars['Boolean'];
  /** Event active status. */
  isActive: Scalars['Boolean'];
  /** Featured status. */
  isFeatured: Scalars['Boolean'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Whether event is R-18. */
  isR18: Scalars['Boolean'];
  /** Whether naming ticket is available. */
  canNameTicket: Scalars['Boolean'];
  /** Whether renaming ticket is available. */
  canRenameTicket: Scalars['Boolean'];
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist: Scalars['Boolean'];
  /** The date the presale registration will be visible on the event information page. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  /** The date the presale registration will no longer be visible on the event information page. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** Whether renaming ticket is available. */
  enableReleaseCodes: Scalars['Boolean'];
  /** Whether changing seats is available. */
  canChangeSeats: Scalars['Boolean'];
  /** Whether transfering ticket is available. */
  canTransferTicket: Scalars['Boolean'];
  /** Whether sell to other fans on the waitlist is enabled. */
  ticketResaleEnabled: Scalars['Boolean'];
  /** Whether to enable waitlist for tickets when sold out. */
  ticketResaleWaitlistEnabled: Scalars['Boolean'];
  /** The renaming fee for the event ticket. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptions>;
  thumbnail?: Maybe<ExtendedFile>;
  logo?: Maybe<ExtendedFile>;
  backgroundImage?: Maybe<ExtendedFile>;
  backgroundImageFullPage?: Maybe<ExtendedFile>;
  descriptionImage?: Maybe<ExtendedFile>;
  printBanner1?: Maybe<ExtendedFile>;
  printBanner2?: Maybe<ExtendedFile>;
  hardCopyTicketBanner?: Maybe<ExtendedFile>;
  /** The terms and conditions of the event */
  termsAndConditions?: Maybe<Scalars['String']>;
  /** Important notice section on the tickets */
  importantNotice?: Maybe<Scalars['String']>;
  /** The accessibility info of the event */
  accessibility?: Maybe<Scalars['String']>;
  /** The presale info of the event */
  presaleInformation?: Maybe<Scalars['String']>;
  /** The invoice info of the event */
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Allocated gates to venue zones */
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGate>>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetails>>;
  /** Featured status. */
  checkForVaccinePass: Scalars['Boolean'];
  /** Email address that gets sent a notifcation every time an order is completed */
  emailNotification?: Maybe<Scalars['String']>;
  /** Once a customer has finished purchasing they can be redirected back to another URL to continuing their purchsing journey */
  redirectURL?: Maybe<Scalars['String']>;
  /** The referral campaign associated with this event */
  referralCampaignId?: Maybe<Scalars['String']>;
  canUsePoints: Scalars['Boolean'];
  /** The ID of the Google Wallet Event Class for this event */
  googleWalletEventClassId?: Maybe<Scalars['String']>;
  lastTicketsViewableEmailsSentAt?: Maybe<Scalars['DateTime']>;
  ticketCover?: Maybe<EventTicketCover>;
  paymentPlanSettings?: Maybe<PaymentPlanSettings>;
  /** event level facebook pixel id */
  pixelId?: Maybe<Scalars['String']>;
  /** Whether to allow a ticket to be scanned in to multiple areas within a venue. */
  multiScanningEnabled: Scalars['Boolean'];
  hiddenFromPublic: Scalars['Boolean'];
  merchantReferenceResult?: Maybe<Scalars['String']>;
  /** Indicates if the event had a public sales release */
  hasPublicRelease: Scalars['Boolean'];
  venueAddress: Venue;
  ticketTypes: Array<TicketType>;
  gateways: Array<Integration>;
  addons: Array<EventAddon>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
  soldTicketsCountToday?: Maybe<Scalars['Float']>;
  soldTicketsCountYesterday?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  totalFaceValue?: Maybe<Scalars['Float']>;
  multiBuyPromotions: Array<EventMultiBuyPromotion>;
  tags: Array<Tag>;
  digitalPassCodesStatus?: Maybe<DigitalPassCodeStatusOutput>;
  digitalInfo?: Maybe<DigitalInfo>;
  defaultDateRange?: Maybe<DefaultDateRange>;
  hasPromoCodes: Scalars['Boolean'];
  isWaitlistAvailable: Scalars['Boolean'];
  listedRelease?: Maybe<Release>;
  ticketsIssued: Scalars['Int'];
  capacity?: Maybe<Scalars['Int']>;
  totalTicketsFaceValue: Scalars['Float'];
  taxRate: Scalars['Float'];
  customTaxRate: Scalars['Float'];
  taxCalculationMethod: TaxCalculationMethod;
  customFields: Array<CustomFieldOuput>;
  /** Indicates if the event had or has a presale waitlist */
  hadOrHasPresaleWaitlist: Scalars['Boolean'];
};


export type EventForCompetitionReportingEntityTicketTypesArgs = {
  ticketTypeFilter?: Maybe<TicketTypeFilter>;
};

export type EventForecastOutput = {
  __typename?: 'EventForecastOutput';
  id: Scalars['String'];
  eventId: Scalars['String'];
  /** The zones and forecasted seat count attributes */
  zones?: Maybe<Array<EventForecastZoneOutput>>;
  /** The array of possible forecasting scenarios */
  scenarios?: Maybe<Array<ForecastScenario>>;
};

/** Object to hold the zoneId and the seat allocation forecasts */
export type EventForecastZone = {
  __typename?: 'EventForecastZone';
  /** The zone id of for the seats forecast. */
  zoneId?: Maybe<Scalars['String']>;
  /** How many seats are forecast to be comps */
  comps: Scalars['Int'];
  /** How many seats are forecast to be contingency */
  contingency: Scalars['Int'];
};

/** Object to hold the zoneId and the seat allocation forecasts */
export type EventForecastZoneOutput = {
  __typename?: 'EventForecastZoneOutput';
  /** The zone id of for the seats forecast. */
  zoneId?: Maybe<Scalars['String']>;
  /** How many seats are forecast to be comps */
  comps: Scalars['Int'];
  /** How many seats are forecast to be contingency */
  contingency: Scalars['Int'];
  /** Sales statistics if available */
  sales?: Maybe<FinancialSales>;
};

export type EventInformation = {
  __typename?: 'EventInformation';
  id: Scalars['String'];
  title: Scalars['String'];
  dates: Array<EventDate>;
  location?: Maybe<Scalars['String']>;
  hasEventEnded?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<ExtendedFile>;
  isSeated: Scalars['Boolean'];
  hasInsideFee: Scalars['Boolean'];
  hasRegistration: Scalars['Boolean'];
  hasOnsaleCompetition: Scalars['Boolean'];
  hasRegistrationCompetition: Scalars['Boolean'];
};

/** Ticket kind for ticket type. */
export enum EventKind {
  Standard = 'STANDARD',
  Digital = 'DIGITAL'
}

export type EventLayoutOutput = {
  __typename?: 'EventLayoutOutput';
  event?: Maybe<EventInformation>;
  defaultDateRange: DateRange;
};

export type EventMultiBuyPromotion = {
  __typename?: 'EventMultiBuyPromotion';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  buyQuantity: Scalars['Float'];
  getQuantity: Scalars['Float'];
  price: Scalars['Float'];
  enabled: Scalars['Boolean'];
  buyTicketType: TicketType;
  getTicketType: TicketType;
  used: Scalars['Float'];
};

export type EventOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
  startDate?: Maybe<OrderByDirection>;
  endDate?: Maybe<OrderByDirection>;
  isFeatured?: Maybe<OrderByDirection>;
};

export type EventOrdersOnlineOutput = {
  __typename?: 'EventOrdersOnlineOutput';
  totalCount: Scalars['Float'];
  baseTickets: Scalars['Float'];
  ticketFees: Scalars['Float'];
  bookingFees: Scalars['Float'];
  transactionFees: Scalars['Float'];
  deliveryFees: Scalars['Float'];
  refundedAmount: Scalars['Float'];
  addons: Scalars['Float'];
  creditsUsed: Scalars['Float'];
  orderIdsOnline: Array<OrderIdsOnlineOutput>;
};

export type EventPromoCode = {
  __typename?: 'EventPromoCode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  enabled: Scalars['Boolean'];
  /** The type of the promotion which determines on which items the promotion will be applied. */
  type: EventPromoCodeType;
  /** Ticket type ids for promo code. */
  ticketTypes: Array<Scalars['String']>;
  /** The total amount of usage of the promotion. */
  used?: Maybe<Scalars['Int']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Int']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
};

export enum EventPromoCodeType {
  EventAndAddons = 'EVENT_AND_ADDONS',
  Event = 'EVENT',
  Addons = 'ADDONS'
}

export type EventsByIdInput = {
  eventIds: Array<Scalars['String']>;
};

export type EventSocialPage = {
  __typename?: 'EventSocialPage';
  name: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type EventSocialPageInput = {
  name: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};

export type EventsWithAccessControlInput = {
  eventId: Scalars['String'];
  releaseId?: Maybe<Scalars['String']>;
  releaseSlug?: Maybe<Scalars['String']>;
};

export type EventsWithAccessControlOutput = {
  __typename?: 'EventsWithAccessControlOutput';
  event: Event;
  release?: Maybe<Release>;
  isAdmin: Scalars['Boolean'];
  accessType?: Maybe<EventAccessStatus>;
};

export type EventTicketCover = {
  __typename?: 'EventTicketCover';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Event that the ticket cover is configured for */
  event: Event;
  enabledAt?: Maybe<Scalars['DateTime']>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Platform fee as percentage of ticket price */
  platformFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
  /** Total fee as percentage of ticket price */
  totalFeePercentage: Scalars['Float'];
  /** Is ticket cover enabled */
  isEnabled: Scalars['Boolean'];
};

export type EventWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Event featured status. */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  pos?: Maybe<Scalars['Boolean']>;
  seasonId?: Maybe<Scalars['String']>;
  /**
   * If this is set to true,
   *     then we will left join release and check whether this event contains a outlet release.
   */
  isOutlet?: Maybe<Scalars['Boolean']>;
  seating?: Maybe<VenueSeating>;
  includeHidden?: Maybe<Scalars['Boolean']>;
};

export type ExtendedFile = {
  __typename?: 'ExtendedFile';
  storage: StorageType;
  directory: Scalars['String'];
  fileName: Scalars['String'];
  originalFileName?: Maybe<Scalars['String']>;
  metaData?: Maybe<MetaData>;
};

export type FacebookPageListsInput = {
  token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type FaceValueOverviewOutput = {
  __typename?: 'FaceValueOverviewOutput';
  totalFaceValue: Scalars['Float'];
  avgOrderItemsCount: Scalars['Float'];
};

export type FailedImportItem = {
  __typename?: 'FailedImportItem';
  index: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
};

export type FansWouldPay = {
  __typename?: 'FansWouldPay';
  mean: Scalars['Float'];
  median: Scalars['Float'];
};

/** The valid values for fee types. */
export enum FeeType {
  FlatRate = 'FlatRate',
  Percentage = 'Percentage'
}

export type FilterArgs = {
  __typename?: 'FilterArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
};

export type FilterSelection = {
  __typename?: 'FilterSelection';
  userTagIds?: Maybe<Array<Scalars['String']>>;
  seasonIds?: Maybe<Array<Scalars['String']>>;
  eventTypes?: Maybe<Array<ScoutEventType>>;
  regions?: Maybe<Array<Scalars['String']>>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  eventIds?: Maybe<Array<Scalars['String']>>;
  eventTitlesForWaitlist?: Maybe<Array<Scalars['String']>>;
  totalEventsPurchasedTo?: Maybe<Scalars['Float']>;
  ticketTypeKinds?: Maybe<Array<TicketTypeKind>>;
  zones?: Maybe<Array<Scalars['String']>>;
  releaseIds?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Gender>>;
  minAge?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
};

export type FilterSelectionInput = {
  userTagIds?: Maybe<Array<Scalars['String']>>;
  seasonIds?: Maybe<Array<Scalars['String']>>;
  eventTypes?: Maybe<Array<ScoutEventType>>;
  regions?: Maybe<Array<Scalars['String']>>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  eventIds?: Maybe<Array<Scalars['String']>>;
  eventTitlesForWaitlist?: Maybe<Array<Scalars['String']>>;
  totalEventsPurchasedTo?: Maybe<Scalars['Float']>;
  ticketTypeKinds?: Maybe<Array<TicketTypeKind>>;
  zones?: Maybe<Array<Scalars['String']>>;
  releaseIds?: Maybe<Array<Scalars['String']>>;
  gender?: Maybe<Array<Gender>>;
  minAge?: Maybe<Scalars['Float']>;
  maxAge?: Maybe<Scalars['Float']>;
};

export type FinancialGatewayGraphOutput = {
  __typename?: 'FinancialGatewayGraphOutput';
  transactions: Scalars['Float'];
  integrations: Array<FinancialGraphIntegration>;
};

export type FinancialGraphIntegration = {
  __typename?: 'FinancialGraphIntegration';
  name: Scalars['String'];
  transactions: Scalars['Float'];
};

export type FinancialOverviewOutput = {
  __typename?: 'FinancialOverviewOutput';
  netSales: Scalars['Float'];
  discounts: Scalars['Float'];
  avgOrderAmount: Scalars['Float'];
  avgOrderItemsCount: Scalars['Float'];
  resaleFees: Scalars['Float'];
  ticketCoverOrderTotal: Scalars['Float'];
  ticketCoverRevenue: Scalars['Float'];
  ticketCoverMargin: Scalars['Float'];
  ticketCoverCost: Scalars['Float'];
  resaleMargin: Scalars['Float'];
  planTotalPaid: Scalars['Float'];
  planTotalOutstanding: Scalars['Float'];
};

export type FinancialReportingOverviewOutput = {
  __typename?: 'FinancialReportingOverviewOutput';
  financial: Array<PeReportStatistic>;
};

export type FinancialSales = {
  __typename?: 'FinancialSales';
  /** This is the sourceId for seasons filter */
  zoneId: Scalars['String'];
  zoneName: Scalars['String'];
  capacity: Scalars['String'];
  available: Scalars['String'];
  availablePercentage?: Maybe<Scalars['Float']>;
  sellableCapacity: Scalars['String'];
  totalNotForSaleCount: Scalars['Float'];
  totalNotForSalePercentage?: Maybe<Scalars['Float']>;
  /** This is the sum of membershipRenewalHolds and holdByMembershipCount */
  allMembershipHolds: Scalars['Float'];
  allMembershipHoldsPercentage?: Maybe<Scalars['Float']>;
  totalMembershipTicketCount: Scalars['Float'];
  totalMembershipTicketPercentage?: Maybe<Scalars['Float']>;
  types: Array<FinancialSalesType>;
  sourceId?: Maybe<Scalars['String']>;
  sourceName?: Maybe<Scalars['String']>;
  issued?: Maybe<Scalars['Float']>;
  ticketIssued?: Maybe<Scalars['Float']>;
  ticketHolds?: Maybe<Scalars['Float']>;
  partPaidTickets?: Maybe<Scalars['Float']>;
  partPaidTicketsPercentage?: Maybe<Scalars['Float']>;
  partPaidMemberships?: Maybe<Scalars['Float']>;
  partPaidMembershipsPercentage?: Maybe<Scalars['Float']>;
  totalHoldsPercentage?: Maybe<Scalars['Float']>;
  transfersPending?: Maybe<Scalars['Float']>;
  transfers?: Maybe<Scalars['Float']>;
  comps?: Maybe<Scalars['Float']>;
  compsPercentage?: Maybe<Scalars['Float']>;
  ticketSold?: Maybe<Scalars['Float']>;
  ticketsRefunded?: Maybe<Scalars['Float']>;
  ticketSoldPercentage: Scalars['String'];
  baseRevenue?: Maybe<Scalars['Float']>;
  partPaidRevenue?: Maybe<Scalars['Float']>;
  refunds?: Maybe<Scalars['Float']>;
  totalBookingFee?: Maybe<Scalars['Float']>;
  totalWithBookingFees?: Maybe<Scalars['Float']>;
  buyerTransferFees?: Maybe<Scalars['Float']>;
  sellerTransferFees?: Maybe<Scalars['Float']>;
  allHolds?: Maybe<Scalars['Float']>;
  isAdmissionZone?: Maybe<Scalars['Boolean']>;
  venueCapacity: Scalars['Float'];
  membershipRenewalHolds?: Maybe<Scalars['Float']>;
  membershipRenewalHoldsPercentage?: Maybe<Scalars['Float']>;
  holdByMembershipCount?: Maybe<Scalars['Float']>;
  holdByMembershipCountPercentage?: Maybe<Scalars['Float']>;
  channelHolds: Scalars['Int'];
};

export type FinancialSalesType = {
  __typename?: 'FinancialSalesType';
  name: Scalars['String'];
  /** This is the sum of ticketIssued and membershipTicketCount */
  issued: Scalars['Float'];
  ticketIssued: Scalars['Float'];
  ticketHolds: Scalars['Float'];
  partPaidTickets: Scalars['Float'];
  partPaidMemberships: Scalars['Float'];
  posTickets?: Maybe<Scalars['Float']>;
  transfersPending: Scalars['Float'];
  transfers: Scalars['Float'];
  comps: Scalars['Float'];
  ticketSold: Scalars['Float'];
  ticketsRefunded: Scalars['Float'];
  baseRevenue: Scalars['Float'];
  partPaidRevenue: Scalars['Float'];
  refunds: Scalars['Float'];
  channel?: Maybe<Scalars['String']>;
  release?: Maybe<Scalars['String']>;
  totalWithBookingFees: Scalars['Float'];
  available?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['String']>;
  /**
   * This value should only exist on FinancialSalesType when 
   *     the filter is the seasons filter and the source ID is all memberships. 
   *     It should only exist on the zone level.
   */
  membershipRenewalHolds?: Maybe<Scalars['Float']>;
  holdByMembershipCount?: Maybe<Scalars['Float']>;
  /** This is the sum of membershipRenewalHolds and holdByMembershipCount */
  allMembershipHolds?: Maybe<Scalars['Float']>;
  /**
   * Ticket type price is a range since the price can be different for different releases and sections.
   * If the price is the same for all releases and sections, the array will only have one value.
   * Otherwise, the array will have two values, the first being the lowest price and the second being the highest price.
   */
  ticketTypePriceRange?: Maybe<Array<Scalars['Float']>>;
  totalBookingFee: Scalars['Float'];
  buyerTransferFees: Scalars['Float'];
  sellerTransferFees: Scalars['Float'];
  totalDiscounts?: Maybe<Scalars['Float']>;
  discounts?: Maybe<Scalars['Float']>;
  /**
   * Booking fee is a range when the type is percentage and ticket type fee is a range.
   * If the price only has one value, the array will only have one value.
   * Otherwise, the array will have two values, the first being the lowest price and the second being the highest price.
   */
  ticketTypeBookingFeeRange?: Maybe<Array<Scalars['Float']>>;
  totalMembershipTicketCount?: Maybe<Scalars['Float']>;
  isCompTicketType?: Maybe<Scalars['Boolean']>;
  isMembership?: Maybe<Scalars['Boolean']>;
  /** Only defined on zone level for seasons filter  */
  isTotalAddon?: Maybe<Scalars['Boolean']>;
  /** Only defined on zone level for seasons filter  */
  isAdmissionZone?: Maybe<Scalars['Boolean']>;
  channelHolds?: Maybe<Scalars['Int']>;
};

export type FindAutomationsInput = {
  isActive: Scalars['Boolean'];
  automationType?: Maybe<Scalars['String']>;
};

export type FindOrderIdForTicketOutput = {
  __typename?: 'findOrderIdForTicketOutput';
  /** Order Id */
  orderId: Scalars['String'];
};

export type FindScoutsInput = {
  isActive?: Maybe<Scalars['Boolean']>;
};

export type ForecastScenario = {
  __typename?: 'ForecastScenario';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The zones and forecasted seat count attributes */
  zones?: Maybe<Array<ForecastScenarioZone>>;
};

/** Object to hold the zoneId and the seat allocation forecasts */
export type ForecastScenarioZone = {
  __typename?: 'ForecastScenarioZone';
  /** The zone id of for the seats forecast. */
  zoneId?: Maybe<Scalars['String']>;
  /** Array of ticket type ids and the forecast cost and spilt of ticket sales */
  ticketTypes: Array<ScenarioZoneTicketType>;
};

export type ForecastZoneInput = {
  /** The zone related to this input */
  zoneId: Scalars['String'];
  /** How many seats are forecast to be comped. */
  comps: Scalars['Float'];
  /** How many seats are forecast to be comped. */
  contingency: Scalars['Float'];
};

export type FormField = {
  __typename?: 'FormField';
  field: Scalars['String'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
};

export type FormFieldInput = {
  field: Scalars['String'];
  isRequired?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  isCustom?: Maybe<Scalars['Boolean']>;
};

export type GalleryImage = {
  __typename?: 'GalleryImage';
  src?: Maybe<Scalars['String']>;
  metaData?: Maybe<MetaData>;
  aspectRatio?: Maybe<Scalars['Float']>;
};

export type GalleryImageInput = {
  src?: Maybe<Scalars['String']>;
  metaData?: Maybe<MetaDataInput>;
  aspectRatio?: Maybe<Scalars['Float']>;
};

export type Gate = {
  __typename?: 'Gate';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

/** The gate belonging to a venue */
export type GateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GatewayOptions = {
  /** A unique id to specify a variation for a payment gateway. */
  variantId?: Maybe<Scalars['String']>;
  isBNPL?: Maybe<Scalars['Boolean']>;
};

/** Amount remaining that can be automatically refunded through the payment gateway */
export type GatewayRefundableAmount = {
  __typename?: 'GatewayRefundableAmount';
  integrationName: Scalars['String'];
  /** The amount that has already been refunded through this gateway. */
  refundedAmount: Scalars['Float'];
  /** The amount that is availble to be refunded automatically through the gateway  */
  refundableAmount: Scalars['Float'];
};

/** The valid values for gender. */
export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other'
}

export type GenderBreakDownData = {
  __typename?: 'GenderBreakDownData';
  male: Scalars['Int'];
  female: Scalars['Int'];
  other: Scalars['Int'];
};

export type GenderBreakDownOutput = {
  __typename?: 'GenderBreakDownOutput';
  reportData: GenderBreakDownData;
  reportType: ReportType;
  shouldHide: Scalars['Boolean'];
};

export type GetHistoryByCustomerIdInput = {
  /** An array of the reference name of the history item. */
  refName?: Maybe<Array<Scalars['String']>>;
  /** Id of order. Used when looking at a customers order */
  orderId?: Maybe<Scalars['String']>;
  /** Id of customers. Not the id of admins. */
  userId?: Maybe<Scalars['String']>;
};

export type GetUserEntriesInput = {
  competitionId: Scalars['String'];
  userId: Scalars['String'];
};

export type GoogleAnalyticsReportingFilterInput = {
  sourceId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  type: GoogleAnalyticsReportType;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

/** Google Analytics report type */
export enum GoogleAnalyticsReportType {
  Device = 'DEVICE',
  TrafficSource = 'TRAFFIC_SOURCE',
  Gender = 'GENDER',
  Age = 'AGE'
}

export type GrantCompetitionRewardInput = {
  competitionId: Scalars['String'];
  userId: Scalars['String'];
  platform: Scalars['String'];
  actionType: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
};

export type GrantRewardOutput = {
  __typename?: 'GrantRewardOutput';
  platform: Scalars['String'];
  actionType: Scalars['String'];
  value: Scalars['Int'];
};

export type GraphDateItem = {
  __typename?: 'GraphDateItem';
  date: Scalars['DateTime'];
  items: Array<GraphItem>;
};

export enum GraphFilterType {
  Overall = 'OVERALL',
  Tickets = 'TICKETS',
  AddOns = 'ADD_ONS',
  Memberships = 'MEMBERSHIPS',
  Package = 'PACKAGE'
}

export type GraphItem = {
  __typename?: 'GraphItem';
  key?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  value: Scalars['Float'];
};

export type GraphOutput = {
  __typename?: 'GraphOutput';
  dates: Array<GraphDateItem>;
};

export type GraphRangeItem = {
  __typename?: 'GraphRangeItem';
  start: Scalars['String'];
  end: Scalars['String'];
  value: Scalars['Float'];
};

export type GraphTextItem = {
  __typename?: 'GraphTextItem';
  identifier: Scalars['String'];
  description: Scalars['String'];
  items: Array<GraphItem>;
};

/** Grey scale options. */
export enum GreyScale {
  Warm = 'Warm',
  Neutral = 'Neutral',
  Cool = 'Cool'
}

export type HandleInstagramCodeInput = {
  code: Scalars['String'];
  url: Scalars['String'];
};

export type HeardAboutUsBreakDownOutput = {
  __typename?: 'HeardAboutUsBreakDownOutput';
  heardAboutUs?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

/** Heard about us type */
export enum HeardAboutUsType {
  WordOfMouth = 'WordOfMouth',
  Radio = 'Radio',
  Billboard = 'Billboard',
  SocialMedia = 'SocialMedia',
  Email = 'Email',
  Other = 'Other',
  InternetSearch = 'InternetSearch'
}

export type HighestValueCustomersOutput = {
  __typename?: 'HighestValueCustomersOutput';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Represents information about an history item. */
export type History = {
  __typename?: 'History';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  refName: Scalars['String'];
  /** The user who make this history, should get this value from @CurrentUser */
  user?: Maybe<User>;
  /** History item description. */
  description: Scalars['String'];
  scoutCommunication?: Maybe<Scout>;
  order?: Maybe<Order>;
  ticket?: Maybe<Ticket>;
};

export type HistoryConnection = {
  __typename?: 'HistoryConnection';
  pageInfo: PageInfo;
  edges: Array<HistoryEdge>;
  totalCount: Scalars['Int'];
};

export type HistoryEdge = {
  __typename?: 'HistoryEdge';
  cursor: Scalars['String'];
  node: History;
};

export type HistoryOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
};

/** The valid reference types for history items. */
export enum HistoryReferenceType {
  Order = 'Order',
  User = 'User',
  ScoutUser = 'ScoutUser',
  Scan = 'Scan',
  Competition = 'Competition'
}

export type HoldBestAvailableSeatsInput = {
  holdToken: Scalars['String'];
  quantity: Scalars['Int'];
  categories: Array<Scalars['Int']>;
};

export type HoldBestAvailableSeatsOutput = {
  __typename?: 'HoldBestAvailableSeatsOutput';
  objects: Array<Scalars['String']>;
  nextToEachOther: Scalars['Boolean'];
};

export type I18nOutput = {
  __typename?: 'I18nOutput';
  data: Scalars['JSON'];
};

/** Image gallery item */
export type ImageGalleryItem = {
  __typename?: 'ImageGalleryItem';
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  original?: Maybe<GalleryImage>;
  cropped?: Maybe<GalleryImage>;
};

/** Image gallery item */
export type ImageGalleryItemInput = {
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  original?: Maybe<GalleryImageInput>;
  cropped?: Maybe<GalleryImageInput>;
};

export type ImportInput = {
  file: Scalars['Upload'];
};

export type ImportOutput = {
  __typename?: 'ImportOutput';
  /** Returns information about failed lines. */
  failed: Array<FailedImportItem>;
};

/** Import user item */
export type ImportUserItem = {
  __typename?: 'ImportUserItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  processedAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  /** import user item status */
  status?: Maybe<ImportUserItemStatus>;
};

export enum ImportUserItemStatus {
  Success = 'Success',
  Failed = 'Failed'
}

export type InitScout = {
  __typename?: 'InitScout';
  seasons: Array<Season>;
  membershipTypes: Array<MembershipType>;
  events: Array<Event>;
  userTags: Array<Tag>;
  zones: Array<Zone>;
};

/** Inside fee and range. */
export type InsideFeeDetails = {
  __typename?: 'InsideFeeDetails';
  priceRangeFrom: Scalars['Float'];
  priceRangeTo: Scalars['Float'];
  insideFee: Scalars['Float'];
};

/** Inside fee and range. */
export type InsideFeeDetailsInput = {
  priceRangeFrom: Scalars['Float'];
  priceRangeTo: Scalars['Float'];
  insideFee: Scalars['Float'];
};

export type InsightsOverviewOutput = {
  __typename?: 'InsightsOverviewOutput';
  customers?: Maybe<Scalars['Float']>;
  addressableAudience?: Maybe<Scalars['Float']>;
  customerValue?: Maybe<Scalars['Float']>;
  numberOfOrders?: Maybe<Scalars['Float']>;
  repeatPurchasers?: Maybe<Scalars['Float']>;
  referralLinksCreated?: Maybe<Scalars['Float']>;
  newReferredCustomers?: Maybe<Scalars['Float']>;
  ordersPerUser?: Maybe<Scalars['Float']>;
  ticketsCount?: Maybe<Scalars['Float']>;
  netSales?: Maybe<Scalars['Float']>;
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Integration type */
  type: IntegrationType;
  /** Integration name */
  name: Scalars['String'];
  /** External public key (can be used in customer frontend) */
  publicKey?: Maybe<Scalars['String']>;
  /** Webhook URL to point integration to */
  webhookUrl?: Maybe<Scalars['String']>;
  /** Transaction fee type. */
  transactionFeeType?: Maybe<FeeType>;
  /** Transaction fee. */
  transactionFee?: Maybe<Scalars['Float']>;
  /** Transaction fee type(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFeeType2?: Maybe<FeeType>;
  /** Transaction fee(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFee2?: Maybe<Scalars['Float']>;
  /** If payment gateways have different option types this field is used to specify which one to force */
  paymentMethodType?: Maybe<Scalars['String']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
  /** Internal API key (should not be shared) */
  secretKey?: Maybe<Scalars['String']>;
  /** External secret key (should not be shared) */
  apiKey?: Maybe<Scalars['String']>;
  /** External secret key for Windcave PxPost. */
  wcPxPostSecretKey?: Maybe<Scalars['String']>;
  /** External public key for Windcave PxPost. */
  wcPxPostPublicKey?: Maybe<Scalars['String']>;
};

/** Integration group. */
export enum IntegrationGroup {
  Payment = 'PAYMENT',
  Ecommerce = 'ECOMMERCE',
  Crm = 'CRM',
  Marketing = 'MARKETING',
  Social = 'SOCIAL',
  Accounting = 'ACCOUNTING'
}

/** Integration type. */
export enum IntegrationType {
  PaymentStripe = 'PAYMENT_STRIPE',
  PaymentWindcave = 'PAYMENT_WINDCAVE',
  PaymentLaybuy = 'PAYMENT_LAYBUY',
  PaymentAfterpay = 'PAYMENT_AFTERPAY',
  PaymentMpaisa = 'PAYMENT_MPAISA',
  PaymentPin = 'PAYMENT_PIN',
  EcommerceShopify = 'ECOMMERCE_SHOPIFY',
  ReportingShopify = 'REPORTING_SHOPIFY',
  CrmKore = 'CRM_KORE',
  CrmCustom = 'CRM_CUSTOM',
  CrmUssegment = 'CRM_USSEGMENT',
  CrmAdobe = 'CRM_ADOBE',
  MarketingDatorama = 'MARKETING_DATORAMA',
  MarketingMailchimp = 'MARKETING_MAILCHIMP',
  MarketingMessageMedia = 'MARKETING_MESSAGE_MEDIA',
  SocialInstagram = 'SOCIAL_INSTAGRAM',
  AccountingXero = 'ACCOUNTING_XERO'
}

export type IntegrationWhereInput = {
  group?: Maybe<IntegrationGroup>;
};

export type JoinWaitlistInput = {
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  usableForMarketing: Scalars['Boolean'];
  eventId: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  userAcquisition?: Maybe<UserAcquisition>;
  customFields?: Maybe<Array<CustomFieldInput>>;
};


/** Landing page options. */
export enum LandingPage {
  MultiEvent = 'MultiEvent',
  SingleEvent = 'SingleEvent',
  Tours = 'Tours'
}

/** Order line items */
export type LineItem = {
  __typename?: 'LineItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: LineItemType;
  /** Ticket type. Only available for ticket type line items. */
  ticketType?: Maybe<TicketType>;
  /** The release associated with the line item. Returns null if the line item is not bound to a release. */
  release?: Maybe<Release>;
  /** Membership type. Only available for membership type line items. */
  membershipType?: Maybe<MembershipType>;
  /** Event addon. Only available for addon line items. */
  eventAddon?: Maybe<EventAddon>;
  /** Membership addon. Only available for addon line items. */
  membershipAddon?: Maybe<MembershipAddon>;
  /** Seat label. Only available for ticket type line items. */
  seatLabel?: Maybe<Scalars['String']>;
  /** Seat zone. Only available for ticket type line items. */
  seatZone?: Maybe<Scalars['String']>;
  seatZoneEntity?: Maybe<Zone>;
  /** Seat section. Only available for ticket type line items. */
  seatSection?: Maybe<Scalars['String']>;
  seatSectionEntity?: Maybe<Section>;
  /** Seat type. Only available for seated events. */
  seatType?: Maybe<SeatType>;
  /** Seat tags. Only available for ticket type line items. */
  seatTags?: Maybe<Array<Scalars['String']>>;
  /** Item name. */
  name: Scalars['String'];
  /** Price of the line item with discounts applied. */
  price: Scalars['Float'];
  /** This is the stadium levy fee amount for the line item. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  /** Original price of the line item without any discounts applied. */
  originalPrice: Scalars['Float'];
  /** Item quantity. */
  quantity: Scalars['Float'];
  /** Ticket fee type. */
  ticketFeeType?: Maybe<FeeType>;
  /** Item ticket fee. */
  ticketFee?: Maybe<Scalars['Float']>;
  /** Tax rate applied to the item. */
  taxRate: Scalars['Float'];
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod?: Maybe<TaxCalculationMethod>;
  isComp: Scalars['Boolean'];
  pointItem?: Maybe<PointItem>;
  /** Ticket fee amount (for 1 ticket) */
  ticketFeeAmount?: Maybe<Scalars['Float']>;
  /** Total per unit amount: price + ticketFee + levyFee */
  totalPerUnit: Scalars['Float'];
  /** Total amount: quantity * (price + ticketFee + levyFee) */
  total: Scalars['Float'];
  taxAmount: Scalars['Float'];
  customTaxRate?: Maybe<Scalars['Float']>;
  /** The amount of custom taxes per item */
  customTaxAmount?: Maybe<Scalars['Float']>;
};

/** Action to perform on line item */
export enum LineItemAction {
  Delete = 'DELETE',
  Update = 'UPDATE',
  Complete = 'COMPLETE'
}

export type LineItemActionInput = {
  /** ID of the line item in the order. */
  id: Scalars['String'];
  /** Action to perform on the line item. */
  action: LineItemAction;
  /** New ticket or membership type ID (only relevant for `UPDATE`). */
  ticketOrMembershipTypeId?: Maybe<Scalars['String']>;
};

export type LineItemConnection = {
  __typename?: 'LineItemConnection';
  pageInfo: PageInfo;
  edges: Array<LineItemEdge>;
  totalCount: Scalars['Int'];
};

export type LineItemEdge = {
  __typename?: 'LineItemEdge';
  cursor: Scalars['String'];
  node: LineItem;
};

export type LineItemInput = {
  /** Item type. */
  type: LineItemType;
  /** Line item quantity. */
  quantity: Scalars['Float'];
  /** Previous ticket. Only applicable for seat changes and membership renewals */
  previousTicketId?: Maybe<Scalars['String']>;
  /** Seat label. Only applicable seated venues in combination with for `ticket` and `membership` line items. */
  seatLabel?: Maybe<Scalars['String']>;
  /** Seat zone. Only applicable to non-seated venues in combination for `ticket` and `membership` line items. */
  seatZone?: Maybe<Scalars['String']>;
  seatType?: Maybe<Scalars['String']>;
  /** Seat tags. Only available for ticket type line items. */
  seatTags?: Maybe<Array<Scalars['String']>>;
  /** Seat section. Only available for ticket type line items. */
  seatSection?: Maybe<Scalars['String']>;
  /** Ticket type. Only applicable for `ticket` line items. */
  ticketTypeId?: Maybe<Scalars['String']>;
  /** Membership type. Only applicable for `membership` line items. */
  membershipTypeId?: Maybe<Scalars['String']>;
  /** Addon id. Only applicable for `addon` line items. */
  addonId?: Maybe<Scalars['String']>;
  /** Ticket transfer id. Only applicable for `ticket` line items in a ticket transfer order. */
  ticketTransferId?: Maybe<Scalars['String']>;
  pointItemId?: Maybe<Scalars['String']>;
  /** Price of the line item with discounts applied. Only used for ticket transfers. */
  price?: Maybe<Scalars['Float']>;
};

export type LineItemsToCompleteInput = {
  /** Quantity of tickets to keep on each line item. */
  items: Array<LineItemToCompleteInput>;
  /** Selected payment method if order status is paid (allowed: cash, eftpos, invoice, other). */
  method: PaymentProvider;
  /** Will not send order confirmation email if set */
  skipOrderConfirmation: Scalars['Boolean'];
};

export type LineItemToCompleteInput = {
  /** ID of the line item in the order. */
  id: Scalars['String'];
  /** Quantity of tickets to keep on each line item. */
  quantity: Scalars['Int'];
};

export enum LineItemType {
  Ticket = 'Ticket',
  Membership = 'Membership',
  Addon = 'Addon',
  Point = 'Point',
  MembershipSingleEventTicket = 'MembershipSingleEventTicket'
}

export type LinkedAccountRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  newAccountPassword: Scalars['String'];
  token: Scalars['String'];
};

export type LinkedAccountUser = {
  __typename?: 'LinkedAccountUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  hasVerifiedEmail: Scalars['Boolean'];
  organizationSlugs: Array<Scalars['String']>;
};

export type ListedReleaseOptionsInput = {
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  startCondition?: Maybe<ReleaseStartCondition>;
  startConditionDate?: Maybe<Scalars['DateTime']>;
  endCondition?: Maybe<ReleaseEndCondition>;
  endConditionDate?: Maybe<Scalars['DateTime']>;
  endConditionQuantity?: Maybe<Scalars['Float']>;
};

export type LocationArgs = {
  __typename?: 'LocationArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  list: Array<Scalars['String']>;
  locationFilterType: LocationFilterTypes;
};

export enum LocationFilterTypes {
  District = 'DISTRICT',
  Locality = 'LOCALITY',
  Region = 'REGION',
  Country = 'COUNTRY',
  State = 'STATE'
}

export type LocationOutput = {
  __typename?: 'LocationOutput';
  city: Scalars['String'];
  count: Scalars['String'];
  amount?: Maybe<Scalars['Float']>;
};

export type LocationOutputV2 = {
  __typename?: 'LocationOutputV2';
  label: Scalars['String'];
  orderCount: Scalars['Float'];
  orderCountPercentage: Scalars['Float'];
  orderAmount: Scalars['Float'];
  orderAmountPercentage: Scalars['Float'];
  ticketSoldPercentage: Scalars['Float'];
  ticketSoldCount: Scalars['Float'];
  membershipSoldCount: Scalars['Float'];
  membershipSoldPercentage: Scalars['Float'];
  userCount: Scalars['Float'];
  userCountPercentage: Scalars['Float'];
  faceValueTicketRevenue: Scalars['Float'];
  faceValueTicketRevenuePercentage: Scalars['Float'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
};

/** Organization marketing settings. */
export type Marketing = {
  __typename?: 'Marketing';
  /** Google Tag Manager ID */
  gtmId?: Maybe<Scalars['String']>;
  /** Facebook Pixel ID */
  pixelId?: Maybe<Scalars['String']>;
};

export type MarketingFiltersInput = {
  ids: Array<Scalars['String']>;
  criteria?: Maybe<BroadcastFilterCriteria>;
  filterType: BroadcastFilterType;
};

/** Organization marketing settings. */
export type MarketingInput = {
  /** Google Tag Manager ID */
  gtmId?: Maybe<Scalars['String']>;
  /** Facebook Pixel ID */
  pixelId?: Maybe<Scalars['String']>;
};

export type Membership = {
  __typename?: 'Membership';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Name of the membership. */
  name: Scalars['String'];
  membershipKind: MembershipKind;
  /** The start date of the membership. */
  startDate: Scalars['DateTime'];
  /** The end date of the membership. */
  endDate: Scalars['DateTime'];
  /** The booking fee type of the membership. */
  bookingFeeType: FeeType;
  /** The booking fee for the membership. */
  bookingFee: Scalars['Float'];
  /** Shipping options of the membership. */
  shippingOptions?: Maybe<ShippingOptions>;
  /** The description of the membership. */
  description: Scalars['String'];
  /** The thumbnail associated with the membership. */
  thumbnail?: Maybe<ExtendedFile>;
  backgroundImage?: Maybe<ExtendedFile>;
  descriptionImage?: Maybe<ExtendedFile>;
  /** The banner associated with the membership. */
  banner?: Maybe<ExtendedFile>;
  printBanner1?: Maybe<ExtendedFile>;
  printBanner2?: Maybe<ExtendedFile>;
  /** The payment gateways enabled for the event. */
  gateways?: Maybe<Array<Integration>>;
  /** Whether naming a membership is available. */
  canNameMembership: Scalars['Boolean'];
  /** Whether renaming a membership is available. */
  canRenameMembership: Scalars['Boolean'];
  /** Whether naming a membership is available. */
  canChangeSeats: Scalars['Boolean'];
  /** The renaming fee for the membership. */
  renamingFee?: Maybe<Scalars['Float']>;
  /** The fee for changing seats for the membership. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The active status of the membership. */
  isActive: Scalars['Boolean'];
  /** Whether the membership is ticketed */
  isTicketed: Scalars['Boolean'];
  events?: Maybe<Array<Event>>;
  membershipZones: Array<MembershipZone>;
  /** The terms and conditions of the membership */
  termsAndConditions?: Maybe<Scalars['String']>;
  /** Important notice section on the tickets */
  importantNotice?: Maybe<Scalars['String']>;
  /** The accessibility info of the membership */
  accessibility?: Maybe<Scalars['String']>;
  /** The accessibility info of the membership */
  invoiceInformation?: Maybe<Scalars['String']>;
  /** Whether the schedule is shown on the customer portal */
  showSchedule?: Maybe<Scalars['Boolean']>;
  /** The referral campaign associated with this membership */
  referralCampaignId?: Maybe<Scalars['String']>;
  /** Email address that gets sent a notifcation every time an order is completed */
  emailNotification?: Maybe<Scalars['String']>;
  /** Flag to determine if the membership is hidden from the public */
  hiddenFromPublic: Scalars['Boolean'];
  /** Flag to issue the value of the membership as credit on sale */
  issueCreditOnSale: Scalars['Boolean'];
  canUsePoints: Scalars['Boolean'];
  enableAutoRenew: Scalars['Boolean'];
  canTransferTickets: Scalars['Boolean'];
  paymentPlanSettings?: Maybe<PaymentPlanSettings>;
  ticketCover?: Maybe<MembershipTicketCover>;
  /** The ID of the Google Wallet Event Class for this membership */
  googleWalletEventClassId?: Maybe<Scalars['String']>;
  releasedRenewalHolds: Scalars['Boolean'];
  types: Array<MembershipType>;
  renewalMembership?: Maybe<Membership>;
  previousMemberships: Array<Membership>;
  season?: Maybe<Season>;
  /** The venue associated with the membership. */
  venue?: Maybe<Venue>;
  addons: Array<MembershipAddon>;
  membershipHoldersCount?: Maybe<Scalars['Float']>;
  multiBuyPromotions: Array<MembershipMultiBuyPromotion>;
  digitalPassCodesStatus?: Maybe<DigitalPassCodeStatusOutput>;
  digitalInfo?: Maybe<DigitalInfo>;
  defaultDateRange?: Maybe<DefaultDateRange>;
  tags: Array<Tag>;
  hasPromoCodes: Scalars['Boolean'];
  taxRate: Scalars['Float'];
  customTaxRate: Scalars['Float'];
  taxCalculationMethod: TaxCalculationMethod;
};

export type MembershipAddon = {
  __typename?: 'MembershipAddon';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  /** Indicates whether naming the addon is possible or not. */
  enableNaming: Scalars['Boolean'];
  /** Maximum quantity to be sold for the addon. */
  quantity: Scalars['Float'];
  /** The price associated with the addon. */
  price: Scalars['Float'];
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  /** The active status of the addon. */
  isActive: Scalars['Boolean'];
  namingFields: Array<FormField>;
  /** Image to be shown on the customer portal of the addon . Returns null if the not set. */
  addonImage?: Maybe<ExtendedFile>;
  /** Quantity allocated already */
  quantityAllocated: Scalars['Float'];
  maxPurchaseQuantity: Scalars['Float'];
  /** Quantity remaining via order created or canceled, does not take into account the order status */
  remaining: Scalars['Float'];
};

export type MembershipArgs = {
  __typename?: 'MembershipArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  membershipIds: Array<Scalars['String']>;
};

export type MembershipAutoRenewal = {
  __typename?: 'MembershipAutoRenewal';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  membership: Membership;
  membershipId: Scalars['String'];
  organizationId: Scalars['String'];
  renewalDate?: Maybe<Scalars['DateTime']>;
  reminderDate?: Maybe<Scalars['DateTime']>;
  /** Mappings for previous memberships used by renewal process */
  membershipMappings: Array<MembershipRenewalMapping>;
  /** Current step of the auto renewal process */
  renewalStep: MembershipAutoRenewalStep;
};

export type MembershipAutoRenewalInput = {
  membershipId: Scalars['String'];
  renewalDate: Scalars['DateTime'];
  reminderDate?: Maybe<Scalars['DateTime']>;
  membershipMappings: Array<MembershipRenewalMappingInput>;
  /** Current step of the auto renewal process */
  renewalStep: MembershipAutoRenewalStep;
};

export type MembershipAutoRenewalOutput = {
  __typename?: 'MembershipAutoRenewalOutput';
  autoRenewal?: Maybe<MembershipAutoRenewal>;
};

export enum MembershipAutoRenewalStep {
  Scheduled = 'Scheduled',
  Reminded = 'Reminded',
  Renewed = 'Renewed'
}

export type MembershipConnection = {
  __typename?: 'MembershipConnection';
  pageInfo: PageInfo;
  edges: Array<MembershipEdge>;
  totalCount: Scalars['Int'];
};

export type MembershipEdge = {
  __typename?: 'MembershipEdge';
  cursor: Scalars['String'];
  node: Membership;
};

export type MembershipFinancial = {
  __typename?: 'MembershipFinancial';
  discounts: PeReportStatistic;
  avgOrderItemsCount: PeReportStatistic;
  netSales: PeReportStatistic;
  averageOrderAmount: PeReportStatistic;
  partPaidRevenue: PeReportStatistic;
};

export type MembershipInformation = {
  __typename?: 'MembershipInformation';
  id: Scalars['String'];
  name: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export enum MembershipKind {
  Standard = 'Standard',
  Digital = 'Digital'
}

export type MembershipLayoutOutput = {
  __typename?: 'MembershipLayoutOutput';
  membership?: Maybe<MembershipInformation>;
  defaultDateRange: DateRange;
};

export type MembershipMultiBuyPromotion = {
  __typename?: 'MembershipMultiBuyPromotion';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  buyQuantity: Scalars['Float'];
  getQuantity: Scalars['Float'];
  price: Scalars['Float'];
  enabled: Scalars['Boolean'];
  buyMembershipType?: Maybe<MembershipType>;
  getMembershipType?: Maybe<MembershipType>;
  used: Scalars['Float'];
};

export type MembershipOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
  startDate?: Maybe<OrderByDirection>;
  endDate?: Maybe<OrderByDirection>;
};

export type MembershipPromoCode = {
  __typename?: 'MembershipPromoCode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The unique code that represents the promotion. */
  code: Scalars['String'];
  /** The discount type associated with the promotion. */
  discountType: DiscountType;
  /** The discount amount of the promotion. */
  discountAmount: Scalars['Float'];
  enabled: Scalars['Boolean'];
  /** The type of the promotion which determines on which items the promotion will be applied. */
  type: MembershipPromoCodeType;
  membershipTypes: Array<Scalars['String']>;
  /** The total amount of usage of the promotion. */
  used?: Maybe<Scalars['Int']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Int']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
};

export enum MembershipPromoCodeType {
  MembershipAndAddons = 'MEMBERSHIP_AND_ADDONS',
  Membership = 'MEMBERSHIP',
  Addons = 'ADDONS'
}

export type MembershipRenewalMapping = {
  __typename?: 'MembershipRenewalMapping';
  membershipId: Scalars['String'];
  membershipTypeMappings?: Maybe<Array<MembershipTypeMapping>>;
};

export type MembershipRenewalMappingInput = {
  membershipId: Scalars['String'];
  membershipTypeMappings?: Maybe<Array<MembershipTypeMappingInput>>;
};

export type MembershipSales = {
  __typename?: 'MembershipSales';
  membershipsIssued: PeReportStatistic;
  membershipsRenewed: PeReportStatistic;
  membershipsSold: PeReportStatistic;
};

/** Represents a membership type in a seating zone. */
export type MembershipSeatingZoneType = {
  __typename?: 'MembershipSeatingZoneType';
  /** The price of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  /** The sections of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `section`. */
  sections?: Maybe<Array<ZonePricingTypeSection>>;
  /** The active status of the zone ticket. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  purchaseRestrictions: Array<PurchaseRestriction>;
  purchaseRestrictionsHelpText?: Maybe<Array<Scalars['String']>>;
  /** The membership type id of the membership zone. */
  membershipTypeId: Scalars['String'];
};

export type MembershipSubscription = {
  __typename?: 'MembershipSubscription';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeSubscriptionId: Scalars['String'];
  /** Billing details of the subscription */
  billingDetails: MembershipSubscriptionBillingDetails;
  /** Number of membership tickets */
  membershipTicketCount: Scalars['Float'];
  /** Last order id */
  lastOrderId: Scalars['String'];
  lastOrder: Order;
  /** User id */
  userId: Scalars['String'];
  renewals: Array<MembershipSubscriptionRenewal>;
  latestRenewalId?: Maybe<Scalars['String']>;
};

export type MembershipSubscriptionBillingDetails = {
  __typename?: 'MembershipSubscriptionBillingDetails';
  cardLast4: Scalars['String'];
  cardBrand: Scalars['String'];
  cardExpiryMonth: Scalars['Float'];
  cardExpiryYear: Scalars['Float'];
  emailAddress: Scalars['String'];
};

export type MembershipSubscriptionPortalOutput = {
  __typename?: 'MembershipSubscriptionPortalOutput';
  url?: Maybe<Scalars['String']>;
};

export type MembershipSubscriptionRenewal = {
  __typename?: 'MembershipSubscriptionRenewal';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  dueAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  remindedAt?: Maybe<Scalars['DateTime']>;
  /** Current state of the subscription */
  subscriptionState?: Maybe<MembershipSubscriptionState>;
  failedAt?: Maybe<Scalars['DateTime']>;
  failureReason?: Maybe<Scalars['String']>;
  membershipSubscriptionId: Scalars['String'];
  currentMembershipId: Scalars['String'];
  currentOrderId?: Maybe<Scalars['String']>;
  previousMembershipId: Scalars['String'];
  previousOrderId: Scalars['String'];
};

export enum MembershipSubscriptionState {
  Scheduled = 'Scheduled',
  Paid = 'Paid',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Cancelled = 'Cancelled'
}

export type MembershipTicketCover = {
  __typename?: 'MembershipTicketCover';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Membership that the ticket cover is configured for */
  membership: Membership;
  enabledAt?: Maybe<Scalars['DateTime']>;
  disabledAt?: Maybe<Scalars['DateTime']>;
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Platform fee as percentage of ticket price */
  platformFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
  /** Total fee as percentage of ticket price */
  totalFeePercentage?: Maybe<Scalars['Float']>;
  /** Is ticket cover enabled */
  isEnabled?: Maybe<Scalars['Boolean']>;
};

export type MembershipTicketCoverInput = {
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
  /** Whether ticket cover is enabled */
  isEnabled: Scalars['Boolean'];
};

export type MembershipType = {
  __typename?: 'MembershipType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The kind of the membership type. */
  kind: MembershipTypeKind;
  /** Number of membership tickets that must be purchased in this set. */
  quantitySet?: Maybe<Scalars['Int']>;
  /** Name of the membership type. */
  name: Scalars['String'];
  /** The ticket fee type. */
  ticketFeeType: FeeType;
  /** The ticket fee. */
  ticketFee: Scalars['Float'];
  /** Restrictions for the membership type. */
  description?: Maybe<Scalars['String']>;
  /** Restrictions for the membership type. */
  restrictions?: Maybe<Scalars['String']>;
  namingFields: Array<FormField>;
  minPurchaseQuantity?: Maybe<Scalars['Int']>;
  maxPurchaseQuantity?: Maybe<Scalars['Int']>;
  isGuestCard: Scalars['Boolean'];
  membership: Membership;
};

export type MembershipTypeEventTransferPrice = {
  __typename?: 'MembershipTypeEventTransferPrice';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  membership: Membership;
  membershipType: MembershipType;
  membershipZone: MembershipZone;
  event: Event;
  maxSalePrice: Scalars['Float'];
};

/** Membership kind for membership type. */
export enum MembershipTypeKind {
  Standard = 'STANDARD',
  ComplimentaryNamed = 'COMPLIMENTARY_NAMED',
  ComplimentaryUnnamed = 'COMPLIMENTARY_UNNAMED',
  Addon = 'ADDON'
}

export type MembershipTypeMapping = {
  __typename?: 'MembershipTypeMapping';
  previousMembershipTypeId: Scalars['String'];
  newMembershipTypeId: Scalars['String'];
};

export type MembershipTypeMappingInput = {
  previousMembershipTypeId: Scalars['String'];
  newMembershipTypeId: Scalars['String'];
};

export type MembershipTypePurchaseTotal = {
  __typename?: 'MembershipTypePurchaseTotal';
  membershipType: MembershipType;
  total: Scalars['Int'];
};

export type MembershipTypeTransferPriceInput = {
  eventId: Scalars['String'];
  maxSalePrice: Scalars['Float'];
};

export type MembershipWhereInput = {
  /** Membership active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
};

export type MembershipZone = {
  __typename?: 'MembershipZone';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  zone: Zone;
  /** The pricing level of the zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The active status of the zone. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  /** The list of ticket types of the release zone. */
  membershipTypes: Array<MembershipSeatingZoneType>;
};

/** The zone belonging to a release zone */
export type MembershipZoneZoneInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** Represents the overview of mentions. */
export type MentionOverview = {
  __typename?: 'MentionOverview';
  commentMentionCount: Scalars['Float'];
  mediaMentionCount: Scalars['Float'];
  storyMentionCount: Scalars['Float'];
};

export type MentionOverviewInput = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type MetaData = {
  __typename?: 'MetaData';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type MetaDataInput = {
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type MinTicketTypePricesForEventOutput = {
  __typename?: 'MinTicketTypePricesForEventOutput';
  ticketTypeId: Scalars['String'];
  price: Scalars['Float'];
};

export type MoneyStatistic = {
  __typename?: 'MoneyStatistic';
  label: Scalars['String'];
  tooltip?: Maybe<StatisticTooltip>;
  value: Scalars['Float'];
};

export type MultiBuyPromotionUnion = EventMultiBuyPromotion | MembershipMultiBuyPromotion;

export type Mutation = {
  __typename?: 'Mutation';
  register: Scalars['Boolean'];
  linkedAccountLogin: LinkedAccountUser;
  linkedAccountRegister: LinkedAccountUser;
  initiateLinkedAccountRegistration: Scalars['Boolean'];
  login: User;
  socialAuth: User;
  logout: Scalars['Boolean'];
  requestMagicLink: Scalars['Boolean'];
  getMagicLink: Scalars['String'];
  requestDeleteAccount: Scalars['Boolean'];
  sendMemberRenewalEmail: Scalars['Boolean'];
  verifyMagicLink: VerifyMagicLinkOutput;
  verifyToken: VerifyMagicLinkOutput;
  requestResetPassword: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  setPasswordForNewUser: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  assignTwoFactorAuthenticationSecret: TwoFactorAuthenticationSetupResult;
  removeTwoFactorAuthenticationSecret: Scalars['Boolean'];
  enableTwoFactorAuthentication: Scalars['Boolean'];
  authenticateWithTwoFactor: User;
  sendTwoFactorCodeViaSMSMessage: Scalars['Boolean'];
  /** Creates a user. */
  createUser: User;
  createPosUser: User;
  createTemporaryPosUser: CreateTemporaryPosUserOutput;
  updatePosUser: User;
  /** Updates a user. */
  updateUser: User;
  /** Deletes a user. */
  deleteUser: DeleteOneOutput;
  /** Delete yourself. */
  deleteSelf: DeleteOneOutput;
  /** Updates the current user. */
  updateProfile: User;
  unsubscribeFromMarketing: User;
  resubscribe: User;
  addUnsubscribeReason: User;
  /** Sends an invite to an existing user. */
  sendUserInvite: User;
  /** Sends invitations to existing users based on CSV file. */
  importAndInviteUsers: ImportOutput;
  importUsers: Scalars['Boolean'];
  updateCreditsForUser: User;
  createPos: Pos;
  createOrUpdatePosRelease: Release;
  setPosReleasePaymentOptions: ReleasePosPaymentOptions;
  updateForecast: EventForecastOutput;
  addForecastScenario: EventForecastOutput;
  deleteForecastScenario: EventForecastOutput;
  createEvent: Event;
  updateEvent: Event;
  duplicateEvent: Scalars['String'];
  addGatesToZoneAndEvent: Event;
  toggleMultiScanning: Scalars['Boolean'];
  createTicketType: TicketType;
  updateTicketType: TicketType;
  deleteTicketType: DeleteOneOutput;
  createRelease: Release;
  updateRelease: Release;
  deleteRelease: DeleteOneOutput;
  holdBestAvailableSeats: HoldBestAvailableSeatsOutput;
  validateReleasePasswordOrRelease: Release;
  validateReleasePassword: ReleasePassword;
  importReleasePasswords: Scalars['Boolean'];
  duplicateSingleRelease: Release;
  createEventPromoCode: EventPromoCode;
  updateEventPromoCode: EventPromoCode;
  deleteEventPromoCode: DeleteOneOutput;
  createEventMultiBuyPromotion: EventMultiBuyPromotion;
  updateEventMultiBuyPromotion: EventMultiBuyPromotion;
  deleteEventMultiBuyPromotion: DeleteOneOutput;
  createEventAddon: EventAddon;
  updateEventAddon: EventAddon;
  deleteEventAddon: DeleteOneOutput;
  addGatesToScannableGatesId: EventAddon;
  createReleaseZone: ReleaseZone;
  updateReleaseZone: ReleaseZone;
  deleteReleaseZone: Scalars['Boolean'];
  reorderTicketTypes: ReleaseZone;
  createOrUpdateAccessCodeRelease: AccessCodeReleaseOutput;
  deleteAccessCode: AccessCodeReleaseOutput;
  updateAccessCode: AccessCodeOutput;
  createOrUpdatePresale: PresaleReleaseOutput;
  deletePresale: Scalars['Boolean'];
  enablePresale: Scalars['Boolean'];
  createOrUpdateNamingField: NamingFieldOutput;
  deleteNamingField: Scalars['Boolean'];
  toggleNamingFields: Scalars['Boolean'];
  toggleCanRename: Scalars['Boolean'];
  resendUsherTicket: Scalars['Boolean'];
  createUsherTicketType: TicketType;
  updateUsherTicketTypeName: TicketType;
  disableUsherTicketType: Scalars['Boolean'];
  deleteUsherTicketType: Scalars['Boolean'];
  createSeason: Season;
  updateSeason: Season;
  completeStripeSetup: Integration;
  completeStripeOAuthSetup: CompleteStripeOAuth;
  createIntegration: Integration;
  updateIntegration: Integration;
  deleteIntegration: Scalars['Boolean'];
  rollIntegrationApiKey: Integration;
  setXeroConfiguration: XeroConfigurationOutput;
  enableXeroIntegration: Scalars['Boolean'];
  completeShopifySetup: Scalars['Boolean'];
  upsertAdobeIntegration: AdobeExperiencePlatformIntegration;
  createOrganization: CreateOrganizationOutput;
  updateOrganization: Organization;
  updateOrganizationById: Organization;
  uploadImage: Scalars['JSON'];
  createOrder: OrderCreateOutput;
  updateOrder: Order;
  updateHoldOrder: Order;
  splitHoldOrder: Order;
  releaseSeats: Order;
  createPayment: CreatePaymentOutput;
  applyPromoCodeToOrder: Order;
  updateTicketCoverForOrder: Order;
  removePromoCodeFromOrder: Order;
  holdCustomerOrder: Order;
  updateOrderStatus: Order;
  completePreprintTicketOrder: Order;
  addPosTransaction: Order;
  removePosTransaction: Order;
  finishPosTransaction: Order;
  sendOrderConfirmation: SendOrderConfirmationOutput;
  sendInternalOrderReceipt: SendOrderConfirmationOutput;
  cancelOrder: Order;
  expireOrder: Order;
  generateXeroInvoiceForOrder: Scalars['String'];
  splitGroupOrder: Order;
  createBulkOrders: Scalars['Boolean'];
  createRefund: Refund;
  createPurchaseRestriction: PurchaseRestriction;
  updatePurchaseRestriction: PurchaseRestriction;
  deletePurchaseRestriction: DeleteOneOutput;
  createHistory: History;
  createVenue: Venue;
  updateVenue: Venue;
  /** Update zones based on seatsChartKey. */
  updateVenueZones: Venue;
  deleteVenue: DeleteOneOutput;
  updateVenueCapacity: Scalars['Boolean'];
  reorderZones: Array<Zone>;
  createMembership: Membership;
  updateMembership: Membership;
  duplicateMembership: Scalars['String'];
  releaseAllMembershipHolds?: Maybe<Scalars['Boolean']>;
  upsertMembershipTypeEventTransferPrices: Array<MembershipTypeEventTransferPrice>;
  createMembershipType: MembershipType;
  updateMembershipType: MembershipType;
  deleteMembershipType: DeleteOneOutput;
  createMembershipAddon: MembershipAddon;
  updateMembershipAddon: MembershipAddon;
  deleteMembershipAddon: DeleteOneOutput;
  createMembershipPromoCode: MembershipPromoCode;
  updateMembershipPromoCode: MembershipPromoCode;
  deleteMembershipPromoCode: DeleteOneOutput;
  createMembershipMultiBuyPromotion: MembershipMultiBuyPromotion;
  updateMembershipMultiBuyPromotion: MembershipMultiBuyPromotion;
  deleteMembershipMultiBuyPromotion: DeleteOneOutput;
  createOrUpdateMembershipAutoRenewal: MembershipAutoRenewal;
  createScout: Scout;
  updateScout: Scout;
  updateScoutUsers: Scalars['Boolean'];
  deleteScoutUsers: Scalars['Boolean'];
  createScoutCommunication: ScoutCommunication;
  updateScoutCommunication: ScoutCommunication;
  createTestEmail: Scalars['Boolean'];
  updateScoutCommunicationUser: ScoutCommunicationUser;
  addReminderToScoutCommunicationUser: Scalars['Boolean'];
  clearReminderFromScoutCommunicationUser: Scalars['Boolean'];
  assignScoutCommunicationUsersToAgent: Scalars['Boolean'];
  createTag: Tag;
  updateTag: Tag;
  tagEntities: Array<Scalars['String']>;
  deleteTag: Scalars['Boolean'];
  updateTicketHolder: Ticket;
  increaseDownloadCount: Scalars['Boolean'];
  releaseClaimsForNextMembership: Array<Ticket>;
  requestTicketTransfer: Scalars['Boolean'];
  cancelPendingTicketTransfer: Scalars['Boolean'];
  listTicketForResale: Scalars['Boolean'];
  unlistTicketForResale: Scalars['Boolean'];
  sendTicket: Scalars['Boolean'];
  scanTicket: ScanTicketOutput;
  redeemTicket: ScanTicketOutput;
  scanVaccinePass: Scalars['Boolean'];
  scanTicketBulk: Array<ScanTicketOutput>;
  importDigitalPassCodesForEvent: Scalars['Boolean'];
  importDigitalPassCodesForMembership: Scalars['Boolean'];
  createPinPaymentCharge: PinPaymentChargeResponse;
  verifyPinPayment3DS: PinPaymentChargeResponse;
  handleInstagramCode: Scalars['String'];
  createPoint: Point;
  updatePoint: Point;
  createPointItem: PointItem;
  updatePointItem: PointItem;
  createReferralCampaign: ReferralCampaign;
  updateReferralCampaign: ReferralCampaign;
  createReferralRewards: Array<ReferralReward>;
  createAutomation: Automation;
  updateAutomation: Automation;
  joinWaitlist: Waitlist;
  setPromoterSettings: PromoterSettingsOutput;
  createRedirectUrl: RedirectUrl;
  createCompetition: Competition;
  updateCompetition: Competition;
  unpublishCompetition: Competition;
  deleteCompetition: Scalars['Boolean'];
  updateAndPublishCompetition: Competition;
  grantCompetitionReward?: Maybe<CompetitionReward>;
  deleteBroadcast: DeleteOneOutput;
  sendBroadcast: Scalars['Boolean'];
  createTrackingCode: Scalars['Boolean'];
  deleteTrackingCode: Scalars['Boolean'];
  onTrackingCodeVisit: Scalars['Boolean'];
  createOrderToTrackingCodeAssociation: Scalars['Boolean'];
  broadcastReportingLinkOpened: Scalars['Boolean'];
  broadcastReportingOrderCreated: Scalars['Boolean'];
  broadcastReportingSetAsSpamFromUser: Scalars['Boolean'];
  createReportSchedule: ReportSchedule;
  updateReportSchedule: ReportSchedule;
  deleteReportSchedule: Scalars['Boolean'];
  pauseReportSchedule: ReportSchedule;
  updateTicketResaleConfig: Event;
  createResaleTicketType: TicketType;
  updateResaleTicketType: TicketType;
  deleteResaleTicketType: Scalars['Boolean'];
  hideResaleTicketTypeFromPublic: Scalars['Boolean'];
  unhideResaleTicketTypeFromPublic: Scalars['Boolean'];
  joinResaleWaitlist: Scalars['Boolean'];
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationLinkedAccountLoginArgs = {
  input: LoginInput;
};


export type MutationLinkedAccountRegisterArgs = {
  input: LinkedAccountRegisterInput;
};


export type MutationInitiateLinkedAccountRegistrationArgs = {
  input: LoginInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationSocialAuthArgs = {
  input: SocialAuthInput;
};


export type MutationRequestMagicLinkArgs = {
  input: RequestMagicLinkInput;
};


export type MutationGetMagicLinkArgs = {
  input: RequestMagicLinkInput;
};


export type MutationRequestDeleteAccountArgs = {
  input: RequestMagicLinkInput;
};


export type MutationSendMemberRenewalEmailArgs = {
  id: Scalars['String'];
};


export type MutationVerifyMagicLinkArgs = {
  input: VerifyEmailInput;
};


export type MutationVerifyTokenArgs = {
  input: VerifyEmailInput;
};


export type MutationRequestResetPasswordArgs = {
  input: RequestResetPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSetPasswordForNewUserArgs = {
  input: SetPasswordForNewUserInput;
};


export type MutationUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


export type MutationEnableTwoFactorAuthenticationArgs = {
  input: TwoFactorAuthenticationCodeInput;
};


export type MutationAuthenticateWithTwoFactorArgs = {
  input: TwoFactorAuthenticationCodeInput;
};


export type MutationCreateUserArgs = {
  sendInvite: Scalars['Boolean'];
  input: CreateUserInput;
};


export type MutationCreatePosUserArgs = {
  input: CreatePosUserInput;
};


export type MutationCreateTemporaryPosUserArgs = {
  input: CreateTemporaryPosUserInput;
};


export type MutationUpdatePosUserArgs = {
  input: UpdatePosUserInput;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUnsubscribeFromMarketingArgs = {
  userId: Scalars['String'];
};


export type MutationResubscribeArgs = {
  userId: Scalars['String'];
};


export type MutationAddUnsubscribeReasonArgs = {
  input: AddUnsubscribeReasonInput;
};


export type MutationSendUserInviteArgs = {
  id: Scalars['String'];
};


export type MutationImportAndInviteUsersArgs = {
  input: ImportInput;
};


export type MutationImportUsersArgs = {
  input: ImportInput;
};


export type MutationUpdateCreditsForUserArgs = {
  input: UpdateCreditsInput;
  userId: Scalars['String'];
};


export type MutationCreatePosArgs = {
  input: CreatePosInput;
};


export type MutationCreateOrUpdatePosReleaseArgs = {
  input: PosReleaseInput;
};


export type MutationSetPosReleasePaymentOptionsArgs = {
  input: SetPosPaymentOptionsInput;
  releaseId: Scalars['String'];
};


export type MutationUpdateForecastArgs = {
  input: UpdateForecastInput;
  id: Scalars['String'];
};


export type MutationAddForecastScenarioArgs = {
  id: Scalars['String'];
};


export type MutationDeleteForecastScenarioArgs = {
  forecastId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationUpdateEventArgs = {
  sendTicketViewableEmails?: Maybe<Scalars['Boolean']>;
  input: UpdateEventInput;
  id: Scalars['String'];
};


export type MutationDuplicateEventArgs = {
  id: Scalars['String'];
};


export type MutationAddGatesToZoneAndEventArgs = {
  input: AddGatesToZoneInput;
  eventId: Scalars['String'];
};


export type MutationToggleMultiScanningArgs = {
  multiScanningEnabled: Scalars['Boolean'];
  eventId: Scalars['String'];
};


export type MutationCreateTicketTypeArgs = {
  input: CreateTicketTypeInput;
};


export type MutationUpdateTicketTypeArgs = {
  id: Scalars['String'];
  input: UpdateTicketTypeInput;
};


export type MutationDeleteTicketTypeArgs = {
  id: Scalars['String'];
};


export type MutationCreateReleaseArgs = {
  input: CreateReleaseInput;
};


export type MutationUpdateReleaseArgs = {
  input: UpdateReleaseInput;
  id: Scalars['String'];
};


export type MutationDeleteReleaseArgs = {
  id: Scalars['String'];
};


export type MutationHoldBestAvailableSeatsArgs = {
  input: HoldBestAvailableSeatsInput;
  id: Scalars['String'];
};


export type MutationValidateReleasePasswordOrReleaseArgs = {
  password: Scalars['String'];
  eventId: Scalars['String'];
};


export type MutationValidateReleasePasswordArgs = {
  password: Scalars['String'];
  id: Scalars['String'];
};


export type MutationImportReleasePasswordsArgs = {
  input: ImportInput;
  id: Scalars['String'];
};


export type MutationDuplicateSingleReleaseArgs = {
  input: DuplicateSingleReleaseInput;
};


export type MutationCreateEventPromoCodeArgs = {
  input: CreateEventPromoCodeInput;
};


export type MutationUpdateEventPromoCodeArgs = {
  input: UpdateEventPromoCodeInput;
  id: Scalars['String'];
};


export type MutationDeleteEventPromoCodeArgs = {
  id: Scalars['String'];
};


export type MutationCreateEventMultiBuyPromotionArgs = {
  input: CreateEventMultiBuyPromotionInput;
};


export type MutationUpdateEventMultiBuyPromotionArgs = {
  id: Scalars['String'];
  input: UpdateEventMultiBuyPromotionInput;
};


export type MutationDeleteEventMultiBuyPromotionArgs = {
  id: Scalars['String'];
};


export type MutationCreateEventAddonArgs = {
  input: CreateEventAddonInput;
};


export type MutationUpdateEventAddonArgs = {
  id: Scalars['String'];
  input: UpdateEventAddonInput;
};


export type MutationDeleteEventAddonArgs = {
  id: Scalars['String'];
};


export type MutationAddGatesToScannableGatesIdArgs = {
  input: AddGatesToEventAddonInput;
  eventId: Scalars['String'];
};


export type MutationCreateReleaseZoneArgs = {
  input: CreateReleaseZoneInput;
};


export type MutationUpdateReleaseZoneArgs = {
  input: UpdateReleaseZoneInput;
  id: Scalars['String'];
};


export type MutationDeleteReleaseZoneArgs = {
  id: Scalars['String'];
};


export type MutationReorderTicketTypesArgs = {
  input: ReorderTicketTypeInput;
};


export type MutationCreateOrUpdateAccessCodeReleaseArgs = {
  input: AccessCodeReleaseInput;
};


export type MutationDeleteAccessCodeArgs = {
  accessCode?: Maybe<Scalars['String']>;
  releaseId: Scalars['String'];
};


export type MutationUpdateAccessCodeArgs = {
  input: AccessCodeInput;
  releaseId: Scalars['String'];
};


export type MutationCreateOrUpdatePresaleArgs = {
  input: PresaleReleaseInput;
};


export type MutationDeletePresaleArgs = {
  releaseId: Scalars['String'];
};


export type MutationEnablePresaleArgs = {
  isEnabled: Scalars['Boolean'];
  releaseId: Scalars['String'];
};


export type MutationCreateOrUpdateNamingFieldArgs = {
  input: NamingFieldInput;
};


export type MutationDeleteNamingFieldArgs = {
  fieldId: Scalars['String'];
};


export type MutationToggleNamingFieldsArgs = {
  isActive: Scalars['Boolean'];
  eventId: Scalars['String'];
};


export type MutationToggleCanRenameArgs = {
  isEnabled: Scalars['Boolean'];
  eventId: Scalars['String'];
};


export type MutationResendUsherTicketArgs = {
  input: EmailUsherComplimentaryInput;
};


export type MutationCreateUsherTicketTypeArgs = {
  createTicketAndOrderInput: UsherComplimetaryCreateInput;
};


export type MutationUpdateUsherTicketTypeNameArgs = {
  name: Scalars['String'];
  id: Scalars['String'];
};


export type MutationDisableUsherTicketTypeArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUsherTicketTypeArgs = {
  id: Scalars['String'];
};


export type MutationCreateSeasonArgs = {
  input: CreateSeasonInput;
};


export type MutationUpdateSeasonArgs = {
  input: UpdateSeasonInput;
  id: Scalars['String'];
};


export type MutationCompleteStripeSetupArgs = {
  id: Scalars['String'];
};


export type MutationCompleteStripeOAuthSetupArgs = {
  code: Scalars['String'];
  state: Scalars['String'];
};


export type MutationCreateIntegrationArgs = {
  input: CreateIntegrationInput;
};


export type MutationUpdateIntegrationArgs = {
  input: UpdateIntegrationInput;
  id: Scalars['String'];
};


export type MutationDeleteIntegrationArgs = {
  id: Scalars['String'];
};


export type MutationRollIntegrationApiKeyArgs = {
  id: Scalars['String'];
};


export type MutationSetXeroConfigurationArgs = {
  input: XeroConfigurationInput;
};


export type MutationEnableXeroIntegrationArgs = {
  input: XeroCallbackInput;
};


export type MutationCompleteShopifySetupArgs = {
  input: ShopifySetupInput;
};


export type MutationUpsertAdobeIntegrationArgs = {
  input: AdobeExperiencePlatformIntegrationInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationByIdArgs = {
  input: UpdateOrganizationInput;
  id: Scalars['String'];
};


export type MutationUploadImageArgs = {
  file: ImportInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
  id: Scalars['String'];
};


export type MutationUpdateHoldOrderArgs = {
  input: UpdateHoldOrderInput;
  id: Scalars['String'];
};


export type MutationSplitHoldOrderArgs = {
  input: SplitOrderInput;
  id: Scalars['String'];
};


export type MutationReleaseSeatsArgs = {
  input: ReleaseSeatsInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
  id: Scalars['String'];
};


export type MutationApplyPromoCodeToOrderArgs = {
  input: ApplyPromoCode;
  id: Scalars['String'];
};


export type MutationUpdateTicketCoverForOrderArgs = {
  input: UpdateOrderTicketCoverInput;
  id: Scalars['String'];
};


export type MutationRemovePromoCodeFromOrderArgs = {
  id: Scalars['String'];
};


export type MutationHoldCustomerOrderArgs = {
  id: Scalars['String'];
};


export type MutationUpdateOrderStatusArgs = {
  input: UpdateOrderStatusInput;
  id: Scalars['String'];
};


export type MutationCompletePreprintTicketOrderArgs = {
  input: LineItemsToCompleteInput;
  id: Scalars['String'];
};


export type MutationAddPosTransactionArgs = {
  input: AddPosTransactionInput;
  id: Scalars['String'];
};


export type MutationRemovePosTransactionArgs = {
  posTransactionId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationFinishPosTransactionArgs = {
  autoScan?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type MutationSendOrderConfirmationArgs = {
  input: SendOrderConfirmationInput;
  id: Scalars['String'];
};


export type MutationSendInternalOrderReceiptArgs = {
  id: Scalars['String'];
};


export type MutationCancelOrderArgs = {
  id: Scalars['String'];
};


export type MutationExpireOrderArgs = {
  id: Scalars['String'];
};


export type MutationGenerateXeroInvoiceForOrderArgs = {
  orderId: Scalars['String'];
};


export type MutationSplitGroupOrderArgs = {
  input: SplitOrderInput;
  id: Scalars['String'];
};


export type MutationCreateBulkOrdersArgs = {
  input: ImportInput;
  createdById: Scalars['String'];
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreatePurchaseRestrictionArgs = {
  input: CreatePurchaseRestrictionInput;
};


export type MutationUpdatePurchaseRestrictionArgs = {
  id: Scalars['String'];
  input: CreatePurchaseRestrictionInput;
};


export type MutationDeletePurchaseRestrictionArgs = {
  id: Scalars['String'];
};


export type MutationCreateHistoryArgs = {
  input: CreateHistoryInput;
};


export type MutationCreateVenueArgs = {
  input: CreateVenueInput;
};


export type MutationUpdateVenueArgs = {
  input: UpdateVenueInput;
  id: Scalars['String'];
};


export type MutationUpdateVenueZonesArgs = {
  id: Scalars['String'];
};


export type MutationDeleteVenueArgs = {
  id: Scalars['String'];
};


export type MutationUpdateVenueCapacityArgs = {
  capacity?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
};


export type MutationReorderZonesArgs = {
  input: ReorderZoneInput;
};


export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
  id: Scalars['String'];
};


export type MutationDuplicateMembershipArgs = {
  id: Scalars['String'];
};


export type MutationReleaseAllMembershipHoldsArgs = {
  id: Scalars['String'];
};


export type MutationUpsertMembershipTypeEventTransferPricesArgs = {
  input: Array<UpdateMembershipTypeEventTransferPriceInput>;
  id: Scalars['String'];
};


export type MutationCreateMembershipTypeArgs = {
  input: CreateMembershipTypeInput;
};


export type MutationUpdateMembershipTypeArgs = {
  input: UpdateMembershipTypeInput;
  id: Scalars['String'];
};


export type MutationDeleteMembershipTypeArgs = {
  id: Scalars['String'];
};


export type MutationCreateMembershipAddonArgs = {
  input: CreateMembershipAddonInput;
};


export type MutationUpdateMembershipAddonArgs = {
  id: Scalars['String'];
  input: UpdateMembershipAddonInput;
};


export type MutationDeleteMembershipAddonArgs = {
  id: Scalars['String'];
};


export type MutationCreateMembershipPromoCodeArgs = {
  input: CreateMembershipPromoCodeInput;
};


export type MutationUpdateMembershipPromoCodeArgs = {
  input: UpdateMembershipPromoCodeInput;
  id: Scalars['String'];
};


export type MutationDeleteMembershipPromoCodeArgs = {
  id: Scalars['String'];
};


export type MutationCreateMembershipMultiBuyPromotionArgs = {
  input: CreateMembershipMultiBuyPromotionInput;
};


export type MutationUpdateMembershipMultiBuyPromotionArgs = {
  id: Scalars['String'];
  input: UpdateMembershipMultiBuyPromotionInput;
};


export type MutationDeleteMembershipMultiBuyPromotionArgs = {
  id: Scalars['String'];
};


export type MutationCreateOrUpdateMembershipAutoRenewalArgs = {
  input: MembershipAutoRenewalInput;
};


export type MutationCreateScoutArgs = {
  input: CreateScoutInput;
};


export type MutationUpdateScoutArgs = {
  input: UpdateScoutInput;
  id: Scalars['String'];
};


export type MutationUpdateScoutUsersArgs = {
  id: Scalars['String'];
};


export type MutationDeleteScoutUsersArgs = {
  input: DeleteScoutUserInput;
};


export type MutationCreateScoutCommunicationArgs = {
  input: CreateScoutCommunicationInput;
};


export type MutationUpdateScoutCommunicationArgs = {
  input: UpdateScoutCommunicationInput;
  id: Scalars['String'];
};


export type MutationCreateTestEmailArgs = {
  input: CreateTestEmailInput;
};


export type MutationUpdateScoutCommunicationUserArgs = {
  input: UpdateScoutCommunicationUserInput;
  id: Scalars['String'];
};


export type MutationAddReminderToScoutCommunicationUserArgs = {
  input: AddReminderToScoutCommunicationUserInput;
  id: Scalars['String'];
};


export type MutationClearReminderFromScoutCommunicationUserArgs = {
  id: Scalars['String'];
};


export type MutationAssignScoutCommunicationUsersToAgentArgs = {
  input: AssignScoutCommunicationUsersToAgentInput;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
  id: Scalars['String'];
};


export type MutationTagEntitiesArgs = {
  input: TagEntitiesInput;
};


export type MutationDeleteTagArgs = {
  entityId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationUpdateTicketHolderArgs = {
  id: Scalars['String'];
  input: UpdateTicketHolderInput;
};


export type MutationIncreaseDownloadCountArgs = {
  id: Scalars['String'];
};


export type MutationReleaseClaimsForNextMembershipArgs = {
  ids: Scalars['String'];
};


export type MutationRequestTicketTransferArgs = {
  input: RequestTicketTransferInput;
};


export type MutationCancelPendingTicketTransferArgs = {
  ticketId: Scalars['String'];
};


export type MutationListTicketForResaleArgs = {
  ticketId: Scalars['String'];
};


export type MutationUnlistTicketForResaleArgs = {
  ticketId: Scalars['String'];
};


export type MutationSendTicketArgs = {
  email: Scalars['String'];
  ticketIds: Array<Scalars['String']>;
};


export type MutationScanTicketArgs = {
  eventId: Scalars['String'];
  input: ScanTicketInput;
};


export type MutationRedeemTicketArgs = {
  input: RedeemTicketInput;
};


export type MutationScanVaccinePassArgs = {
  payload: Scalars['String'];
  ticketId: Scalars['String'];
};


export type MutationScanTicketBulkArgs = {
  input: ScanTicketBulkInput;
};


export type MutationImportDigitalPassCodesForEventArgs = {
  input: ImportInput;
  eventId: Scalars['String'];
};


export type MutationImportDigitalPassCodesForMembershipArgs = {
  input: ImportInput;
  membershipId: Scalars['String'];
};


export type MutationCreatePinPaymentChargeArgs = {
  input: CreatePinPaymentCharge;
};


export type MutationVerifyPinPayment3DsArgs = {
  input: VerifyPinPayment3Ds;
};


export type MutationHandleInstagramCodeArgs = {
  input: HandleInstagramCodeInput;
};


export type MutationCreatePointArgs = {
  input: CreatePointInput;
};


export type MutationUpdatePointArgs = {
  input: UpdatePointInput;
};


export type MutationCreatePointItemArgs = {
  input: CreatePointItemInput;
};


export type MutationUpdatePointItemArgs = {
  input: UpdatePointItemInput;
  id: Scalars['String'];
};


export type MutationCreateReferralCampaignArgs = {
  campaign: CreateReferralCampaignDto;
};


export type MutationUpdateReferralCampaignArgs = {
  campaign: UpdateReferralCampaignDto;
};


export type MutationCreateReferralRewardsArgs = {
  rewards: CreateReferralRewardsDto;
};


export type MutationCreateAutomationArgs = {
  input: CreateAutomationInput;
};


export type MutationUpdateAutomationArgs = {
  input: UpdateAutomationInput;
  id: Scalars['String'];
};


export type MutationJoinWaitlistArgs = {
  input: JoinWaitlistInput;
};


export type MutationSetPromoterSettingsArgs = {
  input: PromoterSettingsInput;
};


export type MutationCreateRedirectUrlArgs = {
  input: CreateRedirectUrlInput;
};


export type MutationCreateCompetitionArgs = {
  input: CreateCompetitionInput;
  eventId: Scalars['String'];
};


export type MutationUpdateCompetitionArgs = {
  input: CreateCompetitionInput;
  competitionId: Scalars['String'];
};


export type MutationUnpublishCompetitionArgs = {
  competitionId: Scalars['String'];
};


export type MutationDeleteCompetitionArgs = {
  competitionId: Scalars['String'];
};


export type MutationUpdateAndPublishCompetitionArgs = {
  input: PublishCompetitionInput;
  eventId: Scalars['String'];
};


export type MutationGrantCompetitionRewardArgs = {
  input: GrantCompetitionRewardInput;
};


export type MutationDeleteBroadcastArgs = {
  id: Scalars['String'];
};


export type MutationSendBroadcastArgs = {
  input: SendBroadcastInput;
};


export type MutationCreateTrackingCodeArgs = {
  input: TrackingCodeCreateInput;
};


export type MutationDeleteTrackingCodeArgs = {
  id: Scalars['String'];
};


export type MutationOnTrackingCodeVisitArgs = {
  input: TrackingCodeVisitInput;
};


export type MutationCreateOrderToTrackingCodeAssociationArgs = {
  input: TrackingCodeOrderInput;
};


export type MutationBroadcastReportingLinkOpenedArgs = {
  input: BroadcastReportingInputBase;
};


export type MutationBroadcastReportingOrderCreatedArgs = {
  input: BroadcastReportingOrderInput;
};


export type MutationBroadcastReportingSetAsSpamFromUserArgs = {
  input: BroadcastReportingInputBase;
};


export type MutationCreateReportScheduleArgs = {
  input: ReportScheduleInput;
  eventId: Scalars['String'];
};


export type MutationUpdateReportScheduleArgs = {
  input: ReportScheduleInput;
  reportScheduleId: Scalars['String'];
};


export type MutationDeleteReportScheduleArgs = {
  reportScheduleId: Scalars['String'];
};


export type MutationPauseReportScheduleArgs = {
  pause: Scalars['Boolean'];
  reportScheduleId: Scalars['String'];
};


export type MutationUpdateTicketResaleConfigArgs = {
  input: UpdateResaleConfigInput;
};


export type MutationCreateResaleTicketTypeArgs = {
  input: CreateResaleTicketTypeInput;
};


export type MutationUpdateResaleTicketTypeArgs = {
  input: CreateResaleTicketTypeInput;
  resaleTicketTypeId: Scalars['String'];
};


export type MutationDeleteResaleTicketTypeArgs = {
  resaleTicketTypeId: Scalars['String'];
};


export type MutationHideResaleTicketTypeFromPublicArgs = {
  resaleTicketTypeId: Scalars['String'];
};


export type MutationUnhideResaleTicketTypeFromPublicArgs = {
  resaleTicketTypeId: Scalars['String'];
};


export type MutationJoinResaleWaitlistArgs = {
  input: ResaleWaitlistInput;
};

export type MyEventsOrderByInput = {
  startDate?: Maybe<OrderByDirection>;
};

export type MyEventsWhereInput = {
  /** Event start date. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** Event end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** Filter on tickets that are (not) related to a membership ticket. */
  hasParentTicket?: Maybe<Scalars['Boolean']>;
};

export type MyLoggedInOrganizationsOutput = {
  __typename?: 'MyLoggedInOrganizationsOutput';
  id: Scalars['String'];
  slug: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  isLinked: Scalars['Boolean'];
};

export type NamingFieldInput = {
  id?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  type: NamingFieldType;
  label: Scalars['String'];
  required: Scalars['Boolean'];
  allTickets: Scalars['Boolean'];
  specificTicketTypes: Array<AccessCodeTicketInput>;
  orderKey: Scalars['Float'];
};

export type NamingFieldOutput = {
  __typename?: 'NamingFieldOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  type: NamingFieldType;
  label: Scalars['String'];
  required: Scalars['Boolean'];
  allTickets: Scalars['Boolean'];
  specificTicketTypes: Array<PublicReleaseTicketOutput>;
  orderKey: Scalars['Float'];
};

export enum NamingFieldType {
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  CompanyName = 'COMPANY_NAME',
  Gender = 'GENDER',
  BirthDate = 'BIRTH_DATE',
  PhoneNumber = 'PHONE_NUMBER',
  PostalCode = 'POSTAL_CODE',
  Email = 'EMAIL',
  OptInToMarketing = 'OPT_IN_TO_MARKETING',
  CustomText = 'CUSTOM_TEXT'
}

export enum NotificationType {
  Email = 'Email',
  Sms = 'SMS'
}

export type NumberStatistic = {
  __typename?: 'NumberStatistic';
  label: Scalars['String'];
  tooltip?: Maybe<StatisticTooltip>;
  value: Scalars['Float'];
};

export type OnsalePromoter = {
  __typename?: 'OnsalePromoter';
  onsalePromoterCompetition: OnsalePromoterCompetition;
  onsalePromoterOutsideCompetition: OnsalePromoterOutsideCompetition;
};

export type OnsalePromoterCompetition = {
  __typename?: 'OnsalePromoterCompetition';
  onsalePromoterRevenueViaReferral: PeReportStatistic;
  onsalePromoterOrdersViaReferral: PeReportStatistic;
  onsalePromoterNewCustomersReferred: PeReportStatistic;
  onsalePromoterReferrerEntriesAwarded: PeReportStatistic;
};

export type OnsalePromoterOutsideCompetition = {
  __typename?: 'OnsalePromoterOutsideCompetition';
  onsalePromoterOutsideRevenueViaReferral: PeReportStatistic;
  onsalePromoterOutsideOrdersViaReferral: PeReportStatistic;
  onsalePromoterOutsideNewCustomersReferred: PeReportStatistic;
  onsalePromoterOutsideReferrerPointsAwarded: PeReportStatistic;
  onsalePromoterOutsideDiscountValue: PeReportStatistic;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  orderType: OrderType;
  orderNumber: Scalars['String'];
  currency: Scalars['String'];
  /** Indicates if the order has been handled on success (e.g. ticket creation). */
  isHandled: Scalars['Boolean'];
  /** Indicates if the order is a full comp order (all tickets are comps). */
  isComp: Scalars['Boolean'];
  /** Buyer details. */
  buyerInformation?: Maybe<BuyerInformation>;
  /** Billing address provided by the customer. */
  billingAddress?: Maybe<Address>;
  /** Shipping address provided by the customer. */
  shippingAddress?: Maybe<Address>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress: Scalars['Boolean'];
  optInForMarketingPromotions?: Maybe<Scalars['Boolean']>;
  membershipSubscriptionId?: Maybe<Scalars['String']>;
  /** The release associated with the order. Returns null if the order is not bound to a release. */
  release?: Maybe<Release>;
  /** The release password associated with the order. Returns null if the order is not bound to a release password. */
  releasePassword?: Maybe<ReleasePassword>;
  /** Indicated whether the order has been paid with a subscription. */
  paidWithSubscription: Scalars['Boolean'];
  /** Current status of the order. */
  status: OrderStatus;
  /** Channel associated with the order. */
  channel: OrderChannel;
  /** Last used payment gateway. */
  paymentGateway?: Maybe<PaymentProvider>;
  /** The status of the latest transaction status. */
  transactionStatus?: Maybe<OrderTransactionStatus>;
  orderTicketCover?: Maybe<OrderTicketCover>;
  /** Timestamp when order confirmation has been sent. */
  orderConfirmationSentAt?: Maybe<Scalars['DateTime']>;
  /** The ip address of the client that is associated with this order. */
  clientIp: Scalars['String'];
  /** Amount of credits used in the order */
  usedCredits?: Maybe<Scalars['Float']>;
  usedPoints: Scalars['Float'];
  /** Amount of credits detucted from the total for changing the seats */
  changingSeatsCredits?: Maybe<Scalars['Float']>;
  /** Fee for changing seats. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  bookingFeeType: FeeType;
  /** Booking fee (amount or percentage based on type). */
  bookingFee: Scalars['Float'];
  /** Calculated booking fee. */
  bookingFeeAmount: Scalars['Float'];
  /** Fee charged for ticket transfer. */
  transferFee?: Maybe<Scalars['Float']>;
  /** The delivery method chosen by the customer. */
  deliveryMethod?: Maybe<ShippingOption>;
  /** Delivery fee (based on delivery method). */
  deliveryFee?: Maybe<Scalars['Float']>;
  /** Transaction fee type. */
  transactionFeeType?: Maybe<FeeType>;
  /** Transaction fee. */
  transactionFee?: Maybe<Scalars['Float']>;
  /** Transaction fee type. */
  transactionFeeType2?: Maybe<FeeType>;
  /** Transaction fee. */
  transactionFee2?: Maybe<Scalars['Float']>;
  /** Calculated transaction fee. */
  transactionFeeAmount?: Maybe<Scalars['Float']>;
  /** Order total (including fees and taxes). */
  total: Scalars['Float'];
  /** Time after which the order will be automatically expired */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** Total amount of refunds. */
  refundedAmount: Scalars['Float'];
  /** If the order is created from order abandoned email. */
  isCreatedFromRecoveredCart: Scalars['Boolean'];
  taxRate?: Maybe<Scalars['Float']>;
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod?: Maybe<TaxCalculationMethod>;
  /** Tax rate: taxRate * total */
  totalTax: Scalars['Float'];
  /** Subtotal: total - taxAmount */
  subtotal: Scalars['Float'];
  amountDue: Scalars['Float'];
  /** Whether the hardcopy tickets have been printed for an order */
  hardCopyTicketsPrinted: Scalars['Boolean'];
  /** How the customer heard about the items they are purchasing */
  heardAboutUs?: Maybe<HeardAboutUsType>;
  /** Heard about us details when heardAboutUs is other */
  heardAboutUsDetails?: Maybe<Scalars['String']>;
  /** How the customer heard about the items they are purchasing */
  dietaryRequirements?: Maybe<Scalars['String']>;
  /** Used to link an order to the referrer user that refered the order creator */
  referralId?: Maybe<Scalars['String']>;
  /**
   * Used to link an order to the referral code that was used to create the order.
   * Currently only used for competitions but will eventually be used for all referrals.
   */
  referralCode?: Maybe<ReferralCode>;
  /** Xero invoice ID if Xero integration is configured and an invoice was created for this order */
  xeroInvoiceId?: Maybe<Scalars['String']>;
  /** Amount of referral discount applied to the order. Used when there is a flat amount applied to the order */
  referralDiscountAmount?: Maybe<Scalars['Float']>;
  compReason?: Maybe<Scalars['String']>;
  expiresInSeconds: Scalars['Float'];
  customTaxRate?: Maybe<Scalars['Float']>;
  customTaxAmount?: Maybe<Scalars['Float']>;
  orderPlan?: Maybe<OrderPlan>;
  /** The event associated with the order. Returns null if the order is not bound to an event. */
  event?: Maybe<Event>;
  /** The payment provider which processed the last transaction. */
  gateway?: Maybe<PaymentIntegrationOutput>;
  /** The payment method used in the last transaction. E.g. credit card, Afterpay, etc. */
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  transactions: OrderTransactionConnection;
  posTransactions: PosTransactionConnection;
  /** List of the order's line items. */
  lineItems: LineItemConnection;
  /** The created tickets for the order. Only returns the membership tickets (including add-on tickets) for memberships. */
  tickets: Array<Ticket>;
  /** Order owner. Not all orders have users. */
  user?: Maybe<User>;
  /** Order is created by */
  createdBy?: Maybe<User>;
  /** The membership associated with the order. Returns null if the order is not bound to a membership. */
  membership?: Maybe<Membership>;
  promoCode?: Maybe<PromoCodeUnion>;
  multiBuyPromotion?: Maybe<MultiBuyPromotionUnion>;
  history: HistoryConnection;
  refunds: Array<Refund>;
  /** Details about how the order can be refunded based on payment methods and how much has already been refunded */
  refundableState: RefundableState;
  pos?: Maybe<Pos>;
  point?: Maybe<Point>;
};


export type OrderTransactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TransactionOrderByInput>;
};


export type OrderPosTransactionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PosTransactionOrderByInput>;
};


export type OrderLineItemsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type OrderHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Order status */
export enum OrderChannel {
  Online = 'Online',
  BackOffice = 'BackOffice',
  Pos = 'POS'
}

export type OrderChargeSetting = {
  __typename?: 'OrderChargeSetting';
  percentage?: Maybe<Scalars['Float']>;
  pricingStrategy: PricingStrategy;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  pageInfo: PageInfo;
  edges: Array<OrderEdge>;
  totalCount: Scalars['Int'];
};

export type OrderCreateOutput = {
  __typename?: 'OrderCreateOutput';
  jobId: Scalars['String'];
  order?: Maybe<Order>;
  createOrderError?: Maybe<Array<CreateOrderErrorUnion>>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String'];
  node: Order;
};

export type OrderIdsOnlineOutput = {
  __typename?: 'OrderIdsOnlineOutput';
  orderId: Scalars['String'];
};

export type OrderOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type OrderPlan = {
  __typename?: 'OrderPlan';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  orderId: Scalars['String'];
  schedule: ScheduleOption;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  status: OrderPlanStatus;
  payments: Array<OrderPlanPayment>;
  billingDetails?: Maybe<BillingDetails>;
};

export type OrderPlanPayment = {
  __typename?: 'OrderPlanPayment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  amountDue: Scalars['Float'];
  dueAt: Scalars['DateTime'];
  paidAt?: Maybe<Scalars['DateTime']>;
  refundedAt?: Maybe<Scalars['DateTime']>;
  status: OrderPlanPaymentStatus;
  stripeInvoiceUrl?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  attemptCount?: Maybe<Scalars['Float']>;
  failureReason?: Maybe<Scalars['String']>;
};

export enum OrderPlanPaymentStatus {
  Upcoming = 'upcoming',
  Paid = 'paid',
  Refunded = 'refunded',
  PartiallyRefunded = 'partiallyRefunded',
  Cancelled = 'cancelled'
}

export enum OrderPlanStatus {
  Pending = 'pending',
  Active = 'active',
  Overdue = 'overdue',
  Cancelled = 'cancelled',
  Completed = 'completed',
  Refunded = 'refunded',
  Stopped = 'stopped'
}

export type OrderRefundDetailsOutput = {
  __typename?: 'OrderRefundDetailsOutput';
  orderId: Scalars['String'];
  baseTickets: Scalars['Float'];
  ticketFees: Scalars['Float'];
  bookingFees: Scalars['Float'];
  transactionFees: Scalars['Float'];
  deliveryFees: Scalars['Float'];
  refundedAmount: Scalars['Float'];
  addons: Scalars['Float'];
  creditsUsed: Scalars['Float'];
};

/** The refund state for an order based on how it was paid for and previous refunds. */
export enum OrderRefundState {
  FullGateway = 'FULL_GATEWAY',
  PartialGateway = 'PARTIAL_GATEWAY',
  ManualOnly = 'MANUAL_ONLY',
  FullyRefunded = 'FULLY_REFUNDED',
  None = 'NONE'
}

/** Order status */
export enum OrderStatus {
  Pending = 'Pending',
  Hold = 'Hold',
  Paid = 'Paid',
  PartPaid = 'PartPaid',
  Completed = 'Completed',
  Canceled = 'Canceled',
  Expired = 'Expired'
}

export type OrderTicketCover = {
  __typename?: 'OrderTicketCover';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  purchasedAt?: Maybe<Scalars['DateTime']>;
  issuedAt?: Maybe<Scalars['DateTime']>;
  promoterFeeAmount: Scalars['Float'];
  platformFeeAmount: Scalars['Float'];
  totalFeeAmount?: Maybe<Scalars['Float']>;
  ticketTicketCovers?: Maybe<Array<TicketTicketCover>>;
  /** Order that the ticket cover is purchased for */
  order: Order;
  retryCount: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour?: Maybe<TicketCoverPurchaseBehaviour>;
};

/** A transaction represents an exchange of money as part of an order. */
export type OrderTransaction = {
  __typename?: 'OrderTransaction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  kind: OrderTransactionKind;
  status: OrderTransactionStatus;
  amount: Scalars['Float'];
  /** Payment method used with Stripe. Null if not a Stripe transaction. */
  stripePaymentMethod?: Maybe<StripePaymentMethod>;
  /** The amount of the transaction that is held for Flicket at the point of sale as a platform commission. */
  platformAdjustment: Scalars['Float'];
};

export type OrderTransactionConnection = {
  __typename?: 'OrderTransactionConnection';
  pageInfo: PageInfo;
  edges: Array<OrderTransactionEdge>;
  totalCount: Scalars['Int'];
};

export type OrderTransactionEdge = {
  __typename?: 'OrderTransactionEdge';
  cursor: Scalars['String'];
  node: OrderTransaction;
};

/** The different kinds of order transactions. */
export enum OrderTransactionKind {
  Sale = 'Sale',
  Refund = 'Refund'
}

/** Payment statuses */
export enum OrderTransactionStatus {
  Waiting = 'Waiting',
  Pending = 'Pending',
  Error = 'Error',
  Failure = 'Failure',
  Success = 'Success',
  Unknown = 'Unknown',
  Cancelled = 'Cancelled'
}

/** Order type */
export enum OrderType {
  Default = 'DEFAULT',
  RenewMemberships = 'RENEW_MEMBERSHIPS',
  ChangeSeats = 'CHANGE_SEATS',
  RenameTickets = 'RENAME_TICKETS',
  TicketTransfer = 'TICKET_TRANSFER',
  MembershipTicketTransfer = 'MEMBERSHIP_TICKET_TRANSFER',
  ResaleTicketTransfer = 'RESALE_TICKET_TRANSFER',
  PointPurchase = 'POINT_PURCHASE',
  PaymentPlan = 'PAYMENT_PLAN',
  Usher = 'USHER'
}

export type OrderWhereInput = {
  source?: Maybe<PointReportingFilterSource>;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  gateway?: Maybe<PaymentProvider>;
  createdById?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  dates?: Maybe<Array<Scalars['DateTime']>>;
  status?: Maybe<Array<OrderStatus>>;
  releaseId?: Maybe<Scalars['String']>;
  release?: Maybe<Scalars['String']>;
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  paymentPlans?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Organization name. */
  name: Scalars['String'];
  /** Organization slug. */
  slug: Scalars['String'];
  /** Contact e-mail address. */
  email: Scalars['String'];
  /** Contact phone. */
  phone: Scalars['String'];
  /** Tax number */
  taxNumber?: Maybe<Scalars['String']>;
  /** Currency */
  currency?: Maybe<Scalars['String']>;
  /** Address details. */
  address?: Maybe<Address>;
  /** Landing page settings. */
  landingPage: LandingPage;
  /** Show memberships in client navigation. */
  showMemberships: Scalars['Boolean'];
  /** Show memberships renewal banner. */
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  /** The support information of the organistion */
  supportInfo?: Maybe<Scalars['String']>;
  /** The privacy policy of the organistion */
  privacyPolicy?: Maybe<Scalars['String']>;
  /** The terms and conditions of the organistion */
  termsAndConditions?: Maybe<Scalars['String']>;
  /** Marketing settings. */
  marketing?: Maybe<Marketing>;
  /** Branding settings. */
  branding: BrandingOutputBase;
  /** Shipping labels. */
  shippingLabels?: Maybe<ShippingLabels>;
  /** Beta features. */
  features?: Maybe<Array<Scalars['String']>>;
  timezone: Scalars['String'];
  locale: Scalars['String'];
  helpLink?: Maybe<Scalars['String']>;
  ticketCoverPlatformFeePercentage?: Maybe<Scalars['Float']>;
  taxRate: Scalars['Float'];
  /** Tax calculation method. either inclusive or exclusive. */
  taxCalculationMethod: TaxCalculationMethod;
  defaultLanguage: Scalars['String'];
  customTaxRate?: Maybe<Scalars['Float']>;
  customTaxName?: Maybe<Scalars['String']>;
  platformChargeSettings?: Maybe<PlatformChargeSettings>;
  point: Point;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  pageInfo: PageInfo;
  edges: Array<OrganizationEdge>;
  totalCount: Scalars['Int'];
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  cursor: Scalars['String'];
  node: Organization;
};

export type OrganizationOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type OrganizationOutputBranding = {
  __typename?: 'OrganizationOutputBranding';
  /** Organization logo. */
  logo?: Maybe<ExtendedFile>;
  /** Organization mobile logo. */
  mobileLogo?: Maybe<ExtendedFile>;
  /** Organization favicon. */
  favicon?: Maybe<ExtendedFile>;
  /** Organization background image. */
  background?: Maybe<ExtendedFile>;
  /** Organization full background image. */
  fullBackground?: Maybe<ExtendedFile>;
  /** Primary color. */
  primaryColor: Scalars['String'];
  /** Secondary color. */
  secondaryColor: Scalars['String'];
  /** Grey scale option. */
  greyScale?: Maybe<GreyScale>;
  P300: Scalars['String'];
  P200: Scalars['String'];
  P100: Scalars['String'];
  S300: Scalars['String'];
  S200: Scalars['String'];
  S100: Scalars['String'];
  N800: Scalars['String'];
  N700: Scalars['String'];
  N600: Scalars['String'];
  N500: Scalars['String'];
  N400: Scalars['String'];
  N300: Scalars['String'];
  N200: Scalars['String'];
  N100: Scalars['String'];
};

export type OrganizationWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

/** Facebook page. */
export type PageListsOutput = {
  __typename?: 'PageListsOutput';
  id: Scalars['String'];
  name: Scalars['String'];
  access_token: Scalars['String'];
};

/** Address details. */
export type PartialAddress = {
  __typename?: 'PartialAddress';
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

/** Address details. */
export type PartialAddressInput = {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type PartPaidTicketStats = {
  __typename?: 'PartPaidTicketStats';
  orderId: Scalars['String'];
  ticketCount: Scalars['Float'];
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
};

export type PaymentError = {
  __typename?: 'PaymentError';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

/** Represents the order creation result. */
export type PaymentIntegrationOutput = {
  __typename?: 'PaymentIntegrationOutput';
  id?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** Integration type */
  type?: Maybe<IntegrationType>;
  /** Integration name */
  name?: Maybe<Scalars['String']>;
  /** External public key (can be used in customer frontend) */
  publicKey?: Maybe<Scalars['String']>;
  /** Webhook URL to point integration to */
  webhookUrl?: Maybe<Scalars['String']>;
  /** Transaction fee type. */
  transactionFeeType?: Maybe<FeeType>;
  /** Transaction fee. */
  transactionFee?: Maybe<Scalars['Float']>;
  /** Transaction fee type(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFeeType2?: Maybe<FeeType>;
  /** Transaction fee(only for stripe). Users can input both fixed and percentage fee for stripe(0.3$+2%). */
  transactionFee2?: Maybe<Scalars['Float']>;
  /** If payment gateways have different option types this field is used to specify which one to force */
  paymentMethodType?: Maybe<Scalars['String']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  stripeConnectAccount?: Maybe<StripeConnectAccount>;
};

export type PaymentOption = {
  __typename?: 'PaymentOption';
  id: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
  type: IntegrationType;
  paymentMethod?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType?: Maybe<FeeType>;
  transactionFee2?: Maybe<Scalars['Float']>;
  transactionFeeType2?: Maybe<FeeType>;
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  calculatedTransactionFee?: Maybe<Scalars['Float']>;
  paymentPlanMetadata?: Maybe<PaymentPlanMetadata>;
};

export type PaymentOptions = {
  __typename?: 'PaymentOptions';
  orderId: Scalars['String'];
  options?: Maybe<Array<PaymentOption>>;
};

export type PaymentPlanMetadata = {
  __typename?: 'PaymentPlanMetadata';
  depositAmount?: Maybe<Scalars['Float']>;
  installmentAmount?: Maybe<Scalars['Float']>;
  installmentCount: Scalars['Float'];
  paymentPlanFeePercentage: Scalars['Float'];
};

export type PaymentPlanSettings = {
  __typename?: 'PaymentPlanSettings';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  disabledAt?: Maybe<Scalars['String']>;
  scheduleOptions?: Maybe<Array<ScheduleOption>>;
  gracePeriodDays: Scalars['Float'];
};

/** Payment providers and methods */
export enum PaymentProvider {
  Stripe = 'Stripe',
  StripeAfterpay = 'StripeAfterpay',
  Laybuy = 'Laybuy',
  Afterpay = 'Afterpay',
  Windcave = 'Windcave',
  MpAiSa = 'MPAiSA',
  Pin = 'Pin',
  Credits = 'Credits',
  Points = 'Points',
  Pos = 'Pos',
  Cash = 'Cash',
  Eftpos = 'Eftpos',
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  Other = 'Other'
}

export type PaymentResult = PaymentSuccess | PaymentError;

export type PaymentSuccess = {
  __typename?: 'PaymentSuccess';
  /** The payment gateway. */
  gateway: PaymentProvider;
  /** The payment url provided by the payment gateway. */
  redirectTo?: Maybe<Scalars['String']>;
};

export type PercentageStatistic = {
  __typename?: 'PercentageStatistic';
  label: Scalars['String'];
  tooltip?: Maybe<StatisticTooltip>;
  value: Scalars['Float'];
  percentage: Scalars['Float'];
};

/** Data to supply the audience page of the post event (PE) report */
export type PeReportAudienceOutput = {
  __typename?: 'PEReportAudienceOutput';
  insights: Array<PeReportStatistic>;
  trafficChannels: Array<PercentageStatistic>;
};

/** Data to supply the overview page of the post event (PE) report */
export type PeReportDemographicsOutput = {
  __typename?: 'PEReportDemographicsOutput';
  ticketsSold?: Maybe<Array<PercentageStatistic>>;
  audienceViews?: Maybe<Array<PercentageStatistic>>;
  gender?: Maybe<GenderBreakDownOutput>;
  age?: Maybe<AgeBreakDownOutput>;
};

export type PeReportEvent = {
  __typename?: 'PEReportEvent';
  title?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  location?: Maybe<Scalars['String']>;
};

/** Data to supply the overview page of the post event (PE) report */
export type PeReportOverviewOutput = {
  __typename?: 'PEReportOverviewOutput';
  event: PeReportEvent;
  financialSales: Array<FinancialSales>;
  statistics: Array<PeReportStatistic>;
  ticketSalesStatistics: Array<PeReportStatistic>;
  salesBreakdown: SalesBreakdown;
};

/** Data to supply the main sale referral page of the post event (PE) report */
export type PeReportReferralOutsideMainSaleOutput = {
  __typename?: 'PEReportReferralOutsideMainSaleOutput';
  promoter: Array<PeReportStatistic>;
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
};

/** Data to supply the post event (PE) report for competition stats */
export type PeReportRegistrationCompetitionOutput = {
  __typename?: 'PEReportRegistrationCompetitionOutput';
  overview: Array<PeReportStatistic>;
  promoter: Array<PeReportStatistic>;
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
  hasCompetition: Scalars['Boolean'];
};

/** Data to supply the overview page of the post event (PE) report */
export type PeReportRegistrationOutput = {
  __typename?: 'PEReportRegistrationOutput';
  overview: Array<PeReportStatistic>;
  promoter: Array<PeReportStatistic>;
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
};

export type PeReportStatistic = NumberStatistic | StringStatistic | MoneyStatistic | PercentageStatistic;

/** Data to supply the overview page of the post event (PE) report */
export type PeReportWaitlistOutput = {
  __typename?: 'PEReportWaitlistOutput';
  statistics: Array<PeReportStatistic>;
};

/** Permission for a specific action */
export enum Permission {
  AccountCreate = 'ACCOUNT_CREATE',
  AccountRead = 'ACCOUNT_READ',
  AccountUpdate = 'ACCOUNT_UPDATE',
  AccountDelete = 'ACCOUNT_DELETE',
  CustomerCreate = 'CUSTOMER_CREATE',
  CustomerRead = 'CUSTOMER_READ',
  CustomerUpdate = 'CUSTOMER_UPDATE',
  CustomerDelete = 'CUSTOMER_DELETE',
  CustomerCredits = 'CUSTOMER_CREDITS',
  CustomerImport = 'CUSTOMER_IMPORT',
  CustomerExport = 'CUSTOMER_EXPORT',
  EventCreate = 'EVENT_CREATE',
  EventRead = 'EVENT_READ',
  EventUpdate = 'EVENT_UPDATE',
  EventDelete = 'EVENT_DELETE',
  MembershipCreate = 'MEMBERSHIP_CREATE',
  MembershipRead = 'MEMBERSHIP_READ',
  MembershipUpdate = 'MEMBERSHIP_UPDATE',
  MembershipDelete = 'MEMBERSHIP_DELETE',
  OrderCreate = 'ORDER_CREATE',
  OrderRead = 'ORDER_READ',
  OrderUpdate = 'ORDER_UPDATE',
  OrderDelete = 'ORDER_DELETE',
  OrderCancel = 'ORDER_CANCEL',
  OrderComp = 'ORDER_COMP',
  OrderRefund = 'ORDER_REFUND',
  OrderExport = 'ORDER_EXPORT',
  OrganizationCreate = 'ORGANIZATION_CREATE',
  OrganizationDelete = 'ORGANIZATION_DELETE',
  OrganizationSettings = 'ORGANIZATION_SETTINGS',
  OrganizationBranding = 'ORGANIZATION_BRANDING',
  OrganizationIntegrations = 'ORGANIZATION_INTEGRATIONS',
  OrganizationVenues = 'ORGANIZATION_VENUES',
  OrganizationLegal = 'ORGANIZATION_LEGAL',
  ReportingRead = 'REPORTING_READ',
  ReportingTabSales = 'REPORTING_TAB_SALES',
  ReportingTabInsights = 'REPORTING_TAB_INSIGHTS',
  ReportingTabPromotions = 'REPORTING_TAB_PROMOTIONS',
  ReportingTabEvents = 'REPORTING_TAB_EVENTS',
  ReportingSales = 'REPORTING_SALES',
  ReportingFinancial = 'REPORTING_FINANCIAL',
  ReportingScans = 'REPORTING_SCANS',
  ReportingPos = 'REPORTING_POS',
  ReportingPostevent = 'REPORTING_POSTEVENT',
  ReportingCapacity = 'REPORTING_CAPACITY',
  ReportingFaceValue = 'REPORTING_FACE_VALUE',
  ReportingGatewayCount = 'REPORTING_GATEWAY_COUNT',
  SeasonCreate = 'SEASON_CREATE',
  SeasonRead = 'SEASON_READ',
  SeasonUpdate = 'SEASON_UPDATE',
  SeasonDelete = 'SEASON_DELETE',
  TicketRead = 'TICKET_READ',
  TicketPrint = 'TICKET_PRINT',
  TicketRelease = 'TICKET_RELEASE',
  TicketImport = 'TICKET_IMPORT',
  TicketExport = 'TICKET_EXPORT',
  PosRead = 'POS_READ',
  PosCreate = 'POS_CREATE',
  PosOperatorCreate = 'POS_OPERATOR_CREATE',
  ScoutRead = 'SCOUT_READ',
  ScoutDelete = 'SCOUT_DELETE',
  SocialRead = 'SOCIAL_READ',
  SocialCreate = 'SOCIAL_CREATE',
  WaitlistRead = 'WAITLIST_READ',
  WaitlistExport = 'WAITLIST_EXPORT',
  AutomationCreate = 'AUTOMATION_CREATE',
  AutomationRead = 'AUTOMATION_READ',
  AutomationUpdate = 'AUTOMATION_UPDATE',
  PointsRead = 'POINTS_READ',
  PointsUpdate = 'POINTS_UPDATE',
  PointsCreate = 'POINTS_CREATE',
  BroadcastCreate = 'BROADCAST_CREATE',
  BroadcastRead = 'BROADCAST_READ',
  CompetitionEntriesRead = 'COMPETITION_ENTRIES_READ',
  TrackingCodesExport = 'TRACKING_CODES_EXPORT'
}

export type PinPaymentChargeResponse = {
  __typename?: 'PinPaymentChargeResponse';
  redirectUrl: Scalars['String'];
};

export type PinPaymentContext = {
  __typename?: 'PinPaymentContext';
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  isSandbox: Scalars['Boolean'];
  orderId?: Maybe<Scalars['String']>;
};

export type PlatformChargeSettings = {
  __typename?: 'PlatformChargeSettings';
  orderChargeSettings?: Maybe<Array<OrderChargeSetting>>;
};

export type Point = {
  __typename?: 'Point';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  name: Scalars['String'];
  termsAndConditions?: Maybe<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
  pointItems: Array<PointItem>;
  gateways: Array<Integration>;
};

export type PointItem = {
  __typename?: 'PointItem';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  point: Point;
  name: Scalars['String'];
  price: Scalars['Float'];
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<ExtendedFile>;
  banner?: Maybe<ExtendedFile>;
  backgroundImage?: Maybe<ExtendedFile>;
  descriptionImage?: Maybe<ExtendedFile>;
  isVisible: Scalars['Boolean'];
};

export enum PointReportingFilterSource {
  Overall = 'OVERALL',
  Membership = 'MEMBERSHIP',
  Event = 'EVENT',
  Package = 'PACKAGE'
}

/** Represents a POS terminal. */
export type Pos = {
  __typename?: 'Pos';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Friendly name of the POS terminal. */
  name: Scalars['String'];
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
  /** Available payment option incl. fees. */
  paymentOptions: PosPaymentOptions;
  /** Available delivery options for the POS terminal. */
  deliveryOptions: Array<ShippingOption>;
  /** The beginning float of change for the POS terminal. */
  cashAmount?: Maybe<Scalars['Float']>;
  /** Whether receipts are turned on. */
  enableReceipts: Scalars['Boolean'];
  sessions?: Maybe<Array<PosSession>>;
  event: Event;
  release: Release;
  users: Array<PosUser>;
};

export type PosFinancialData = {
  __typename?: 'PosFinancialData';
  grossSales: PeReportStatistic;
  eftpos: PeReportStatistic;
  cash: PeReportStatistic;
  creditCard: PeReportStatistic;
};

export type PosFloatData = {
  __typename?: 'PosFloatData';
  startFloat: PeReportStatistic;
  cashReceived: PeReportStatistic;
  cashReturned: PeReportStatistic;
  endFloat: PeReportStatistic;
};

export type PosPaymentFee = {
  __typename?: 'PosPaymentFee';
  /** Fee type. */
  feeType: FeeType;
  /** Fee amount */
  feeAmount: Scalars['Float'];
};

export type PosPaymentFeeInput = {
  /** Fee type. */
  feeType: FeeType;
  /** Fee amount */
  feeAmount: Scalars['Float'];
};

/** Represents POS options incl. fees. */
export type PosPaymentOptions = {
  __typename?: 'PosPaymentOptions';
  /** Cash fee (normally $0). */
  cash?: Maybe<PosPaymentFee>;
  /** Eftpos fee. */
  eftpos?: Maybe<PosPaymentFee>;
  /** Credit card fee. */
  cc?: Maybe<PosPaymentFee>;
  /** MPAiSA fee. */
  MPAiSA?: Maybe<PosPaymentFee>;
};

export type PosPaymentOptionsInput = {
  /** Cash fee (normally $0). */
  cash?: Maybe<PosPaymentFeeInput>;
  /** Eftpos fee. */
  eftpos?: Maybe<PosPaymentFeeInput>;
  /** Credit card fee. */
  cc?: Maybe<PosPaymentFeeInput>;
  /** MPAiSA fee. */
  MPAiSA?: Maybe<PosPaymentFeeInput>;
};

export type PosReleaseInput = {
  releaseId?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  tickets: Array<PosTicketDto>;
  posPaymentOptions?: Maybe<PosPaymentOptionsInput>;
  bookingFeeType?: Maybe<FeeType>;
  bookingFee?: Maybe<Scalars['Float']>;
};

export type PosSaleOverview = {
  __typename?: 'PosSaleOverview';
  ticketSoldAtDoor: PeReportStatistic;
  posOrders: PeReportStatistic;
  averageOrderSize: PeReportStatistic;
  ticketPerHour: PeReportStatistic;
};

export type PosSalesOverviewOutput = {
  __typename?: 'PosSalesOverviewOutput';
  soldAtTheDoor: Scalars['Float'];
  orders: Scalars['Float'];
  soldOnline: Scalars['Float'];
  scannedPercentage: Scalars['Float'];
  average: Scalars['Float'];
  perHour: Scalars['Float'];
};

export type PosSession = {
  __typename?: 'PosSession';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  pos: Pos;
  operator?: Maybe<User>;
  startedAt: Scalars['DateTime'];
  endedAt?: Maybe<Scalars['DateTime']>;
  startCash?: Maybe<Scalars['Float']>;
  endCash?: Maybe<Scalars['Float']>;
};

export type PosTicketDto = {
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
  isActive: Scalars['Boolean'];
  price: Scalars['Float'];
};

export type PostmarkEmailStatsOverviewOutput = {
  __typename?: 'PostmarkEmailStatsOverviewOutput';
  sent: Scalars['Float'];
  bounced: Scalars['Float'];
  smtpApiErrors: Scalars['Float'];
  bounceRate: Scalars['Float'];
  spamComplaints: Scalars['Float'];
  spamComplaintsRate: Scalars['Float'];
  opens: Scalars['Float'];
  uniqueOpens: Scalars['Float'];
  tracked: Scalars['Float'];
  withLinkTracking: Scalars['Float'];
  withOpenTracking: Scalars['Float'];
  totalTrackedLinksSent: Scalars['Float'];
  uniqueLinksClicked: Scalars['Float'];
  totalClicks: Scalars['Float'];
  withClientRecorded: Scalars['Float'];
  withPlatformRecorded: Scalars['Float'];
};

export type PosTransaction = {
  __typename?: 'PosTransaction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Used payment gateway. */
  paymentGateway: PaymentProvider;
  amount: Scalars['Float'];
  fee: Scalars['Float'];
  /** Return amount (only relevant for cash). */
  returned: Scalars['Float'];
};

export type PosTransactionConnection = {
  __typename?: 'PosTransactionConnection';
  pageInfo: PageInfo;
  edges: Array<PosTransactionEdge>;
  totalCount: Scalars['Int'];
};

export type PosTransactionEdge = {
  __typename?: 'PosTransactionEdge';
  cursor: Scalars['String'];
  node: PosTransaction;
};

export type PosTransactionOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
};

export type PosUser = {
  __typename?: 'PosUser';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
};

/** Create or update a presale. */
export type PresaleReleaseInput = {
  releaseId?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  tickets: Array<AccessCodeTicketInput>;
  accessCodes: Array<AccessCodeInput>;
  accessScreenMessage?: Maybe<Scalars['String']>;
  accessScreenImage?: Maybe<Scalars['Upload']>;
};

/** Portable representation of presale release. */
export type PresaleReleaseOutput = {
  __typename?: 'PresaleReleaseOutput';
  releaseId: Scalars['String'];
  name: Scalars['String'];
  /** The presale URL slug */
  slug: Scalars['String'];
  isActive: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  status?: Maybe<PresaleStatus>;
  /** The tickets that can be purchased with this presale */
  tickets: Array<PresaleTicketOutput>;
  /** The access codes that can be used with this presale */
  accessCodes: Array<AccessCodeOutput>;
  accessScreenMessage?: Maybe<Scalars['String']>;
  /** Image to be shown on the customer portal of the ticket . Returns null if the not set. */
  accessScreenImage?: Maybe<ExtendedFile>;
};

export enum PresaleStatus {
  Scheduled = 'SCHEDULED',
  PendingScheduled = 'PENDING_SCHEDULED',
  Completed = 'COMPLETED',
  Disabled = 'DISABLED',
  OnSale = 'ON_SALE',
  PendingOnSale = 'PENDING_ON_SALE'
}

/** Presale ticket output */
export type PresaleTicketOutput = {
  __typename?: 'PresaleTicketOutput';
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
};

export enum PricingStrategy {
  LineItemsExcComp = 'LINE_ITEMS_EXC_COMP',
  LineItemsComp = 'LINE_ITEMS_COMP',
  PaymentPlanFee = 'PAYMENT_PLAN_FEE'
}

export type PromoCodeUnion = EventPromoCode | MembershipPromoCode;

export type PromoterOnsaleOutput = {
  __typename?: 'PromoterOnsaleOutput';
  revenue: Scalars['Float'];
  newCustomers: Scalars['Float'];
  referralEntriesAwarded: Scalars['Float'];
  orders: Scalars['Float'];
};

export type PromoterRegistrationOutput = {
  __typename?: 'PromoterRegistrationOutput';
  newCustomers: Scalars['Float'];
  referralEntriesAwarded: Scalars['Float'];
  registrationsViaReferral: Scalars['Float'];
};

export type PromoterSettingsInput = {
  eventId: Scalars['String'];
  config?: Maybe<Array<PromoterShareActionInput>>;
  socialPages?: Maybe<Array<EventSocialPageInput>>;
  rewardSettings?: Maybe<RewardSettingsDto>;
};

export type PromoterSettingsOutput = {
  __typename?: 'PromoterSettingsOutput';
  configurations?: Maybe<Array<PromoterShareAction>>;
  socialPages?: Maybe<Array<EventSocialPage>>;
  /** The referral campaign associated with this event */
  referralCampaign?: Maybe<ReferralCampaign>;
};


export type PromoterSettingsOutputReferralCampaignArgs = {
  eventId: Scalars['String'];
};

export type PromoterShareAction = {
  __typename?: 'PromoterShareAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  platform: Scalars['String'];
  actionType: Scalars['String'];
  socialLinkType?: Maybe<Scalars['String']>;
};

export type PromoterShareActionInput = {
  platform: Scalars['String'];
  actionType: Scalars['String'];
};

export type PublicReleaseTicketInput = {
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
  /** The ticket name */
  name: Scalars['String'];
  /** The ticket zone name */
  zoneName?: Maybe<Scalars['String']>;
  /** The ticket quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** The ticket price */
  price?: Maybe<Scalars['Float']>;
  /** If ticket is hidden by the release */
  isHidden: Scalars['Boolean'];
};

/** Listed release ticket (ticketType or addon). */
export type PublicReleaseTicketOutput = {
  __typename?: 'PublicReleaseTicketOutput';
  /** The ticket id */
  id: Scalars['String'];
  /** The type of ticket */
  type: TicketTypeKind;
  /** The ticket name */
  name: Scalars['String'];
  /** The ticket zone name */
  zoneName?: Maybe<Scalars['String']>;
  /** The ticket quantity */
  quantity?: Maybe<Scalars['Float']>;
  /** The ticket price */
  price?: Maybe<Scalars['Float']>;
  /** If ticket is hidden by the release */
  isHidden: Scalars['Boolean'];
};

export type PublishCompetitionInput = {
  competitionType: CompetitionType;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  referralRewardValue?: Maybe<Scalars['Int']>;
  bannerImage?: Maybe<Scalars['Upload']>;
  prizes?: Maybe<Array<CompetitionPrizeInput>>;
  promoter?: Maybe<Array<CompetitionPromoterInput>>;
  competitionId?: Maybe<Scalars['String']>;
};

export type PurchaseHistoryArgs = {
  __typename?: 'PurchaseHistoryArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  eventIds: Array<Scalars['String']>;
};

export type PurchaseRestriction = {
  __typename?: 'PurchaseRestriction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  restrictedType: LineItemType;
  restrictedId: Scalars['ID'];
  quantity: Scalars['Int'];
  requiredEntities: Array<PurchaseRestrictionRequiredEntity>;
  isExclusive: Scalars['Boolean'];
  isAlwaysVisible: Scalars['Boolean'];
};

export type PurchaseRestrictionInput = {
  restrictedType: LineItemType;
  restrictedId: Scalars['ID'];
  quantity: Scalars['Int'];
  requiredEntities: Array<PurchaseRestrictionRequiredEntityInput>;
  isExclusive?: Maybe<Scalars['Boolean']>;
  isAlwaysVisible?: Maybe<Scalars['Boolean']>;
};

export type PurchaseRestrictionRequiredEntity = {
  __typename?: 'PurchaseRestrictionRequiredEntity';
  requiredType: LineItemType;
  requiredId: Scalars['ID'];
};

export type PurchaseRestrictionRequiredEntityInput = {
  requiredType: LineItemType;
  requiredId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  isLoggedIn: Scalars['Boolean'];
  me: User;
  myLoggedInOrganizations?: Maybe<Array<MyLoggedInOrganizationsOutput>>;
  /** Fetches users (paginated). */
  users: UserConnection;
  /** Fetches users updated between given date range. Dates supplied in organizations timezone. */
  usersUpdatedBetween: Array<User>;
  /** Fetches a user. */
  user: User;
  /** Fetches a users referral ID. */
  findUserReferralId: User;
  posUsers: Array<User>;
  accountsList: UserConnection;
  linkedAccountUser: LinkedAccountUser;
  poses: Array<Pos>;
  pos: Pos;
  getPosReleasePaymentOptions?: Maybe<ReleasePosPaymentOptions>;
  posSessions: Array<PosSession>;
  events: EventConnection;
  event: Event;
  eventsById: Array<Event>;
  getEventAndReleaseForCustomer: EventsWithAccessControlOutput;
  getEventForCompetitionReporting: EventForCompetitionReportingEntity;
  myEvents: Array<Event>;
  eventForecast: EventForecastOutput;
  hasSeatedEvent: Scalars['Boolean'];
  ticketTypes: Array<TicketType>;
  ticketTypesById: Array<TicketType>;
  releases: Array<Release>;
  getReleasesForPrivateLinks: Array<Release>;
  release: Release;
  getReleaseBySlug?: Maybe<Release>;
  getPublicReleaseTickets?: Maybe<Array<PublicReleaseTicketOutput>>;
  eventPromoCodes: Array<EventPromoCode>;
  eventMultiBuyPromotions: Array<EventMultiBuyPromotion>;
  eventAddons: Array<EventAddon>;
  getAccessCode: AccessCodeReleaseOutput;
  getPaginatedAccessCode: AccessCodeConnection;
  getAccessCodes: Array<AccessCodeReleaseOutput>;
  listPresales: Array<PresaleReleaseOutput>;
  listNamingFields: Array<NamingFieldOutput>;
  seasons: Array<Season>;
  eventOrMembershipSeasons: Array<Season>;
  season: Season;
  integrations: Array<Integration>;
  integration: Integration;
  stripeConnectUrl: Scalars['String'];
  getConsentUrl: Scalars['String'];
  getXeroAccounts: XeroAllAccountsOutput;
  getXeroConfiguration?: Maybe<XeroConfigurationOutput>;
  disableXeroIntegration: Scalars['Boolean'];
  hasXeroIntegration: Scalars['Boolean'];
  disableShopifyIntegration: Scalars['Boolean'];
  customerOrders: Array<ShopifyCustomerOrder>;
  deleteAdobeIntegration: Scalars['Boolean'];
  adobeIntegration?: Maybe<AdobeExperiencePlatformIntegration>;
  currentOrganization: CurrentOrganizationOutput;
  /** [super-admin] | Fetches organizations (paginated) */
  organizations: OrganizationConnection;
  /** [super-admin] | Fetches an organization by id */
  organization: Organization;
  /** [app] | Fetches organization-names and Ids (paginated) */
  organizationNames: OrganizationConnection;
  /** Fetches orders (paginated). */
  orders: OrderConnection;
  /** Fetches an order. */
  order?: Maybe<Order>;
  /** Checks an order for successful payment. */
  pollOrder?: Maybe<Order>;
  /** Fetches online orders ids for an event */
  orderIdsOnline?: Maybe<Array<OrderIdsOnlineOutput>>;
  /** Fetches online orders for an event */
  eventOrdersOnline?: Maybe<EventOrdersOnlineOutput>;
  /** Returns an orders details required to do refunds */
  orderRefundDetails?: Maybe<OrderRefundDetailsOutput>;
  /** Fetches orders of a user (paginated). */
  myOrders: OrderConnection;
  /** Fetches membership hold orders of a user. */
  myMembershipHoldOrders: Array<Order>;
  /** Gets updated orders */
  ordersUpdatedSince: CustomSaleOutput;
  /** Gets updated orders */
  ordersUpdatedSinceForEvent: CustomSaleOutput;
  /** Fetches payment options for an order during checkout. */
  getPaymentOptions: PaymentOptions;
  /** Gets ticket cover amount for order */
  calculateTicketCoverAmounts: TicketCoverAmount;
  getOrderJobStatus: OrderCreateOutput;
  getEventPurchaseRestrictions: Array<PurchaseRestriction>;
  getMembershipPurchaseRestrictions: Array<PurchaseRestriction>;
  getOrderPlan: OrderPlan;
  payRemainingOrderPlan: OrderPlan;
  getHistoryByCustomerId: Array<History>;
  venues: Array<Venue>;
  venue: Venue;
  venueSeatsThumbnail?: Maybe<Scalars['String']>;
  getZonesByEventId: Array<Zone>;
  getGatesByEventId: Array<Gate>;
  memberships: MembershipConnection;
  membership?: Maybe<Membership>;
  previousMembershipCount?: Maybe<Scalars['Float']>;
  membershipTypeEventTransferPrices?: Maybe<Array<MembershipTypeEventTransferPrice>>;
  membershipTypes: Array<MembershipType>;
  membershipAddons: Array<MembershipAddon>;
  membershipPromoCodes: Array<MembershipPromoCode>;
  membershipMultiBuyPromotions: Array<MembershipMultiBuyPromotion>;
  getSubscriptionPortalUrlForTicket: MembershipSubscriptionPortalOutput;
  getSubscriptionPortalUrlForOrder: MembershipSubscriptionPortalOutput;
  getMembershipSubscription: MembershipSubscription;
  getMembershipSubscriptionsForPreviousMemberships: Array<MembershipSubscription>;
  getMembershipAutoRenewal: MembershipAutoRenewalOutput;
  scout: Scout;
  scouts: Array<Scout>;
  /** Fetches users (paginated). */
  scoutUsers: ScoutUserConnection;
  initScout: InitScout;
  scoutCommunications: Array<ScoutCommunication>;
  scoutCommunication: ScoutCommunication;
  scoutCommunicationUsers: ScoutCommunicationUserConnection;
  scoutCommunicationUser: ScoutCommunicationUser;
  scoutUserReminders: Array<ScoutCommunicationUser>;
  tags: Array<Tag>;
  myMembershipTickets: Array<Ticket>;
  usersEventTickets: Array<Ticket>;
  myMembershipAddons: Array<Ticket>;
  myEventTickets: Array<Ticket>;
  myPaymentPlanTickets: Array<PartPaidTicketStats>;
  ticket?: Maybe<Ticket>;
  findOrderIdForTicket: FindOrderIdForTicketOutput;
  ticketsSoldForUser: Array<TicketTypePurchaseTotal>;
  membershipsSoldForUser: Array<MembershipTypePurchaseTotal>;
  getTicketsByIds: Array<Ticket>;
  /** DEPRECATED: should use searchTicketsByEventApp to fetch for scanner app */
  searchTickets: TicketConnection;
  searchTicketsByEventApp: SearchEventTicketsAppOutput;
  /** Returns stringyfied mapping of details per seat label. */
  ticketDetailsForBackOfficeOrders: Scalars['String'];
  transferredMembershipTicketsForOrder?: Maybe<Array<Ticket>>;
  getSentTicketsById: Array<Ticket>;
  myScannedTickets: Array<Ticket>;
  myScannedTicketsCount: Scalars['Float'];
  pinPaymentContext: PinPaymentContext;
  windcavePaymentContext: WindcavePaymentContext;
  windcavePaymentResult: WindcavePaymentResultOutput;
  stripePaymentContext: StripePaymentContext;
  socialMentions: SocialConnection;
  mentionOverview: MentionOverview;
  facebookPageLists: Array<PageListsOutput>;
  mailchimpDomains: Scalars['JSON'];
  mailchimpLists: Scalars['JSON'];
  mailchimpTemplates: Scalars['JSON'];
  point?: Maybe<Point>;
  pointItem: PointItem;
  pointItems: Array<PointItem>;
  activities: ActivityConnection;
  getI18n: I18nOutput;
  getReferralCampaigns: Array<ReferralCampaign>;
  getReferralCampaignsByEventId?: Maybe<Array<ReferralCampaign>>;
  getReferralCampaignByEventId?: Maybe<ReferralCampaign>;
  getReferralCampaignByReferralCode?: Maybe<ReferralCampaign>;
  getReferralCampaignByOrderId?: Maybe<ReferralCampaign>;
  getReferralCampaignStats: ReferralCampaignOverviewOutput;
  getReferralRewardByOrderId?: Maybe<ReferralReward>;
  referralUrlByOrderId?: Maybe<Scalars['String']>;
  referralUrlByWaitlistUserId?: Maybe<Scalars['String']>;
  automations: Array<Automation>;
  automation: Automation;
  getPromoterSettings?: Maybe<PromoterSettingsOutput>;
  getRedirectUrlByCode: RedirectUrl;
  competitions: Array<Competition>;
  competition: Competition;
  activeCompetitions?: Maybe<Array<Competition>>;
  activeCompetitionByType?: Maybe<Competition>;
  userEntries?: Maybe<Array<GrantRewardOutput>>;
  chargeSummary: Array<ChargeSummaryOutput>;
  chargeMonthSummary: ChargeMonthSummaryOutput;
  emailHTMLPreview: Scalars['String'];
  broadcasts: BroadcastConnection;
  broadcast: BroadcastOutput;
  audienceCount: Scalars['Float'];
  marketingUserCount: Scalars['Float'];
  broadcastFilterState: Array<BroadcastMarketingV2StateOutput>;
  broadcastBounces: Array<User>;
  defaultBroadcastStyle?: Maybe<BroadcastStyle>;
  getRegionsForCountry: Array<Scalars['String']>;
  getBroadcastLocationFilterOptions: BroadcastLocationFilterOptions;
  getTrackingCodesByEventId: Array<TrackingCodeOutput>;
  salesOverview: SalesOverviewOutput;
  scoutCampaignOverview: ScoutCampaignOverviewOutput;
  scoutCampaignTopTags: Array<TopTagsOutput>;
  scoutCampaignHighestValueCustomers: Array<HighestValueCustomersOutput>;
  scoutCampaignGenderBreakdown: GenderBreakDownOutput;
  scoutCampaignCustomerLocations: Array<LocationOutput>;
  summaryOverview: SummaryOverviewOutput;
  summaryOverviewV2: SummaryOverviewOutput;
  salesGraph: GraphOutput;
  customerLocations: Array<LocationOutput>;
  customerLocationsByFilter: Array<LocationOutputV2>;
  salesChannelGraph: SalesChannelGraphOutput;
  financialOverview: FinancialOverviewOutput;
  faceValueOverview: FaceValueOverviewOutput;
  financialGraph: GraphOutput;
  faceValueFinancialGraph: GraphOutput;
  financialGateways: FinancialGatewayGraphOutput;
  salesReport: SalesReportOutput;
  scanVisitors?: Maybe<Array<ScannedVisitorsOutput>>;
  scanVisitorsOverTime?: Maybe<Array<GraphRangeItem>>;
  scanTicketTypes?: Maybe<Array<GraphTextItem>>;
  posSalesOverview: PosSalesOverviewOutput;
  posFinancialOverview: Array<GraphItem>;
  posFloatOfChange: Array<GraphItem>;
  insightsOverview: InsightsOverviewOutput;
  highestValueCustomers: Array<HighestValueCustomersOutput>;
  topTags: Array<TopTagsOutput>;
  topReferrers: Array<TopReferrersOutput>;
  topRepeatPurchasers: Array<TopRepeatPurchasers>;
  insightsGenderBreakDown: GenderBreakDownOutput;
  insightsHeardAboutUsBreakDown: Array<HeardAboutUsBreakDownOutput>;
  insightsAgeBreakDown: AgeBreakDownOutput;
  waitlistOverview: WaitlistOverviewOutput;
  capacityOverview: Array<FinancialSales>;
  capacitySeatingMap: Scalars['String'];
  capacitySeatingMapTooltips: Scalars['String'];
  broadcastReportStats: BroadcastReportingOutput;
  purchasingDaysBreakdown: Array<BarchartOutput>;
  referralPromotionReportStats: ReferralPromotionReportingOutput;
  timeOfDayBreakdown: Array<BarchartOutput>;
  getOnsaleCompetitionReportStats: CompetitionReportingOnsaleOutput;
  googleAnalyticsReport: Array<BarchartOutput>;
  getRegistrationCompetitionReportStats: CompetitionReportingRegistrationOutput;
  getRegistrationReferralReportStats: ReferralRegistrationPromotionReportingOutput;
  getCompetitionReferralReportForCustomer: Array<CompetitionCustomerReporting>;
  getReferralReportForCustomer: Array<ReferralCustomerReporting>;
  scanUsherTicketTypes?: Maybe<GraphItem>;
  getRegistrationCompetitionReportStatsByEventId: CompetitionReportingRegistrationOutput;
  getOnsaleCompetitionReportStatsByEventId: CompetitionReportingOnsaleOutput;
  reportingLayout: ReportingLayoutOutput;
  reportingSearchIndex: ReportingSearchIndexOutput;
  reportingOverview: ReportingOverviewOutput;
  financialReportingOverview: FinancialReportingOverviewOutput;
  customerReportingOverview: CustomerReportingOverviewArgs;
  peReportOverview: PeReportOverviewOutput;
  peReportAudience: PeReportAudienceOutput;
  resaleWaitlistOverview: PeReportWaitlistOutput;
  registrationOverview: PeReportRegistrationOutput;
  registrationCompetitionOverview: PeReportRegistrationCompetitionOutput;
  peReferralPromotionReportStats: PeReportReferralOutsideMainSaleOutput;
  peOnsaleCompetitionReportStats: PeReportRegistrationCompetitionOutput;
  /** Source is hard coded to EVENT in peReport.graphql */
  demographics: PeReportDemographicsOutput;
  defaultDatesForPostEventReport: DefaultDates;
  peSalesChannelGraph: SalesChannelGraphOutput;
  peFinancialGateways: FinancialGatewayGraphOutput;
  getReportSchedulesForEvent: Array<ReportSchedule>;
  resaleTickets: Array<TicketType>;
  resaleTicket: TicketType;
  resaleTicketsMetrics: Array<ResaleTicketMetrics>;
  resaleWaitlistCount: ResaleWaitlistCount;
  getReleaseUrlForResaleTicketType: Scalars['String'];
  getMinTicketTypePricesForEvent: Array<MinTicketTypePricesForEventOutput>;
  resaleTicketTypesForWaitlist: Array<TicketType>;
  codeReport: CodeReportOutput;
  /** Gets created/updated ATR orders between a supplied date range. Loads order and order.user relations. */
  atrMembershipOrdersUpdatedBetween: Array<AtrMembershipOrdersUpdatedBetweenOutput>;
  /** Fetches users updated between given date range. Dates supplied in organizations timezone. */
  atrUsersUpdatedBetween: Array<AtrUsersUpdatedBetweenOutput>;
};


export type QueryUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<UserOrderByInput>;
};


export type QueryUsersUpdatedBetweenArgs = {
  input: UsersUpdatedBetweenInput;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryFindUserReferralIdArgs = {
  id: Scalars['String'];
};


export type QueryPosUsersArgs = {
  eventId: Scalars['String'];
};


export type QueryLinkedAccountUserArgs = {
  id: Scalars['String'];
};


export type QueryPosesArgs = {
  eventId: Scalars['String'];
};


export type QueryPosArgs = {
  id: Scalars['String'];
};


export type QueryGetPosReleasePaymentOptionsArgs = {
  releaseId: Scalars['String'];
};


export type QueryPosSessionsArgs = {
  posId: Scalars['String'];
};


export type QueryEventsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<EventOrderByInput>;
};


export type QueryEventArgs = {
  id: Scalars['String'];
};


export type QueryEventsByIdArgs = {
  input: EventsByIdInput;
};


export type QueryGetEventAndReleaseForCustomerArgs = {
  input: EventsWithAccessControlInput;
};


export type QueryGetEventForCompetitionReportingArgs = {
  id: Scalars['String'];
};


export type QueryMyEventsArgs = {
  where?: Maybe<MyEventsWhereInput>;
  orderBy?: Maybe<MyEventsOrderByInput>;
};


export type QueryEventForecastArgs = {
  eventId: Scalars['String'];
};


export type QueryTicketTypesArgs = {
  eventId: Scalars['String'];
};


export type QueryTicketTypesByIdArgs = {
  input: TicketTypesByIdInput;
};


export type QueryReleasesArgs = {
  event: Scalars['String'];
};


export type QueryGetReleasesForPrivateLinksArgs = {
  eventId: Scalars['String'];
};


export type QueryReleaseArgs = {
  id: Scalars['String'];
};


export type QueryGetReleaseBySlugArgs = {
  eventId: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};


export type QueryGetPublicReleaseTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryEventPromoCodesArgs = {
  eventId: Scalars['String'];
};


export type QueryEventMultiBuyPromotionsArgs = {
  eventId: Scalars['String'];
};


export type QueryEventAddonsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetAccessCodeArgs = {
  releaseId: Scalars['String'];
};


export type QueryGetPaginatedAccessCodeArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where: AccessCodeWhereInput;
};


export type QueryGetAccessCodesArgs = {
  eventId: Scalars['String'];
};


export type QueryListPresalesArgs = {
  eventId: Scalars['String'];
};


export type QueryListNamingFieldsArgs = {
  eventId: Scalars['String'];
};


export type QuerySeasonArgs = {
  id: Scalars['String'];
};


export type QueryIntegrationsArgs = {
  input?: Maybe<IntegrationWhereInput>;
};


export type QueryIntegrationArgs = {
  id: Scalars['String'];
};


export type QueryStripeConnectUrlArgs = {
  id: Scalars['String'];
};


export type QueryCustomerOrdersArgs = {
  customerId: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<OrganizationOrderByInput>;
};


export type QueryOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryOrganizationNamesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<OrganizationOrderByInput>;
};


export type QueryOrdersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryPollOrderArgs = {
  id: Scalars['String'];
};


export type QueryOrderIdsOnlineArgs = {
  id: Scalars['String'];
};


export type QueryEventOrdersOnlineArgs = {
  id: Scalars['String'];
};


export type QueryOrderRefundDetailsArgs = {
  eventId: Scalars['String'];
  orderId: Scalars['String'];
};


export type QueryMyOrdersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
};


export type QueryOrdersUpdatedSinceArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId?: Maybe<Scalars['String']>;
};


export type QueryOrdersUpdatedSinceForEventArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentOptionsArgs = {
  id: Scalars['String'];
};


export type QueryCalculateTicketCoverAmountsArgs = {
  id: Scalars['String'];
};


export type QueryGetOrderJobStatusArgs = {
  jobId: Scalars['String'];
};


export type QueryGetEventPurchaseRestrictionsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetMembershipPurchaseRestrictionsArgs = {
  membershipId: Scalars['String'];
};


export type QueryGetOrderPlanArgs = {
  orderId: Scalars['String'];
};


export type QueryPayRemainingOrderPlanArgs = {
  orderPlanId: Scalars['String'];
};


export type QueryGetHistoryByCustomerIdArgs = {
  input: GetHistoryByCustomerIdInput;
};


export type QueryVenueArgs = {
  id: Scalars['String'];
};


export type QueryVenueSeatsThumbnailArgs = {
  id: Scalars['String'];
};


export type QueryGetZonesByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QueryGetGatesByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QueryMembershipsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<MembershipWhereInput>;
  orderBy?: Maybe<MembershipOrderByInput>;
};


export type QueryMembershipArgs = {
  id: Scalars['String'];
};


export type QueryPreviousMembershipCountArgs = {
  id: Scalars['String'];
};


export type QueryMembershipTypeEventTransferPricesArgs = {
  id: Scalars['String'];
};


export type QueryMembershipTypesArgs = {
  membership: Scalars['String'];
};


export type QueryMembershipAddonsArgs = {
  membershipId: Scalars['String'];
};


export type QueryMembershipPromoCodesArgs = {
  membershipId: Scalars['String'];
};


export type QueryMembershipMultiBuyPromotionsArgs = {
  membershipId: Scalars['String'];
};


export type QueryGetSubscriptionPortalUrlForTicketArgs = {
  ticketId: Scalars['String'];
};


export type QueryGetSubscriptionPortalUrlForOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryGetMembershipSubscriptionArgs = {
  membershipSubscriptionId: Scalars['String'];
};


export type QueryGetMembershipSubscriptionsForPreviousMembershipsArgs = {
  previousMembershipIds: Array<Scalars['String']>;
};


export type QueryGetMembershipAutoRenewalArgs = {
  membershipId: Scalars['String'];
};


export type QueryScoutArgs = {
  id: Scalars['String'];
};


export type QueryScoutsArgs = {
  input?: Maybe<FindScoutsInput>;
};


export type QueryScoutUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
  stage?: Maybe<Array<ScoutStage>>;
  agentUserId?: Maybe<Scalars['String']>;
  hasPhoneNumber?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
};


export type QueryScoutCommunicationsArgs = {
  input: ScoutCommunicationsInput;
};


export type QueryScoutCommunicationArgs = {
  id: Scalars['String'];
};


export type QueryScoutCommunicationUsersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<UserWhereInput>;
  hasPhoneNumber?: Maybe<Scalars['Boolean']>;
  stage?: Maybe<Array<ScoutStage>>;
  agentUserId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};


export type QueryScoutCommunicationUserArgs = {
  id: Scalars['String'];
};


export type QueryMyMembershipAddonsArgs = {
  membershipId: Scalars['String'];
};


export type QueryMyEventTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryTicketArgs = {
  id: Scalars['String'];
};


export type QueryFindOrderIdForTicketArgs = {
  ticketId: Scalars['String'];
};


export type QueryTicketsSoldForUserArgs = {
  eventId: Scalars['String'];
};


export type QueryMembershipsSoldForUserArgs = {
  membershipId: Scalars['String'];
};


export type QueryGetTicketsByIdsArgs = {
  ids: Scalars['String'];
};


export type QuerySearchTicketsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<TicketWhereInput>;
  orderBy?: Maybe<TicketWhereInput>;
  eventId: Scalars['String'];
};


export type QuerySearchTicketsByEventAppArgs = {
  q?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
};


export type QueryTicketDetailsForBackOfficeOrdersArgs = {
  membershipId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
};


export type QueryTransferredMembershipTicketsForOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryGetSentTicketsByIdArgs = {
  sendId: Scalars['String'];
};


export type QueryMyScannedTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryMyScannedTicketsCountArgs = {
  eventId: Scalars['String'];
};


export type QueryPinPaymentContextArgs = {
  id: Scalars['String'];
};


export type QueryWindcavePaymentContextArgs = {
  id: Scalars['String'];
};


export type QueryWindcavePaymentResultArgs = {
  result: Scalars['String'];
  externalId: Scalars['String'];
};


export type QueryStripePaymentContextArgs = {
  id: Scalars['String'];
};


export type QuerySocialMentionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<SocialWhereInput>;
};


export type QueryMentionOverviewArgs = {
  input: MentionOverviewInput;
};


export type QueryFacebookPageListsArgs = {
  input: FacebookPageListsInput;
};


export type QueryPointItemArgs = {
  id: Scalars['String'];
};


export type QueryActivitiesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<ActivityWhereInput>;
};


export type QueryGetReferralCampaignsByEventIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralCampaignByEventIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralCampaignByReferralCodeArgs = {
  referralCode: Scalars['String'];
};


export type QueryGetReferralCampaignByOrderIdArgs = {
  id: Scalars['String'];
};


export type QueryGetReferralCampaignStatsArgs = {
  eventId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryGetReferralRewardByOrderIdArgs = {
  id: Scalars['String'];
};


export type QueryReferralUrlByOrderIdArgs = {
  orderId: Scalars['String'];
};


export type QueryReferralUrlByWaitlistUserIdArgs = {
  waitlistUserId: Scalars['String'];
  eventId: Scalars['String'];
};


export type QueryAutomationsArgs = {
  input: FindAutomationsInput;
};


export type QueryAutomationArgs = {
  id: Scalars['String'];
};


export type QueryGetPromoterSettingsArgs = {
  eventId: Scalars['String'];
};


export type QueryGetRedirectUrlByCodeArgs = {
  input: RedirectCodeInput;
};


export type QueryCompetitionsArgs = {
  eventId: Scalars['String'];
};


export type QueryCompetitionArgs = {
  id: Scalars['String'];
};


export type QueryActiveCompetitionsArgs = {
  eventId: Scalars['String'];
};


export type QueryActiveCompetitionByTypeArgs = {
  input: ActiveCompetitionByTypeInput;
};


export type QueryUserEntriesArgs = {
  input: GetUserEntriesInput;
};


export type QueryChargeMonthSummaryArgs = {
  date?: Maybe<Scalars['DateTime']>;
};


export type QueryEmailHtmlPreviewArgs = {
  broadcastId: Scalars['String'];
};


export type QueryBroadcastsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<BroadcastWhereInput>;
};


export type QueryBroadcastArgs = {
  id: Scalars['String'];
};


export type QueryAudienceCountArgs = {
  input: AudienceCountInput;
};


export type QueryMarketingUserCountArgs = {
  input: BroadcastFilterCountInput;
};


export type QueryBroadcastFilterStateArgs = {
  input: BroadcastMarketingV2StateInput;
};


export type QueryBroadcastBouncesArgs = {
  count: Scalars['Float'];
  start: Scalars['Float'];
  id: Scalars['String'];
};


export type QueryGetRegionsForCountryArgs = {
  country: Scalars['String'];
};


export type QueryGetTrackingCodesByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QuerySalesOverviewArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QueryScoutCampaignOverviewArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryScoutCampaignTopTagsArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryScoutCampaignHighestValueCustomersArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryScoutCampaignGenderBreakdownArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryScoutCampaignCustomerLocationsArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QuerySummaryOverviewArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QuerySalesGraphArgs = {
  source: PointReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<GraphFilterType>;
};


export type QueryCustomerLocationsArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QueryCustomerLocationsByFilterArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  filter: LocationFilterTypes;
};


export type QuerySalesChannelGraphArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QueryFinancialOverviewArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QueryFaceValueOverviewArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QueryFinancialGraphArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryFaceValueFinancialGraphArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QueryFinancialGatewaysArgs = {
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
  source: PointReportingFilterSource;
};


export type QuerySalesReportArgs = {
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  source: ReportingFinancialSalesBreakdownSource;
  sourceId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};


export type QueryScanVisitorsArgs = {
  eventId: Scalars['String'];
};


export type QueryScanVisitorsOverTimeArgs = {
  eventId: Scalars['String'];
};


export type QueryScanTicketTypesArgs = {
  eventId: Scalars['String'];
};


export type QueryPosSalesOverviewArgs = {
  eventId: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryPosFinancialOverviewArgs = {
  eventId: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryPosFloatOfChangeArgs = {
  eventId: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryInsightsOverviewArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryHighestValueCustomersArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryTopTagsArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryTopReferrersArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryTopRepeatPurchasersArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsGenderBreakDownArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsHeardAboutUsBreakDownArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryInsightsAgeBreakDownArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryWaitlistOverviewArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryCapacityOverviewArgs = {
  eventId: Scalars['String'];
};


export type QueryCapacitySeatingMapArgs = {
  eventId: Scalars['String'];
};


export type QueryCapacitySeatingMapTooltipsArgs = {
  eventId: Scalars['String'];
};


export type QueryBroadcastReportStatsArgs = {
  input: BroadcastReportingStatsInput;
};


export type QueryPurchasingDaysBreakdownArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryReferralPromotionReportStatsArgs = {
  input: ReferralPromotionReportingInput;
};


export type QueryTimeOfDayBreakdownArgs = {
  source: ReportingFilterSource;
  sourceId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  release?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  scoutCommunicationId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  tagId?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetOnsaleCompetitionReportStatsArgs = {
  input: CompetitionReportingInput;
};


export type QueryGoogleAnalyticsReportArgs = {
  input: GoogleAnalyticsReportingFilterInput;
};


export type QueryGetRegistrationCompetitionReportStatsArgs = {
  input: CompetitionReportingInput;
};


export type QueryGetRegistrationReferralReportStatsArgs = {
  input: ReferralPromotionReportingInput;
};


export type QueryGetCompetitionReferralReportForCustomerArgs = {
  input: CustomerCompetitionReportingInput;
};


export type QueryGetReferralReportForCustomerArgs = {
  input: CustomerReferralReportingInput;
};


export type QueryScanUsherTicketTypesArgs = {
  eventId: Scalars['String'];
};


export type QueryGetRegistrationCompetitionReportStatsByEventIdArgs = {
  eventId?: Maybe<Scalars['String']>;
};


export type QueryGetOnsaleCompetitionReportStatsByEventIdArgs = {
  eventId: Scalars['String'];
};


export type QueryReportingLayoutArgs = {
  input?: Maybe<ReportingLayoutInput>;
};


export type QueryReportingOverviewArgs = {
  eventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
  source?: Maybe<ReportingFinancialSalesBreakdownSource>;
  sourceId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryFinancialReportingOverviewArgs = {
  eventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
  source?: Maybe<ReportingFinancialSalesBreakdownSource>;
  sourceId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryCustomerReportingOverviewArgs = {
  eventId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
  source?: Maybe<ReportingFinancialSalesBreakdownSource>;
  sourceId?: Maybe<Scalars['String']>;
  seasonId?: Maybe<Scalars['String']>;
  channel?: Maybe<OrderChannel>;
  posId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
};


export type QueryPeReportOverviewArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryPeReportAudienceArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryResaleWaitlistOverviewArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryRegistrationOverviewArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryRegistrationCompetitionOverviewArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryPeReferralPromotionReportStatsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryPeOnsaleCompetitionReportStatsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryDemographicsArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
  locationFilterTypes?: Maybe<LocationFilterTypes>;
};


export type QueryDefaultDatesForPostEventReportArgs = {
  eventId: Scalars['String'];
};


export type QueryPeSalesChannelGraphArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryPeFinancialGatewaysArgs = {
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  eventId: Scalars['String'];
};


export type QueryGetReportSchedulesForEventArgs = {
  eventId: Scalars['String'];
};


export type QueryResaleTicketsArgs = {
  eventId: Scalars['String'];
};


export type QueryResaleTicketArgs = {
  id: Scalars['String'];
};


export type QueryResaleTicketsMetricsArgs = {
  eventId: Scalars['String'];
};


export type QueryResaleWaitlistCountArgs = {
  eventId: Scalars['String'];
};


export type QueryGetReleaseUrlForResaleTicketTypeArgs = {
  eventId: Scalars['String'];
};


export type QueryGetMinTicketTypePricesForEventArgs = {
  eventId: Scalars['String'];
};


export type QueryResaleTicketTypesForWaitlistArgs = {
  eventId: Scalars['String'];
  releaseId?: Maybe<Scalars['String']>;
};


export type QueryCodeReportArgs = {
  eventId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type QueryAtrMembershipOrdersUpdatedBetweenArgs = {
  input: AtrMembershipOrdersUpdatedBetweenInput;
};


export type QueryAtrUsersUpdatedBetweenArgs = {
  input: UsersUpdatedBetweenInput;
};

export type RedeemTicketInput = {
  /** Ticket number */
  ticketNumber: Scalars['String'];
  /** Event ID */
  eventId: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type RedirectCodeInput = {
  /** Full URL path. */
  redirectCode: Scalars['String'];
};

/** Mapping of a shortened URL to its full path */
export type RedirectUrl = {
  __typename?: 'RedirectUrl';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Type of entity this redirect is for. */
  refName: Scalars['String'];
  /** Reference entity ID. */
  refId: Scalars['String'];
  /** Shortned URL slug. */
  shortenedSlug: Scalars['String'];
  /** Full URL path. */
  redirectURL: Scalars['String'];
  /** Number of times the shortened URL has redirected to the full path. */
  timesUsed: Scalars['Float'];
};

/** Referral Campaign */
export type ReferralCampaign = {
  __typename?: 'ReferralCampaign';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The unique identifier for the referral campaign */
  referralRelease?: Maybe<Release>;
  /** The eventId that the referral campaign is associated with */
  event?: Maybe<Event>;
  /** The memebrshipId that the referral campaign is associated with */
  membership?: Maybe<Membership>;
  /** Whether the referral campaign is active */
  isActive: Scalars['Boolean'];
  /** The type of reward for the owner of the referral */
  referralOwnerRewardType: ReferralRewardType;
  /** The reward quantity type. */
  referralOwnerRewardQuantityType: ReferralRewardQuantityType;
  /** The quantity of the reward for the owner of the referral */
  referralOwnerRewardQuantity: Scalars['Float'];
  /** The type of reward for the user of the referral */
  referralUserRewardType: ReferralRewardType;
  /** The reward quantity type. */
  referralUserRewardQuantityType: ReferralRewardQuantityType;
  /** The quantity of the reward for the user of the referral */
  referralUserRewardQuantity: Scalars['Float'];
};

export type ReferralCampaignOverviewOutput = {
  __typename?: 'ReferralCampaignOverviewOutput';
  numberOfOrders: Scalars['Float'];
  userRewards: Scalars['Float'];
  ownerRewards: Scalars['Float'];
  referralLinksCreated: Scalars['Float'];
  referralLinksClicked: Scalars['Float'];
  tickets: Scalars['Float'];
  revenueGenerated: Scalars['Float'];
  customersAccquired: Scalars['Float'];
};

/** A referral code for a user and event */
export type ReferralCode = {
  __typename?: 'ReferralCode';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ReferralCustomerReporting = {
  __typename?: 'ReferralCustomerReporting';
  orderNumber: Scalars['String'];
  eventLogo?: Maybe<ExtendedFile>;
  eventName: Scalars['String'];
  orderDate: Scalars['DateTime'];
  referralsMade: Scalars['Int'];
  referralLink?: Maybe<Scalars['String']>;
};

export type ReferralPromotionReportingInput = {
  eventId: Scalars['String'];
};

export type ReferralPromotionReportingOutput = {
  __typename?: 'ReferralPromotionReportingOutput';
  revenue: Scalars['Float'];
  roi: Scalars['Float'];
  orders: Scalars['Float'];
  newReferredCustomers: Scalars['Float'];
  referrerPointsAwarded: Scalars['Float'];
  discountValue: Scalars['Float'];
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
};

export type ReferralRegistrationPromotionReportingOutput = {
  __typename?: 'ReferralRegistrationPromotionReportingOutput';
  newReferredCustomers: Scalars['Float'];
  registrations: Scalars['Float'];
  actionEngagement?: Maybe<Array<ActionEngagementRecordOutput>>;
};

/** Referral Reward */
export type ReferralReward = {
  __typename?: 'ReferralReward';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  order: Order;
  /** The referral campaign the rewards are from */
  referralCampaign: ReferralCampaign;
  /** Organization associated with the referral campaign */
  organization: Organization;
  /** The role of the user played when receiving these rewards */
  rewardForRole: ReferralRole;
  /** The type of reward for the referral */
  rewardType: ReferralRewardType;
  /** The reward quantity type. */
  rewardQuantityType: ReferralRewardQuantityType;
  /** The quantity of the reward for the owner of the referral */
  rewardQuantity: Scalars['Float'];
};

/** The valid values for quantities of referral rewards. */
export enum ReferralRewardQuantityType {
  FlatRate = 'flatRate',
  Percentage = 'percentage'
}

/** The valid values for referral rewards. Some are only applicable to certain roles in a referral transaction. */
export enum ReferralRewardType {
  Points = 'Points',
  Credits = 'Credits',
  Discount = 'Discount'
}

/** The role the user played when in a referral exchange. */
export enum ReferralRole {
  Owner = 'Owner',
  User = 'User'
}

/** Represents a refund of items in an order. */
export type Refund = {
  __typename?: 'Refund';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  kind: RefundKind;
  /** The amount of the refund. */
  amount: Scalars['Float'];
  /** Notes why the refund has been initiated. */
  notes: Scalars['String'];
  /** Whether the refund is a manual one. */
  isManual: Scalars['Boolean'];
  gateway?: Maybe<RefundGateway>;
};

/** Amount remaining that can be automatically refunded through the payment gateway */
export type RefundableState = {
  __typename?: 'RefundableState';
  refundState: OrderRefundState;
  totalRefundableAmount: Scalars['Float'];
  /** The amount that can be automatically refunded */
  gatewayRefundableAmount?: Maybe<GatewayRefundableAmount>;
};

/** Refund gateways. */
export enum RefundGateway {
  Cash = 'Cash',
  Eftpos = 'Eftpos',
  CreditCard = 'CreditCard',
  Invoice = 'Invoice',
  Other = 'Other'
}

/** Refund kinds. */
export enum RefundKind {
  Default = 'Default',
  TicketTransfer = 'TicketTransfer'
}

export type RegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  posId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  enableTwoFactorAuthentication: Scalars['Boolean'];
  userAcquisition?: Maybe<UserAcquisition>;
};

/** Represents a release in an event. */
export type Release = {
  __typename?: 'Release';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Release name. */
  name: Scalars['String'];
  /** Release type. */
  type: ReleaseType;
  /** The slug for the unlisted release. Returns null if the release type is set to other than `unlisted`. */
  slug?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  /** The start condition of the release. */
  startCondition: ReleaseStartCondition;
  /** The start date of the release. Returns null if the startCondition is set to other than "date". */
  startConditionDate?: Maybe<Scalars['DateTime']>;
  /** The end condition of the release. */
  endCondition: ReleaseEndCondition;
  /** The quantity limit of the release. Returns null if the endCondition is set to other than "quantity". */
  endConditionQuantity?: Maybe<Scalars['Float']>;
  /** The end date of the release. Returs null if the endCondition is set to other than "date". */
  endConditionDate?: Maybe<Scalars['DateTime']>;
  /** The status of the release. */
  isActive: Scalars['Boolean'];
  /** The title of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleTitle?: Maybe<Scalars['String']>;
  /** The logo of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleLogo?: Maybe<ExtendedFile>;
  /** The header image of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleHeader?: Maybe<ExtendedFile>;
  /** The information of the unlisted release. Returns null if the `type` is set to other than `unlisted`. */
  presaleInformation?: Maybe<Scalars['String']>;
  /** The information that will be displayed on the ticketing page for a specific release. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** Toggle whether to ask customers how they heard about their purcahse */
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  /** Toggle whether to ask for dietary requirements with their order */
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  allowPublicComp: Scalars['Boolean'];
  /** Toggle whether customers can create hold orders for this release */
  allowHoldOrders: Scalars['Boolean'];
  allowAccount2Account?: Maybe<Scalars['Boolean']>;
  minPurchaseQuantity: Scalars['Int'];
  maxPurchaseQuantity: Scalars['Int'];
  isEnabled: Scalars['Boolean'];
  status: ReleaseStatus;
  event: Event;
  canBeHardDeleted: Scalars['Boolean'];
  requiresAuth: Scalars['Boolean'];
  releaseZones: Array<ReleaseZone>;
  releaseEventAddons: Array<ReleaseEventAddon>;
  isResaleRelease: Scalars['Boolean'];
  channels: Array<EventChannelOutput>;
};

export enum ReleaseEndCondition {
  Manual = 'MANUAL',
  Date = 'DATE',
  Quantity = 'QUANTITY'
}

export type ReleaseEventAddon = {
  __typename?: 'ReleaseEventAddon';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  eventAddon: EventAddon;
  releaseId: Scalars['String'];
  eventAddonId: Scalars['String'];
};

/** Represents a unique password code in a release. */
export type ReleasePassword = {
  __typename?: 'ReleasePassword';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** The usage limit of the release password. Returns null if password has no usage limit. */
  quantityPaid: Scalars['Int'];
  /** The usage limit of the release password for complimentary tickets. */
  quantityComp: Scalars['Int'];
  totalUsedPaid: Scalars['Int'];
  totalUsedComp: Scalars['Int'];
};

export type ReleasePosPaymentOptions = {
  __typename?: 'ReleasePosPaymentOptions';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  releaseId: Scalars['String'];
  organizationId: Scalars['String'];
  paymentOptions: PosPaymentOptions;
  /** Booking fee type. */
  bookingFeeType: FeeType;
  /** Booking fee. */
  bookingFee: Scalars['Float'];
};

/** Creates a refund. */
export type ReleaseSeatsInput = {
  /** Order id for which the seats will be released. */
  orderId: Scalars['String'];
  /** Notes why the seats are released. */
  notes: Scalars['String'];
  /** Tickets to realease (will also release child tickets). */
  ticketIds: Array<Scalars['String']>;
};

export enum ReleaseStartCondition {
  Manual = 'MANUAL',
  Date = 'DATE'
}

export type ReleaseStatus = {
  __typename?: 'ReleaseStatus';
  value: ReleaseStatusValue;
  message?: Maybe<Scalars['String']>;
};

export enum ReleaseStatusValue {
  ReleaseHasEndedDueToDate = 'RELEASE_HAS_ENDED_DUE_TO_DATE',
  ReleaseIsPrivateWithFutureStartDate = 'RELEASE_IS_PRIVATE_WITH_FUTURE_START_DATE',
  ReleaseIsPrivate = 'RELEASE_IS_PRIVATE',
  ReleaseIsPublic = 'RELEASE_IS_PUBLIC'
}

export enum ReleaseType {
  Listed = 'LISTED',
  Unlisted = 'UNLISTED',
  Presale = 'PRESALE',
  Pos = 'POS',
  Referral = 'REFERRAL',
  Outlet = 'OUTLET',
  Resale = 'RESALE'
}

export type ReleaseZone = {
  __typename?: 'ReleaseZone';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  zone: Zone;
  /** The pricing level of the zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The active status of the zone. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  isSoldOut: Scalars['Boolean'];
  /** The list of ticket types of the release zone. */
  ticketTypes: Array<ReleaseZoneTicketType>;
};

export type ReleaseZoneInput = {
  id?: Maybe<Scalars['String']>;
  zone: ReleaseZoneZoneInput;
  /** The pricing level of the release zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The active status of the zone seating. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The list of ticket types of the release zone. */
  ticketTypes: Array<ReleaseZoneTicketTypeInput>;
};

/** Represents a ticket type in a release zone. */
export type ReleaseZoneTicketType = {
  __typename?: 'ReleaseZoneTicketType';
  /** The price of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  /** The sections of the zone ticket. Returns null if `pricingLevel` of the parent is set to other than `section`. */
  sections?: Maybe<Array<ZonePricingTypeSection>>;
  /** The active status of the zone ticket. Defaults to `false`. */
  isActive: Scalars['Boolean'];
  purchaseRestrictions: Array<PurchaseRestriction>;
  purchaseRestrictionsHelpText?: Maybe<Array<Scalars['String']>>;
  /** The ticket type id of the zone ticket. */
  ticketTypeId?: Maybe<Scalars['String']>;
  minPurchaseQuantity: Scalars['Int'];
  maxPurchaseQuantity: Scalars['Int'];
  isSoldOut: Scalars['Boolean'];
  ticketAvailabilityStatus: TicketAvailabilityStatus;
};

export type ReleaseZoneTicketTypeInput = {
  /** The ticket type id of the zone ticket. */
  ticketTypeId: Scalars['String'];
  /** The price of the zone ticket. Pass null if `ReleaseZoneInput.pricingLevel` is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  /** The sections of the zone ticket. Pass null if `ReleaseZoneInput.pricingLevel` is set to other than `section`. */
  sections?: Maybe<Array<ReleaseZoneTicketTypeSectionInput>>;
  /** The active status of the zone ticket type. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
};

export type ReleaseZoneTicketTypeSectionInput = {
  /** The section id. Should be equal to one of the sections defined in the event venue. */
  sectionId: Scalars['String'];
  /** The section name. Should be equal to one of the sections defined in the event venue. */
  name: Scalars['String'];
  /** The price of the section ticket. */
  price?: Maybe<Scalars['Float']>;
};

/** The zone belonging to a release zone */
export type ReleaseZoneZoneInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ReorderTicketTypeInput = {
  releaseZoneId: Scalars['String'];
  ticketTypeIds: Array<Scalars['String']>;
};

export type ReorderZoneInput = {
  venueId: Scalars['String'];
  zoneIds: Array<Scalars['String']>;
};

export enum ReportingFilterSource {
  Overall = 'OVERALL',
  Membership = 'MEMBERSHIP',
  Event = 'EVENT'
}

export enum ReportingFinancialSalesBreakdownSource {
  Membership = 'MEMBERSHIP',
  Event = 'EVENT'
}

export type ReportingLayoutInput = {
  allEvents?: Maybe<Scalars['Boolean']>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  allMemberships?: Maybe<Scalars['Boolean']>;
  seasonId?: Maybe<Scalars['String']>;
  allSeasons?: Maybe<Scalars['Boolean']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type ReportingLayoutOutput = EventLayoutOutput | MembershipLayoutOutput | SeasonLayoutOutput;

export type ReportingOverviewOutput = {
  __typename?: 'ReportingOverviewOutput';
  ticketSales: Array<PeReportStatistic>;
  financial: Array<PeReportStatistic>;
  audience: Array<PeReportStatistic>;
};

export type ReportingSearchIndexOutput = {
  __typename?: 'ReportingSearchIndexOutput';
  searchableEvents: Array<SearchEvent>;
  searchableSeasons: Array<SearchSeason>;
  searchableMemberships: Array<SearchMembership>;
};

export type ReportSchedule = {
  __typename?: 'ReportSchedule';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  eventId: Scalars['String'];
  scheduleName: Scalars['String'];
  recipientList: Array<Scalars['String']>;
  scheduleOptions: ReportScheduleOptions;
  isPaused: Scalars['Boolean'];
  scheduleDateInfo: ReportScheduleDateInfoOutput;
};

/** Report schedule date information provided in the organizations timezone ready for presentation. */
export type ReportScheduleDateInfoOutput = {
  __typename?: 'ReportScheduleDateInfoOutput';
  nextSendDate?: Maybe<Scalars['String']>;
  firstSendDate?: Maybe<Scalars['String']>;
  mostRecentSendDate?: Maybe<Scalars['String']>;
};

export type ReportScheduleInput = {
  scheduleName: Scalars['String'];
  recipientList: Array<Scalars['String']>;
  scheduleOptions: ReportScheduleOptionsInput;
  isPaused: Scalars['Boolean'];
};

export type ReportScheduleOptions = {
  __typename?: 'ReportScheduleOptions';
  frequency: Scalars['String'];
  /** The time of day as a 24h string in the format HH:MM */
  sendTime: Scalars['String'];
  sendDay?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type ReportScheduleOptionsInput = {
  frequency: Scalars['String'];
  /** The time of day as a 24h string in the format HH:MM */
  sendTime: Scalars['String'];
  sendDay?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export enum ReportType {
  Internal = 'INTERNAL',
  GoogleAnalytics = 'GOOGLE_ANALYTICS'
}

export type RequestMagicLinkInput = {
  email: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
  entry?: Maybe<Scalars['String']>;
};

export type RequestResetPasswordInput = {
  email: Scalars['String'];
};

export type RequestTicketTransferInput = {
  email: Scalars['String'];
  ticketId: Scalars['String'];
  /**
   * The price set by the seller for the ticket transfer. Must be between the seller transfer fee 
   *     and the original line item price. If not supplied, the original line item price is used.
   */
  ticketSalePrice?: Maybe<Scalars['Float']>;
};

export type ResaleTicketMetrics = {
  __typename?: 'ResaleTicketMetrics';
  ticketTypeId: Scalars['String'];
  numInterestedFans: Scalars['Float'];
  soldRevenue: Scalars['Float'];
  /** The mean and median prices that customers have said they would pay. Will be null if no data is present. */
  fansWouldPay?: Maybe<FansWouldPay>;
  numCurrentlyOnSale: Scalars['Float'];
  numSold: Scalars['Float'];
};

export type ResaleWaitlistCount = {
  __typename?: 'ResaleWaitlistCount';
  numWaitlisted: Scalars['Float'];
};

/** input for the resale waitlist */
export type ResaleWaitlistInput = {
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  usableForMarketing: Scalars['Boolean'];
  eventId: Scalars['String'];
  referralCode?: Maybe<Scalars['String']>;
  userAcquisition?: Maybe<UserAcquisition>;
  customFields?: Maybe<Array<CustomFieldInput>>;
  /** The resale ticket types */
  resaleTicketTypes?: Maybe<Array<ResaleWaitlistInputTicketTypes>>;
};

export type ResaleWaitlistInputTicketTypes = {
  id: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

export type ResetPasswordInput = {
  token: Scalars['String'];
  password: Scalars['String'];
};

export type ResponseLabelValue = {
  __typename?: 'ResponseLabelValue';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type RewardSettingsDto = {
  referralOwnerRewardQuantityType: ReferralRewardQuantityType;
  referralOwnerRewardQuantity?: Maybe<Scalars['Float']>;
  referralUserRewardQuantityType: ReferralRewardQuantityType;
  referralUserRewardQuantity?: Maybe<Scalars['Float']>;
};

export enum Role {
  Superadmin = 'Superadmin',
  Admin = 'Admin',
  Sales = 'Sales',
  PosAdmin = 'PosAdmin',
  PosOperator = 'PosOperator',
  VenueAdmin = 'VenueAdmin',
  Scanner = 'Scanner',
  User = 'User',
  EventReporting = 'EventReporting',
  BackOfficeStaff = 'BackOfficeStaff',
  SalesOutlet = 'SalesOutlet',
  EventManager = 'EventManager',
  SubPromoter = 'SubPromoter'
}

export type SalesBreakdown = {
  __typename?: 'SalesBreakdown';
  items: Array<NumberStatistic>;
  totalNet: Scalars['Float'];
  faceValue: Scalars['Float'];
};

export type SalesChannelGraphOutput = {
  __typename?: 'SalesChannelGraphOutput';
  transactions: Scalars['Float'];
  online: Scalars['Float'];
  backOffice: Scalars['Float'];
  pos: Scalars['Float'];
};

export type SalesOverviewOutput = {
  __typename?: 'SalesOverviewOutput';
  ticketsIssued: Scalars['Float'];
  membershipsIssued: Scalars['Float'];
  membershipsSold: Scalars['Float'];
  addonsIssued: Scalars['Float'];
  orders: Scalars['Float'];
  ordersPartPaid: Scalars['Float'];
  cartAbandonment: Scalars['Float'];
  returningCustomers: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  ticketsPartPaid: Scalars['Float'];
  membershipsPartPaid: Scalars['Float'];
  ticketsComp: Scalars['Float'];
  ticketsTransferred: Scalars['Float'];
  packagesSold: Scalars['Float'];
  pointsDistributed: Scalars['Float'];
  membershipsRenewed: Scalars['Float'];
  ticketCoverOrdersSold: Scalars['Float'];
  ticketCoverTicketsIssued: Scalars['Float'];
  ticketCoverMembershipsIssued: Scalars['Float'];
  cartRecovered: Scalars['Float'];
};

export type SalesReportOutput = {
  __typename?: 'SalesReportOutput';
  salesBreakdown: SalesBreakdown;
  financialSales: Array<FinancialSales>;
};

export type Scan = {
  __typename?: 'Scan';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  scannedAt: Scalars['DateTime'];
  scanner: User;
  event: Event;
  direction: TicketScanDirection;
  type: TicketScanType;
  gate: Gate;
  hasScannedParentTicket: Scalars['Boolean'];
};

export type ScannedVisitorsCategory = {
  __typename?: 'ScannedVisitorsCategory';
  title: Scalars['String'];
  displayValue: Scalars['String'];
  chartProps: ScannedVisitorsChartProps;
  descriptionItems: Array<Scalars['String']>;
};

export type ScannedVisitorsChartProps = {
  __typename?: 'ScannedVisitorsChartProps';
  percentage: Scalars['Float'];
};

export type ScannedVisitorsGate = {
  __typename?: 'ScannedVisitorsGate';
  title: Scalars['String'];
  subtitle: Scalars['String'];
  displayValue: Scalars['String'];
};

export type ScannedVisitorsOutput = {
  __typename?: 'ScannedVisitorsOutput';
  category: ScannedVisitorsCategory;
  items: Array<ScannedVisitorsGate>;
};

/** Scan status. */
export enum ScanStatus {
  Ok = 'OK',
  Warning = 'WARNING',
  Error = 'ERROR'
}

export type ScanTicketBulk = {
  /** Ticket number */
  ticketNumber: Scalars['String'];
  /** Scanner gate */
  gateId: Scalars['String'];
  /** Scanner direction */
  scanDirection: TicketScanDirection;
  /** Ticket scan type */
  scanType?: Maybe<TicketScanType>;
  eventId?: Maybe<Scalars['String']>;
  scannedAt: Scalars['DateTime'];
};

export type ScanTicketBulkInput = {
  /** Scan data. */
  scans: Array<ScanTicketBulk>;
};

export type ScanTicketInput = {
  /** Ticket number */
  ticketNumber: Scalars['String'];
  /** Scanner gate */
  gateId: Scalars['String'];
  /** Scanner direction */
  scanDirection: TicketScanDirection;
  /** Ticket scan type */
  scanType?: Maybe<TicketScanType>;
};

export type ScanTicketOutput = {
  __typename?: 'ScanTicketOutput';
  status: ScanStatus;
  message?: Maybe<Scalars['String']>;
  ticket?: Maybe<Ticket>;
};

export type ScenarioInput = {
  scenarioId: Scalars['String'];
  /** The zones and Scenario inputs. */
  zones: Array<ScenarioZoneInput>;
};

export type ScenarioZoneInput = {
  /** The zone related to this input */
  zoneId: Scalars['String'];
  /** The ticket types for the zone and Scenario inputs. */
  ticketTypes: Array<ScenarioZoneTicketTypeInput>;
};

/** An object with ticket type id and forecast cost and split of sales */
export type ScenarioZoneTicketType = {
  __typename?: 'ScenarioZoneTicketType';
  /** Ticket type id */
  ticketTypeId: Scalars['String'];
  /** The forecast price of a ticket */
  price: Scalars['Float'];
  /** The forecast split of sales for this ticket type as a percentage */
  splitPercentage: Scalars['Float'];
};

export type ScenarioZoneTicketTypeInput = {
  /** The ticket type of this input. */
  ticketTypeId: Scalars['String'];
  /** The price of a seat in this zone for the ticket type. */
  price: Scalars['Float'];
  /** What percentage of the seats will be this ticket type */
  splitPercentage: Scalars['Float'];
};

export type ScheduleOption = {
  __typename?: 'ScheduleOption';
  id: Scalars['String'];
  interval: ScheduleOptionInterval;
  installmentCount: Scalars['Float'];
  availableUntil?: Maybe<Scalars['String']>;
  minimumOrderAmount?: Maybe<Scalars['Float']>;
  finalPaymentDate?: Maybe<Scalars['String']>;
  depositPercentage?: Maybe<Scalars['Float']>;
  /** This fee is collected to cover the Stripe costs. */
  stripeFeePercentage?: Maybe<Scalars['Float']>;
  /** This fee is collected as additional revenue for offering Payment Plans. */
  promoterFeePercentage?: Maybe<Scalars['Float']>;
  /** This fee is collected for Flicket as part of Payment Plans. */
  flicketFeePercentage?: Maybe<Scalars['Float']>;
};

export type ScheduleOptionInput = {
  id: Scalars['String'];
  interval: ScheduleOptionInterval;
  installmentCount: Scalars['Float'];
  availableUntil?: Maybe<Scalars['String']>;
  minimumOrderAmount?: Maybe<Scalars['Float']>;
  finalPaymentDate?: Maybe<Scalars['String']>;
  depositPercentage?: Maybe<Scalars['Float']>;
  /** This fee is collected to cover the Stripe costs. */
  stripeFeePercentage?: Maybe<Scalars['Float']>;
  /** This fee is collected as additional revenue for offering Payment Plans. */
  promoterFeePercentage?: Maybe<Scalars['Float']>;
  /** This fee is collected for Flicket as part of Payment Plans. */
  flicketFeePercentage?: Maybe<Scalars['Float']>;
};

export enum ScheduleOptionInterval {
  Day = 'day',
  Week = 'week',
  Month = 'month'
}

export type Scout = {
  __typename?: 'Scout';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  purpose?: Maybe<ScoutPurpose>;
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  promoteTo: Array<FilterSelection>;
  exclude: Array<FilterSelection>;
  /** Used to show what we are tracking */
  trackSource?: Maybe<Scalars['String']>;
  /** Id of source */
  trackSourceId?: Maybe<Scalars['String']>;
  /** Release Id of the source that we are tracking */
  trackReleaseId?: Maybe<Scalars['String']>;
  /** Scout active status. */
  isActive: Scalars['Boolean'];
  total: Scalars['Float'];
};

export type ScoutCampaignOverviewOutput = {
  __typename?: 'ScoutCampaignOverviewOutput';
  ticketsSold: Scalars['Float'];
  membershipsSold: Scalars['Float'];
  addonsSold: Scalars['Float'];
  orders: Scalars['Float'];
  cartAbandonment: Scalars['Float'];
  returningCustomers: Scalars['Float'];
  ticketsPaid: Scalars['Float'];
  ticketsComp: Scalars['Float'];
  totalRevenue: Scalars['Float'];
  todayRevenue: Scalars['Float'];
  campaignSize: Scalars['Float'];
  contactRate: Scalars['Float'];
  conversionRate: Scalars['Float'];
  emailClickRate: Scalars['Float'];
  emailOpenRate: Scalars['Float'];
};

export type ScoutCommunication = {
  __typename?: 'ScoutCommunication';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** If a campaign is created from a segment, then it should have a scout Id. */
  scout?: Maybe<Scout>;
  /** If a campaign is created from another campaign, then it should have a campaign Id. */
  campaign?: Maybe<ScoutCommunication>;
  /** email, sms or call */
  type: ScoutCommunicationType;
  data: Scalars['JSON'];
  name: Scalars['String'];
  purpose: ScoutPurpose;
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  /** Used to show what we are tracking */
  trackSource?: Maybe<ReportingFilterSource>;
  /** Id of source */
  trackSourceId?: Maybe<Scalars['String']>;
  /** Release Id of the source that we are tracking */
  trackReleaseId?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  shouldUpdate?: Maybe<Scalars['Boolean']>;
  /** Required when type is email communication */
  mailchimpCampaignId?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  link?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  conversions: Scalars['Float'];
};

export type ScoutCommunicationsInput = {
  isActive?: Maybe<Scalars['Boolean']>;
};

/** The communication types for a scout campaign. */
export enum ScoutCommunicationType {
  Email = 'Email',
  Sms = 'SMS',
  Call = 'Call'
}

export type ScoutCommunicationUser = {
  __typename?: 'ScoutCommunicationUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  scoutCommunication: ScoutCommunication;
  stage: ScoutStage;
  remindAt?: Maybe<Scalars['DateTime']>;
  user: User;
  agentUser?: Maybe<User>;
  response?: Maybe<Scalars['JSON']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  scoutUser?: Maybe<ScoutUser>;
  history: HistoryConnection;
};


export type ScoutCommunicationUserHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};

export type ScoutCommunicationUserConnection = {
  __typename?: 'ScoutCommunicationUserConnection';
  pageInfo: PageInfo;
  edges: Array<ScoutCommunicationUserEdge>;
  totalCount: Scalars['Int'];
};

export type ScoutCommunicationUserEdge = {
  __typename?: 'ScoutCommunicationUserEdge';
  cursor: Scalars['String'];
  node: ScoutCommunicationUser;
};

export enum ScoutEventType {
  Event = 'Event',
  Membership = 'Membership'
}

/** The valid purposes for a scout. */
export enum ScoutPurpose {
  SellMembership = 'SellMembership',
  SellTickets = 'SellTickets',
  SellMerchandise = 'SellMerchandise',
  Communication = 'Communication'
}

/** The valid stages for a scout user. */
export enum ScoutStage {
  NoContact = 'NoContact',
  NoAnswer = 'NoAnswer',
  InProgress = 'InProgress',
  Completed = 'Completed'
}

export type ScoutUser = {
  __typename?: 'ScoutUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  scout: Scout;
  user: User;
  stage: ScoutStage;
  agentUser?: Maybe<User>;
  remindAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type ScoutUserConnection = {
  __typename?: 'ScoutUserConnection';
  pageInfo: PageInfo;
  edges: Array<ScoutUserEdge>;
  totalCount: Scalars['Int'];
};

export type ScoutUserEdge = {
  __typename?: 'ScoutUserEdge';
  cursor: Scalars['String'];
  node: ScoutUser;
};

export type SearchEvent = {
  __typename?: 'SearchEvent';
  id: Scalars['String'];
  title: Scalars['String'];
  dates: Array<EventDate>;
};

export type SearchEventTicketsAppOutput = {
  __typename?: 'SearchEventTicketsAppOutput';
  totalCount: Scalars['Float'];
  tickets: Array<AppTicket>;
};

export type SearchMembership = {
  __typename?: 'SearchMembership';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SearchSeason = {
  __typename?: 'SearchSeason';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Season = {
  __typename?: 'Season';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Season name */
  name: Scalars['String'];
  /** Events in the season */
  events: Array<Event>;
  /** Season active status */
  isActive: Scalars['Boolean'];
  /** Description of the season */
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<ExtendedFile>;
  backgroundImageFullPage?: Maybe<ExtendedFile>;
  displayOrder: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  defaultDateRange?: Maybe<DefaultDateRange>;
};


export type SeasonDefaultDateRangeArgs = {
  input: TicketSoldTimeInput;
};

export type SeasonInformation = {
  __typename?: 'SeasonInformation';
  id: Scalars['String'];
  name: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

export type SeasonLayoutOutput = {
  __typename?: 'SeasonLayoutOutput';
  season?: Maybe<SeasonInformation>;
  defaultDateRange: DateRange;
};

/** The valid values for seat type (based on seats.io). */
export enum SeatType {
  Seat = 'SEAT',
  GeneralAdmission = 'GENERAL_ADMISSION'
}

export type Section = {
  __typename?: 'Section';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  seatsIoKey?: Maybe<Scalars['Int']>;
  isActive: Scalars['Boolean'];
};

export type SendBroadcastInput = {
  richTextString: Scalars['String'];
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  audience?: Maybe<BroadcastAudience>;
  fromName: Scalars['String'];
  subject: Scalars['String'];
  /** If the input contains send time, then this is a scheduled email */
  sendTime?: Maybe<Scalars['DateTime']>;
  broadcastId?: Maybe<Scalars['String']>;
  ticketTypeId?: Maybe<Array<Scalars['String']>>;
  zoneId?: Maybe<Array<Scalars['String']>>;
  releaseId?: Maybe<Array<Scalars['String']>>;
  addonId?: Maybe<Array<Scalars['String']>>;
  /** If the input contains test email, then send test email */
  testEmail?: Maybe<Scalars['String']>;
  status: BroadcastStatus;
  type: BroadcastType;
  marketingType?: Maybe<BroadcastMarketingType>;
  marketingFilters?: Maybe<Array<MarketingFiltersInput>>;
  marketingFiltersV2?: Maybe<Array<BroadcastMarketingFiltersV2Input>>;
  transactionalType?: Maybe<BroadcastTransactionalType>;
  style?: Maybe<BroadcastStyleInput>;
};

export type SendOrderConfirmationInput = {
  notificationType?: Maybe<NotificationType>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SendOrderConfirmationOutput = {
  __typename?: 'SendOrderConfirmationOutput';
  notificationType: NotificationType;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SetPasswordForNewUserInput = {
  password: Scalars['String'];
};

export type SetPosPaymentOptionsInput = {
  posPaymentOptions?: Maybe<PosPaymentOptionsInput>;
  bookingFeeType?: Maybe<FeeType>;
  bookingFee?: Maybe<Scalars['Float']>;
};

/** Represents shipping labels. */
export type ShippingLabels = {
  __typename?: 'ShippingLabels';
  /** Shipping label for e-tickets. */
  eTicket?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['String']>;
};

/** Specifies the input fields for shipping labels. */
export type ShippingLabelsInput = {
  /** Shipping label for e-tickets. */
  eTicket?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['String']>;
  /** Shipping label for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['String']>;
};

export enum ShippingOption {
  Eticket = 'ETICKET',
  PrintedTicketByMail = 'PRINTED_TICKET_BY_MAIL',
  PrintedTicketByCourier = 'PRINTED_TICKET_BY_COURIER',
  PrintedTicketByCourierRural = 'PRINTED_TICKET_BY_COURIER_RURAL',
  PrintedTicketAtVenue = 'PRINTED_TICKET_AT_VENUE'
}

/** Represents shipping options. */
export type ShippingOptions = {
  __typename?: 'ShippingOptions';
  /** Shipping fee for e-tickets. */
  eTicket?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['Float']>;
};

/** Specifies the input fields for shipping options. Set to `null` for unavailability per item. */
export type ShippingOptionsInput = {
  /** Shipping fee for e-tickets. */
  eTicket?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by mail. */
  printedTicketByMail?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier. */
  printedTicketByCourier?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets by courier - rural. */
  printedTicketByCourierRural?: Maybe<Scalars['Float']>;
  /** Shipping fee for printed tickets at venue. */
  printedTicketAtVenue?: Maybe<Scalars['Float']>;
};

/** Represents a Shopify customer */
export type ShopifyCustomerOrder = {
  __typename?: 'ShopifyCustomerOrder';
  id: Scalars['String'];
  total: Scalars['String'];
  currencyCode: Scalars['String'];
  lineItems: Array<ShopifyLineItem>;
};

export type ShopifyIntegration = {
  __typename?: 'ShopifyIntegration';
  storefrontAccessToken: Scalars['String'];
  shopDomain: Scalars['String'];
};

export type ShopifyLineItem = {
  __typename?: 'ShopifyLineItem';
  id: Scalars['String'];
  title: Scalars['String'];
  quantity: Scalars['Float'];
};

export type ShopifySetupInput = {
  token: Scalars['String'];
};

export type ShopifyUser = {
  __typename?: 'ShopifyUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Shopify user ID */
  shopifyUserId: Scalars['String'];
  /** Total amount spent on Shopify store with currency code */
  totalSpent?: Maybe<Scalars['String']>;
};

export type SocialAuthInput = {
  socialAuthType: SocialAuthType;
  accessToken: Scalars['String'];
};

/** Social authentication type */
export enum SocialAuthType {
  Facebook = 'Facebook',
  Google = 'Google'
}

export type SocialConnection = {
  __typename?: 'SocialConnection';
  pageInfo: PageInfo;
  edges: Array<SocialMentionEdge>;
  totalCount: Scalars['Int'];
};

export type SocialMention = {
  __typename?: 'SocialMention';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: Scalars['String'];
  /** igsid of customer, exist for story mentions */
  senderId?: Maybe<Scalars['String']>;
  /** igid of organisation */
  recipientId: Scalars['String'];
  /** id of comment */
  commentId?: Maybe<Scalars['String']>;
  /** id of media */
  mediaId?: Maybe<Scalars['String']>;
  /** content of comment mention and media mention */
  content?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** url of mentions */
  url: Scalars['String'];
};

export type SocialMentionEdge = {
  __typename?: 'SocialMentionEdge';
  cursor: Scalars['String'];
  node: SocialMention;
};

export type SocialWhereInput = {
  mentionType?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
};

export type SplitOrderInput = {
  /** List of the order's line items ids that need to be transfered. */
  lineItemIds: Array<Scalars['String']>;
  /** User id can be provided for new order */
  userId?: Maybe<Scalars['String']>;
  /** Delivery method for the new order */
  deliveryMethod: ShippingOption;
  /** Multi buy promotion id for current order. */
  curMultiBuyId?: Maybe<Scalars['String']>;
  /** Multi buy promotion id for new order. */
  newMultiBuyId?: Maybe<Scalars['String']>;
  newTicketTypes?: Maybe<Array<SplitOrderTicketTypes>>;
};

export type SplitOrderTicketTypes = {
  lineItemId: Scalars['String'];
  ticketTypeId: Scalars['String'];
};

export type StatisticTooltip = {
  __typename?: 'StatisticTooltip';
  content: Scalars['String'];
  /** Link for the learn More button. This should be a link to the external documentation page. */
  link?: Maybe<Scalars['String']>;
};

/** File storage type. */
export enum StorageType {
  Local = 'Local',
  Google = 'Google'
}

export type StringStatistic = {
  __typename?: 'StringStatistic';
  label: Scalars['String'];
  tooltip?: Maybe<StatisticTooltip>;
  value: Scalars['String'];
};

export type StripeConnectAccount = {
  __typename?: 'StripeConnectAccount';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  stripeAccountId: Scalars['String'];
  organization: Organization;
  integration?: Maybe<Integration>;
  setupState: StripeConnectSetupState;
};

/** Stripe Connect setup state. */
export enum StripeConnectSetupState {
  Incomplete = 'INCOMPLETE',
  Pending = 'PENDING',
  Restricted = 'RESTRICTED',
  Active = 'ACTIVE'
}

export type StripePaymentContext = {
  __typename?: 'StripePaymentContext';
  redirectUrl?: Maybe<Scalars['String']>;
  checkoutUrl: Scalars['String'];
  orderId: Scalars['String'];
  stripeAccountId: Scalars['String'];
  publishableKey?: Maybe<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
};

/** Payment method used with Stripe. */
export enum StripePaymentMethod {
  CreditCard = 'CreditCard',
  Afterpay = 'Afterpay'
}

export type SummaryOverviewOutput = {
  __typename?: 'SummaryOverviewOutput';
  ticketsIssued: Scalars['Float'];
  membershipsIssued: Scalars['Float'];
  addonsIssued: Scalars['Float'];
  orders: Scalars['Float'];
  cartAbandonment: Scalars['Float'];
  returningCustomers: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  ticketsComp: Scalars['Float'];
  totalNetRevenue: Scalars['Float'];
  todayNetRevenue: Scalars['Float'];
  yesterdayNetRevenue: Scalars['Float'];
  ticketsSoldToday: Scalars['Float'];
  ticketsSoldYesterday: Scalars['Float'];
  ticketsPartPaid: Scalars['Float'];
  membershipsPartPaid: Scalars['Float'];
  ordersPartPaid: Scalars['Float'];
  membershipsRenewed: Scalars['Float'];
  totalFaceRevenue: Scalars['Float'];
  faceRevenueToday: Scalars['Float'];
  faceRevenueYesterday: Scalars['Float'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export type TagArgs = {
  __typename?: 'TagArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  tagIds: Array<Scalars['String']>;
};

export type TagEntitiesInput = {
  /** tag ids */
  tags: Array<Scalars['String']>;
  entityIds?: Maybe<Array<Scalars['String']>>;
  scoutCommunicationId?: Maybe<Scalars['String']>;
};

/** The tax calculation method, either inclusive or exclusive */
export enum TaxCalculationMethod {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export type Ticket = {
  __typename?: 'Ticket';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Ticket owner. Not all tickets have users. */
  user?: Maybe<User>;
  /** Ticket number */
  ticketNumber: Scalars['String'];
  /** Ticket creation origin */
  origin: TicketOrigin;
  /** Ticket status */
  status: TicketStatus;
  /** Ticket event. Only available for single tickets. */
  event?: Maybe<Event>;
  /** Ticket release. Only available for single tickets. */
  release?: Maybe<Release>;
  /** Ticket seat label. Only available for seated events. */
  seatLabel?: Maybe<Scalars['String']>;
  /** Ticket seat zone. Only available for seated events. */
  seatZone?: Maybe<Scalars['String']>;
  seatZoneEntity?: Maybe<Zone>;
  /** Ticket seat section. Only available for seated events. */
  seatSection?: Maybe<Scalars['String']>;
  seatSectionEntity?: Maybe<Section>;
  /** Ticket seat type. Only available for seated events. */
  seatType?: Maybe<SeatType>;
  /** Ticket seat tags. Only available for seated events. */
  seatTags?: Maybe<Array<Scalars['String']>>;
  /** Indicates if the claim for the seat has been released for the next membership. */
  hasReleasedNextMembershipClaim: Scalars['Boolean'];
  ticketTransfer?: Maybe<TicketTransfer>;
  /** Indicates if all required ticket holder naming fields have been filled in. */
  isNamed: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  postalCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  memberSince?: Maybe<Scalars['DateTime']>;
  licensePlate?: Maybe<Scalars['String']>;
  membershipNumber?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  dietaryRequirements?: Maybe<Scalars['String']>;
  lengthOfStay?: Maybe<Scalars['String']>;
  optInToMarketing?: Maybe<Scalars['Boolean']>;
  vaccinePass?: Maybe<VaccinePass>;
  emergencyName?: Maybe<Scalars['String']>;
  emergencyContact?: Maybe<Scalars['String']>;
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<CustomField>>;
  dunedinResidentialAddress?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  membershipSubscriptionId?: Maybe<Scalars['String']>;
  /** Timestamp when ticket was most recently preprinted. */
  preprint?: Maybe<Scalars['DateTime']>;
  scans?: Maybe<Array<Scan>>;
  ticketSendFields?: Maybe<Array<TicketSendFields>>;
  firstScanIn?: Maybe<Scan>;
  lastScanOut?: Maybe<Scan>;
  ticketCover?: Maybe<TicketTicketCover>;
  downloadCount: Scalars['Float'];
  /**
   * Only populated if at least the relations ['event', 'event.venue', 'event.venue.gates'] are loaded,
   *       Also load ['event.eventAddon'] if used for getting scannable gates from event addon
   */
  gates?: Maybe<Array<Gate>>;
  isViewable: Scalars['Boolean'];
  /** The membership type associated with the ticket. */
  membershipType?: Maybe<MembershipType>;
  /** The ticket type associated with the ticket. */
  ticketType?: Maybe<TicketType>;
  lineItem?: Maybe<LineItem>;
  maxTransferPrice?: Maybe<Scalars['Float']>;
  order?: Maybe<Order>;
  isHold: Scalars['Boolean'];
  displayName: Scalars['String'];
  parentTicketNumber?: Maybe<Scalars['String']>;
  /**
   * Returns the transfer fee paid for the ticket. If the ticket was sold, it will
   * include the seller fee. If the ticket was bought through a transfer, it will
   * include the buyer fee.
   */
  transferFee?: Maybe<TicketTransferFee>;
  digitalPassCode?: Maybe<Scalars['String']>;
  digitalEventUrl?: Maybe<Scalars['String']>;
  shouldBeNamed: Scalars['Boolean'];
  qrCode: Scalars['String'];
  hasRenewedMembership: Scalars['Boolean'];
  /** Parent ticket. Only available for single tickets that are child tickets of a membership. */
  parentTicket?: Maybe<Ticket>;
  childTickets?: Maybe<Array<Ticket>>;
  membership?: Maybe<Membership>;
  addon?: Maybe<AddonUnion>;
  transferStatus?: Maybe<TicketTransferStatus>;
  resellabilityState: TicketResellabilityState;
  purchaseRestrictionText?: Maybe<Array<Scalars['String']>>;
};

export enum TicketAvailabilityStatus {
  Available = 'AVAILABLE',
  AllocationExhausted = 'ALLOCATION_EXHAUSTED',
  Soldout = 'SOLDOUT'
}

export type TicketConnection = {
  __typename?: 'TicketConnection';
  pageInfo: PageInfo;
  edges: Array<TicketEdge>;
  totalCount: Scalars['Int'];
};

export type TicketCoverAmount = {
  __typename?: 'TicketCoverAmount';
  totalFeeAmount: Scalars['Float'];
};

export type TicketCoverInput = {
  /** Promoter fee as percentage of ticket price */
  promoterFeePercentage: Scalars['Float'];
  /** Ticket cover purchase behaviour */
  purchaseBehaviour: TicketCoverPurchaseBehaviour;
  /** Whether ticket cover is enabled */
  isEnabled: Scalars['Boolean'];
};

export enum TicketCoverPurchaseBehaviour {
  Optional = 'Optional',
  Additional = 'Additional',
  Included = 'Included'
}

export type TicketEdge = {
  __typename?: 'TicketEdge';
  cursor: Scalars['String'];
  node: Ticket;
};

export type TicketNotAvailableError = CreateOrderError & {
  __typename?: 'TicketNotAvailableError';
  message: Scalars['String'];
  shouldDisplayWaitlist: Scalars['Boolean'];
  eventId: Scalars['String'];
  releaseId: Scalars['String'];
};

/** Ticket creation origin. */
export enum TicketOrigin {
  Order = 'ORDER',
  Import = 'IMPORT'
}

export type TicketResellabilityState = {
  __typename?: 'TicketResellabilityState';
  ticketTransferAllowed: Scalars['Boolean'];
  ticketResaleAllowed: Scalars['Boolean'];
  hoursLeftToTransfer: Scalars['Float'];
  /** The latest date that a transfer can be completed. Only relevant to Afterpay orders. */
  latestTransferCompletionDate?: Maybe<Scalars['DateTime']>;
  /**
   * The reason why the ticket is not resellable. This field is only present when
   * ticketResaleAllowed or ticketTransferAllowed is false
   */
  reason?: Maybe<Scalars['String']>;
};

/** Scanner direction. */
export enum TicketScanDirection {
  ScanIn = 'SCAN_IN',
  ScanOut = 'SCAN_OUT'
}

/** Ticket scan type. */
export enum TicketScanType {
  Eticket = 'ETICKET',
  Hardcopy = 'HARDCOPY',
  Digital = 'DIGITAL',
  Pos = 'POS'
}

export type TicketSendFields = {
  __typename?: 'TicketSendFields';
  active: Scalars['Boolean'];
  email: Scalars['String'];
};

export type TicketSoldTimeInput = {
  source: ReportingFilterSource;
};

/** Ticket status. */
export enum TicketStatus {
  Active = 'ACTIVE',
  SeatChanged = 'SEAT_CHANGED',
  OrderCanceled = 'ORDER_CANCELED',
  OrderChanged = 'ORDER_CHANGED',
  Released = 'RELEASED',
  Refunded = 'REFUNDED',
  Transferred = 'TRANSFERRED',
  TransferPending = 'TRANSFER_PENDING',
  MarkedForResale = 'MARKED_FOR_RESALE'
}

export type TicketTicketCover = {
  __typename?: 'TicketTicketCover';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  perTicketAmount: Scalars['Float'];
  orderTicketCover: OrderTicketCover;
};

export type TicketTransfer = {
  __typename?: 'TicketTransfer';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  transferStatus: TicketTransferStatus;
  newOwnerEmail?: Maybe<Scalars['String']>;
  buyerTransferFee: Scalars['Float'];
  sellerTransferFee: Scalars['Float'];
  originalLineItemPrice: Scalars['Float'];
  newLineItemPrice: Scalars['Float'];
  transferGroupId?: Maybe<Scalars['String']>;
};

export type TicketTransferFee = {
  __typename?: 'TicketTransferFee';
  type: TransferFeeType;
  fee: Scalars['Float'];
};

/** The status of the ticket transfer. */
export enum TicketTransferStatus {
  Pending = 'PENDING',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED'
}

/** Ticket type. */
export type TicketType = {
  __typename?: 'TicketType';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  event: Event;
  /** The kind of the ticket type. */
  kind: TicketTypeKind;
  quantity?: Maybe<Scalars['Int']>;
  minPurchaseQuantity?: Maybe<Scalars['Int']>;
  maxPurchaseQuantity?: Maybe<Scalars['Int']>;
  /** Whether the ticket type is grouped. */
  isGrouped: Scalars['Boolean'];
  /** The amount of people in a group. Returns null if the ticket type is not grouped. */
  groupAmount?: Maybe<Scalars['Int']>;
  /** Number of tickets that must be purchased in this set. */
  quantitySet?: Maybe<Scalars['Int']>;
  /** Ticket group name. */
  name: Scalars['String'];
  /** Ticket type allowed dates. */
  dates: Array<TicketTypeDate>;
  /** Ticket fee type. */
  bookingFeeType: FeeType;
  /** Ticket fee. */
  bookingFee: Scalars['Float'];
  /** Per ticket stadium levy ticket fee. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  /** Mark a ticket type as high demand */
  inHighDemand: Scalars['Boolean'];
  /** Mark a ticket type as sold out */
  soldOut: Scalars['Boolean'];
  /** Ticket description */
  description?: Maybe<Scalars['String']>;
  /** Restrictions for the ticket group. */
  restrictions?: Maybe<Scalars['String']>;
  /** Print at home information for the ticket group. */
  printAtHomeInformation?: Maybe<Scalars['String']>;
  /** Image for the bottom of the print at home ticket . Returns null if the not set. */
  ticketBanner?: Maybe<ExtendedFile>;
  /** Image to be shown on the customer portal of the ticket . Returns null if the not set. */
  ticketImage?: Maybe<ExtendedFile>;
  /** Image gallery for ticket listing and sub-page. */
  imageGallery: Array<ImageGalleryItem>;
  namingFields: Array<FormField>;
  price?: Maybe<Scalars['Float']>;
  isResaleTicket: Scalars['Boolean'];
  /** The resale ticket type that this ticket will be transformed into when resold. */
  resaleTicketTypeId?: Maybe<Scalars['String']>;
  /** If this is a resale ticket, this is the array of ticketTypes that is grouped under this resale ticket */
  resaleTicketTypes: Array<TicketType>;
  /** Number of fans on waitlist */
  waitlistFanCount: Scalars['Int'];
  resaleHiddenFromPublic: Scalars['Boolean'];
  isUsherDisabled: Scalars['Boolean'];
  tags: Array<Tag>;
  totalSold?: Maybe<Scalars['Int']>;
};

export type TicketTypeArgs = {
  __typename?: 'TicketTypeArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  ticketTypeIds: Array<Scalars['String']>;
};

/** Allowed date for the ticket type. */
export type TicketTypeDate = {
  __typename?: 'TicketTypeDate';
  eventDateId: Scalars['ID'];
  /** Alternate entry time for the date. */
  alternateEntryTime?: Maybe<Scalars['String']>;
};

/** The subset of ticket types the caller would like returned as part of the event */
export enum TicketTypeFilter {
  All = 'ALL',
  ResaleOnly = 'RESALE_ONLY'
}

/** Ticket kind for ticket type. */
export enum TicketTypeKind {
  Standard = 'STANDARD',
  ComplimentaryNamed = 'COMPLIMENTARY_NAMED',
  ComplimentaryUnnamed = 'COMPLIMENTARY_UNNAMED',
  Usher = 'USHER',
  Addon = 'ADDON'
}

export type TicketTypePurchaseTotal = {
  __typename?: 'TicketTypePurchaseTotal';
  ticketType: TicketType;
  total: Scalars['Int'];
};

export type TicketTypesByIdInput = {
  ticketTypeIds: Array<Scalars['String']>;
};

export type TicketWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
};

export type TokenSet = {
  __typename?: 'TokenSet';
  idToken?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['Float']>;
  tokenType?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
};

export type TopReferrersOutput = {
  __typename?: 'TopReferrersOutput';
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type TopRepeatPurchasers = {
  __typename?: 'TopRepeatPurchasers';
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type TopTagsOutput = {
  __typename?: 'TopTagsOutput';
  tag?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Float']>;
};

export type TrackingCodeCreateInput = {
  eventId: Scalars['String'];
  title: Scalars['String'];
};

export type TrackingCodeOrderInput = {
  trackingCodeTitle: Scalars['String'];
  eventId: Scalars['String'];
  orderId: Scalars['String'];
};

export type TrackingCodeOutput = {
  __typename?: 'TrackingCodeOutput';
  id: Scalars['ID'];
  title: Scalars['String'];
  eventId: Scalars['String'];
  url: Scalars['String'];
  visitorCount: Scalars['Float'];
  sales: Scalars['Float'];
  totalTicketRevenue: Scalars['Float'];
  ticketsSold: Scalars['Float'];
  createdAt: Scalars['DateTime'];
};

export type TrackingCodeVisitInput = {
  trackingCodeTitle: Scalars['String'];
  eventId: Scalars['String'];
};

export type TransactionOrderByInput = {
  createdAt?: Maybe<OrderByDirection>;
};

/** Transfer fee type. */
export enum TransferFeeType {
  Buyer = 'BUYER',
  Seller = 'SELLER'
}

export type TwoFactorAuthenticationCodeInput = {
  twoFactorAuthenticationCode: Scalars['String'];
};

export type TwoFactorAuthenticationSetupResult = {
  __typename?: 'TwoFactorAuthenticationSetupResult';
  dataURL: Scalars['String'];
  secret: Scalars['String'];
};

/** UnsubscribeReason reason. */
export enum UnsubscribeReason {
  NoLongerInterested = 'NoLongerInterested',
  NeverSubscribed = 'NeverSubscribed',
  TooFrequent = 'TooFrequent',
  Other = 'OTHER'
}

export type UpdateAutomationInput = {
  name?: Maybe<Scalars['String']>;
  automationType?: Maybe<AutomationType>;
  templateId?: Maybe<Scalars['Float']>;
  subject?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  targetSource?: Maybe<ReportingFilterSource>;
  targetSourceId?: Maybe<Scalars['String']>;
  targetReleaseId?: Maybe<Scalars['String']>;
  timeOfDay?: Maybe<Scalars['DateTime']>;
  templateName?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
};

export type UpdateCreditsInput = {
  credits: Scalars['Float'];
};

/** Updates the info for a digital event/membership. */
export type UpdateDigitalInfoInput = {
  /** The url that digital pass codes are appended to in order to access the digital event/membership. */
  url?: Maybe<Scalars['String']>;
  /** The Postmark email template that is used to send digital pass codes. */
  emailTemplateId?: Maybe<Scalars['String']>;
  additionalEmailInfo?: Maybe<AdditionalEmailInfoInput>;
};

export type UpdateEventAddonInput = {
  /** The name of the addon. */
  name?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming?: Maybe<Scalars['Boolean']>;
  /** Maximum quantity to be sold for the addon. */
  quantity?: Maybe<Scalars['Float']>;
  /** The price associated with the addon. */
  price?: Maybe<Scalars['Float']>;
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** Images for the ticket gallery */
  imageGallery?: Maybe<Array<ImageGalleryItemInput>>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  /** The active status of the addon. */
  isActive?: Maybe<Scalars['Boolean']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
  insideFee?: Maybe<Scalars['Float']>;
  /** Whether hide QR code on ticket. */
  hideQRCode?: Maybe<Scalars['Boolean']>;
};

/** Updates an event. */
export type UpdateEventInput = {
  /** The kind of event. */
  eventKind?: Maybe<EventKind>;
  /** Represents additional info required to host a digital event. */
  digitalInfo?: Maybe<UpdateDigitalInfoInput>;
  /** Event season. */
  season?: Maybe<Scalars['String']>;
  venue?: Maybe<Scalars['String']>;
  /** Event title. */
  title?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  hardCopyTicketBanner?: Maybe<Scalars['Upload']>;
  /** Event description. */
  description?: Maybe<Scalars['String']>;
  /** Event dates. */
  dates?: Maybe<Array<CreateEventDate>>;
  /** Date at which tickets are viewable/printable from. */
  ticketsViewableFromDate?: Maybe<Scalars['String']>;
  /** Event active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Event featured status. */
  isFeatured?: Maybe<Scalars['Boolean']>;
  /** Booking fee type. */
  bookingFeeType?: Maybe<FeeType>;
  /** Booking fee. */
  bookingFee?: Maybe<Scalars['Float']>;
  /** Whether event is R-18. */
  isR18?: Maybe<Scalars['Boolean']>;
  /** Whether event is checking for vaccine passports on entry */
  checkForVaccinePass?: Maybe<Scalars['Boolean']>;
  /** Whether naming ticket is available. */
  canNameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether renaming ticket is available. */
  canRenameTicket?: Maybe<Scalars['Boolean']>;
  /** Whether changing seats is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** Whether transfering ticket is available. */
  canTransferTicket?: Maybe<Scalars['Boolean']>;
  /** Whether the waitlist is visible on the event information page */
  enableWaitlist?: Maybe<Scalars['Boolean']>;
  /** The start date of the presale registration. */
  waitlistStartDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the presale registration. */
  waitlistEndDate?: Maybe<Scalars['DateTime']>;
  /** Whether release codes are visible on the event information page */
  enableReleaseCodes?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the event ticket. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  /** The payment gateways enabled for the event. */
  gateways?: Maybe<Array<Scalars['String']>>;
  termsAndConditions?: Maybe<Scalars['String']>;
  importantNotice?: Maybe<Scalars['String']>;
  accessibility?: Maybe<Scalars['String']>;
  invoiceInformation?: Maybe<Scalars['String']>;
  presaleInformation?: Maybe<Scalars['String']>;
  emailNotification?: Maybe<Scalars['String']>;
  redirectURL?: Maybe<Scalars['String']>;
  zonesAllocatedGates?: Maybe<Array<ZoneAllocatedGateInput>>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Inside fees for the event */
  insideFeeDetails?: Maybe<Array<InsideFeeDetailsInput>>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  gaVenue?: Maybe<CreateGaEventVenueInput>;
  ticketCover?: Maybe<TicketCoverInput>;
  listedReleaseOptions?: Maybe<ListedReleaseOptionsInput>;
  merchantReference?: Maybe<Scalars['String']>;
  pixelId?: Maybe<Scalars['String']>;
  hiddenFromPublic?: Maybe<Scalars['Boolean']>;
  enablePaymentPlans?: Maybe<Scalars['Boolean']>;
  paymentPlanScheduleOptions?: Maybe<Array<ScheduleOptionInput>>;
};

export type UpdateEventMultiBuyPromotionInput = {
  name?: Maybe<Scalars['String']>;
  buyQuantity?: Maybe<Scalars['Float']>;
  getQuantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  buyTicketTypeId?: Maybe<Scalars['String']>;
  getTicketTypeId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateEventPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code?: Maybe<Scalars['String']>;
  /** The discount type associated with the promotion. */
  discountType?: Maybe<DiscountType>;
  /** The discount amount of the promotion. */
  discountAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<EventPromoCodeType>;
  ticketTypes?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

/** Updates an EventForecst */
export type UpdateForecastInput = {
  eventId: Scalars['String'];
  /** The zones and forecast inputs. */
  zones: Array<ForecastZoneInput>;
  /** The zones and forecast inputs. */
  scenarios: Array<ScenarioInput>;
};

export type UpdateHoldOrderInput = {
  /** List of actions to perform on line items. */
  lineItemActions: Array<LineItemActionInput>;
  /** Multi buy promotion id. */
  multiBuyId?: Maybe<Scalars['String']>;
};

export type UpdateIntegrationInput = {
  name?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  wcPxPostSecretKey?: Maybe<Scalars['String']>;
  wcPxPostPublicKey?: Maybe<Scalars['String']>;
  webhookUrl?: Maybe<Scalars['String']>;
  transactionFeeType?: Maybe<FeeType>;
  transactionFee?: Maybe<Scalars['Float']>;
  transactionFeeType2?: Maybe<FeeType>;
  transactionFee2?: Maybe<Scalars['Float']>;
  paymentMethodType?: Maybe<Scalars['String']>;
  facebookTempToken?: Maybe<Scalars['String']>;
  facebookPageId?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  bnplEnabled?: Maybe<Scalars['Boolean']>;
  bnplTransactionFeePercent?: Maybe<Scalars['Float']>;
  createAccount?: Maybe<Scalars['Boolean']>;
  type: IntegrationType;
};

export type UpdateMembershipAddonInput = {
  /** The name of the addon. */
  name?: Maybe<Scalars['String']>;
  /** Indicates whether naming the addon is possible or not. */
  enableNaming?: Maybe<Scalars['Boolean']>;
  /** Maximum quantity to be sold for the addon. */
  quantity?: Maybe<Scalars['Float']>;
  /** The price associated with the addon. */
  price?: Maybe<Scalars['Float']>;
  /** The brief description of the addon. */
  description?: Maybe<Scalars['String']>;
  /** The restrictions applied to the addon. */
  restrictions?: Maybe<Scalars['String']>;
  /** The active status of the addon. */
  isActive?: Maybe<Scalars['Boolean']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** Image banner specific for a ticket type on the print at home ticket */
  addonImage?: Maybe<Scalars['Upload']>;
};

export type UpdateMembershipInput = {
  /** Name of the membership. */
  name?: Maybe<Scalars['String']>;
  /** The kind of membership. */
  membershipKind?: Maybe<MembershipKind>;
  /** Represents additional info required to host a digital event. */
  digitalInfo?: Maybe<UpdateDigitalInfoInput>;
  /** The previous memberships related to the membership. */
  previousMemberships?: Maybe<Array<Scalars['String']>>;
  /** The start date of the membership. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date of the membership. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The booking fee type of the membership. */
  bookingFeeType?: Maybe<FeeType>;
  /** The booking fee for the membership. */
  bookingFee?: Maybe<Scalars['Float']>;
  /** Shipping options. */
  shippingOptions?: Maybe<ShippingOptionsInput>;
  /** The description of the membership. */
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  printBanner1?: Maybe<Scalars['Upload']>;
  printBanner2?: Maybe<Scalars['Upload']>;
  /** The payment gateways enabled for the membership. */
  gateways?: Maybe<Array<Scalars['String']>>;
  /** Whether naming a membership is available. */
  canNameMembership?: Maybe<Scalars['Boolean']>;
  /** Whether renaming a membership is available. */
  canRenameMembership?: Maybe<Scalars['Boolean']>;
  /** Flag to determine if the membership is hidden from the public */
  hiddenFromPublic?: Maybe<Scalars['Boolean']>;
  /** Whether naming a membership is available. */
  canChangeSeats?: Maybe<Scalars['Boolean']>;
  /** The fee for changing seats for the membership. */
  changingSeatsFee?: Maybe<Scalars['Float']>;
  /** Whether the membership is ticketed. */
  isTicketed?: Maybe<Scalars['Boolean']>;
  /** The active status of the membership. */
  isActive?: Maybe<Scalars['Boolean']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  importantNotice?: Maybe<Scalars['String']>;
  accessibility?: Maybe<Scalars['String']>;
  invoiceInformation?: Maybe<Scalars['String']>;
  showSchedule?: Maybe<Scalars['Boolean']>;
  emailNotification?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  canUsePoints?: Maybe<Scalars['Boolean']>;
  enableAutoRenew?: Maybe<Scalars['Boolean']>;
  /** Whether individual event tickets can be transferred. */
  canTransferTickets?: Maybe<Scalars['Boolean']>;
  ticketCover?: Maybe<MembershipTicketCoverInput>;
  enablePaymentPlans?: Maybe<Scalars['Boolean']>;
  paymentPlanScheduleOptions?: Maybe<Array<ScheduleOptionInput>>;
  /** The season associated with the membership. */
  season?: Maybe<Scalars['String']>;
  /** The events associated with the membership. */
  events?: Maybe<Array<Scalars['String']>>;
  /** The zones of the seating. */
  membershipZones?: Maybe<Array<UpdateMembershipZoneInput>>;
};

export type UpdateMembershipMultiBuyPromotionInput = {
  name?: Maybe<Scalars['String']>;
  buyQuantity?: Maybe<Scalars['Float']>;
  getQuantity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  buyMembershipTypeId?: Maybe<Scalars['String']>;
  getMembershipTypeId?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateMembershipPromoCodeInput = {
  /** The unique code that represents the promotion. */
  code?: Maybe<Scalars['String']>;
  /** The discount type associated with the promotion. */
  discountType?: Maybe<DiscountType>;
  /** The discount amount of the promotion. */
  discountAmount?: Maybe<Scalars['Float']>;
  /** The maximum amount of use condition of the promotion. Returns `null` when inactive. */
  maxUsage?: Maybe<Scalars['Float']>;
  /** The start date condition of the promotion. Returns `null` when inactive. */
  startDate?: Maybe<Scalars['DateTime']>;
  /** The end date condition of the promotion. Returns `null` when inactive. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The minimum order amount condition of the promotion. Returns `null` when inactive. */
  minOrderAmount?: Maybe<Scalars['Float']>;
  /** The maximum order amount condition of the promotion. Returns `null` when inactive. */
  maxOrderAmount?: Maybe<Scalars['Float']>;
  type?: Maybe<MembershipPromoCodeType>;
  membershipTypes?: Maybe<Array<Scalars['String']>>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type UpdateMembershipTypeEventTransferPriceInput = {
  membershipTypeId: Scalars['String'];
  membershipZoneId: Scalars['String'];
  prices: Array<MembershipTypeTransferPriceInput>;
};

export type UpdateMembershipTypeInput = {
  /** The kind of the membership type. */
  kind?: Maybe<MembershipTypeKind>;
  /** Name of the membership type. */
  name?: Maybe<Scalars['String']>;
  /** The ticket fee type. */
  ticketFeeType?: Maybe<FeeType>;
  /** The number of membership tickets that must be purchased together in this set. */
  quantitySet?: Maybe<Scalars['Float']>;
  /** The ticket fee. */
  ticketFee?: Maybe<Scalars['Float']>;
  /** Restrictions for the membership type. */
  description?: Maybe<Scalars['String']>;
  /** Restrictions for the membership type. */
  restrictions?: Maybe<Scalars['String']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  isGuestCard?: Maybe<Scalars['Boolean']>;
};

export type UpdateMembershipZoneInput = {
  /** The  memebershipZone id. */
  id?: Maybe<Scalars['String']>;
  /** The  zone. */
  zone: MembershipZoneZoneInput;
  /** The pricing level of the seating zone. Will be set to `zone` for non-seated events. */
  pricingLevel: ZonePricingLevel;
  /** The active status of the membership seating. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The list of membership types of the seating zone. */
  membershipTypes: Array<UpdateMembershipZoneMembershipTypeInput>;
};

export type UpdateMembershipZoneMembershipTypeInput = {
  /** The membership type id of the seating. */
  membershipTypeId: Scalars['String'];
  /** The price of the zone per membership. Pass null if `pricingLevel` of the parent is set to other than `zone`. */
  price?: Maybe<Scalars['Float']>;
  /** The sections of the membership seating. Pass null if `pricingLevel` of the parent is set to other than `section`. */
  sections?: Maybe<Array<UpdateMembershipZoneMembershipTypeSectionInput>>;
  /** The active status of the membership type. Defaults to `false`. */
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UpdateMembershipZoneMembershipTypeSectionInput = {
  /** The section id. Should be equal to one of the sections defined in the venue. */
  sectionId: Scalars['String'];
  /** The section name. Should be equal to one of the sections defined in the venue. */
  name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
};

export type UpdateOrderInput = {
  /** The step the order currently is in. */
  step: Scalars['Float'];
  /** The delivery method chosen by the customer. */
  deliveryMethod?: Maybe<ShippingOption>;
  /** Buyer details. */
  buyerInformation?: Maybe<BuyerInformationInput>;
  /** Billing address provided by the customer. */
  billingAddress?: Maybe<AddressInput>;
  /** Shipping address provided by the customer. */
  shippingAddress?: Maybe<AddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  /** If the purchaser has added dietary requirements */
  dietaryRequirements?: Maybe<Scalars['String']>;
  /** How the user heard about the event */
  heardAboutUs?: Maybe<HeardAboutUsType>;
  /** Heard about us details when heardAboutUs is other */
  heardAboutUsDetails?: Maybe<Scalars['String']>;
  /** If the order is starting a membership subscription */
  automaticRenewMambership?: Maybe<Scalars['Boolean']>;
  /**
   * Whether the order is updated on admin portal. 
   *     If the user is an admin, we won't update the details even if this is true.
   */
  updateDetails?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

export type UpdateOrderStatusInput = {
  /** Order status to transition to. */
  status?: Maybe<OrderStatus>;
  /** Selected payment method if order status is paid (allowed: cash, eftpos, invoice, other). */
  method?: Maybe<PaymentProvider>;
  /** Will not send order confirmation email if set */
  skipOrderConfirmation?: Maybe<Scalars['Boolean']>;
  /** Will send the customer an invoice email for hold orders if set */
  sendHoldOrderCustomerEmail?: Maybe<Scalars['Boolean']>;
  /** Whether to generate a Xero invoice for order. Only applies to hold orders for organizations with Xero integration enabled. */
  shouldGenerateXeroInvoice?: Maybe<Scalars['Boolean']>;
  isComp?: Maybe<Scalars['Boolean']>;
  compReason?: Maybe<Scalars['String']>;
};

export type UpdateOrderTicketCoverInput = {
  includeTicketCover: Scalars['Boolean'];
};

export type UpdateOrganizationInput = {
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  supportInfo?: Maybe<Scalars['String']>;
  landingPage?: Maybe<LandingPage>;
  showMemberships?: Maybe<Scalars['Boolean']>;
  showMembershipRenewal?: Maybe<Scalars['Boolean']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  privacyPolicy?: Maybe<Scalars['String']>;
  branding?: Maybe<BrandingInput>;
  marketing?: Maybe<MarketingInput>;
  helpLink?: Maybe<Scalars['String']>;
  customTaxRate?: Maybe<Scalars['Float']>;
  customTaxName?: Maybe<Scalars['String']>;
  /** Shipping options. */
  shippingLabels?: Maybe<ShippingLabelsInput>;
};

export type UpdatePasswordInput = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

export type UpdatePointInput = {
  name?: Maybe<Scalars['String']>;
  termsAndConditions?: Maybe<Scalars['String']>;
  gatewayIds: Array<Scalars['String']>;
  isEnabled: Scalars['Boolean'];
};

export type UpdatePointItemInput = {
  name: Scalars['String'];
  price: Scalars['Float'];
  amount: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  banner?: Maybe<Scalars['Upload']>;
  backgroundImage?: Maybe<Scalars['Upload']>;
  descriptionImage?: Maybe<Scalars['Upload']>;
  isVisible: Scalars['Boolean'];
};

export type UpdatePosUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  accessCode?: Maybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  twoFactorAuthenticationSecret?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  /** Billing address provided by the user. */
  billingAddress?: Maybe<PartialAddressInput>;
  /** Shipping address provided by the user. */
  shippingAddress?: Maybe<PartialAddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  credits?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  instagramUsername?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Elevate users privileges from user to admin. */
  elevatePrivileges?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

/** Updates a Referral Campaign */
export type UpdateReferralCampaignDto = {
  referralReleaseId?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  referralOwnerRewardType?: Maybe<ReferralRewardType>;
  referralOwnerRewardQuantityType?: Maybe<ReferralRewardQuantityType>;
  referralOwnerRewardQuantity?: Maybe<Scalars['Float']>;
  referralUserRewardType?: Maybe<ReferralRewardType>;
  referralUserRewardQuantityType?: Maybe<ReferralRewardQuantityType>;
  referralUserRewardQuantity?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
};

export type UpdateReleaseInput = {
  /** Release name. */
  name?: Maybe<Scalars['String']>;
  /** Release type. */
  type?: Maybe<ReleaseType>;
  /** The slug for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  slug?: Maybe<Scalars['String']>;
  /** The password for the unlisted release. Pass null if the release type is set to other than `unlisted`. */
  password?: Maybe<Scalars['String']>;
  /** The start condition of the release. */
  startCondition?: Maybe<ReleaseStartCondition>;
  /** The start date of the release. Pass null if the startCondition is set to other than `date`. */
  startConditionDate?: Maybe<Scalars['DateTime']>;
  /** The end condition of the release. */
  endCondition?: Maybe<ReleaseEndCondition>;
  /** The quantity limit of the release. Pass null if the endCondition is set to other than `quantity`. */
  endConditionQuantity?: Maybe<Scalars['Float']>;
  /** The end date of the release. Pass null if the endCondition is set to other than `date`. */
  endConditionDate?: Maybe<Scalars['DateTime']>;
  /** The status of the release. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** The zones of the release. */
  releaseZones?: Maybe<Array<ReleaseZoneInput>>;
  /** The title of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleTitle?: Maybe<Scalars['String']>;
  presaleLogo?: Maybe<Scalars['Upload']>;
  presaleHeader?: Maybe<Scalars['Upload']>;
  /** The information of the unlisted release. Pass null if the `type` is set to other than `unlisted`. */
  presaleInformation?: Maybe<Scalars['String']>;
  /** The information that will be shown to customers on the ticketing page. */
  releaseNotes?: Maybe<Scalars['String']>;
  /** Ask how did you heard about us during the purchasing flow */
  askHowDidYouHearAboutUs?: Maybe<Scalars['Boolean']>;
  /** Request purchasers  */
  askForDietaryRequirements?: Maybe<Scalars['Boolean']>;
  /** Are comps visible to public */
  allowPublicComp?: Maybe<Scalars['Boolean']>;
  /** Can this release use Windcaves Account2Account functionality */
  allowAccount2Account?: Maybe<Scalars['Boolean']>;
  /** Can customers create hold orders for this release */
  allowHoldOrders?: Maybe<Scalars['Boolean']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  releaseEventAddons?: Maybe<Array<Scalars['String']>>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  channels?: Maybe<Array<Scalars['String']>>;
};

export type UpdateReleaseZoneInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Images for the ticket gallery */
  imageGallery?: Maybe<Array<ImageGalleryItemInput>>;
  capacity?: Maybe<Scalars['Int']>;
  admission: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};

export type UpdateResaleConfigInput = {
  eventId: Scalars['String'];
  canTransferTicket: Scalars['Boolean'];
  ticketResaleWaitlistEnabled: Scalars['Boolean'];
  ticketResaleEnabled: Scalars['Boolean'];
  /** The fee the seller pays for transferring a ticket. */
  sellerTransferringTicketFee?: Maybe<Scalars['Float']>;
  /** The fee the buyer pays for transferring a ticket. */
  buyerTransferringTicketFee?: Maybe<Scalars['Float']>;
};

export type UpdateScoutCommunicationInput = {
  name?: Maybe<Scalars['String']>;
  purpose?: Maybe<ScoutPurpose>;
  scheduledAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<ScoutCommunicationType>;
  includeOptedOutOfMarketing?: Maybe<Scalars['Boolean']>;
  segmentId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  trackSource?: Maybe<ReportingFilterSource>;
  trackSourceId?: Maybe<Scalars['String']>;
  trackReleaseId?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  shouldUpdate?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  listId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['Float']>;
  subject?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type UpdateScoutCommunicationUserInput = {
  stage: ScoutStage;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
};

export type UpdateScoutInput = {
  name?: Maybe<Scalars['String']>;
  promoteTo?: Maybe<Array<FilterSelectionInput>>;
  exclude?: Maybe<Array<FilterSelectionInput>>;
  isActive: Scalars['Boolean'];
};

export type UpdateSeasonInput = {
  /** Season name. */
  name?: Maybe<Scalars['String']>;
  /** Season active status. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Season description. */
  description?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['Upload']>;
  backgroundImageFullPage?: Maybe<Scalars['Upload']>;
  displayOrder?: Maybe<Scalars['Float']>;
};

export type UpdateTagInput = {
  name?: Maybe<Scalars['String']>;
};

export type UpdateTicketHolderInput = {
  vaccinePass?: Maybe<Scalars['Upload']>;
  optInToMarketing?: Maybe<Scalars['Boolean']>;
  customFields?: Maybe<Array<CustomFieldInput>>;
  /** First name of the ticket holder. */
  firstName?: Maybe<Scalars['String']>;
  /** Last name of the ticket holder. */
  lastName?: Maybe<Scalars['String']>;
  /** Email of the ticket holder. */
  email?: Maybe<Scalars['String']>;
  /** Phone number of the ticket holder. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Birth date of the ticket holder. */
  birthDate?: Maybe<Scalars['DateTime']>;
  /** Gender of the ticket holder. */
  gender?: Maybe<Gender>;
  /** Postal code of the ticket holder. */
  postalCode?: Maybe<Scalars['String']>;
  /** Company name of the ticket holder (optional). */
  companyName?: Maybe<Scalars['String']>;
  /** Since when the ticket holder is a member. */
  memberSince?: Maybe<Scalars['DateTime']>;
  /** License plate of the ticket holder. */
  licensePlate?: Maybe<Scalars['String']>;
  /** Membership number of the ticket holder. */
  membershipNumber?: Maybe<Scalars['String']>;
  /** Comments of the ticket holder. */
  comments?: Maybe<Scalars['String']>;
  /** Dietary requirements of the ticket holder. */
  dietaryRequirements?: Maybe<Scalars['String']>;
  /** How long someone is staying at an event */
  lengthOfStay?: Maybe<Scalars['String']>;
  /** Name of the emergency contact */
  emergencyName?: Maybe<Scalars['String']>;
  /** Phone number of the emergency contact */
  emergencyContact?: Maybe<Scalars['String']>;
  /** Phone number of the emergency contact */
  emergencyContactRelationship?: Maybe<Scalars['String']>;
  /** Residential address in Dunedin */
  dunedinResidentialAddress?: Maybe<Scalars['String']>;
  /** Student id of the ticket holder */
  studentId?: Maybe<Scalars['String']>;
};

export type UpdateTicketTypeInput = {
  /** The kind of the ticket type. */
  kind?: Maybe<TicketTypeKind>;
  /** Whether the ticket type is grouped. */
  isGrouped?: Maybe<Scalars['Boolean']>;
  /** The amount of people in a group. Returns null if the ticket type is not grouped. */
  groupAmount?: Maybe<Scalars['Float']>;
  /** The number of tickets that must be purchased together in this set. */
  quantitySet?: Maybe<Scalars['Float']>;
  /** Ticket group name. */
  name?: Maybe<Scalars['String']>;
  /** Event dates. */
  dates?: Maybe<Array<CreateTicketTypeDate>>;
  /** Booking fee type. */
  bookingFeeType?: Maybe<FeeType>;
  /** Booking fee. */
  bookingFee?: Maybe<Scalars['Float']>;
  /** Per ticket stadium levy ticket fee. */
  stadiumLevyFee?: Maybe<Scalars['Float']>;
  /** Mark a ticket type as high demand */
  inHighDemand?: Maybe<Scalars['Boolean']>;
  /** MMark a ticket type as sold out */
  soldOut?: Maybe<Scalars['Boolean']>;
  /** Description of the ticket */
  description?: Maybe<Scalars['String']>;
  /** Restrictions for the ticket group. */
  restrictions?: Maybe<Scalars['String']>;
  /** Print at home information for the ticket group. */
  printAtHomeInformation?: Maybe<Scalars['String']>;
  namingFields?: Maybe<Array<FormFieldInput>>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketBanner?: Maybe<Scalars['Upload']>;
  /** Image banner specific for a ticket type on the print at home ticket */
  ticketImage?: Maybe<Scalars['Upload']>;
  /** Images for the ticket gallery */
  imageGallery?: Maybe<Array<ImageGalleryItemInput>>;
  quantity?: Maybe<Scalars['Float']>;
  minPurchaseQuantity?: Maybe<Scalars['Float']>;
  maxPurchaseQuantity?: Maybe<Scalars['Float']>;
  tags?: Maybe<Array<Scalars['String']>>;
  /** Price for v2 event */
  price?: Maybe<Scalars['Float']>;
  /** releaseZoneId for v2 event */
  releaseZoneId?: Maybe<Scalars['String']>;
  /** isActive for v2 event */
  isActive?: Maybe<Scalars['Boolean']>;
  /** isComplimentary for v2 event */
  isComplimentary?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  twoFactorAuthenticationSecret?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  phoneNumber?: Maybe<Scalars['String']>;
  birthDate?: Maybe<Scalars['DateTime']>;
  gender?: Maybe<Gender>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  /** Billing address provided by the user. */
  billingAddress?: Maybe<PartialAddressInput>;
  /** Shipping address provided by the user. */
  shippingAddress?: Maybe<PartialAddressInput>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress?: Maybe<Scalars['Boolean']>;
  credits?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  taxNumber?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  instagramUsername?: Maybe<Scalars['String']>;
  referralId?: Maybe<Scalars['String']>;
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** Elevate users privileges from user to admin. */
  elevatePrivileges?: Maybe<Scalars['Boolean']>;
  userAcquisition?: Maybe<UserAcquisition>;
};

export type UpdateVenueInput = {
  accessibilityInfo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  capacityUtilization?: Maybe<Scalars['Float']>;
  timezone?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  location?: Maybe<CoordinateInput>;
  zones?: Maybe<Array<ZoneInput>>;
  gates?: Maybe<Array<GateInput>>;
};


export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  roles: Array<Role>;
  /** Permision set based on roles */
  permissions: Array<Permission>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  isTwoFactorAuthenticationEnabled: Scalars['Boolean'];
  isLoggedInWithLinkedAccount: Scalars['Boolean'];
  hasVerifiedEmail: Scalars['Boolean'];
  birthDate?: Maybe<Scalars['DateTime']>;
  phoneNumber?: Maybe<Scalars['String']>;
  gender?: Maybe<Gender>;
  usableForMarketing?: Maybe<Scalars['Boolean']>;
  /** Billing address associated with the user. */
  billingAddress?: Maybe<PartialAddress>;
  /** Shipping address associated with the user. */
  shippingAddress?: Maybe<PartialAddress>;
  /** Whether the shipping address matches the billing address. */
  shippingAddressMatchesBillingAddress: Scalars['Boolean'];
  /** User credits balance */
  credits?: Maybe<Scalars['Float']>;
  /** User points balance */
  points: Scalars['Float'];
  usSegment?: Maybe<UsSegment>;
  /** unique uuid of a customer used to link to referral campaigns */
  referralId: Scalars['String'];
  /** User instagram username */
  instagramUsername?: Maybe<Scalars['String']>;
  /** The company name of the user. */
  companyName?: Maybe<Scalars['String']>;
  /** The tax number of the user. */
  taxNumber?: Maybe<Scalars['String']>;
  /** The region of the user, derived from the postal code of billing address. */
  region?: Maybe<Scalars['String']>;
  unsubscribeReason?: Maybe<UnsubscribeReason>;
  otherUnsubscribeReason?: Maybe<Scalars['String']>;
  lastTimeLoggedInAt?: Maybe<Scalars['DateTime']>;
  tags: Array<Tag>;
  scoutCommunications: Array<ScoutCommunication>;
  orders: OrderConnection;
  membershipTickets: Array<Ticket>;
  eventTickets: Array<Ticket>;
  hasMemberships: Scalars['Boolean'];
  hasTickets: Scalars['Boolean'];
  LTV: Scalars['Float'];
  history: HistoryConnection;
  /** The IDs of the events the user can access when their account is scoped for only certain events */
  accessEventIds?: Maybe<Array<Scalars['String']>>;
  /** The IDs of the memberships the user can access when their account is scoped for only certain events */
  accessMembershipIds?: Maybe<Array<Scalars['String']>>;
  /** The Shopify user entry for the user if they have used the organization's Shopify store */
  shopifyUser?: Maybe<ShopifyUser>;
};


export type UserOrdersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<OrderWhereInput>;
  orderBy?: Maybe<OrderOrderByInput>;
};


export type UserHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<HistoryOrderByInput>;
};

export type UserAcquisition = {
  referralCodeId?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
  totalCount: Scalars['Int'];
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node: User;
};

export type UserOrderByInput = {
  firstName?: Maybe<OrderByDirection>;
  lastName?: Maybe<OrderByDirection>;
  createdAt?: Maybe<OrderByDirection>;
  updatedAt?: Maybe<OrderByDirection>;
};

export type UsersUpdatedBetweenInput = {
  /** The start date of the range to search provided in the organizations timezone. */
  startDate: Scalars['DateTime'];
  /** The end date of the range to search provided in the organizations timezone. */
  endDate: Scalars['DateTime'];
};

export type UserWhereInput = {
  /** Fuzzy search query. */
  q?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
};

export type UsherComplimetaryCreateInput = {
  releaseId: Scalars['String'];
  seatZoneId?: Maybe<Scalars['String']>;
  seatZone: Scalars['String'];
  eventId: Scalars['String'];
  releaseZoneId: Scalars['String'];
  name: Scalars['String'];
};

export type UsSegment = {
  __typename?: 'UsSegment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
  organization: Organization;
  accuracy: Scalars['Float'];
  /** A single letter representation of the segment a user is in */
  segment: Scalars['String'];
  /** Address that was provided for the Us Segment */
  address: PartialAddress;
};

export type VaccinePass = {
  __typename?: 'VaccinePass';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** Pass owner */
  user?: Maybe<User>;
  /** Ticket that the pass is asscoiated with */
  ticket?: Maybe<Ticket>;
  /** Raw payload of the vaccine pass */
  payload?: Maybe<Scalars['String']>;
  /** Decoded payload of the vaccine pass */
  payloadDecoded: Scalars['JSON'];
};

export type Venue = {
  __typename?: 'Venue';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  /** Seats.io chart key */
  seatsChartKey?: Maybe<Scalars['String']>;
  zones: Array<Zone>;
  /** Venue address */
  address: Address;
  /** Venue seating settings. */
  seating: VenueSeating;
  capacity?: Maybe<Scalars['Int']>;
  /** Venue capacity utilization as a percentage. */
  capacityUtilization: Scalars['Float'];
  accessibilityInfo?: Maybe<Scalars['String']>;
  /** Venue coordinates (lat, lng). */
  location?: Maybe<Coordinate>;
  gates: Array<Gate>;
  trackHardCopyTickets: Scalars['Boolean'];
  /** Does this stadium require a per ticket levy to be charged */
  hasStadiumLevy: Scalars['Boolean'];
  timezone?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  /** Filter options that are available for a seated venue */
  filterOptions?: Maybe<Array<Scalars['String']>>;
  createdFromEvent: Scalars['Boolean'];
};

/** Venue seating settings. */
export enum VenueSeating {
  Seated = 'Seated',
  NonSeated = 'NonSeated',
  Digital = 'Digital'
}

export type VerifyEmailInput = {
  token: Scalars['String'];
};

export type VerifyMagicLinkOutput = {
  __typename?: 'VerifyMagicLinkOutput';
  redirectUrl: Scalars['String'];
};

export type VerifyPinPayment3Ds = {
  refId: Scalars['String'];
  sessionToken: Scalars['String'];
};

/** Represents a date in an event. */
export type Waitlist = {
  __typename?: 'Waitlist';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  user: User;
  event: Event;
};

export type WaitlistOverviewOutput = {
  __typename?: 'WaitlistOverviewOutput';
  totalCustomers?: Maybe<Scalars['Float']>;
  totalNewCustomers?: Maybe<Scalars['Float']>;
  totalReturningCustomers?: Maybe<Scalars['Float']>;
  customersOptedIntoMarketing?: Maybe<Scalars['Float']>;
  totalRevenue?: Maybe<Scalars['Float']>;
  numberOfOrders?: Maybe<Scalars['Float']>;
  numberOfSuccesfulUsers?: Maybe<Scalars['Float']>;
  numberOfTickets?: Maybe<Scalars['Float']>;
  yetToConvert?: Maybe<Scalars['Float']>;
};

/** Represents a customer on a waitlist for an event. This customer may or may not exist as an actual user. */
export type WaitlistUser = {
  __typename?: 'WaitlistUser';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization: Organization;
  event: Event;
  /** The referral code used when registering for presale access. Will be tied to the referring user/waitlist user */
  referralCode?: Maybe<ReferralCode>;
  /** The user that is on the waitlist. This may be null if the user does not already exist in our system. */
  user: User;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  usableForMarketing: Scalars['Boolean'];
  fullName: Scalars['String'];
};

/** Contains encoded ticket data in the format required for various wallet providers. */
export type WalletTicket = {
  __typename?: 'WalletTicket';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  /** (Apple): authentication token to be used with Apple Wallet. */
  authenticationToken?: Maybe<Scalars['String']>;
  /** (Google): The object ID to identify this ticket in the Google Wallet API. */
  googleObjectId?: Maybe<Scalars['String']>;
  /** The ticket that is encoded in the entity */
  ticket?: Maybe<Ticket>;
  /** The organization associated with the ticket. */
  organization?: Maybe<Organization>;
};

export type WindcavePaymentContext = {
  __typename?: 'WindcavePaymentContext';
  redirectUrl?: Maybe<Scalars['String']>;
  checkoutUrl: Scalars['String'];
  orderId: Scalars['String'];
};

/** The payment result. */
export type WindcavePaymentResultOutput = {
  __typename?: 'WindcavePaymentResultOutput';
  redirectUrl: Scalars['String'];
};

/** Represents a Xero account. */
export type XeroAccountOutput = {
  __typename?: 'XeroAccountOutput';
  accountId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxType?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

/** Represents all available Xero accounts. */
export type XeroAllAccountsOutput = {
  __typename?: 'XeroAllAccountsOutput';
  currentAccountCode?: Maybe<Scalars['String']>;
  availableAccounts?: Maybe<Array<XeroAccountOutput>>;
};

export type XeroCallbackInput = {
  code: Scalars['String'];
};

export type XeroConfigurationInput = {
  accountCode: Scalars['String'];
  sendFlicketInvoice: Scalars['Boolean'];
  invoiceStatus: XeroInvoiceStatus;
  numDaysToPay: Scalars['Float'];
  useCompanyNameOnInvoice: Scalars['Boolean'];
};

export type XeroConfigurationOutput = {
  __typename?: 'XeroConfigurationOutput';
  accountCode?: Maybe<Scalars['String']>;
  sendFlicketInvoice?: Maybe<Scalars['Boolean']>;
  invoiceStatus?: Maybe<XeroInvoiceStatus>;
  numDaysToPay?: Maybe<Scalars['Float']>;
  useCompanyNameOnInvoice?: Maybe<Scalars['Boolean']>;
};

/** Xero invoice status */
export enum XeroInvoiceStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Authorised = 'AUTHORISED'
}

export type Zone = {
  __typename?: 'Zone';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  name: Scalars['String'];
  capacity?: Maybe<Scalars['Int']>;
  sections?: Maybe<Array<Section>>;
  stand?: Maybe<Scalars['String']>;
  seated: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  /** Image gallery for zone listing and sub-page. */
  imageGallery: Array<ImageGalleryItem>;
  displayOrder: Scalars['Int'];
  admission: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
};

/** Allowed date for the zone. */
export type ZoneAllocatedDate = {
  __typename?: 'ZoneAllocatedDate';
  eventDateId: Scalars['ID'];
  /** Alternate entry time for the zone. */
  alternateEntryTime: Scalars['String'];
};

export type ZoneAllocatedDateInput = {
  eventDateId: Scalars['ID'];
  /** Alternate entry time for the zone. */
  alternateEntryTime: Scalars['String'];
};

export type ZoneAllocatedGate = {
  __typename?: 'ZoneAllocatedGate';
  zoneId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  gates?: Maybe<Array<Scalars['String']>>;
  sections?: Maybe<Array<ZoneAllocatedGateSection>>;
  availabilityCount?: Maybe<ZoneAllocationAvailability>;
  /** Alternate entry time for the zone. */
  dates?: Maybe<Array<ZoneAllocatedDate>>;
};

export type ZoneAllocatedGateInput = {
  zoneId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  gates?: Maybe<Array<Scalars['String']>>;
  sections?: Maybe<Array<ZoneAllocatedGateSectionInput>>;
  availabilityCount?: Maybe<ZoneAllocationAvailabilityInput>;
  /** Alternate entry time for the zone. */
  dates?: Maybe<Array<ZoneAllocatedDateInput>>;
};

export type ZoneAllocatedGateSection = {
  __typename?: 'ZoneAllocatedGateSection';
  /** Section name. */
  name: Scalars['String'];
  /** Section id. */
  sectionId?: Maybe<Scalars['String']>;
  gates?: Maybe<Array<Scalars['String']>>;
};

export type ZoneAllocatedGateSectionInput = {
  /** Section name. */
  name: Scalars['String'];
  /** Section id. */
  sectionId?: Maybe<Scalars['String']>;
  gates?: Maybe<Array<Scalars['String']>>;
};

export type ZoneAllocationAvailability = {
  __typename?: 'ZoneAllocationAvailability';
  notForSale?: Maybe<Scalars['Float']>;
  holdByMembership?: Maybe<Scalars['Float']>;
  booked?: Maybe<Scalars['Float']>;
  available?: Maybe<Scalars['Float']>;
};

export type ZoneAllocationAvailabilityInput = {
  notForSale?: Maybe<Scalars['Float']>;
  holdByMembership?: Maybe<Scalars['Float']>;
  booked?: Maybe<Scalars['Float']>;
  available?: Maybe<Scalars['Float']>;
};

export type ZoneArgs = {
  __typename?: 'ZoneArgs';
  kind: BroadcastMarketingFiltersV2Kind;
  exclude: Scalars['Boolean'];
  eventId: Scalars['String'];
  seatZoneIds: Array<Scalars['String']>;
};

/** The zone belonging to a venue (can only be set for non-seated venues) */
export type ZoneInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  capacity?: Maybe<Scalars['Int']>;
  stand?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Float'];
  admission?: Maybe<Scalars['Boolean']>;
};

export enum ZonePricingLevel {
  Zone = 'ZONE',
  Section = 'SECTION'
}

/** Represents a section level pricing. */
export type ZonePricingTypeSection = {
  __typename?: 'ZonePricingTypeSection';
  /** Section name. */
  name: Scalars['String'];
  /** Section id. */
  sectionId: Scalars['String'];
  /** The price of the section ticket type or the section membership type. */
  price?: Maybe<Scalars['Float']>;
};

export type ActiveCompetitionByTypeQueryVariables = Exact<{
  input: ActiveCompetitionByTypeInput;
}>;


export type ActiveCompetitionByTypeQuery = (
  { __typename?: 'Query' }
  & { activeCompetitionByType?: Maybe<(
    { __typename?: 'Competition' }
    & CompetitionFragmentFragment
  )> }
);

export type ActiveCompetitionsQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type ActiveCompetitionsQuery = (
  { __typename?: 'Query' }
  & { activeCompetitions?: Maybe<Array<(
    { __typename?: 'Competition' }
    & CompetitionFragmentFragment
  )>> }
);

export type CompetitionFragmentFragment = (
  { __typename?: 'Competition' }
  & Pick<Competition, 'id' | 'startDate' | 'endDate' | 'competitionType' | 'description' | 'termsAndConditions' | 'title' | 'isPublished'>
  & { prizes?: Maybe<Array<(
    { __typename?: 'CompetitionPrize' }
    & CompetitionPrizeFragmentFragment
  )>>, promoter?: Maybe<Array<(
    { __typename?: 'CompetitionReward' }
    & CompetitionRewardFragmentFragment
  )>> }
);

export type CompetitionByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompetitionByIdQuery = (
  { __typename?: 'Query' }
  & { competition: (
    { __typename?: 'Competition' }
    & CompetitionFragmentFragment
  ) }
);

export type CompetitionPrizeFragmentFragment = (
  { __typename?: 'CompetitionPrize' }
  & Pick<CompetitionPrize, 'id' | 'title' | 'description' | 'prizeOrder' | 'quantity'>
  & { image?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )> }
);

export type CompetitionRewardFragmentFragment = (
  { __typename?: 'CompetitionReward' }
  & Pick<CompetitionReward, 'platform' | 'actionType' | 'value' | 'isEnabled' | 'socialLinkType'>
);

export type GetEventAndReleaseForCustomerQueryVariables = Exact<{
  input: EventsWithAccessControlInput;
}>;


export type GetEventAndReleaseForCustomerQuery = (
  { __typename?: 'Query' }
  & { getEventAndReleaseForCustomer: (
    { __typename?: 'EventsWithAccessControlOutput' }
    & Pick<EventsWithAccessControlOutput, 'isAdmin' | 'accessType'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'hasPublicRelease'>
      & { venue: (
        { __typename?: 'Venue' }
        & VenueFragment
      ), addons: Array<(
        { __typename?: 'EventAddon' }
        & Pick<EventAddon, 'id'>
      )>, ticketTypes: Array<(
        { __typename?: 'TicketType' }
        & { imageGallery: Array<(
          { __typename?: 'ImageGalleryItem' }
          & ImageGalleryFragmentFragment
        )> }
        & TicketTypeBaseFragment
        & TicketTypeInfoFragment
        & FinanceFragment
      )>, paymentPlanSettings?: Maybe<(
        { __typename?: 'PaymentPlanSettings' }
        & Pick<PaymentPlanSettings, 'disabledAt'>
      )> }
      & EventFragment
    ), release?: Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id' | 'name' | 'type' | 'slug' | 'isActive' | 'requiresAuth' | 'minPurchaseQuantity' | 'maxPurchaseQuantity' | 'isResaleRelease' | 'presaleTitle' | 'presaleInformation' | 'releaseNotes' | 'allowPublicComp'>
      & { status: (
        { __typename?: 'ReleaseStatus' }
        & Pick<ReleaseStatus, 'value' | 'message'>
      ), event: (
        { __typename?: 'Event' }
        & Pick<Event, 'hasPublicRelease'>
        & { venue: (
          { __typename?: 'Venue' }
          & VenueFragment
        ), ticketTypes: Array<(
          { __typename?: 'TicketType' }
          & TicketTypeBaseFragment
          & TicketTypeInfoFragment
        )>, multiBuyPromotions: Array<(
          { __typename?: 'EventMultiBuyPromotion' }
          & Pick<EventMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
          & { getTicketType: (
            { __typename?: 'TicketType' }
            & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
            & { dates: Array<(
              { __typename?: 'TicketTypeDate' }
              & Pick<TicketTypeDate, 'alternateEntryTime'>
            )> }
          ), buyTicketType: (
            { __typename?: 'TicketType' }
            & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
            & { dates: Array<(
              { __typename?: 'TicketTypeDate' }
              & Pick<TicketTypeDate, 'alternateEntryTime'>
            )> }
          ) }
        )> }
        & EventFragment
      ), presaleHeader?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, presaleLogo?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, releaseZones: Array<(
        { __typename?: 'ReleaseZone' }
        & Pick<ReleaseZone, 'id' | 'isActive' | 'isSoldOut' | 'pricingLevel'>
        & { zone: (
          { __typename?: 'Zone' }
          & Pick<Zone, 'id' | 'name' | 'description' | 'displayOrder'>
          & { imageGallery: Array<(
            { __typename?: 'ImageGalleryItem' }
            & ImageGalleryFragmentFragment
          )> }
        ), ticketTypes: Array<(
          { __typename?: 'ReleaseZoneTicketType' }
          & Pick<ReleaseZoneTicketType, 'price' | 'isActive' | 'ticketTypeId' | 'isSoldOut' | 'minPurchaseQuantity' | 'maxPurchaseQuantity' | 'ticketAvailabilityStatus' | 'purchaseRestrictionsHelpText'>
          & { sections?: Maybe<Array<(
            { __typename?: 'ZonePricingTypeSection' }
            & Pick<ZonePricingTypeSection, 'name' | 'price'>
          )>>, purchaseRestrictions: Array<(
            { __typename?: 'PurchaseRestriction' }
            & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
            & { requiredEntities: Array<(
              { __typename?: 'PurchaseRestrictionRequiredEntity' }
              & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
            )> }
          )> }
        )> }
      )>, releaseEventAddons: Array<(
        { __typename?: 'ReleaseEventAddon' }
        & { eventAddon: (
          { __typename?: 'EventAddon' }
          & Pick<EventAddon, 'id' | 'name' | 'enableNaming' | 'price' | 'description' | 'restrictions' | 'isActive' | 'quantity' | 'addonAvailabilityStatus' | 'maxPurchaseQuantity' | 'purchaseRestrictionsHelpText'>
          & { addonImage?: Maybe<(
            { __typename?: 'ExtendedFile' }
            & FileFragment
          )>, purchaseRestrictions: Array<(
            { __typename?: 'PurchaseRestriction' }
            & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
            & { requiredEntities: Array<(
              { __typename?: 'PurchaseRestrictionRequiredEntity' }
              & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
            )> }
          )>, imageGallery: Array<(
            { __typename?: 'ImageGalleryItem' }
            & ImageGalleryFragmentFragment
          )> }
        ) }
      )>, channels: Array<(
        { __typename?: 'EventChannelOutput' }
        & Pick<EventChannelOutput, 'id' | 'label' | 'isEnabled'>
      )> }
    )> }
  ) }
);

export type UserEntriesQueryVariables = Exact<{
  input: GetUserEntriesInput;
}>;


export type UserEntriesQuery = (
  { __typename?: 'Query' }
  & { userEntries?: Maybe<Array<(
    { __typename?: 'GrantRewardOutput' }
    & Pick<GrantRewardOutput, 'platform' | 'actionType' | 'value'>
  )>> }
);

export type GrantCompetitionRewardMutationVariables = Exact<{
  input: GrantCompetitionRewardInput;
}>;


export type GrantCompetitionRewardMutation = (
  { __typename?: 'Mutation' }
  & { grantCompetitionReward?: Maybe<(
    { __typename?: 'CompetitionReward' }
    & Pick<CompetitionReward, 'platform' | 'actionType' | 'value'>
  )> }
);

export type JoinResaleWaitlistMutationVariables = Exact<{
  input: ResaleWaitlistInput;
}>;


export type JoinResaleWaitlistMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'joinResaleWaitlist'>
);

export type MembershipsOverviewQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type MembershipsOverviewQuery = (
  { __typename?: 'Query' }
  & { memberships: (
    { __typename?: 'MembershipConnection' }
    & Pick<MembershipConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'MembershipEdge' }
      & { node: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'name' | 'membershipKind' | 'startDate' | 'endDate'>
        & { thumbnail?: Maybe<(
          { __typename?: 'ExtendedFile' }
          & FileFragment
        )>, events?: Maybe<Array<(
          { __typename?: 'Event' }
          & Pick<Event, 'id'>
          & { venueAddress: (
            { __typename?: 'Venue' }
            & Pick<Venue, 'name'>
            & { address: (
              { __typename?: 'Address' }
              & Pick<Address, 'city'>
            ) }
          ) }
        )>>, membershipZones: Array<(
          { __typename?: 'MembershipZone' }
          & Pick<MembershipZone, 'id' | 'pricingLevel' | 'isActive'>
          & { zone: (
            { __typename?: 'Zone' }
            & Pick<Zone, 'id' | 'name'>
          ), membershipTypes: Array<(
            { __typename?: 'MembershipSeatingZoneType' }
            & Pick<MembershipSeatingZoneType, 'price' | 'isActive'>
            & { sections?: Maybe<Array<(
              { __typename?: 'ZonePricingTypeSection' }
              & Pick<ZonePricingTypeSection, 'name' | 'price'>
            )>> }
          )> }
        )> }
        & MembershipBaseFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type PointFragment = (
  { __typename?: 'Point' }
  & Pick<Point, 'id' | 'name' | 'termsAndConditions'>
  & { gateways: Array<(
    { __typename?: 'Integration' }
    & Pick<Integration, 'id' | 'name' | 'type' | 'transactionFeeType' | 'transactionFee' | 'transactionFeeType2' | 'transactionFee2' | 'publicKey' | 'paymentMethodType'>
  )> }
);

export type PointItemFragment = (
  { __typename?: 'PointItem' }
  & Pick<PointItem, 'id' | 'name' | 'price' | 'amount' | 'isVisible' | 'description'>
  & { banner?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, thumbnail?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, backgroundImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, descriptionImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )> }
);

export type PointQueryVariables = Exact<{ [key: string]: never; }>;


export type PointQuery = (
  { __typename?: 'Query' }
  & { point?: Maybe<(
    { __typename?: 'Point' }
    & PointFragment
  )> }
);

export type PointItemQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PointItemQuery = (
  { __typename?: 'Query' }
  & { pointItem: (
    { __typename?: 'PointItem' }
    & PointItemFragment
  ) }
);

export type PointItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type PointItemsQuery = (
  { __typename?: 'Query' }
  & { pointItems: Array<(
    { __typename?: 'PointItem' }
    & PointItemFragment
  )> }
);

export type GetReferralCampaignByReferralCodeQueryVariables = Exact<{
  referralCode: Scalars['String'];
}>;


export type GetReferralCampaignByReferralCodeQuery = (
  { __typename?: 'Query' }
  & { getReferralCampaignByReferralCode?: Maybe<(
    { __typename?: 'ReferralCampaign' }
    & Pick<ReferralCampaign, 'id' | 'isActive' | 'referralOwnerRewardType' | 'referralOwnerRewardQuantityType' | 'referralOwnerRewardQuantity' | 'referralUserRewardType' | 'referralUserRewardQuantityType' | 'referralUserRewardQuantity'>
    & { referralRelease?: Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id'>
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  )> }
);

export type AddressFragment = (
  { __typename?: 'Address' }
  & Pick<Address, 'line1' | 'line2' | 'suburb' | 'postalCode' | 'city' | 'state' | 'country'>
);

export type CompetitionStatsFragmentFragment = (
  { __typename?: 'CompetitionCustomerReporting' }
  & Pick<CompetitionCustomerReporting, 'competitionEndDate' | 'competitionReferralLink' | 'competitionTitle' | 'competitionSharePageLink' | 'referralsMade' | 'entries' | 'eventId' | 'isCompetitionRunning' | 'competitionId' | 'competitionEventTitle'>
  & { competitionEventLogo?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & Pick<ExtendedFile, 'fileName' | 'storage' | 'originalFileName' | 'directory'>
    & { metaData?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'height' | 'width'>
    )> }
  )>, competitionBannerImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & Pick<ExtendedFile, 'fileName' | 'storage' | 'originalFileName' | 'directory'>
    & { metaData?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'height' | 'width'>
    )> }
  )> }
);

export type EventBaseFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'createdAt' | 'updatedAt'>
);

export type EventFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'customTaxRate' | 'taxRate' | 'taxCalculationMethod' | 'ticketResaleWaitlistEnabled'>
  & { multiBuyPromotions: Array<(
    { __typename?: 'EventMultiBuyPromotion' }
    & Pick<EventMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
    & { getTicketType: (
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'alternateEntryTime'>
      )> }
    ), buyTicketType: (
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'alternateEntryTime'>
      )> }
    ) }
  )>, customFields: Array<(
    { __typename?: 'CustomFieldOuput' }
    & Pick<CustomFieldOuput, 'id' | 'label' | 'value' | 'targetArea'>
  )> }
  & EventBaseFragment
  & EventInfoFragment
  & EventOptionsFragment
);

export type EventInfoFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'title' | 'description' | 'eventKind' | 'hasEventEnded' | 'startDate' | 'endDate' | 'isFeatured' | 'importantNotice' | 'termsAndConditions' | 'accessibility' | 'isR18' | 'presaleInformation' | 'enableReleaseCodes' | 'enableWaitlist' | 'isWaitlistAvailable' | 'waitlistEndDate' | 'pixelId' | 'hiddenFromPublic'>
  & { dates: Array<(
    { __typename?: 'EventDate' }
    & Pick<EventDate, 'startDate' | 'endDate'>
  )>, thumbnail?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, logo?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, backgroundImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, backgroundImageFullPage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, descriptionImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, printBanner1?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, printBanner2?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, digitalPassCodesStatus?: Maybe<(
    { __typename?: 'DigitalPassCodeStatusOutput' }
    & Pick<DigitalPassCodeStatusOutput, 'remainingPassCodes'>
  )>, season?: Maybe<(
    { __typename?: 'Season' }
    & Pick<Season, 'id'>
  )> }
);

export type EventOptionsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'isActive' | 'seatsEventKey' | 'bookingFeeType' | 'bookingFee' | 'canUsePoints' | 'canChangeSeats' | 'changingSeatsFee'>
  & { shippingOptions?: Maybe<(
    { __typename?: 'ShippingOptions' }
    & ShippingOptionsFragment
  )>, gateways: Array<(
    { __typename?: 'Integration' }
    & Pick<Integration, 'id' | 'name' | 'type' | 'transactionFee' | 'transactionFeeType' | 'transactionFeeType2' | 'transactionFee2' | 'bnplEnabled' | 'bnplTransactionFeePercent'>
  )> }
);

export type FileFragment = (
  { __typename?: 'ExtendedFile' }
  & Pick<ExtendedFile, 'storage' | 'directory' | 'fileName' | 'originalFileName'>
  & { metaData?: Maybe<(
    { __typename?: 'MetaData' }
    & Pick<MetaData, 'width' | 'height'>
  )> }
);

export type ImageGalleryFragmentFragment = (
  { __typename?: 'ImageGalleryItem' }
  & Pick<ImageGalleryItem, 'id' | 'title'>
  & { original?: Maybe<(
    { __typename?: 'GalleryImage' }
    & Pick<GalleryImage, 'src' | 'aspectRatio'>
    & { metaData?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'width' | 'height'>
    )> }
  )>, cropped?: Maybe<(
    { __typename?: 'GalleryImage' }
    & Pick<GalleryImage, 'src' | 'aspectRatio'>
    & { metaData?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'width' | 'height'>
    )> }
  )> }
);

export type IntegrationFragmentFragment = (
  { __typename?: 'Integration' }
  & Pick<Integration, 'id' | 'type' | 'paymentMethodType' | 'name' | 'transactionFeeType' | 'transactionFee' | 'transactionFeeType2' | 'transactionFee2' | 'publicKey' | 'webhookUrl' | 'bnplEnabled' | 'bnplTransactionFeePercent'>
);

export type LineItemBaseFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'id' | 'createdAt' | 'updatedAt'>
);

export type LineItemFinanceFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'price' | 'originalPrice' | 'quantity' | 'ticketFee' | 'ticketFeeAmount' | 'stadiumLevyFee' | 'taxRate' | 'total' | 'taxAmount' | 'customTaxAmount' | 'customTaxRate'>
  & { pointItem?: Maybe<(
    { __typename?: 'PointItem' }
    & Pick<PointItem, 'id' | 'name' | 'amount'>
  )>, ticketType?: Maybe<(
    { __typename?: 'TicketType' }
    & Pick<TicketType, 'id' | 'name'>
  )>, membershipType?: Maybe<(
    { __typename?: 'MembershipType' }
    & Pick<MembershipType, 'id'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'name'>
    ) }
  )>, eventAddon?: Maybe<(
    { __typename?: 'EventAddon' }
    & Pick<EventAddon, 'id'>
  )>, membershipAddon?: Maybe<(
    { __typename?: 'MembershipAddon' }
    & Pick<MembershipAddon, 'id'>
  )> }
);

export type LineItemFragment = (
  { __typename?: 'LineItem' }
  & LineItemBaseFragment
  & LineItemInfoFragment
  & LineItemSeatFragment
  & LineItemFinanceFragment
);

export type LineItemInfoFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'name' | 'type'>
);

export type LineItemSeatFragment = (
  { __typename?: 'LineItem' }
  & Pick<LineItem, 'seatLabel' | 'seatZone' | 'seatSection' | 'seatTags'>
);

export type MembershipBaseFragment = (
  { __typename?: 'Membership' }
  & Pick<Membership, 'id' | 'createdAt' | 'updatedAt'>
);

export type MembershipFragment = (
  { __typename?: 'Membership' }
  & Pick<Membership, 'taxRate' | 'customTaxRate' | 'taxCalculationMethod'>
  & { types: Array<(
    { __typename?: 'MembershipType' }
    & MembershipTypeFragment
  )>, multiBuyPromotions: Array<(
    { __typename?: 'MembershipMultiBuyPromotion' }
    & Pick<MembershipMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
    & { getMembershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'id' | 'name' | 'description' | 'restrictions' | 'kind'>
    )>, buyMembershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'id' | 'name' | 'description' | 'restrictions' | 'kind'>
    )> }
  )> }
  & MembershipBaseFragment
  & MembershipInfoFragment
  & MembershipOptionsFragment
);

export type MembershipInfoFragment = (
  { __typename?: 'Membership' }
  & Pick<Membership, 'name' | 'membershipKind' | 'startDate' | 'endDate' | 'description' | 'showSchedule' | 'termsAndConditions' | 'accessibility' | 'importantNotice'>
  & { digitalPassCodesStatus?: Maybe<(
    { __typename?: 'DigitalPassCodeStatusOutput' }
    & Pick<DigitalPassCodeStatusOutput, 'remainingPassCodes'>
  )>, thumbnail?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, banner?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, backgroundImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, descriptionImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, ticketCover?: Maybe<(
    { __typename?: 'MembershipTicketCover' }
    & Pick<MembershipTicketCover, 'isEnabled' | 'totalFeePercentage' | 'purchaseBehaviour'>
  )> }
);

export type MembershipOptionsFragment = (
  { __typename?: 'Membership' }
  & Pick<Membership, 'bookingFeeType' | 'bookingFee' | 'canNameMembership' | 'canRenameMembership' | 'canChangeSeats' | 'changingSeatsFee' | 'canUsePoints' | 'isActive' | 'showSchedule'>
  & { shippingOptions?: Maybe<(
    { __typename?: 'ShippingOptions' }
    & ShippingOptionsFragment
  )>, gateways?: Maybe<Array<(
    { __typename?: 'Integration' }
    & Pick<Integration, 'id' | 'name' | 'type' | 'transactionFee' | 'transactionFeeType' | 'transactionFeeType2' | 'transactionFee2' | 'bnplEnabled' | 'bnplTransactionFeePercent'>
  )>>, addons: Array<(
    { __typename?: 'MembershipAddon' }
    & Pick<MembershipAddon, 'id' | 'name' | 'enableNaming' | 'price' | 'description' | 'restrictions' | 'isActive' | 'maxPurchaseQuantity'>
    & { namingFields: Array<(
      { __typename?: 'FormField' }
      & NamingFieldsFragment
    )>, addonImage?: Maybe<(
      { __typename?: 'ExtendedFile' }
      & FileFragment
    )> }
  )> }
);

export type MembershipTypeFragment = (
  { __typename?: 'MembershipType' }
  & Pick<MembershipType, 'id' | 'name' | 'kind' | 'quantitySet' | 'ticketFeeType' | 'description' | 'restrictions' | 'ticketFee' | 'minPurchaseQuantity' | 'maxPurchaseQuantity'>
  & { namingFields: Array<(
    { __typename?: 'FormField' }
    & NamingFieldsFragment
  )> }
);

export type NamingFieldsFragment = (
  { __typename?: 'FormField' }
  & Pick<FormField, 'field' | 'isRequired' | 'label' | 'isCustom'>
);

export type OrderBaseFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'id' | 'createdAt' | 'updatedAt'>
);

export type CheckoutOrderFragment = (
  { __typename?: 'Order' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'startDate' | 'endDate' | 'eventKind' | 'title' | 'canRenameTicket' | 'sellerTransferringTicketFee' | 'canTransferTicket' | 'ticketsViewableFromDate' | 'hasPromoCodes' | 'redirectURL' | 'canUsePoints'>
    & { paymentPlanSettings?: Maybe<(
      { __typename?: 'PaymentPlanSettings' }
      & Pick<PaymentPlanSettings, 'disabledAt'>
      & { scheduleOptions?: Maybe<Array<(
        { __typename?: 'ScheduleOption' }
        & Pick<ScheduleOption, 'id' | 'interval' | 'installmentCount' | 'availableUntil' | 'minimumOrderAmount' | 'stripeFeePercentage'>
      )>> }
    )>, venue: (
      { __typename?: 'Venue' }
      & Pick<Venue, 'seating' | 'name'>
      & { zones: Array<(
        { __typename?: 'Zone' }
        & Pick<Zone, 'name' | 'stand'>
      )>, gates: Array<(
        { __typename?: 'Gate' }
        & Pick<Gate, 'name' | 'id'>
      )> }
      & VenueAddressFragment
    ), zonesAllocatedGates?: Maybe<Array<(
      { __typename?: 'ZoneAllocatedGate' }
      & Pick<ZoneAllocatedGate, 'name'>
    )>>, gateways: Array<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'name' | 'id' | 'transactionFeeType' | 'transactionFee' | 'transactionFeeType2' | 'transactionFee2' | 'type' | 'publicKey' | 'paymentMethodType' | 'bnplEnabled' | 'bnplTransactionFeePercent'>
    )>, shippingOptions?: Maybe<(
      { __typename?: 'ShippingOptions' }
      & ShippingOptionsFragment
    )>, ticketTypes: Array<(
      { __typename?: 'TicketType' }
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'alternateEntryTime'>
      )> }
    )>, multiBuyPromotions: Array<(
      { __typename?: 'EventMultiBuyPromotion' }
      & Pick<EventMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
      & { getTicketType: (
        { __typename?: 'TicketType' }
        & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
        & { dates: Array<(
          { __typename?: 'TicketTypeDate' }
          & Pick<TicketTypeDate, 'alternateEntryTime'>
        )> }
      ), buyTicketType: (
        { __typename?: 'TicketType' }
        & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
        & { dates: Array<(
          { __typename?: 'TicketTypeDate' }
          & Pick<TicketTypeDate, 'alternateEntryTime'>
        )> }
      ) }
    )>, customFields: Array<(
      { __typename?: 'CustomFieldOuput' }
      & Pick<CustomFieldOuput, 'id' | 'label' | 'value' | 'targetArea'>
    )> }
  )>, membership?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'name' | 'canUsePoints' | 'enableAutoRenew' | 'membershipKind' | 'hasPromoCodes' | 'showSchedule' | 'isTicketed'>
    & { paymentPlanSettings?: Maybe<(
      { __typename?: 'PaymentPlanSettings' }
      & Pick<PaymentPlanSettings, 'disabledAt'>
      & { scheduleOptions?: Maybe<Array<(
        { __typename?: 'ScheduleOption' }
        & Pick<ScheduleOption, 'id' | 'interval' | 'installmentCount' | 'availableUntil' | 'minimumOrderAmount' | 'stripeFeePercentage'>
      )>> }
    )>, shippingOptions?: Maybe<(
      { __typename?: 'ShippingOptions' }
      & ShippingOptionsFragment
    )>, gateways?: Maybe<Array<(
      { __typename?: 'Integration' }
      & Pick<Integration, 'name' | 'id' | 'transactionFeeType' | 'transactionFee' | 'transactionFeeType2' | 'transactionFee2' | 'type' | 'publicKey' | 'bnplEnabled' | 'bnplTransactionFeePercent'>
    )>>, venue?: Maybe<(
      { __typename?: 'Venue' }
      & Pick<Venue, 'name' | 'seating'>
      & VenueAddressFragment
    )>, multiBuyPromotions: Array<(
      { __typename?: 'MembershipMultiBuyPromotion' }
      & Pick<MembershipMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
      & { getMembershipType?: Maybe<(
        { __typename?: 'MembershipType' }
        & Pick<MembershipType, 'id' | 'name' | 'description' | 'restrictions' | 'kind'>
      )>, buyMembershipType?: Maybe<(
        { __typename?: 'MembershipType' }
        & Pick<MembershipType, 'id' | 'name' | 'description' | 'restrictions' | 'kind'>
      )> }
    )> }
  )> }
  & OrderFragment
);

export type OrderFinanceFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'bookingFeeType' | 'bookingFee' | 'bookingFeeAmount' | 'transferFee' | 'changingSeatsCredits' | 'deliveryFee' | 'subtotal' | 'amountDue' | 'total' | 'totalTax' | 'taxCalculationMethod' | 'taxRate' | 'customTaxAmount' | 'customTaxRate' | 'transactionFeeAmount' | 'usedCredits' | 'usedPoints' | 'paymentGateway' | 'membershipSubscriptionId' | 'isComp' | 'refundedAmount' | 'transactionFeeType' | 'transactionFee' | 'transactionFeeType2' | 'transactionFee2'>
  & { multiBuyPromotion?: Maybe<(
    { __typename?: 'EventMultiBuyPromotion' }
    & Pick<EventMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price'>
    & { getTicketType: (
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'alternateEntryTime'>
      )> }
    ), buyTicketType: (
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'alternateEntryTime'>
      )> }
    ) }
  ) | (
    { __typename?: 'MembershipMultiBuyPromotion' }
    & Pick<MembershipMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price'>
    & { getMembershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'id' | 'name' | 'description' | 'restrictions' | 'kind'>
    )>, buyMembershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'id' | 'name' | 'description' | 'restrictions' | 'kind'>
    )> }
  )> }
  & PromoCodeFragment
);

export type OrderFragment = (
  { __typename?: 'Order' }
  & OrderBaseFragment
  & OrderInfoFragment
  & OrderFinanceFragment
  & OrderItemsFragment
  & OrderUserFragment
);

export type OrderInfoFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'orderNumber' | 'orderType' | 'status' | 'transactionStatus' | 'deliveryMethod' | 'expiryDate' | 'expiresInSeconds' | 'channel' | 'referralId' | 'heardAboutUs' | 'dietaryRequirements'>
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'title' | 'sellerTransferringTicketFee'>
    & { ticketCover?: Maybe<(
      { __typename?: 'EventTicketCover' }
      & Pick<EventTicketCover, 'isEnabled' | 'totalFeePercentage' | 'purchaseBehaviour'>
    )> }
  )>, orderTicketCover?: Maybe<(
    { __typename?: 'OrderTicketCover' }
    & Pick<OrderTicketCover, 'totalFeeAmount' | 'purchaseBehaviour'>
  )>, orderPlan?: Maybe<(
    { __typename?: 'OrderPlan' }
    & { billingDetails?: Maybe<(
      { __typename?: 'BillingDetails' }
      & Pick<BillingDetails, 'totalFeeAmount'>
    )> }
  )>, release?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'slug' | 'askHowDidYouHearAboutUs' | 'askForDietaryRequirements' | 'allowAccount2Account' | 'allowHoldOrders' | 'type'>
  )>, pos?: Maybe<(
    { __typename?: 'Pos' }
    & Pick<Pos, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    ), release: (
      { __typename?: 'Release' }
      & Pick<Release, 'id'>
    ) }
  )> }
);

export type OrderItemsFragment = (
  { __typename?: 'Order' }
  & { lineItems: (
    { __typename?: 'LineItemConnection' }
    & { edges: Array<(
      { __typename?: 'LineItemEdge' }
      & { node: (
        { __typename?: 'LineItem' }
        & LineItemFragment
      ) }
    )> }
  ), orderTicketCover?: Maybe<(
    { __typename?: 'OrderTicketCover' }
    & Pick<OrderTicketCover, 'totalFeeAmount' | 'purchaseBehaviour'>
  )>, orderPlan?: Maybe<(
    { __typename?: 'OrderPlan' }
    & { billingDetails?: Maybe<(
      { __typename?: 'BillingDetails' }
      & Pick<BillingDetails, 'totalFeeAmount'>
    )> }
  )>, tickets: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'status' | 'displayName'>
    & { lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & LineItemFragment
    )>, ticketType?: Maybe<(
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id' | 'name' | 'kind'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'eventDateId' | 'alternateEntryTime'>
      )>, namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, parentTicket?: Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'id' | 'firstName' | 'lastName'>
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'title' | 'startDate' | 'endDate' | 'eventKind' | 'canRenameTicket' | 'sellerTransferringTicketFee' | 'canTransferTicket' | 'canChangeSeats' | 'ticketResaleEnabled' | 'ticketsViewableFromDate' | 'isR18'>
      & { dates: Array<(
        { __typename?: 'EventDate' }
        & Pick<EventDate, 'id' | 'startDate' | 'endDate'>
      )>, venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      ), zonesAllocatedGates?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGate' }
        & Pick<ZoneAllocatedGate, 'name'>
        & { dates?: Maybe<Array<(
          { __typename?: 'ZoneAllocatedDate' }
          & Pick<ZoneAllocatedDate, 'eventDateId' | 'alternateEntryTime'>
        )>> }
      )>>, ticketCover?: Maybe<(
        { __typename?: 'EventTicketCover' }
        & Pick<EventTicketCover, 'isEnabled' | 'totalFeePercentage' | 'purchaseBehaviour'>
      )> }
    )>, membershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'name'>
      & { membership: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'name' | 'canRenameMembership' | 'membershipKind' | 'isTicketed'>
      ), namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'name' | 'membershipKind' | 'canRenameMembership' | 'startDate' | 'endDate'>
      & { venue?: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      )>, ticketCover?: Maybe<(
        { __typename?: 'MembershipTicketCover' }
        & Pick<MembershipTicketCover, 'isEnabled' | 'totalFeePercentage' | 'purchaseBehaviour'>
      )> }
    )>, addon?: Maybe<(
      { __typename?: 'EventAddon' }
      & Pick<EventAddon, 'id' | 'name' | 'enableNaming' | 'hideQRCode'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    ) | (
      { __typename?: 'MembershipAddon' }
      & Pick<MembershipAddon, 'id' | 'name' | 'enableNaming'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )> }
    & TicketFragment
  )>, event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'canChangeSeats' | 'changingSeatsFee' | 'canRenameTicket'>
    & EventBaseFragment
    & EventInfoFragment
  )>, membership?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'canRenameMembership'>
    & MembershipBaseFragment
    & MembershipInfoFragment
  )>, transactions: (
    { __typename?: 'OrderTransactionConnection' }
    & { edges: Array<(
      { __typename?: 'OrderTransactionEdge' }
      & { node: (
        { __typename?: 'OrderTransaction' }
        & Pick<OrderTransaction, 'id' | 'kind' | 'status' | 'amount'>
      ) }
    )> }
  ), posTransactions: (
    { __typename?: 'PosTransactionConnection' }
    & { edges: Array<(
      { __typename?: 'PosTransactionEdge' }
      & { node: (
        { __typename?: 'PosTransaction' }
        & Pick<PosTransaction, 'id' | 'amount' | 'fee' | 'paymentGateway' | 'returned'>
      ) }
    )> }
  ) }
);

export type PromoCodeFragment = (
  { __typename?: 'Order' }
  & { promoCode?: Maybe<(
    { __typename: 'EventPromoCode' }
    & Pick<EventPromoCode, 'id' | 'code' | 'discountType' | 'discountAmount'>
    & { eventType: EventPromoCode['type'] }
  ) | (
    { __typename: 'MembershipPromoCode' }
    & Pick<MembershipPromoCode, 'id' | 'code' | 'discountType' | 'discountAmount'>
    & { membershipType: MembershipPromoCode['type'] }
  )> }
);

export type OrderUserFragment = (
  { __typename?: 'Order' }
  & Pick<Order, 'shippingAddressMatchesBillingAddress'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'referralId' | 'birthDate' | 'credits' | 'fullName' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'points'>
    & { billingAddress?: Maybe<(
      { __typename?: 'PartialAddress' }
      & PartialAddressFragment
    )>, shippingAddress?: Maybe<(
      { __typename?: 'PartialAddress' }
      & PartialAddressFragment
    )> }
  )>, buyerInformation?: Maybe<(
    { __typename?: 'BuyerInformation' }
    & Pick<BuyerInformation, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'taxNumber' | 'postalCode' | 'city' | 'country'>
  )>, billingAddress?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )>, shippingAddress?: Maybe<(
    { __typename?: 'Address' }
    & AddressFragment
  )> }
);

export type PartialAddressFragment = (
  { __typename?: 'PartialAddress' }
  & Pick<PartialAddress, 'line1' | 'line2' | 'suburb' | 'postalCode' | 'city' | 'state' | 'country'>
);

export type ReferralStatsFragmentFragment = (
  { __typename?: 'ReferralCustomerReporting' }
  & Pick<ReferralCustomerReporting, 'referralsMade' | 'eventName' | 'orderDate' | 'orderNumber' | 'referralLink'>
  & { eventLogo?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & Pick<ExtendedFile, 'fileName' | 'storage' | 'originalFileName' | 'directory'>
    & { metaData?: Maybe<(
      { __typename?: 'MetaData' }
      & Pick<MetaData, 'height' | 'width'>
    )> }
  )> }
);

export type ReleaseBaseFragment = (
  { __typename?: 'Release' }
  & Pick<Release, 'id' | 'createdAt' | 'updatedAt'>
);

export type ReleaseFragment = (
  { __typename?: 'Release' }
  & ReleaseBaseFragment
  & ReleaseInfoFragment
  & ReleaseOptionsFragment
);

export type ReleaseInfoFragment = (
  { __typename?: 'Release' }
  & Pick<Release, 'name' | 'slug' | 'type' | 'presaleTitle' | 'presaleInformation' | 'releaseNotes'>
  & { presaleLogo?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, presaleHeader?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, releaseEventAddons: Array<(
    { __typename?: 'ReleaseEventAddon' }
    & { eventAddon: (
      { __typename?: 'EventAddon' }
      & Pick<EventAddon, 'id' | 'name' | 'enableNaming' | 'price' | 'description' | 'restrictions' | 'isActive' | 'addonAvailabilityStatus' | 'maxPurchaseQuantity'>
      & { addonImage?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )> }
    ) }
  )> }
);

export type ReleaseOptionsFragment = (
  { __typename?: 'Release' }
  & Pick<Release, 'startCondition' | 'startConditionDate' | 'endCondition' | 'endConditionQuantity' | 'endConditionDate' | 'isActive' | 'askHowDidYouHearAboutUs' | 'askForDietaryRequirements' | 'allowPublicComp' | 'allowAccount2Account'>
  & { releaseZones: Array<(
    { __typename?: 'ReleaseZone' }
    & ReleaseZonesFragment
  )> }
);

export type ReleaseZonesFragment = (
  { __typename?: 'ReleaseZone' }
  & Pick<ReleaseZone, 'pricingLevel' | 'isActive'>
  & { zone: (
    { __typename?: 'Zone' }
    & Pick<Zone, 'name'>
  ), ticketTypes: Array<(
    { __typename?: 'ReleaseZoneTicketType' }
    & Pick<ReleaseZoneTicketType, 'price' | 'isActive' | 'ticketTypeId' | 'isSoldOut' | 'minPurchaseQuantity' | 'maxPurchaseQuantity'>
    & { sections?: Maybe<Array<(
      { __typename?: 'ZonePricingTypeSection' }
      & Pick<ZonePricingTypeSection, 'name' | 'price'>
    )>> }
  )> }
);

export type SeasonFragmentFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'id' | 'name' | 'isActive' | 'startDate' | 'description' | 'endDate' | 'updatedAt' | 'createdAt' | 'displayOrder'>
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'title' | 'startDate' | 'eventKind' | 'isActive'>
  )>, thumbnail?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )>, backgroundImageFullPage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )> }
);

export type SeasonBaseFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'id' | 'createdAt' | 'updatedAt'>
);

export type SeasonFragment = (
  { __typename?: 'Season' }
  & SeasonBaseFragment
  & SeasonInfoFragment
);

export type SeasonInfoFragment = (
  { __typename?: 'Season' }
  & Pick<Season, 'name' | 'isActive' | 'startDate' | 'endDate'>
);

export type ShippingOptionsFragment = (
  { __typename?: 'ShippingOptions' }
  & Pick<ShippingOptions, 'eTicket' | 'printedTicketByMail' | 'printedTicketByCourier' | 'printedTicketByCourierRural' | 'printedTicketAtVenue'>
);

export type TicketTypeBaseFragment = (
  { __typename?: 'TicketType' }
  & Pick<TicketType, 'id' | 'createdAt' | 'updatedAt'>
);

export type FinanceFragment = (
  { __typename?: 'TicketType' }
  & Pick<TicketType, 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee' | 'stadiumLevyFee' | 'kind'>
);

export type TicketTypeFragment = (
  { __typename?: 'TicketType' }
  & TicketTypeBaseFragment
  & TicketTypeInfoFragment
  & FinanceFragment
);

export type TicketTypeInfoFragment = (
  { __typename?: 'TicketType' }
  & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'quantitySet' | 'inHighDemand' | 'soldOut' | 'isResaleTicket' | 'resaleHiddenFromPublic' | 'quantity'>
  & { dates: Array<(
    { __typename?: 'TicketTypeDate' }
    & Pick<TicketTypeDate, 'alternateEntryTime'>
  )>, namingFields: Array<(
    { __typename?: 'FormField' }
    & NamingFieldsFragment
  )>, ticketImage?: Maybe<(
    { __typename?: 'ExtendedFile' }
    & FileFragment
  )> }
);

export type TicketBaseFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'id' | 'createdAt' | 'updatedAt'>
);

export type TicketFragment = (
  { __typename?: 'Ticket' }
  & TicketBaseFragment
  & TicketInfoFragment
  & TicketUserFragment
  & TicketSendFragment
);

export type TicketInfoFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'displayName' | 'ticketNumber' | 'digitalPassCode' | 'digitalEventUrl' | 'seatLabel' | 'seatZone' | 'seatSection' | 'seatTags' | 'isNamed' | 'shouldBeNamed' | 'qrCode' | 'status' | 'transferStatus' | 'maxTransferPrice' | 'purchaseRestrictionText'>
  & { resellabilityState: (
    { __typename?: 'TicketResellabilityState' }
    & Pick<TicketResellabilityState, 'ticketResaleAllowed' | 'ticketTransferAllowed' | 'latestTransferCompletionDate' | 'reason' | 'hoursLeftToTransfer'>
  ), gates?: Maybe<Array<(
    { __typename?: 'Gate' }
    & Pick<Gate, 'id' | 'name'>
  )>> }
);

export type TicketUserFragment = (
  { __typename?: 'Ticket' }
  & Pick<Ticket, 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'birthDate' | 'gender' | 'postalCode' | 'companyName' | 'licensePlate' | 'memberSince' | 'membershipNumber' | 'comments' | 'dietaryRequirements' | 'lengthOfStay' | 'optInToMarketing' | 'emergencyName' | 'emergencyContact' | 'emergencyContactRelationship' | 'dunedinResidentialAddress' | 'studentId'>
  & { vaccinePass?: Maybe<(
    { __typename?: 'VaccinePass' }
    & Pick<VaccinePass, 'id'>
  )>, customFields?: Maybe<Array<(
    { __typename?: 'CustomField' }
    & Pick<CustomField, 'fieldName' | 'fieldValue'>
  )>> }
);

export type UserBaseFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'createdAt' | 'updatedAt'>
);

export type BillingAndShippingFragment = (
  { __typename?: 'User' }
  & Pick<User, 'shippingAddressMatchesBillingAddress'>
  & { billingAddress?: Maybe<(
    { __typename?: 'PartialAddress' }
    & PartialAddressFragment
  )>, shippingAddress?: Maybe<(
    { __typename?: 'PartialAddress' }
    & PartialAddressFragment
  )> }
);

export type CredentialsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'email' | 'roles' | 'hasPassword' | 'permissions' | 'isTwoFactorAuthenticationEnabled'>
);

export type UserFragment = (
  { __typename?: 'User' }
  & UserBaseFragment
  & NameFragment
  & PersonalFragment
  & BillingAndShippingFragment
  & CredentialsFragment
);

export type NameFragment = (
  { __typename?: 'User' }
  & Pick<User, 'firstName' | 'lastName' | 'fullName'>
);

export type PersonalFragment = (
  { __typename?: 'User' }
  & Pick<User, 'birthDate' | 'phoneNumber' | 'gender' | 'usableForMarketing' | 'credits' | 'points' | 'taxNumber' | 'companyName' | 'instagramUsername' | 'referralId'>
);

export type VenueAddressFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'timezone' | 'locale'>
  & { address: (
    { __typename?: 'Address' }
    & AddressFragment
  ), location?: Maybe<(
    { __typename?: 'Coordinate' }
    & Pick<Coordinate, 'lat' | 'lng'>
  )> }
);

export type VenueBaseFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'id' | 'createdAt' | 'updatedAt'>
);

export type VenueFragment = (
  { __typename?: 'Venue' }
  & VenueBaseFragment
  & VenueInfoFragment
  & VenueAddressFragment
  & SeatsIoFragment
);

export type VenueInfoFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'name' | 'capacity' | 'accessibilityInfo' | 'filterOptions'>
);

export type SeatsIoFragment = (
  { __typename?: 'Venue' }
  & Pick<Venue, 'seatsChartKey' | 'seating'>
  & { zones: Array<(
    { __typename?: 'Zone' }
    & Pick<Zone, 'name' | 'stand'>
    & { sections?: Maybe<Array<(
      { __typename?: 'Section' }
      & Pick<Section, 'name'>
    )>> }
  )> }
  & VenueAddressFragment
);

export type AddTransactionMutationVariables = Exact<{
  id: Scalars['String'];
  input: AddPosTransactionInput;
}>;


export type AddTransactionMutation = (
  { __typename?: 'Mutation' }
  & { addPosTransaction: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type OnTrackingCodeVisitMutationVariables = Exact<{
  input: TrackingCodeVisitInput;
}>;


export type OnTrackingCodeVisitMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'onTrackingCodeVisit'>
);

export type AddUnsubscribeReasonMutationVariables = Exact<{
  input: AddUnsubscribeReasonInput;
}>;


export type AddUnsubscribeReasonMutation = (
  { __typename?: 'Mutation' }
  & { addUnsubscribeReason: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ApplyPromoCodeMutationVariables = Exact<{
  input: ApplyPromoCode;
  id: Scalars['String'];
}>;


export type ApplyPromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { applyPromoCodeToOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
    & PromoCodeFragment
  ) }
);

export type AssignTwoFactorAuthenticationSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type AssignTwoFactorAuthenticationSecretMutation = (
  { __typename?: 'Mutation' }
  & { assignTwoFactorAuthenticationSecret: (
    { __typename?: 'TwoFactorAuthenticationSetupResult' }
    & Pick<TwoFactorAuthenticationSetupResult, 'dataURL' | 'secret'>
  ) }
);

export type AuthenticateWithTwoFactorMutationVariables = Exact<{
  input: TwoFactorAuthenticationCodeInput;
}>;


export type AuthenticateWithTwoFactorMutation = (
  { __typename?: 'Mutation' }
  & { authenticateWithTwoFactor: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type BroadcastReportingLinkOpenedMutationVariables = Exact<{
  input: BroadcastReportingInputBase;
}>;


export type BroadcastReportingLinkOpenedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'broadcastReportingLinkOpened'>
);

export type BroadcastReportingOrderCreatedMutationVariables = Exact<{
  input: BroadcastReportingOrderInput;
}>;


export type BroadcastReportingOrderCreatedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'broadcastReportingOrderCreated'>
);

export type BroadcastReportingSetAsSpamFromUserMutationVariables = Exact<{
  input: BroadcastReportingInputBase;
}>;


export type BroadcastReportingSetAsSpamFromUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'broadcastReportingSetAsSpamFromUser'>
);

export type CancelOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type CancelOrderMutation = (
  { __typename?: 'Mutation' }
  & { cancelOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type CancelPendingTicketTransferMutationVariables = Exact<{
  ticketId: Scalars['String'];
}>;


export type CancelPendingTicketTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelPendingTicketTransfer'>
);

export type CreateOrderMutationVariables = Exact<{
  input: CreateOrderInput;
}>;


export type CreateOrderMutation = (
  { __typename?: 'Mutation' }
  & { createOrder: (
    { __typename?: 'OrderCreateOutput' }
    & Pick<OrderCreateOutput, 'jobId'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'title'>
      )>, membership?: Maybe<(
        { __typename?: 'Membership' }
        & Pick<Membership, 'name'>
      )> }
      & OrderFragment
    )>, createOrderError?: Maybe<Array<(
      { __typename: 'TicketNotAvailableError' }
      & Pick<TicketNotAvailableError, 'message' | 'shouldDisplayWaitlist' | 'eventId' | 'releaseId'>
    )>> }
  ) }
);

export type CreateOrderToTrackingCodeAssociationMutationVariables = Exact<{
  input: TrackingCodeOrderInput;
}>;


export type CreateOrderToTrackingCodeAssociationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOrderToTrackingCodeAssociation'>
);

export type CreatePaymentMutationVariables = Exact<{
  id: Scalars['String'];
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & { createPayment: (
    { __typename?: 'CreatePaymentOutput' }
    & { result?: Maybe<(
      { __typename: 'PaymentSuccess' }
      & Pick<PaymentSuccess, 'gateway' | 'redirectTo'>
    ) | (
      { __typename: 'PaymentError' }
      & Pick<PaymentError, 'code' | 'message'>
    )> }
  ) }
);

export type CreatePinPaymentChargeMutationVariables = Exact<{
  input: CreatePinPaymentCharge;
}>;


export type CreatePinPaymentChargeMutation = (
  { __typename?: 'Mutation' }
  & { createPinPaymentCharge: (
    { __typename?: 'PinPaymentChargeResponse' }
    & Pick<PinPaymentChargeResponse, 'redirectUrl'>
  ) }
);

export type CreateRedirectUrlMutationVariables = Exact<{
  input: CreateRedirectUrlInput;
}>;


export type CreateRedirectUrlMutation = (
  { __typename?: 'Mutation' }
  & { createRedirectUrl: (
    { __typename?: 'RedirectUrl' }
    & Pick<RedirectUrl, 'redirectURL'>
  ) }
);

export type DeleteSelfMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteSelfMutation = (
  { __typename?: 'Mutation' }
  & { deleteSelf: (
    { __typename?: 'DeleteOneOutput' }
    & Pick<DeleteOneOutput, 'deletedId'>
  ) }
);

export type EnableTwoFactorAuthenticationMutationVariables = Exact<{
  input: TwoFactorAuthenticationCodeInput;
}>;


export type EnableTwoFactorAuthenticationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'enableTwoFactorAuthentication'>
);

export type ExpireOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type ExpireOrderMutation = (
  { __typename?: 'Mutation' }
  & { expireOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type FinishPosTransactionMutationVariables = Exact<{
  id: Scalars['String'];
  autoScan?: Maybe<Scalars['Boolean']>;
}>;


export type FinishPosTransactionMutation = (
  { __typename?: 'Mutation' }
  & { finishPosTransaction: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type HoldBestAvailableSeatsMutationVariables = Exact<{
  id: Scalars['String'];
  input: HoldBestAvailableSeatsInput;
}>;


export type HoldBestAvailableSeatsMutation = (
  { __typename?: 'Mutation' }
  & { holdBestAvailableSeats: (
    { __typename?: 'HoldBestAvailableSeatsOutput' }
    & Pick<HoldBestAvailableSeatsOutput, 'objects'>
  ) }
);

export type HoldCustomerOrderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type HoldCustomerOrderMutation = (
  { __typename?: 'Mutation' }
  & { holdCustomerOrder: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type IncreaseDownloadCountMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type IncreaseDownloadCountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'increaseDownloadCount'>
);

export type JoinWaitlistMutationVariables = Exact<{
  input: JoinWaitlistInput;
}>;


export type JoinWaitlistMutation = (
  { __typename?: 'Mutation' }
  & { joinWaitlist: (
    { __typename?: 'Waitlist' }
    & Pick<Waitlist, 'id'>
  ) }
);

export type ListTicketForResaleMutationVariables = Exact<{
  ticketId: Scalars['String'];
}>;


export type ListTicketForResaleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'listTicketForResale'>
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RegisterMutationVariables = Exact<{
  input: RegisterInput;
}>;


export type RegisterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'register'>
);

export type RemovePromoCodeMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type RemovePromoCodeMutation = (
  { __typename?: 'Mutation' }
  & { removePromoCodeFromOrder: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type RemoveTransactionMutationVariables = Exact<{
  id: Scalars['String'];
  transactionId: Scalars['String'];
}>;


export type RemoveTransactionMutation = (
  { __typename?: 'Mutation' }
  & { removePosTransaction: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type RemoveTwoFactorAuthenticationSecretMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveTwoFactorAuthenticationSecretMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTwoFactorAuthenticationSecret'>
);

export type RequestDeleteAccountMutationVariables = Exact<{
  input: RequestMagicLinkInput;
}>;


export type RequestDeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestDeleteAccount'>
);

export type RequestMagicLinkMutationVariables = Exact<{
  input: RequestMagicLinkInput;
}>;


export type RequestMagicLinkMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestMagicLink'>
);

export type RequestResetPasswordMutationVariables = Exact<{
  input: RequestResetPasswordInput;
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestResetPassword'>
);

export type RequestTicketTransferMutationVariables = Exact<{
  input: RequestTicketTransferInput;
}>;


export type RequestTicketTransferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'requestTicketTransfer'>
);

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type ResubscribeMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ResubscribeMutation = (
  { __typename?: 'Mutation' }
  & { resubscribe: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type SendOrderConfirmationMutationVariables = Exact<{
  id: Scalars['String'];
  input: SendOrderConfirmationInput;
}>;


export type SendOrderConfirmationMutation = (
  { __typename?: 'Mutation' }
  & { sendOrderConfirmation: (
    { __typename?: 'SendOrderConfirmationOutput' }
    & Pick<SendOrderConfirmationOutput, 'email'>
  ) }
);

export type SendTwoFactorCodeViaSmsMessageMutationVariables = Exact<{ [key: string]: never; }>;


export type SendTwoFactorCodeViaSmsMessageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendTwoFactorCodeViaSMSMessage'>
);

export type SocialAuthMutationVariables = Exact<{
  input: SocialAuthInput;
}>;


export type SocialAuthMutation = (
  { __typename?: 'Mutation' }
  & { socialAuth: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type SplitGroupOrderMutationVariables = Exact<{
  id: Scalars['String'];
  input: SplitOrderInput;
}>;


export type SplitGroupOrderMutation = (
  { __typename?: 'Mutation' }
  & { splitGroupOrder: (
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  ) }
);

export type UnlistTicketForResaleMutationVariables = Exact<{
  ticketId: Scalars['String'];
}>;


export type UnlistTicketForResaleMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'unlistTicketForResale'>
);

export type UnsubscribeFromMarketingMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type UnsubscribeFromMarketingMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribeFromMarketing: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateOrderInput;
}>;


export type UpdateOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateOrder: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type UpdateOrderStatusMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateOrderStatusInput;
}>;


export type UpdateOrderStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateOrderStatus: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePassword'>
);

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateProfileInput;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type UpdateTicketCoverForOrderMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateOrderTicketCoverInput;
}>;


export type UpdateTicketCoverForOrderMutation = (
  { __typename?: 'Mutation' }
  & { updateTicketCoverForOrder: (
    { __typename?: 'Order' }
    & OrderFragment
  ) }
);

export type UpdateTicketHolderMutationVariables = Exact<{
  id: Scalars['String'];
  input: UpdateTicketHolderInput;
}>;


export type UpdateTicketHolderMutation = (
  { __typename?: 'Mutation' }
  & { updateTicketHolder: (
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'id'>
  ) }
);

export type ValidateReleasePasswordMutationVariables = Exact<{
  id: Scalars['String'];
  password: Scalars['String'];
}>;


export type ValidateReleasePasswordMutation = (
  { __typename?: 'Mutation' }
  & { validateReleasePassword: (
    { __typename?: 'ReleasePassword' }
    & Pick<ReleasePassword, 'id' | 'quantityPaid' | 'quantityComp' | 'totalUsedPaid' | 'totalUsedComp'>
  ) }
);

export type ValidateReleasePasswordOrReleaseMutationVariables = Exact<{
  eventId: Scalars['String'];
  password: Scalars['String'];
}>;


export type ValidateReleasePasswordOrReleaseMutation = (
  { __typename?: 'Mutation' }
  & { validateReleasePasswordOrRelease: (
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'slug' | 'password'>
  ) }
);

export type VerifyMagicLinkMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyMagicLinkMutation = (
  { __typename?: 'Mutation' }
  & { verifyMagicLink: (
    { __typename?: 'VerifyMagicLinkOutput' }
    & Pick<VerifyMagicLinkOutput, 'redirectUrl'>
  ) }
);

export type VerifyPinPayment3DsMutationVariables = Exact<{
  input: VerifyPinPayment3Ds;
}>;


export type VerifyPinPayment3DsMutation = (
  { __typename?: 'Mutation' }
  & { verifyPinPayment3DS: (
    { __typename?: 'PinPaymentChargeResponse' }
    & Pick<PinPaymentChargeResponse, 'redirectUrl'>
  ) }
);

export type VerifyTokenMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyTokenMutation = (
  { __typename?: 'Mutation' }
  & { verifyToken: (
    { __typename?: 'VerifyMagicLinkOutput' }
    & Pick<VerifyMagicLinkOutput, 'redirectUrl'>
  ) }
);

export type GetPromoterSettingsQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetPromoterSettingsQuery = (
  { __typename?: 'Query' }
  & { getPromoterSettings?: Maybe<(
    { __typename?: 'PromoterSettingsOutput' }
    & { configurations?: Maybe<Array<(
      { __typename?: 'PromoterShareAction' }
      & Pick<PromoterShareAction, 'platform' | 'actionType' | 'socialLinkType'>
    )>>, socialPages?: Maybe<Array<(
      { __typename?: 'EventSocialPage' }
      & Pick<EventSocialPage, 'name' | 'isEnabled' | 'url'>
    )>> }
  )> }
);

export type CalculateTicketCoverAmountsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CalculateTicketCoverAmountsQuery = (
  { __typename?: 'Query' }
  & { calculateTicketCoverAmounts: (
    { __typename?: 'TicketCoverAmount' }
    & Pick<TicketCoverAmount, 'totalFeeAmount'>
  ) }
);

export type CurrentOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentOrganizationQuery = (
  { __typename?: 'Query' }
  & { currentOrganization: (
    { __typename?: 'CurrentOrganizationOutput' }
    & Pick<CurrentOrganizationOutput, 'id' | 'name' | 'slug' | 'showMemberships' | 'showMembershipRenewal' | 'landingPage' | 'privacyPolicy' | 'termsAndConditions' | 'supportInfo' | 'currency' | 'taxRate' | 'features' | 'timezone' | 'locale' | 'helpLink' | 'defaultI18nLanguage' | 'defaultLanguage' | 'customTaxName'>
    & { address?: Maybe<(
      { __typename?: 'Address' }
      & Pick<Address, 'country'>
    )>, shippingLabels?: Maybe<(
      { __typename?: 'ShippingLabels' }
      & Pick<ShippingLabels, 'eTicket' | 'printedTicketByMail' | 'printedTicketByCourier' | 'printedTicketByCourierRural' | 'printedTicketAtVenue'>
    )>, marketing?: Maybe<(
      { __typename?: 'Marketing' }
      & Pick<Marketing, 'gtmId' | 'pixelId'>
    )>, shopify?: Maybe<(
      { __typename?: 'ShopifyIntegration' }
      & Pick<ShopifyIntegration, 'shopDomain' | 'storefrontAccessToken'>
    )>, branding: (
      { __typename?: 'OrganizationOutputBranding' }
      & Pick<OrganizationOutputBranding, 'greyScale' | 'P300' | 'P200' | 'P100' | 'S300' | 'S200' | 'S100' | 'N800' | 'N700' | 'N600' | 'N500' | 'N400' | 'N300' | 'N200' | 'N100'>
      & { logo?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, mobileLogo?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, favicon?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, background?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, fullBackground?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )> }
    ), point?: Maybe<(
      { __typename?: 'Point' }
      & Pick<Point, 'name' | 'isEnabled'>
    )> }
  ) }
);

export type EventQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EventQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'hasPublicRelease'>
    & { venue: (
      { __typename?: 'Venue' }
      & VenueFragment
    ), ticketTypes: Array<(
      { __typename?: 'TicketType' }
      & TicketTypeBaseFragment
      & TicketTypeInfoFragment
      & FinanceFragment
    )> }
    & EventFragment
  ) }
);

export type EventWithGatesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EventWithGatesQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'title'>
    & { venue: (
      { __typename?: 'Venue' }
      & Pick<Venue, 'id'>
    ), zonesAllocatedGates?: Maybe<Array<(
      { __typename?: 'ZoneAllocatedGate' }
      & Pick<ZoneAllocatedGate, 'name' | 'gates'>
      & { sections?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGateSection' }
        & Pick<ZoneAllocatedGateSection, 'name' | 'gates'>
      )>> }
    )>> }
  ) }
);

export type EventWithTicketTypeFilterQueryVariables = Exact<{
  id: Scalars['String'];
  ticketTypeFilter?: Maybe<TicketTypeFilter>;
}>;


export type EventWithTicketTypeFilterQuery = (
  { __typename?: 'Query' }
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'hasPublicRelease'>
    & { venue: (
      { __typename?: 'Venue' }
      & VenueFragment
    ), ticketTypes: Array<(
      { __typename?: 'TicketType' }
      & { resaleTicketTypes: Array<(
        { __typename?: 'TicketType' }
        & Pick<TicketType, 'id'>
      )> }
      & TicketTypeBaseFragment
      & TicketTypeInfoFragment
      & FinanceFragment
    )> }
    & EventFragment
  ) }
);

export type EventsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<EventOrderByInput>;
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'EventConnection' }
    & Pick<EventConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'EventEdge' }
      & { node: (
        { __typename?: 'Event' }
        & Pick<Event, 'hasPublicRelease'>
        & { venue: (
          { __typename?: 'Venue' }
          & VenueBaseFragment
          & VenueInfoFragment
          & VenueAddressFragment
        ) }
        & EventBaseFragment
        & EventInfoFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  ) }
);

export type FindOrderIdForTicketQueryVariables = Exact<{
  ticketId: Scalars['String'];
}>;


export type FindOrderIdForTicketQuery = (
  { __typename?: 'Query' }
  & { findOrderIdForTicket: (
    { __typename?: 'findOrderIdForTicketOutput' }
    & Pick<FindOrderIdForTicketOutput, 'orderId'>
  ) }
);

export type GetCompetitionReferralReportForCustomerQueryVariables = Exact<{
  input: CustomerCompetitionReportingInput;
}>;


export type GetCompetitionReferralReportForCustomerQuery = (
  { __typename?: 'Query' }
  & { getCompetitionReferralReportForCustomer: Array<(
    { __typename?: 'CompetitionCustomerReporting' }
    & CompetitionStatsFragmentFragment
  )> }
);

export type GetEventPurchaseRestrictionsQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetEventPurchaseRestrictionsQuery = (
  { __typename?: 'Query' }
  & { getEventPurchaseRestrictions: Array<(
    { __typename?: 'PurchaseRestriction' }
    & Pick<PurchaseRestriction, 'id' | 'restrictedType' | 'restrictedId' | 'quantity' | 'isExclusive' | 'isAlwaysVisible' | 'createdAt' | 'updatedAt'>
    & { requiredEntities: Array<(
      { __typename?: 'PurchaseRestrictionRequiredEntity' }
      & Pick<PurchaseRestrictionRequiredEntity, 'requiredId' | 'requiredType'>
    )> }
  )> }
);

export type GetI18nQueryVariables = Exact<{ [key: string]: never; }>;


export type GetI18nQuery = (
  { __typename?: 'Query' }
  & { getI18n: (
    { __typename?: 'I18nOutput' }
    & Pick<I18nOutput, 'data'>
  ) }
);

export type GetMembershipSubscriptionQueryVariables = Exact<{
  membershipSubscriptionId: Scalars['String'];
}>;


export type GetMembershipSubscriptionQuery = (
  { __typename?: 'Query' }
  & { getMembershipSubscription: (
    { __typename?: 'MembershipSubscription' }
    & { billingDetails: (
      { __typename?: 'MembershipSubscriptionBillingDetails' }
      & Pick<MembershipSubscriptionBillingDetails, 'emailAddress' | 'cardLast4' | 'cardBrand' | 'cardExpiryMonth' | 'cardExpiryYear'>
    ) }
  ) }
);

export type GetOrderJobStatusQueryVariables = Exact<{
  jobId: Scalars['String'];
}>;


export type GetOrderJobStatusQuery = (
  { __typename?: 'Query' }
  & { getOrderJobStatus: (
    { __typename?: 'OrderCreateOutput' }
    & Pick<OrderCreateOutput, 'jobId'>
    & { order?: Maybe<(
      { __typename?: 'Order' }
      & { event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'title'>
      )>, membership?: Maybe<(
        { __typename?: 'Membership' }
        & Pick<Membership, 'name'>
      )> }
      & OrderFragment
    )>, createOrderError?: Maybe<Array<(
      { __typename: 'TicketNotAvailableError' }
      & Pick<TicketNotAvailableError, 'message' | 'shouldDisplayWaitlist' | 'eventId' | 'releaseId'>
    )>> }
  ) }
);

export type GetOrderPlanQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetOrderPlanQuery = (
  { __typename?: 'Query' }
  & { getOrderPlan: (
    { __typename?: 'OrderPlan' }
    & Pick<OrderPlan, 'orderId' | 'status'>
    & { schedule: (
      { __typename?: 'ScheduleOption' }
      & Pick<ScheduleOption, 'interval' | 'installmentCount'>
    ), payments: Array<(
      { __typename?: 'OrderPlanPayment' }
      & Pick<OrderPlanPayment, 'id' | 'amountDue' | 'dueAt' | 'paidAt' | 'refundedAt' | 'status' | 'stripeInvoiceUrl' | 'failureReason' | 'attemptCount'>
    )>, billingDetails?: Maybe<(
      { __typename?: 'BillingDetails' }
      & Pick<BillingDetails, 'cardLast4' | 'cardBrand' | 'cardExpiryMonth' | 'cardExpiryYear' | 'totalFeeAmount'>
    )> }
  ) }
);

export type GetPaymentOptionsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPaymentOptionsQuery = (
  { __typename?: 'Query' }
  & { getPaymentOptions: (
    { __typename?: 'PaymentOptions' }
    & Pick<PaymentOptions, 'orderId'>
    & { options?: Maybe<Array<(
      { __typename?: 'PaymentOption' }
      & Pick<PaymentOption, 'id' | 'value' | 'label' | 'type' | 'paymentMethod' | 'description' | 'order' | 'transactionFee' | 'transactionFeeType' | 'transactionFee2' | 'transactionFeeType2' | 'bnplEnabled' | 'calculatedTransactionFee'>
      & { paymentPlanMetadata?: Maybe<(
        { __typename?: 'PaymentPlanMetadata' }
        & Pick<PaymentPlanMetadata, 'depositAmount' | 'installmentAmount' | 'installmentCount' | 'paymentPlanFeePercentage'>
      )> }
    )>> }
  ) }
);

export type GetRedirectUrlByCodeQueryVariables = Exact<{
  input: RedirectCodeInput;
}>;


export type GetRedirectUrlByCodeQuery = (
  { __typename?: 'Query' }
  & { getRedirectUrlByCode: (
    { __typename?: 'RedirectUrl' }
    & Pick<RedirectUrl, 'redirectURL'>
  ) }
);

export type GetReferralCampaignByEventIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReferralCampaignByEventIdQuery = (
  { __typename?: 'Query' }
  & { getReferralCampaignByEventId?: Maybe<(
    { __typename?: 'ReferralCampaign' }
    & Pick<ReferralCampaign, 'id' | 'isActive' | 'referralOwnerRewardType' | 'referralOwnerRewardQuantityType' | 'referralOwnerRewardQuantity' | 'referralUserRewardType' | 'referralUserRewardQuantityType' | 'referralUserRewardQuantity'>
    & { referralRelease?: Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id'>
    )> }
  )> }
);

export type GetReferralCampaignByOrderIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReferralCampaignByOrderIdQuery = (
  { __typename?: 'Query' }
  & { getReferralCampaignByOrderId?: Maybe<(
    { __typename?: 'ReferralCampaign' }
    & Pick<ReferralCampaign, 'id' | 'isActive' | 'referralOwnerRewardType' | 'referralOwnerRewardQuantityType' | 'referralOwnerRewardQuantity' | 'referralUserRewardType' | 'referralUserRewardQuantityType' | 'referralUserRewardQuantity'>
    & { referralRelease?: Maybe<(
      { __typename?: 'Release' }
      & Pick<Release, 'id'>
    )> }
  )> }
);

export type GetReferralCampaignsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralCampaignsQuery = (
  { __typename?: 'Query' }
  & { getReferralCampaigns: Array<(
    { __typename?: 'ReferralCampaign' }
    & Pick<ReferralCampaign, 'id' | 'isActive'>
    & { event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  )> }
);

export type GetReferralReportForCustomerQueryVariables = Exact<{
  input: CustomerReferralReportingInput;
}>;


export type GetReferralReportForCustomerQuery = (
  { __typename?: 'Query' }
  & { getReferralReportForCustomer: Array<(
    { __typename?: 'ReferralCustomerReporting' }
    & ReferralStatsFragmentFragment
  )> }
);

export type GetReferralRewardByOrderIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReferralRewardByOrderIdQuery = (
  { __typename?: 'Query' }
  & { getReferralRewardByOrderId?: Maybe<(
    { __typename?: 'ReferralReward' }
    & Pick<ReferralReward, 'id' | 'rewardType' | 'rewardQuantityType' | 'rewardQuantity'>
    & { referralCampaign: (
      { __typename?: 'ReferralCampaign' }
      & Pick<ReferralCampaign, 'id'>
    ) }
  )> }
);

export type GetReleaseByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetReleaseByIdQuery = (
  { __typename?: 'Query' }
  & { release: (
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'name' | 'type' | 'slug' | 'isActive' | 'requiresAuth' | 'minPurchaseQuantity' | 'maxPurchaseQuantity' | 'presaleTitle' | 'presaleInformation' | 'releaseNotes' | 'allowPublicComp' | 'isResaleRelease'>
    & { status: (
      { __typename?: 'ReleaseStatus' }
      & Pick<ReleaseStatus, 'value' | 'message'>
    ), event: (
      { __typename?: 'Event' }
      & Pick<Event, 'hasPublicRelease'>
      & { venue: (
        { __typename?: 'Venue' }
        & VenueFragment
      ), ticketTypes: Array<(
        { __typename?: 'TicketType' }
        & TicketTypeBaseFragment
        & TicketTypeInfoFragment
      )>, multiBuyPromotions: Array<(
        { __typename?: 'EventMultiBuyPromotion' }
        & Pick<EventMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
        & { getTicketType: (
          { __typename?: 'TicketType' }
          & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
          & { dates: Array<(
            { __typename?: 'TicketTypeDate' }
            & Pick<TicketTypeDate, 'alternateEntryTime'>
          )> }
        ), buyTicketType: (
          { __typename?: 'TicketType' }
          & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
          & { dates: Array<(
            { __typename?: 'TicketTypeDate' }
            & Pick<TicketTypeDate, 'alternateEntryTime'>
          )> }
        ) }
      )> }
      & EventFragment
    ), presaleHeader?: Maybe<(
      { __typename?: 'ExtendedFile' }
      & FileFragment
    )>, presaleLogo?: Maybe<(
      { __typename?: 'ExtendedFile' }
      & FileFragment
    )>, releaseZones: Array<(
      { __typename?: 'ReleaseZone' }
      & Pick<ReleaseZone, 'isActive' | 'pricingLevel'>
      & { zone: (
        { __typename?: 'Zone' }
        & Pick<Zone, 'name' | 'description' | 'displayOrder'>
      ), ticketTypes: Array<(
        { __typename?: 'ReleaseZoneTicketType' }
        & Pick<ReleaseZoneTicketType, 'price' | 'isActive' | 'ticketTypeId' | 'isSoldOut' | 'minPurchaseQuantity' | 'maxPurchaseQuantity' | 'ticketAvailabilityStatus' | 'purchaseRestrictionsHelpText'>
        & { sections?: Maybe<Array<(
          { __typename?: 'ZonePricingTypeSection' }
          & Pick<ZonePricingTypeSection, 'name' | 'price'>
        )>>, purchaseRestrictions: Array<(
          { __typename?: 'PurchaseRestriction' }
          & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
          & { requiredEntities: Array<(
            { __typename?: 'PurchaseRestrictionRequiredEntity' }
            & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
          )> }
        )> }
      )> }
    )>, releaseEventAddons: Array<(
      { __typename?: 'ReleaseEventAddon' }
      & { eventAddon: (
        { __typename?: 'EventAddon' }
        & Pick<EventAddon, 'id' | 'name' | 'enableNaming' | 'price' | 'description' | 'restrictions' | 'isActive' | 'addonAvailabilityStatus' | 'maxPurchaseQuantity' | 'purchaseRestrictionsHelpText'>
        & { addonImage?: Maybe<(
          { __typename?: 'ExtendedFile' }
          & FileFragment
        )>, purchaseRestrictions: Array<(
          { __typename?: 'PurchaseRestriction' }
          & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
          & { requiredEntities: Array<(
            { __typename?: 'PurchaseRestrictionRequiredEntity' }
            & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
          )> }
        )> }
      ) }
    )>, channels: Array<(
      { __typename?: 'EventChannelOutput' }
      & Pick<EventChannelOutput, 'id' | 'label' | 'isEnabled'>
    )> }
  ) }
);

export type GetReleaseBySlugQueryVariables = Exact<{
  slug?: Maybe<Scalars['String']>;
  eventId: Scalars['String'];
}>;


export type GetReleaseBySlugQuery = (
  { __typename?: 'Query' }
  & { getReleaseBySlug?: Maybe<(
    { __typename?: 'Release' }
    & Pick<Release, 'id' | 'name' | 'type' | 'slug' | 'isActive' | 'requiresAuth' | 'minPurchaseQuantity' | 'maxPurchaseQuantity' | 'presaleTitle' | 'presaleInformation' | 'releaseNotes' | 'allowPublicComp' | 'isResaleRelease'>
    & { status: (
      { __typename?: 'ReleaseStatus' }
      & Pick<ReleaseStatus, 'value' | 'message'>
    ), event: (
      { __typename?: 'Event' }
      & Pick<Event, 'hasPublicRelease'>
      & { venue: (
        { __typename?: 'Venue' }
        & VenueFragment
      ), ticketTypes: Array<(
        { __typename?: 'TicketType' }
        & TicketTypeBaseFragment
        & TicketTypeInfoFragment
      )>, multiBuyPromotions: Array<(
        { __typename?: 'EventMultiBuyPromotion' }
        & Pick<EventMultiBuyPromotion, 'id' | 'name' | 'getQuantity' | 'buyQuantity' | 'price' | 'enabled'>
        & { getTicketType: (
          { __typename?: 'TicketType' }
          & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
          & { dates: Array<(
            { __typename?: 'TicketTypeDate' }
            & Pick<TicketTypeDate, 'alternateEntryTime'>
          )> }
        ), buyTicketType: (
          { __typename?: 'TicketType' }
          & Pick<TicketType, 'id' | 'name' | 'description' | 'restrictions' | 'kind' | 'isGrouped' | 'groupAmount' | 'bookingFeeType' | 'bookingFee'>
          & { dates: Array<(
            { __typename?: 'TicketTypeDate' }
            & Pick<TicketTypeDate, 'alternateEntryTime'>
          )> }
        ) }
      )> }
      & EventFragment
    ), presaleHeader?: Maybe<(
      { __typename?: 'ExtendedFile' }
      & FileFragment
    )>, presaleLogo?: Maybe<(
      { __typename?: 'ExtendedFile' }
      & FileFragment
    )>, releaseZones: Array<(
      { __typename?: 'ReleaseZone' }
      & Pick<ReleaseZone, 'isActive' | 'pricingLevel'>
      & { zone: (
        { __typename?: 'Zone' }
        & Pick<Zone, 'name' | 'description' | 'displayOrder'>
      ), ticketTypes: Array<(
        { __typename?: 'ReleaseZoneTicketType' }
        & Pick<ReleaseZoneTicketType, 'price' | 'isActive' | 'ticketTypeId' | 'isSoldOut' | 'minPurchaseQuantity' | 'maxPurchaseQuantity' | 'ticketAvailabilityStatus' | 'purchaseRestrictionsHelpText'>
        & { sections?: Maybe<Array<(
          { __typename?: 'ZonePricingTypeSection' }
          & Pick<ZonePricingTypeSection, 'name' | 'price'>
        )>>, purchaseRestrictions: Array<(
          { __typename?: 'PurchaseRestriction' }
          & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
          & { requiredEntities: Array<(
            { __typename?: 'PurchaseRestrictionRequiredEntity' }
            & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
          )> }
        )> }
      )> }
    )>, releaseEventAddons: Array<(
      { __typename?: 'ReleaseEventAddon' }
      & { eventAddon: (
        { __typename?: 'EventAddon' }
        & Pick<EventAddon, 'id' | 'name' | 'enableNaming' | 'price' | 'description' | 'restrictions' | 'isActive' | 'addonAvailabilityStatus' | 'maxPurchaseQuantity' | 'purchaseRestrictionsHelpText'>
        & { addonImage?: Maybe<(
          { __typename?: 'ExtendedFile' }
          & FileFragment
        )>, purchaseRestrictions: Array<(
          { __typename?: 'PurchaseRestriction' }
          & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
          & { requiredEntities: Array<(
            { __typename?: 'PurchaseRestrictionRequiredEntity' }
            & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
          )> }
        )> }
      ) }
    )>, channels: Array<(
      { __typename?: 'EventChannelOutput' }
      & Pick<EventChannelOutput, 'id' | 'label' | 'isEnabled'>
    )> }
  )> }
);

export type GetSubscriptionPortalUrlForOrderQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type GetSubscriptionPortalUrlForOrderQuery = (
  { __typename?: 'Query' }
  & { getSubscriptionPortalUrlForOrder: (
    { __typename?: 'MembershipSubscriptionPortalOutput' }
    & Pick<MembershipSubscriptionPortalOutput, 'url'>
  ) }
);

export type GetSubscriptionPortalUrlForTicketQueryVariables = Exact<{
  ticketId: Scalars['String'];
}>;


export type GetSubscriptionPortalUrlForTicketQuery = (
  { __typename?: 'Query' }
  & { getSubscriptionPortalUrlForTicket: (
    { __typename?: 'MembershipSubscriptionPortalOutput' }
    & Pick<MembershipSubscriptionPortalOutput, 'url'>
  ) }
);

export type GetTicketsByIdsQueryVariables = Exact<{
  ids: Scalars['String'];
}>;


export type GetTicketsByIdsQuery = (
  { __typename?: 'Query' }
  & { getTicketsByIds: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'status' | 'shouldBeNamed'>
    & { addon?: Maybe<(
      { __typename?: 'EventAddon' }
      & Pick<EventAddon, 'id' | 'name' | 'enableNaming'>
    ) | (
      { __typename?: 'MembershipAddon' }
      & Pick<MembershipAddon, 'id' | 'name' | 'enableNaming'>
    )>, lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & LineItemFragment
    )>, parentTicket?: Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'firstName' | 'lastName' | 'id'>
      & { membershipType?: Maybe<(
        { __typename?: 'MembershipType' }
        & { membership: (
          { __typename?: 'Membership' }
          & Pick<Membership, 'canRenameMembership' | 'name'>
        ) }
      )> }
    )>, ticketType?: Maybe<(
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'name'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'alternateEntryTime'>
      )> }
    )>, membershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & { membership: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'name'>
      ) }
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'title' | 'startDate' | 'endDate' | 'canChangeSeats' | 'changingSeatsFee' | 'canRenameTicket'>
      & { venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      ), zonesAllocatedGates?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGate' }
        & Pick<ZoneAllocatedGate, 'name'>
      )>> }
    )> }
    & TicketFragment
  )> }
);

export type HandleInstagramCodeMutationVariables = Exact<{
  input: HandleInstagramCodeInput;
}>;


export type HandleInstagramCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleInstagramCode'>
);

export type HasMembershipToRenewQueryVariables = Exact<{ [key: string]: never; }>;


export type HasMembershipToRenewQuery = (
  { __typename?: 'Query' }
  & { myMembershipTickets: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'hasRenewedMembership'>
    & { membershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'name'>
      & { membership: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'name' | 'isActive' | 'id'>
        & { renewalMembership?: Maybe<(
          { __typename?: 'Membership' }
          & Pick<Membership, 'id'>
        )> }
      ) }
    )> }
  )> }
);

export type IntegrationsQueryVariables = Exact<{
  input?: Maybe<IntegrationWhereInput>;
}>;


export type IntegrationsQuery = (
  { __typename?: 'Query' }
  & { integrations: Array<(
    { __typename?: 'Integration' }
    & IntegrationFragmentFragment
  )> }
);

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type IsLoggedInQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isLoggedIn'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type MembershipQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MembershipQuery = (
  { __typename?: 'Query' }
  & { membership?: Maybe<(
    { __typename?: 'Membership' }
    & { paymentPlanSettings?: Maybe<(
      { __typename?: 'PaymentPlanSettings' }
      & Pick<PaymentPlanSettings, 'disabledAt'>
      & { scheduleOptions?: Maybe<Array<(
        { __typename?: 'ScheduleOption' }
        & Pick<ScheduleOption, 'id' | 'interval' | 'installmentCount' | 'stripeFeePercentage' | 'promoterFeePercentage' | 'flicketFeePercentage' | 'minimumOrderAmount' | 'finalPaymentDate'>
      )>> }
    )>, venue?: Maybe<(
      { __typename?: 'Venue' }
      & VenueFragment
    )>, events?: Maybe<Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'eventKind' | 'seatsEventKey' | 'title' | 'startDate' | 'description' | 'hasPublicRelease'>
      & { venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & VenueAddressFragment
      ), printBanner1?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )> }
    )>>, membershipZones: Array<(
      { __typename?: 'MembershipZone' }
      & Pick<MembershipZone, 'id' | 'pricingLevel' | 'isActive'>
      & { zone: (
        { __typename?: 'Zone' }
        & Pick<Zone, 'id' | 'name' | 'description' | 'displayOrder'>
      ), membershipTypes: Array<(
        { __typename?: 'MembershipSeatingZoneType' }
        & Pick<MembershipSeatingZoneType, 'membershipTypeId' | 'price' | 'isActive' | 'purchaseRestrictionsHelpText'>
        & { sections?: Maybe<Array<(
          { __typename?: 'ZonePricingTypeSection' }
          & Pick<ZonePricingTypeSection, 'name' | 'price'>
        )>>, purchaseRestrictions: Array<(
          { __typename?: 'PurchaseRestriction' }
          & Pick<PurchaseRestriction, 'id' | 'quantity' | 'isExclusive' | 'isAlwaysVisible'>
          & { requiredEntities: Array<(
            { __typename?: 'PurchaseRestrictionRequiredEntity' }
            & Pick<PurchaseRestrictionRequiredEntity, 'requiredType' | 'requiredId'>
          )> }
        )> }
      )> }
    )> }
    & MembershipFragment
  )> }
);

export type MembershipsQueryVariables = Exact<{
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  where?: Maybe<MembershipWhereInput>;
  orderBy?: Maybe<MembershipOrderByInput>;
}>;


export type MembershipsQuery = (
  { __typename?: 'Query' }
  & { memberships: (
    { __typename?: 'MembershipConnection' }
    & Pick<MembershipConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'MembershipEdge' }
      & { node: (
        { __typename?: 'Membership' }
        & { venue?: Maybe<(
          { __typename?: 'Venue' }
          & VenueInfoFragment
          & VenueAddressFragment
        )>, events?: Maybe<Array<(
          { __typename?: 'Event' }
          & Pick<Event, 'id'>
          & { venueAddress: (
            { __typename?: 'Venue' }
            & Pick<Venue, 'name'>
            & { address: (
              { __typename?: 'Address' }
              & Pick<Address, 'city'>
            ) }
          ) }
        )>>, membershipZones: Array<(
          { __typename?: 'MembershipZone' }
          & Pick<MembershipZone, 'id' | 'pricingLevel' | 'isActive'>
          & { zone: (
            { __typename?: 'Zone' }
            & Pick<Zone, 'id' | 'name'>
          ), membershipTypes: Array<(
            { __typename?: 'MembershipSeatingZoneType' }
            & Pick<MembershipSeatingZoneType, 'price' | 'isActive'>
            & { sections?: Maybe<Array<(
              { __typename?: 'ZonePricingTypeSection' }
              & Pick<ZonePricingTypeSection, 'name' | 'price'>
            )>> }
          )> }
        )> }
        & MembershipBaseFragment
        & MembershipInfoFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>
    ) }
  ) }
);

export type MembershipsSoldForUserQueryVariables = Exact<{
  membershipId: Scalars['String'];
}>;


export type MembershipsSoldForUserQuery = (
  { __typename?: 'Query' }
  & { membershipsSoldForUser: Array<(
    { __typename?: 'MembershipTypePurchaseTotal' }
    & Pick<MembershipTypePurchaseTotal, 'total'>
    & { membershipType: (
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'id'>
    ) }
  )> }
);

export type MyEventTicketsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MyEventTicketsQuery = (
  { __typename?: 'Query' }
  & { myEventTickets: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'status'>
    & { addon?: Maybe<(
      { __typename?: 'EventAddon' }
      & Pick<EventAddon, 'id' | 'name' | 'enableNaming' | 'hideQRCode'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    ) | (
      { __typename?: 'MembershipAddon' }
      & Pick<MembershipAddon, 'id' | 'name' | 'enableNaming'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & LineItemFragment
    )>, parentTicket?: Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'firstName' | 'lastName' | 'id'>
      & { membershipType?: Maybe<(
        { __typename?: 'MembershipType' }
        & { membership: (
          { __typename?: 'Membership' }
          & Pick<Membership, 'canRenameMembership' | 'name' | 'showSchedule' | 'membershipKind'>
        ) }
      )> }
    )>, ticketType?: Maybe<(
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id' | 'kind' | 'name'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'eventDateId' | 'alternateEntryTime'>
      )>, namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, membershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'name'>
      & { membership: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'membershipKind' | 'name' | 'isTicketed'>
      ), namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'title' | 'startDate' | 'endDate' | 'eventKind' | 'canChangeSeats' | 'changingSeatsFee' | 'canRenameTicket' | 'ticketResaleEnabled' | 'sellerTransferringTicketFee' | 'canTransferTicket' | 'ticketsViewableFromDate' | 'isR18'>
      & { dates: Array<(
        { __typename?: 'EventDate' }
        & Pick<EventDate, 'id' | 'startDate' | 'endDate'>
      )>, backgroundImage?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      ), zonesAllocatedGates?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGate' }
        & Pick<ZoneAllocatedGate, 'name'>
        & { dates?: Maybe<Array<(
          { __typename?: 'ZoneAllocatedDate' }
          & Pick<ZoneAllocatedDate, 'eventDateId' | 'alternateEntryTime'>
        )>> }
      )>> }
    )>, membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'name' | 'membershipKind' | 'startDate' | 'endDate'>
      & { venue?: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      )> }
    )> }
    & TicketFragment
  )> }
);

export type MyEventsQueryVariables = Exact<{
  where?: Maybe<MyEventsWhereInput>;
  orderBy?: Maybe<MyEventsOrderByInput>;
}>;


export type MyEventsQuery = (
  { __typename?: 'Query' }
  & { myEvents: Array<(
    { __typename?: 'Event' }
    & { venue: (
      { __typename?: 'Venue' }
      & Pick<Venue, 'name'>
      & VenueAddressFragment
    ) }
    & EventBaseFragment
    & EventInfoFragment
  )> }
);

export type MyMembershipAddonsQueryVariables = Exact<{
  membershipId: Scalars['String'];
}>;


export type MyMembershipAddonsQuery = (
  { __typename?: 'Query' }
  & { myMembershipAddons: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'id' | 'isNamed' | 'firstName' | 'lastName'>
    & { parentTicket?: Maybe<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'firstName' | 'lastName' | 'id'>
      & { membershipType?: Maybe<(
        { __typename?: 'MembershipType' }
        & { membership: (
          { __typename?: 'Membership' }
          & Pick<Membership, 'name'>
        ) }
      )> }
    )>, membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'name' | 'startDate' | 'endDate'>
      & { venue?: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & VenueAddressFragment
      )> }
    )>, membershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'name'>
      & { membership: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'name' | 'membershipKind'>
      ) }
    )>, lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & LineItemFragment
    )>, addon?: Maybe<(
      { __typename?: 'EventAddon' }
      & Pick<EventAddon, 'id' | 'enableNaming' | 'name'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    ) | (
      { __typename?: 'MembershipAddon' }
      & Pick<MembershipAddon, 'id' | 'enableNaming' | 'name'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, ticketType?: Maybe<(
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'name'>
      & { dates: Array<(
        { __typename?: 'TicketTypeDate' }
        & Pick<TicketTypeDate, 'eventDateId' | 'alternateEntryTime'>
      )>, namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'title' | 'eventKind' | 'startDate' | 'endDate' | 'canChangeSeats' | 'changingSeatsFee' | 'canRenameTicket' | 'sellerTransferringTicketFee' | 'canTransferTicket' | 'ticketsViewableFromDate' | 'isR18'>
      & { dates: Array<(
        { __typename?: 'EventDate' }
        & Pick<EventDate, 'id' | 'startDate' | 'endDate'>
      )>, venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      ), zonesAllocatedGates?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGate' }
        & Pick<ZoneAllocatedGate, 'name'>
        & { dates?: Maybe<Array<(
          { __typename?: 'ZoneAllocatedDate' }
          & Pick<ZoneAllocatedDate, 'eventDateId' | 'alternateEntryTime'>
        )>> }
      )>> }
    )> }
    & TicketFragment
  )> }
);

export type MyMembershipHoldOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type MyMembershipHoldOrdersQuery = (
  { __typename?: 'Query' }
  & { myMembershipHoldOrders: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'total' | 'orderNumber'>
    & { membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'name'>
    )> }
  )> }
);

export type MyMembershipTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyMembershipTicketsQuery = (
  { __typename?: 'Query' }
  & { myMembershipTickets: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'status' | 'hasRenewedMembership' | 'shouldBeNamed'>
    & { lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & LineItemFragment
    )>, membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'endDate'>
    )>, membershipType?: Maybe<(
      { __typename?: 'MembershipType' }
      & Pick<MembershipType, 'name'>
      & { membership: (
        { __typename?: 'Membership' }
        & Pick<Membership, 'membershipKind' | 'canRenameMembership' | 'showSchedule' | 'name' | 'isActive' | 'id'>
        & { thumbnail?: Maybe<(
          { __typename?: 'ExtendedFile' }
          & FileFragment
        )>, renewalMembership?: Maybe<(
          { __typename?: 'Membership' }
          & Pick<Membership, 'id'>
        )> }
      ), namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'eventKind'>
      & { thumbnail?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & VenueAddressFragment
      ) }
    )> }
    & TicketFragment
  )> }
);

export type MyPaymentPlanTicketsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPaymentPlanTicketsQuery = (
  { __typename?: 'Query' }
  & { myPaymentPlanTickets: Array<(
    { __typename?: 'PartPaidTicketStats' }
    & Pick<PartPaidTicketStats, 'orderId' | 'ticketCount' | 'membershipId' | 'eventId'>
  )> }
);

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderQuery = (
  { __typename?: 'Query' }
  & { order?: Maybe<(
    { __typename?: 'Order' }
    & CheckoutOrderFragment
  )> }
);

export type MyOrdersQueryVariables = Exact<{
  where?: Maybe<OrderWhereInput>;
}>;


export type MyOrdersQuery = (
  { __typename?: 'Query' }
  & { myOrders: (
    { __typename?: 'OrderConnection' }
    & Pick<OrderConnection, 'totalCount'>
    & { edges: Array<(
      { __typename?: 'OrderEdge' }
      & { node: (
        { __typename?: 'Order' }
        & OrderBaseFragment
        & OrderInfoFragment
      ) }
    )>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  ) }
);

export type PinPaymentContextQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PinPaymentContextQuery = (
  { __typename?: 'Query' }
  & { pinPaymentContext: (
    { __typename?: 'PinPaymentContext' }
    & Pick<PinPaymentContext, 'amount' | 'currency' | 'redirectUrl' | 'publicKey' | 'isSandbox' | 'orderId'>
  ) }
);

export type PollOrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type PollOrderQuery = (
  { __typename?: 'Query' }
  & { pollOrder?: Maybe<(
    { __typename?: 'Order' }
    & CheckoutOrderFragment
  )> }
);

export type GetPosQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetPosQuery = (
  { __typename?: 'Query' }
  & { pos: (
    { __typename?: 'Pos' }
    & Pick<Pos, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'deliveryOptions'>
    & { paymentOptions: (
      { __typename?: 'PosPaymentOptions' }
      & { cash?: Maybe<(
        { __typename?: 'PosPaymentFee' }
        & Pick<PosPaymentFee, 'feeType' | 'feeAmount'>
      )>, eftpos?: Maybe<(
        { __typename?: 'PosPaymentFee' }
        & Pick<PosPaymentFee, 'feeType' | 'feeAmount'>
      )>, cc?: Maybe<(
        { __typename?: 'PosPaymentFee' }
        & Pick<PosPaymentFee, 'feeType' | 'feeAmount'>
      )>, MPAiSA?: Maybe<(
        { __typename?: 'PosPaymentFee' }
        & Pick<PosPaymentFee, 'feeType' | 'feeAmount'>
      )> }
    ), event: (
      { __typename?: 'Event' }
      & Pick<Event, 'title' | 'id' | 'startDate' | 'endDate'>
      & { venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & VenueAddressFragment
      ) }
    ), release: (
      { __typename?: 'Release' }
      & Pick<Release, 'name' | 'id'>
    ) }
  ) }
);

export type ReferralUrlByOrderIdQueryVariables = Exact<{
  orderId: Scalars['String'];
}>;


export type ReferralUrlByOrderIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'referralUrlByOrderId'>
);

export type ReferralUrlByWaitlistUserIdQueryVariables = Exact<{
  eventId: Scalars['String'];
  waitlistUserId: Scalars['String'];
}>;


export type ReferralUrlByWaitlistUserIdQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'referralUrlByWaitlistUserId'>
);

export type ReportSpamMembershipQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ReportSpamMembershipQuery = (
  { __typename?: 'Query' }
  & { membership?: Maybe<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'id' | 'name'>
  )> }
);

export type ResaleTicketTypesForWaitlistQueryVariables = Exact<{
  eventId: Scalars['String'];
  releaseId?: Maybe<Scalars['String']>;
}>;


export type ResaleTicketTypesForWaitlistQuery = (
  { __typename?: 'Query' }
  & { resaleTicketTypesForWaitlist: Array<(
    { __typename?: 'TicketType' }
    & Pick<TicketType, 'id' | 'name'>
  )> }
);

export type SeasonsQueryVariables = Exact<{ [key: string]: never; }>;


export type SeasonsQuery = (
  { __typename?: 'Query' }
  & { seasons: Array<(
    { __typename?: 'Season' }
    & SeasonFragmentFragment
  )> }
);

export type SingleEventQueryVariables = Exact<{ [key: string]: never; }>;


export type SingleEventQuery = (
  { __typename?: 'Query' }
  & { currentOrganization: (
    { __typename?: 'CurrentOrganizationOutput' }
    & { singleEvent?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'hasPublicRelease'>
      & { logo?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, venue: (
        { __typename?: 'Venue' }
        & VenueBaseFragment
        & VenueInfoFragment
        & VenueAddressFragment
      ) }
      & EventBaseFragment
      & EventInfoFragment
    )> }
  ) }
);

export type StripePaymentContextQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StripePaymentContextQuery = (
  { __typename?: 'Query' }
  & { stripePaymentContext: (
    { __typename?: 'StripePaymentContext' }
    & Pick<StripePaymentContext, 'redirectUrl' | 'checkoutUrl' | 'orderId' | 'stripeAccountId' | 'publishableKey' | 'clientSecret'>
  ) }
);

export type TicketNamingFieldFragment = (
  { __typename?: 'Ticket' }
  & { membershipType?: Maybe<(
    { __typename?: 'MembershipType' }
    & Pick<MembershipType, 'name' | 'kind'>
    & { membership: (
      { __typename?: 'Membership' }
      & Pick<Membership, 'id' | 'isTicketed' | 'canNameMembership' | 'canRenameMembership' | 'membershipKind' | 'endDate'>
      & { renewalMembership?: Maybe<(
        { __typename?: 'Membership' }
        & Pick<Membership, 'id'>
      )> }
    ), namingFields: Array<(
      { __typename?: 'FormField' }
      & NamingFieldsFragment
    )> }
  )>, ticketType?: Maybe<(
    { __typename?: 'TicketType' }
    & Pick<TicketType, 'id' | 'kind' | 'name'>
    & { dates: Array<(
      { __typename?: 'TicketTypeDate' }
      & Pick<TicketTypeDate, 'eventDateId' | 'alternateEntryTime'>
    )>, namingFields: Array<(
      { __typename?: 'FormField' }
      & NamingFieldsFragment
    )> }
  )> }
);

export type TicketQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TicketQuery = (
  { __typename?: 'Query' }
  & { ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'transferStatus' | 'shouldBeNamed' | 'purchaseRestrictionText' | 'maxTransferPrice' | 'membershipSubscriptionId'>
    & { childTickets?: Maybe<Array<(
      { __typename?: 'Ticket' }
      & Pick<Ticket, 'transferStatus'>
      & { lineItem?: Maybe<(
        { __typename?: 'LineItem' }
        & LineItemFragment
      )>, event?: Maybe<(
        { __typename?: 'Event' }
        & Pick<Event, 'title' | 'startDate' | 'endDate'>
        & { dates: Array<(
          { __typename?: 'EventDate' }
          & Pick<EventDate, 'id' | 'startDate' | 'endDate'>
        )>, venue: (
          { __typename?: 'Venue' }
          & Pick<Venue, 'name'>
          & { gates: Array<(
            { __typename?: 'Gate' }
            & Pick<Gate, 'name'>
          )> }
          & VenueAddressFragment
        ), zonesAllocatedGates?: Maybe<Array<(
          { __typename?: 'ZoneAllocatedGate' }
          & Pick<ZoneAllocatedGate, 'name'>
          & { dates?: Maybe<Array<(
            { __typename?: 'ZoneAllocatedDate' }
            & Pick<ZoneAllocatedDate, 'eventDateId' | 'alternateEntryTime'>
          )>> }
        )>> }
      )> }
      & TicketFragment
      & TicketNamingFieldFragment
    )>>, resellabilityState: (
      { __typename?: 'TicketResellabilityState' }
      & Pick<TicketResellabilityState, 'ticketResaleAllowed' | 'ticketTransferAllowed' | 'latestTransferCompletionDate' | 'reason'>
    ), lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & Pick<LineItem, 'type' | 'name'>
    )>, addon?: Maybe<(
      { __typename: 'EventAddon' }
      & Pick<EventAddon, 'name' | 'id'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    ) | (
      { __typename: 'MembershipAddon' }
      & Pick<MembershipAddon, 'id' | 'name'>
      & { namingFields: Array<(
        { __typename?: 'FormField' }
        & NamingFieldsFragment
      )> }
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'title' | 'eventKind' | 'startDate' | 'endDate' | 'canRenameTicket' | 'ticketsViewableFromDate' | 'sellerTransferringTicketFee' | 'canTransferTicket' | 'isR18'>
      & { venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      ), zonesAllocatedGates?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGate' }
        & Pick<ZoneAllocatedGate, 'name'>
        & { dates?: Maybe<Array<(
          { __typename?: 'ZoneAllocatedDate' }
          & Pick<ZoneAllocatedDate, 'eventDateId' | 'alternateEntryTime'>
        )>> }
      )>> }
    )>, membership?: Maybe<(
      { __typename?: 'Membership' }
      & Pick<Membership, 'name' | 'membershipKind' | 'canNameMembership' | 'canRenameMembership' | 'startDate'>
      & { backgroundImage?: Maybe<(
        { __typename?: 'ExtendedFile' }
        & FileFragment
      )>, venue?: Maybe<(
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      )> }
    )> }
    & TicketFragment
    & TicketNamingFieldFragment
  )> }
);

export type TicketDetailsForBackOfficeOrdersQueryVariables = Exact<{
  eventId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
}>;


export type TicketDetailsForBackOfficeOrdersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'ticketDetailsForBackOfficeOrders'>
);

export type TicketGatesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TicketGatesQuery = (
  { __typename?: 'Query' }
  & { ticket?: Maybe<(
    { __typename?: 'Ticket' }
    & { gates?: Maybe<Array<(
      { __typename?: 'Gate' }
      & Pick<Gate, 'id' | 'name'>
    )>> }
  )> }
);

export type SendTicketMutationVariables = Exact<{
  ticketIds: Array<Scalars['String']>;
  email: Scalars['String'];
}>;


export type SendTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendTicket'>
);

export type TicketSendFragment = (
  { __typename?: 'Ticket' }
  & { ticketSendFields?: Maybe<Array<(
    { __typename?: 'TicketSendFields' }
    & Pick<TicketSendFields, 'active' | 'email'>
  )>> }
);

export type GetSentTicketsByIdQueryVariables = Exact<{
  sendId: Scalars['String'];
}>;


export type GetSentTicketsByIdQuery = (
  { __typename?: 'Query' }
  & { getSentTicketsById: Array<(
    { __typename?: 'Ticket' }
    & Pick<Ticket, 'transferStatus' | 'purchaseRestrictionText'>
    & { lineItem?: Maybe<(
      { __typename?: 'LineItem' }
      & LineItemFragment
    )>, addon?: Maybe<(
      { __typename?: 'EventAddon' }
      & Pick<EventAddon, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'enableNaming' | 'hideQRCode'>
    ) | (
      { __typename?: 'MembershipAddon' }
      & Pick<MembershipAddon, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'enableNaming'>
    )>, event?: Maybe<(
      { __typename?: 'Event' }
      & Pick<Event, 'title' | 'startDate' | 'endDate' | 'canChangeSeats' | 'changingSeatsFee' | 'canRenameTicket'>
      & { venue: (
        { __typename?: 'Venue' }
        & Pick<Venue, 'name'>
        & { zones: Array<(
          { __typename?: 'Zone' }
          & Pick<Zone, 'name' | 'stand'>
        )>, gates: Array<(
          { __typename?: 'Gate' }
          & Pick<Gate, 'name' | 'id'>
        )> }
        & VenueAddressFragment
      ), zonesAllocatedGates?: Maybe<Array<(
        { __typename?: 'ZoneAllocatedGate' }
        & Pick<ZoneAllocatedGate, 'name'>
      )>> }
    )> }
    & TicketFragment
    & TicketNamingFieldFragment
  )> }
);

export type TicketsSoldForUserQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type TicketsSoldForUserQuery = (
  { __typename?: 'Query' }
  & { ticketsSoldForUser: Array<(
    { __typename?: 'TicketTypePurchaseTotal' }
    & Pick<TicketTypePurchaseTotal, 'total'>
    & { ticketType: (
      { __typename?: 'TicketType' }
      & Pick<TicketType, 'id'>
    ) }
  )> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ) }
);

export type VenueSeatsThumbnailQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type VenueSeatsThumbnailQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'venueSeatsThumbnail'>
);

export type VenueWithGatesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type VenueWithGatesQuery = (
  { __typename?: 'Query' }
  & { venue: (
    { __typename?: 'Venue' }
    & Pick<Venue, 'name'>
    & { gates: Array<(
      { __typename?: 'Gate' }
      & Pick<Gate, 'id' | 'name'>
    )> }
  ) }
);

export type WindcavePaymentContextQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type WindcavePaymentContextQuery = (
  { __typename?: 'Query' }
  & { windcavePaymentContext: (
    { __typename?: 'WindcavePaymentContext' }
    & Pick<WindcavePaymentContext, 'redirectUrl' | 'checkoutUrl' | 'orderId'>
  ) }
);

export type WindcavePaymentResultQueryVariables = Exact<{
  externalId: Scalars['String'];
  result: Scalars['String'];
}>;


export type WindcavePaymentResultQuery = (
  { __typename?: 'Query' }
  & { windcavePaymentResult: (
    { __typename?: 'WindcavePaymentResultOutput' }
    & Pick<WindcavePaymentResultOutput, 'redirectUrl'>
  ) }
);

export type GetXeroConfigurationQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetXeroConfigurationQueryQuery = (
  { __typename?: 'Query' }
  & { getXeroConfiguration?: Maybe<(
    { __typename?: 'XeroConfigurationOutput' }
    & Pick<XeroConfigurationOutput, 'accountCode' | 'sendFlicketInvoice' | 'invoiceStatus'>
  )> }
);

export const FileFragmentDoc = gql`
    fragment File on ExtendedFile {
  storage
  directory
  fileName
  originalFileName
  metaData {
    width
    height
  }
}
    `;
export const CompetitionPrizeFragmentFragmentDoc = gql`
    fragment CompetitionPrizeFragment on CompetitionPrize {
  id
  title
  description
  image {
    ...File
  }
  prizeOrder
  quantity
}
    ${FileFragmentDoc}`;
export const CompetitionRewardFragmentFragmentDoc = gql`
    fragment CompetitionRewardFragment on CompetitionReward {
  platform
  actionType
  value
  isEnabled
  socialLinkType
}
    `;
export const CompetitionFragmentFragmentDoc = gql`
    fragment CompetitionFragment on Competition {
  id
  startDate
  endDate
  competitionType
  description
  termsAndConditions
  title
  isPublished
  prizes {
    ...CompetitionPrizeFragment
  }
  promoter {
    ...CompetitionRewardFragment
  }
}
    ${CompetitionPrizeFragmentFragmentDoc}
${CompetitionRewardFragmentFragmentDoc}`;
export const PointFragmentDoc = gql`
    fragment point on Point {
  id
  name
  termsAndConditions
  gateways {
    id
    name
    type
    transactionFeeType
    transactionFee
    transactionFeeType2
    transactionFee2
    publicKey
    paymentMethodType
  }
}
    `;
export const PointItemFragmentDoc = gql`
    fragment pointItem on PointItem {
  id
  name
  price
  amount
  isVisible
  description
  banner {
    ...File
  }
  thumbnail {
    ...File
  }
  backgroundImage {
    ...File
  }
  descriptionImage {
    ...File
  }
}
    ${FileFragmentDoc}`;
export const CompetitionStatsFragmentFragmentDoc = gql`
    fragment CompetitionStatsFragment on CompetitionCustomerReporting {
  competitionEndDate
  competitionReferralLink
  competitionTitle
  competitionSharePageLink
  referralsMade
  entries
  eventId
  isCompetitionRunning
  competitionId
  competitionEventTitle
  competitionEventLogo {
    fileName
    storage
    originalFileName
    directory
    metaData {
      height
      width
    }
  }
  competitionBannerImage {
    fileName
    storage
    originalFileName
    directory
    metaData {
      height
      width
    }
  }
}
    `;
export const EventBaseFragmentDoc = gql`
    fragment EventBase on Event {
  id
  createdAt
  updatedAt
}
    `;
export const EventInfoFragmentDoc = gql`
    fragment EventInfo on Event {
  title
  description
  eventKind
  dates {
    startDate
    endDate
  }
  hasEventEnded
  startDate
  endDate
  isFeatured
  thumbnail {
    ...File
  }
  logo {
    ...File
  }
  backgroundImage {
    ...File
  }
  backgroundImageFullPage {
    ...File
  }
  descriptionImage {
    ...File
  }
  printBanner1 {
    ...File
  }
  printBanner2 {
    ...File
  }
  digitalPassCodesStatus {
    remainingPassCodes
  }
  importantNotice
  termsAndConditions
  accessibility
  isR18
  presaleInformation
  enableReleaseCodes
  enableWaitlist
  isWaitlistAvailable
  waitlistEndDate
  season {
    id
  }
  pixelId
  hiddenFromPublic
}
    ${FileFragmentDoc}`;
export const ShippingOptionsFragmentDoc = gql`
    fragment ShippingOptions on ShippingOptions {
  eTicket
  printedTicketByMail
  printedTicketByCourier
  printedTicketByCourierRural
  printedTicketAtVenue
}
    `;
export const EventOptionsFragmentDoc = gql`
    fragment EventOptions on Event {
  isActive
  seatsEventKey
  bookingFeeType
  bookingFee
  canUsePoints
  canChangeSeats
  changingSeatsFee
  shippingOptions {
    ...ShippingOptions
  }
  gateways {
    id
    name
    type
    transactionFee
    transactionFeeType
    transactionFeeType2
    transactionFee2
    bnplEnabled
    bnplTransactionFeePercent
  }
}
    ${ShippingOptionsFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  ...EventBase
  ...EventInfo
  ...EventOptions
  customTaxRate
  taxRate
  taxCalculationMethod
  multiBuyPromotions {
    id
    name
    getQuantity
    getTicketType {
      id
      name
      description
      restrictions
      kind
      isGrouped
      groupAmount
      dates {
        alternateEntryTime
      }
      bookingFeeType
      bookingFee
    }
    buyQuantity
    buyTicketType {
      id
      name
      description
      restrictions
      kind
      isGrouped
      groupAmount
      dates {
        alternateEntryTime
      }
      bookingFeeType
      bookingFee
    }
    price
    enabled
  }
  ticketResaleWaitlistEnabled
  customFields {
    id
    label
    value
    targetArea
  }
}
    ${EventBaseFragmentDoc}
${EventInfoFragmentDoc}
${EventOptionsFragmentDoc}`;
export const ImageGalleryFragmentFragmentDoc = gql`
    fragment ImageGalleryFragment on ImageGalleryItem {
  id
  title
  original {
    src
    metaData {
      width
      height
    }
    aspectRatio
  }
  cropped {
    src
    metaData {
      width
      height
    }
    aspectRatio
  }
}
    `;
export const IntegrationFragmentFragmentDoc = gql`
    fragment IntegrationFragment on Integration {
  id
  type
  paymentMethodType
  name
  transactionFeeType
  transactionFee
  transactionFeeType2
  transactionFee2
  publicKey
  webhookUrl
  bnplEnabled
  bnplTransactionFeePercent
}
    `;
export const MembershipBaseFragmentDoc = gql`
    fragment MembershipBase on Membership {
  id
  createdAt
  updatedAt
}
    `;
export const MembershipInfoFragmentDoc = gql`
    fragment MembershipInfo on Membership {
  name
  membershipKind
  startDate
  endDate
  description
  showSchedule
  digitalPassCodesStatus {
    remainingPassCodes
  }
  thumbnail {
    ...File
  }
  banner {
    ...File
  }
  backgroundImage {
    ...File
  }
  descriptionImage {
    ...File
  }
  termsAndConditions
  accessibility
  importantNotice
  ticketCover {
    isEnabled
    totalFeePercentage
    purchaseBehaviour
  }
}
    ${FileFragmentDoc}`;
export const NamingFieldsFragmentDoc = gql`
    fragment namingFields on FormField {
  field
  isRequired
  label
  isCustom
}
    `;
export const MembershipOptionsFragmentDoc = gql`
    fragment MembershipOptions on Membership {
  bookingFeeType
  bookingFee
  shippingOptions {
    ...ShippingOptions
  }
  gateways {
    id
    name
    type
    transactionFee
    transactionFeeType
    transactionFeeType2
    transactionFee2
    bnplEnabled
    bnplTransactionFeePercent
  }
  addons {
    id
    name
    enableNaming
    price
    description
    restrictions
    isActive
    maxPurchaseQuantity
    namingFields {
      ...namingFields
    }
    addonImage {
      ...File
    }
  }
  canNameMembership
  canRenameMembership
  canChangeSeats
  changingSeatsFee
  canUsePoints
  isActive
  showSchedule
}
    ${ShippingOptionsFragmentDoc}
${NamingFieldsFragmentDoc}
${FileFragmentDoc}`;
export const MembershipTypeFragmentDoc = gql`
    fragment MembershipType on MembershipType {
  id
  name
  kind
  quantitySet
  ticketFeeType
  description
  restrictions
  ticketFee
  namingFields {
    ...namingFields
  }
  minPurchaseQuantity
  maxPurchaseQuantity
}
    ${NamingFieldsFragmentDoc}`;
export const MembershipFragmentDoc = gql`
    fragment Membership on Membership {
  ...MembershipBase
  ...MembershipInfo
  ...MembershipOptions
  taxRate
  customTaxRate
  taxCalculationMethod
  types {
    ...MembershipType
  }
  multiBuyPromotions {
    id
    name
    getQuantity
    getMembershipType {
      id
      name
      description
      restrictions
      kind
    }
    buyQuantity
    buyMembershipType {
      id
      name
      description
      restrictions
      kind
    }
    price
    enabled
  }
}
    ${MembershipBaseFragmentDoc}
${MembershipInfoFragmentDoc}
${MembershipOptionsFragmentDoc}
${MembershipTypeFragmentDoc}`;
export const OrderBaseFragmentDoc = gql`
    fragment OrderBase on Order {
  id
  createdAt
  updatedAt
}
    `;
export const OrderInfoFragmentDoc = gql`
    fragment OrderInfo on Order {
  orderNumber
  orderType
  status
  transactionStatus
  deliveryMethod
  expiryDate
  expiresInSeconds
  channel
  referralId
  event {
    id
    title
    sellerTransferringTicketFee
    ticketCover {
      isEnabled
      totalFeePercentage
      purchaseBehaviour
    }
  }
  orderTicketCover {
    totalFeeAmount
    purchaseBehaviour
  }
  orderPlan {
    billingDetails {
      totalFeeAmount
    }
  }
  release {
    id
    slug
    askHowDidYouHearAboutUs
    askForDietaryRequirements
    allowAccount2Account
    allowHoldOrders
    type
  }
  heardAboutUs
  dietaryRequirements
  pos {
    id
    event {
      id
    }
    release {
      id
    }
  }
}
    `;
export const PromoCodeFragmentDoc = gql`
    fragment PromoCode on Order {
  promoCode {
    ... on EventPromoCode {
      __typename
      id
      code
      discountType
      discountAmount
      eventType: type
    }
    ... on MembershipPromoCode {
      __typename
      id
      code
      discountType
      discountAmount
      membershipType: type
    }
  }
}
    `;
export const OrderFinanceFragmentDoc = gql`
    fragment OrderFinance on Order {
  bookingFeeType
  bookingFee
  bookingFeeAmount
  transferFee
  changingSeatsCredits
  deliveryFee
  subtotal
  amountDue
  total
  totalTax
  taxCalculationMethod
  taxRate
  customTaxAmount
  customTaxRate
  transactionFeeAmount
  usedCredits
  usedPoints
  paymentGateway
  membershipSubscriptionId
  isComp
  refundedAmount
  transactionFeeType
  transactionFee
  transactionFeeType2
  transactionFee2
  multiBuyPromotion {
    ... on EventMultiBuyPromotion {
      id
      name
      getQuantity
      getTicketType {
        id
        name
        description
        restrictions
        kind
        isGrouped
        groupAmount
        dates {
          alternateEntryTime
        }
        bookingFeeType
        bookingFee
      }
      buyQuantity
      buyTicketType {
        id
        name
        description
        restrictions
        kind
        isGrouped
        groupAmount
        dates {
          alternateEntryTime
        }
        bookingFeeType
        bookingFee
      }
      price
    }
    ... on MembershipMultiBuyPromotion {
      id
      name
      getQuantity
      getMembershipType {
        id
        name
        description
        restrictions
        kind
      }
      buyQuantity
      buyMembershipType {
        id
        name
        description
        restrictions
        kind
      }
      price
    }
  }
  ...PromoCode
}
    ${PromoCodeFragmentDoc}`;
export const LineItemBaseFragmentDoc = gql`
    fragment LineItemBase on LineItem {
  id
  createdAt
  updatedAt
}
    `;
export const LineItemInfoFragmentDoc = gql`
    fragment LineItemInfo on LineItem {
  name
  type
}
    `;
export const LineItemSeatFragmentDoc = gql`
    fragment LineItemSeat on LineItem {
  seatLabel
  seatZone
  seatSection
  seatTags
}
    `;
export const LineItemFinanceFragmentDoc = gql`
    fragment LineItemFinance on LineItem {
  price
  originalPrice
  quantity
  ticketFee
  ticketFeeAmount
  stadiumLevyFee
  taxRate
  total
  taxAmount
  customTaxAmount
  customTaxRate
  pointItem {
    id
    name
    amount
  }
  ticketType {
    id
    name
  }
  membershipType {
    id
    membership {
      name
    }
  }
  eventAddon {
    id
  }
  membershipAddon {
    id
  }
}
    `;
export const LineItemFragmentDoc = gql`
    fragment LineItem on LineItem {
  ...LineItemBase
  ...LineItemInfo
  ...LineItemSeat
  ...LineItemFinance
}
    ${LineItemBaseFragmentDoc}
${LineItemInfoFragmentDoc}
${LineItemSeatFragmentDoc}
${LineItemFinanceFragmentDoc}`;
export const TicketBaseFragmentDoc = gql`
    fragment TicketBase on Ticket {
  id
  createdAt
  updatedAt
}
    `;
export const TicketInfoFragmentDoc = gql`
    fragment TicketInfo on Ticket {
  displayName
  ticketNumber
  digitalPassCode
  digitalEventUrl
  seatLabel
  seatZone
  seatSection
  seatTags
  isNamed
  shouldBeNamed
  qrCode
  status
  transferStatus
  resellabilityState {
    ticketResaleAllowed
    ticketTransferAllowed
    latestTransferCompletionDate
    reason
    hoursLeftToTransfer
  }
  maxTransferPrice
  purchaseRestrictionText
  gates {
    id
    name
  }
}
    `;
export const TicketUserFragmentDoc = gql`
    fragment TicketUser on Ticket {
  firstName
  lastName
  email
  phoneNumber
  birthDate
  gender
  postalCode
  companyName
  licensePlate
  memberSince
  membershipNumber
  comments
  dietaryRequirements
  lengthOfStay
  optInToMarketing
  vaccinePass {
    id
  }
  emergencyName
  emergencyContact
  emergencyContactRelationship
  dunedinResidentialAddress
  studentId
  customFields {
    fieldName
    fieldValue
  }
}
    `;
export const TicketSendFragmentDoc = gql`
    fragment TicketSend on Ticket {
  ticketSendFields {
    active
    email
  }
}
    `;
export const TicketFragmentDoc = gql`
    fragment Ticket on Ticket {
  ...TicketBase
  ...TicketInfo
  ...TicketUser
  ...TicketSend
}
    ${TicketBaseFragmentDoc}
${TicketInfoFragmentDoc}
${TicketUserFragmentDoc}
${TicketSendFragmentDoc}`;
export const AddressFragmentDoc = gql`
    fragment Address on Address {
  line1
  line2
  suburb
  postalCode
  city
  state
  country
}
    `;
export const VenueAddressFragmentDoc = gql`
    fragment VenueAddress on Venue {
  address {
    ...Address
  }
  location {
    lat
    lng
  }
  timezone
  locale
}
    ${AddressFragmentDoc}`;
export const OrderItemsFragmentDoc = gql`
    fragment OrderItems on Order {
  lineItems {
    edges {
      node {
        ...LineItem
      }
    }
  }
  orderTicketCover {
    totalFeeAmount
    purchaseBehaviour
  }
  orderPlan {
    billingDetails {
      totalFeeAmount
    }
  }
  tickets {
    ...Ticket
    status
    displayName
    lineItem {
      ...LineItem
    }
    ticketType {
      id
      name
      kind
      dates {
        eventDateId
        alternateEntryTime
      }
      namingFields {
        ...namingFields
      }
    }
    parentTicket {
      id
      firstName
      lastName
    }
    event {
      id
      title
      dates {
        id
        startDate
        endDate
      }
      startDate
      endDate
      eventKind
      canRenameTicket
      sellerTransferringTicketFee
      canTransferTicket
      canChangeSeats
      ticketResaleEnabled
      ticketsViewableFromDate
      isR18
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      zonesAllocatedGates {
        name
        dates {
          eventDateId
          alternateEntryTime
        }
      }
      ticketCover {
        isEnabled
        totalFeePercentage
        purchaseBehaviour
      }
    }
    membershipType {
      name
      membership {
        name
        canRenameMembership
        membershipKind
        isTicketed
      }
      namingFields {
        ...namingFields
      }
    }
    membership {
      name
      membershipKind
      canRenameMembership
      startDate
      endDate
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      ticketCover {
        isEnabled
        totalFeePercentage
        purchaseBehaviour
      }
    }
    addon {
      ... on MembershipAddon {
        id
        name
        enableNaming
        namingFields {
          ...namingFields
        }
      }
      ... on EventAddon {
        id
        name
        enableNaming
        hideQRCode
        namingFields {
          ...namingFields
        }
      }
    }
  }
  event {
    ...EventBase
    ...EventInfo
    canChangeSeats
    changingSeatsFee
    canRenameTicket
  }
  membership {
    ...MembershipBase
    ...MembershipInfo
    canRenameMembership
  }
  transactions {
    edges {
      node {
        id
        kind
        status
        amount
      }
    }
  }
  posTransactions {
    edges {
      node {
        id
        amount
        fee
        paymentGateway
        returned
      }
    }
  }
}
    ${LineItemFragmentDoc}
${TicketFragmentDoc}
${NamingFieldsFragmentDoc}
${VenueAddressFragmentDoc}
${EventBaseFragmentDoc}
${EventInfoFragmentDoc}
${MembershipBaseFragmentDoc}
${MembershipInfoFragmentDoc}`;
export const PartialAddressFragmentDoc = gql`
    fragment PartialAddress on PartialAddress {
  line1
  line2
  suburb
  postalCode
  city
  state
  country
}
    `;
export const OrderUserFragmentDoc = gql`
    fragment OrderUser on Order {
  user {
    id
    referralId
    birthDate
    credits
    fullName
    firstName
    lastName
    email
    phoneNumber
    points
    billingAddress {
      ...PartialAddress
    }
    shippingAddress {
      ...PartialAddress
    }
  }
  buyerInformation {
    firstName
    lastName
    email
    phoneNumber
    taxNumber
    postalCode
    city
    country
  }
  billingAddress {
    ...Address
  }
  shippingAddress {
    ...Address
  }
  shippingAddressMatchesBillingAddress
}
    ${PartialAddressFragmentDoc}
${AddressFragmentDoc}`;
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  ...OrderBase
  ...OrderInfo
  ...OrderFinance
  ...OrderItems
  ...OrderUser
}
    ${OrderBaseFragmentDoc}
${OrderInfoFragmentDoc}
${OrderFinanceFragmentDoc}
${OrderItemsFragmentDoc}
${OrderUserFragmentDoc}`;
export const CheckoutOrderFragmentDoc = gql`
    fragment CheckoutOrder on Order {
  ...Order
  event {
    id
    startDate
    endDate
    eventKind
    title
    canRenameTicket
    sellerTransferringTicketFee
    canTransferTicket
    ticketsViewableFromDate
    hasPromoCodes
    redirectURL
    canUsePoints
    paymentPlanSettings {
      disabledAt
      scheduleOptions {
        id
        interval
        installmentCount
        availableUntil
        minimumOrderAmount
        stripeFeePercentage
      }
    }
    venue {
      seating
      name
      ...VenueAddress
      zones {
        name
        stand
      }
      gates {
        name
        id
      }
    }
    zonesAllocatedGates {
      name
    }
    gateways {
      name
      id
      transactionFeeType
      transactionFee
      transactionFeeType2
      transactionFee2
      type
      publicKey
      paymentMethodType
      bnplEnabled
      bnplTransactionFeePercent
    }
    shippingOptions {
      ...ShippingOptions
    }
    ticketTypes {
      dates {
        alternateEntryTime
      }
    }
    multiBuyPromotions {
      id
      name
      getQuantity
      getTicketType {
        id
        name
        description
        restrictions
        kind
        isGrouped
        groupAmount
        dates {
          alternateEntryTime
        }
        bookingFeeType
        bookingFee
      }
      buyQuantity
      buyTicketType {
        id
        name
        description
        restrictions
        kind
        isGrouped
        groupAmount
        dates {
          alternateEntryTime
        }
        bookingFeeType
        bookingFee
      }
      price
      enabled
    }
    customFields {
      id
      label
      value
      targetArea
    }
  }
  membership {
    name
    canUsePoints
    enableAutoRenew
    membershipKind
    hasPromoCodes
    showSchedule
    isTicketed
    paymentPlanSettings {
      disabledAt
      scheduleOptions {
        id
        interval
        installmentCount
        availableUntil
        minimumOrderAmount
        stripeFeePercentage
      }
    }
    shippingOptions {
      ...ShippingOptions
    }
    gateways {
      name
      id
      transactionFeeType
      transactionFee
      transactionFeeType2
      transactionFee2
      type
      publicKey
      bnplEnabled
      bnplTransactionFeePercent
    }
    venue {
      name
      seating
      ...VenueAddress
    }
    multiBuyPromotions {
      id
      name
      getQuantity
      getMembershipType {
        id
        name
        description
        restrictions
        kind
      }
      buyQuantity
      buyMembershipType {
        id
        name
        description
        restrictions
        kind
      }
      price
      enabled
    }
  }
}
    ${OrderFragmentDoc}
${VenueAddressFragmentDoc}
${ShippingOptionsFragmentDoc}`;
export const ReferralStatsFragmentFragmentDoc = gql`
    fragment ReferralStatsFragment on ReferralCustomerReporting {
  referralsMade
  eventName
  orderDate
  orderNumber
  referralLink
  eventLogo {
    fileName
    storage
    originalFileName
    directory
    metaData {
      height
      width
    }
  }
}
    `;
export const ReleaseBaseFragmentDoc = gql`
    fragment ReleaseBase on Release {
  id
  createdAt
  updatedAt
}
    `;
export const ReleaseInfoFragmentDoc = gql`
    fragment ReleaseInfo on Release {
  name
  slug
  type
  presaleTitle
  presaleLogo {
    ...File
  }
  presaleHeader {
    ...File
  }
  presaleInformation
  releaseNotes
  releaseEventAddons {
    eventAddon {
      id
      name
      enableNaming
      price
      description
      restrictions
      isActive
      addonAvailabilityStatus
      maxPurchaseQuantity
      addonImage {
        ...File
      }
    }
  }
}
    ${FileFragmentDoc}`;
export const ReleaseZonesFragmentDoc = gql`
    fragment ReleaseZones on ReleaseZone {
  zone {
    name
  }
  pricingLevel
  isActive
  ticketTypes {
    price
    isActive
    ticketTypeId
    sections {
      name
      price
    }
    isSoldOut
    minPurchaseQuantity
    maxPurchaseQuantity
  }
}
    `;
export const ReleaseOptionsFragmentDoc = gql`
    fragment ReleaseOptions on Release {
  startCondition
  startConditionDate
  endCondition
  endConditionQuantity
  endConditionDate
  isActive
  askHowDidYouHearAboutUs
  askForDietaryRequirements
  allowPublicComp
  allowAccount2Account
  releaseZones {
    ...ReleaseZones
  }
}
    ${ReleaseZonesFragmentDoc}`;
export const ReleaseFragmentDoc = gql`
    fragment Release on Release {
  ...ReleaseBase
  ...ReleaseInfo
  ...ReleaseOptions
}
    ${ReleaseBaseFragmentDoc}
${ReleaseInfoFragmentDoc}
${ReleaseOptionsFragmentDoc}`;
export const SeasonFragmentFragmentDoc = gql`
    fragment SeasonFragment on Season {
  id
  name
  isActive
  startDate
  description
  endDate
  events {
    id
    title
    startDate
    eventKind
    isActive
  }
  thumbnail {
    ...File
  }
  backgroundImageFullPage {
    ...File
  }
  updatedAt
  createdAt
  displayOrder
}
    ${FileFragmentDoc}`;
export const SeasonBaseFragmentDoc = gql`
    fragment SeasonBase on Season {
  id
  createdAt
  updatedAt
}
    `;
export const SeasonInfoFragmentDoc = gql`
    fragment SeasonInfo on Season {
  name
  isActive
  startDate
  endDate
}
    `;
export const SeasonFragmentDoc = gql`
    fragment Season on Season {
  ...SeasonBase
  ...SeasonInfo
}
    ${SeasonBaseFragmentDoc}
${SeasonInfoFragmentDoc}`;
export const TicketTypeBaseFragmentDoc = gql`
    fragment TicketTypeBase on TicketType {
  id
  createdAt
  updatedAt
}
    `;
export const TicketTypeInfoFragmentDoc = gql`
    fragment TicketTypeInfo on TicketType {
  id
  name
  dates {
    alternateEntryTime
  }
  description
  restrictions
  kind
  quantitySet
  namingFields {
    ...namingFields
  }
  ticketImage {
    ...File
  }
  inHighDemand
  soldOut
  isResaleTicket
  resaleHiddenFromPublic
  quantity
}
    ${NamingFieldsFragmentDoc}
${FileFragmentDoc}`;
export const FinanceFragmentDoc = gql`
    fragment Finance on TicketType {
  isGrouped
  groupAmount
  bookingFeeType
  bookingFee
  stadiumLevyFee
  kind
}
    `;
export const TicketTypeFragmentDoc = gql`
    fragment TicketType on TicketType {
  ...TicketTypeBase
  ...TicketTypeInfo
  ...Finance
}
    ${TicketTypeBaseFragmentDoc}
${TicketTypeInfoFragmentDoc}
${FinanceFragmentDoc}`;
export const UserBaseFragmentDoc = gql`
    fragment UserBase on User {
  id
  createdAt
  updatedAt
}
    `;
export const NameFragmentDoc = gql`
    fragment Name on User {
  firstName
  lastName
  fullName
}
    `;
export const PersonalFragmentDoc = gql`
    fragment Personal on User {
  birthDate
  phoneNumber
  gender
  usableForMarketing
  credits
  points
  taxNumber
  companyName
  instagramUsername
  referralId
}
    `;
export const BillingAndShippingFragmentDoc = gql`
    fragment BillingAndShipping on User {
  billingAddress {
    ...PartialAddress
  }
  shippingAddress {
    ...PartialAddress
  }
  shippingAddressMatchesBillingAddress
}
    ${PartialAddressFragmentDoc}`;
export const CredentialsFragmentDoc = gql`
    fragment Credentials on User {
  email
  roles
  hasPassword
  permissions
  isTwoFactorAuthenticationEnabled
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  ...UserBase
  ...Name
  ...Personal
  ...BillingAndShipping
  ...Credentials
}
    ${UserBaseFragmentDoc}
${NameFragmentDoc}
${PersonalFragmentDoc}
${BillingAndShippingFragmentDoc}
${CredentialsFragmentDoc}`;
export const VenueBaseFragmentDoc = gql`
    fragment VenueBase on Venue {
  id
  createdAt
  updatedAt
}
    `;
export const VenueInfoFragmentDoc = gql`
    fragment VenueInfo on Venue {
  name
  capacity
  accessibilityInfo
  filterOptions
}
    `;
export const SeatsIoFragmentDoc = gql`
    fragment SeatsIO on Venue {
  seatsChartKey
  zones {
    name
    sections {
      name
    }
    stand
  }
  seating
  ...VenueAddress
}
    ${VenueAddressFragmentDoc}`;
export const VenueFragmentDoc = gql`
    fragment Venue on Venue {
  ...VenueBase
  ...VenueInfo
  ...VenueAddress
  ...SeatsIO
}
    ${VenueBaseFragmentDoc}
${VenueInfoFragmentDoc}
${VenueAddressFragmentDoc}
${SeatsIoFragmentDoc}`;
export const TicketNamingFieldFragmentDoc = gql`
    fragment TicketNamingField on Ticket {
  membershipType {
    name
    kind
    membership {
      id
      isTicketed
      canNameMembership
      canRenameMembership
      membershipKind
      endDate
      renewalMembership {
        id
      }
    }
    namingFields {
      ...namingFields
    }
  }
  ticketType {
    id
    kind
    name
    dates {
      eventDateId
      alternateEntryTime
    }
    namingFields {
      ...namingFields
    }
  }
}
    ${NamingFieldsFragmentDoc}`;
export const ActiveCompetitionByTypeDocument = gql`
    query activeCompetitionByType($input: ActiveCompetitionByTypeInput!) {
  activeCompetitionByType(input: $input) {
    ...CompetitionFragment
  }
}
    ${CompetitionFragmentFragmentDoc}`;
export const ActiveCompetitionsDocument = gql`
    query activeCompetitions($eventId: String!) {
  activeCompetitions(eventId: $eventId) {
    ...CompetitionFragment
  }
}
    ${CompetitionFragmentFragmentDoc}`;
export const CompetitionByIdDocument = gql`
    query competitionById($id: String!) {
  competition(id: $id) {
    ...CompetitionFragment
  }
}
    ${CompetitionFragmentFragmentDoc}`;
export const GetEventAndReleaseForCustomerDocument = gql`
    query getEventAndReleaseForCustomer($input: EventsWithAccessControlInput!) {
  getEventAndReleaseForCustomer(input: $input) {
    event {
      ...Event
      venue {
        ...Venue
      }
      addons {
        id
      }
      ticketTypes {
        ...TicketTypeBase
        ...TicketTypeInfo
        ...Finance
        imageGallery {
          ...ImageGalleryFragment
        }
      }
      hasPublicRelease
      paymentPlanSettings {
        disabledAt
      }
    }
    release {
      id
      name
      type
      slug
      isActive
      requiresAuth
      status {
        value
        message
      }
      minPurchaseQuantity
      maxPurchaseQuantity
      isResaleRelease
      event {
        ...Event
        venue {
          ...Venue
        }
        ticketTypes {
          ...TicketTypeBase
          ...TicketTypeInfo
        }
        hasPublicRelease
        multiBuyPromotions {
          id
          name
          getQuantity
          getTicketType {
            id
            name
            description
            restrictions
            kind
            isGrouped
            groupAmount
            dates {
              alternateEntryTime
            }
            bookingFeeType
            bookingFee
          }
          buyQuantity
          buyTicketType {
            id
            name
            description
            restrictions
            kind
            isGrouped
            groupAmount
            dates {
              alternateEntryTime
            }
            bookingFeeType
            bookingFee
          }
          price
          enabled
        }
      }
      presaleTitle
      presaleHeader {
        ...File
      }
      presaleLogo {
        ...File
      }
      presaleInformation
      releaseNotes
      allowPublicComp
      releaseZones {
        id
        zone {
          id
          name
          description
          displayOrder
          imageGallery {
            ...ImageGalleryFragment
          }
        }
        isActive
        isSoldOut
        pricingLevel
        ticketTypes {
          price
          sections {
            name
            price
          }
          isActive
          ticketTypeId
          isSoldOut
          minPurchaseQuantity
          maxPurchaseQuantity
          ticketAvailabilityStatus
          purchaseRestrictions {
            id
            quantity
            requiredEntities {
              requiredType
              requiredId
            }
            isExclusive
            isAlwaysVisible
          }
          purchaseRestrictionsHelpText
        }
      }
      releaseEventAddons {
        eventAddon {
          id
          name
          enableNaming
          price
          description
          restrictions
          isActive
          quantity
          addonAvailabilityStatus
          maxPurchaseQuantity
          addonImage {
            ...File
          }
          purchaseRestrictions {
            id
            quantity
            requiredEntities {
              requiredType
              requiredId
            }
            isExclusive
            isAlwaysVisible
          }
          purchaseRestrictionsHelpText
          imageGallery {
            ...ImageGalleryFragment
          }
        }
      }
      channels {
        id
        label
        isEnabled
      }
    }
    isAdmin
    accessType
  }
}
    ${EventFragmentDoc}
${VenueFragmentDoc}
${TicketTypeBaseFragmentDoc}
${TicketTypeInfoFragmentDoc}
${FinanceFragmentDoc}
${ImageGalleryFragmentFragmentDoc}
${FileFragmentDoc}`;
export const UserEntriesDocument = gql`
    query userEntries($input: GetUserEntriesInput!) {
  userEntries(input: $input) {
    platform
    actionType
    value
  }
}
    `;
export const GrantCompetitionRewardDocument = gql`
    mutation grantCompetitionReward($input: GrantCompetitionRewardInput!) {
  grantCompetitionReward(input: $input) {
    platform
    actionType
    value
  }
}
    `;
export const JoinResaleWaitlistDocument = gql`
    mutation joinResaleWaitlist($input: ResaleWaitlistInput!) {
  joinResaleWaitlist(input: $input)
}
    `;
export const MembershipsOverviewDocument = gql`
    query membershipsOverview($before: String, $after: String, $first: Int, $last: Int) {
  memberships(before: $before, after: $after, first: $first, last: $last) {
    edges {
      node {
        ...MembershipBase
        name
        membershipKind
        startDate
        endDate
        thumbnail {
          ...File
        }
        events {
          id
          venueAddress {
            name
            address {
              city
            }
          }
        }
        membershipZones {
          id
          zone {
            id
            name
          }
          pricingLevel
          isActive
          membershipTypes {
            price
            sections {
              name
              price
            }
            isActive
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${MembershipBaseFragmentDoc}
${FileFragmentDoc}`;
export const PointDocument = gql`
    query point {
  point {
    ...point
  }
}
    ${PointFragmentDoc}`;
export const PointItemDocument = gql`
    query pointItem($id: String!) {
  pointItem(id: $id) {
    ...pointItem
  }
}
    ${PointItemFragmentDoc}`;
export const PointItemsDocument = gql`
    query pointItems {
  pointItems {
    ...pointItem
  }
}
    ${PointItemFragmentDoc}`;
export const GetReferralCampaignByReferralCodeDocument = gql`
    query getReferralCampaignByReferralCode($referralCode: String!) {
  getReferralCampaignByReferralCode(referralCode: $referralCode) {
    id
    isActive
    referralRelease {
      id
    }
    referralOwnerRewardType
    referralOwnerRewardQuantityType
    referralOwnerRewardQuantity
    referralUserRewardType
    referralUserRewardQuantityType
    referralUserRewardQuantity
    event {
      id
    }
  }
}
    `;
export const AddTransactionDocument = gql`
    mutation addTransaction($id: String!, $input: AddPosTransactionInput!) {
  addPosTransaction(id: $id, input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const OnTrackingCodeVisitDocument = gql`
    mutation onTrackingCodeVisit($input: TrackingCodeVisitInput!) {
  onTrackingCodeVisit(input: $input)
}
    `;
export const AddUnsubscribeReasonDocument = gql`
    mutation addUnsubscribeReason($input: AddUnsubscribeReasonInput!) {
  addUnsubscribeReason(input: $input) {
    id
  }
}
    `;
export const ApplyPromoCodeDocument = gql`
    mutation applyPromoCode($input: ApplyPromoCode!, $id: String!) {
  applyPromoCodeToOrder(input: $input, id: $id) {
    id
    ...PromoCode
  }
}
    ${PromoCodeFragmentDoc}`;
export const AssignTwoFactorAuthenticationSecretDocument = gql`
    mutation assignTwoFactorAuthenticationSecret {
  assignTwoFactorAuthenticationSecret {
    dataURL
    secret
  }
}
    `;
export const AuthenticateWithTwoFactorDocument = gql`
    mutation authenticateWithTwoFactor($input: TwoFactorAuthenticationCodeInput!) {
  authenticateWithTwoFactor(input: $input) {
    id
  }
}
    `;
export const BroadcastReportingLinkOpenedDocument = gql`
    mutation broadcastReportingLinkOpened($input: BroadcastReportingInputBase!) {
  broadcastReportingLinkOpened(input: $input)
}
    `;
export const BroadcastReportingOrderCreatedDocument = gql`
    mutation broadcastReportingOrderCreated($input: BroadcastReportingOrderInput!) {
  broadcastReportingOrderCreated(input: $input)
}
    `;
export const BroadcastReportingSetAsSpamFromUserDocument = gql`
    mutation broadcastReportingSetAsSpamFromUser($input: BroadcastReportingInputBase!) {
  broadcastReportingSetAsSpamFromUser(input: $input)
}
    `;
export const CancelOrderDocument = gql`
    mutation cancelOrder($id: String!) {
  cancelOrder(id: $id) {
    id
  }
}
    `;
export const CancelPendingTicketTransferDocument = gql`
    mutation cancelPendingTicketTransfer($ticketId: String!) {
  cancelPendingTicketTransfer(ticketId: $ticketId)
}
    `;
export const CreateOrderDocument = gql`
    mutation createOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    jobId
    order {
      ...Order
      event {
        title
      }
      membership {
        name
      }
    }
    createOrderError {
      ... on TicketNotAvailableError {
        __typename
        message
        shouldDisplayWaitlist
        eventId
        releaseId
      }
      ... on CreateOrderError {
        __typename
        message
      }
    }
  }
}
    ${OrderFragmentDoc}`;
export const CreateOrderToTrackingCodeAssociationDocument = gql`
    mutation createOrderToTrackingCodeAssociation($input: TrackingCodeOrderInput!) {
  createOrderToTrackingCodeAssociation(input: $input)
}
    `;
export const CreatePaymentDocument = gql`
    mutation createPayment($id: String!, $input: CreatePaymentInput!) {
  createPayment(id: $id, input: $input) {
    result {
      __typename
      ... on PaymentSuccess {
        gateway
        redirectTo
      }
      ... on PaymentError {
        code
        message
      }
    }
  }
}
    `;
export const CreatePinPaymentChargeDocument = gql`
    mutation createPinPaymentCharge($input: CreatePinPaymentCharge!) {
  createPinPaymentCharge(input: $input) {
    redirectUrl
  }
}
    `;
export const CreateRedirectUrlDocument = gql`
    mutation createRedirectUrl($input: CreateRedirectUrlInput!) {
  createRedirectUrl(input: $input) {
    redirectURL
  }
}
    `;
export const DeleteSelfDocument = gql`
    mutation deleteSelf {
  deleteSelf {
    deletedId
  }
}
    `;
export const EnableTwoFactorAuthenticationDocument = gql`
    mutation enableTwoFactorAuthentication($input: TwoFactorAuthenticationCodeInput!) {
  enableTwoFactorAuthentication(input: $input)
}
    `;
export const ExpireOrderDocument = gql`
    mutation expireOrder($id: String!) {
  expireOrder(id: $id) {
    id
  }
}
    `;
export const FinishPosTransactionDocument = gql`
    mutation finishPosTransaction($id: String!, $autoScan: Boolean) {
  finishPosTransaction(id: $id, autoScan: $autoScan) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const HoldBestAvailableSeatsDocument = gql`
    mutation holdBestAvailableSeats($id: String!, $input: HoldBestAvailableSeatsInput!) {
  holdBestAvailableSeats(id: $id, input: $input) {
    objects
  }
}
    `;
export const HoldCustomerOrderDocument = gql`
    mutation holdCustomerOrder($id: String!) {
  holdCustomerOrder(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const IncreaseDownloadCountDocument = gql`
    mutation increaseDownloadCount($id: String!) {
  increaseDownloadCount(id: $id)
}
    `;
export const JoinWaitlistDocument = gql`
    mutation joinWaitlist($input: JoinWaitlistInput!) {
  joinWaitlist(input: $input) {
    id
  }
}
    `;
export const ListTicketForResaleDocument = gql`
    mutation listTicketForResale($ticketId: String!) {
  listTicketForResale(ticketId: $ticketId)
}
    `;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    id
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export const RegisterDocument = gql`
    mutation register($input: RegisterInput!) {
  register(input: $input)
}
    `;
export const RemovePromoCodeDocument = gql`
    mutation removePromoCode($id: String!) {
  removePromoCodeFromOrder(id: $id) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const RemoveTransactionDocument = gql`
    mutation removeTransaction($id: String!, $transactionId: String!) {
  removePosTransaction(id: $id, posTransactionId: $transactionId) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const RemoveTwoFactorAuthenticationSecretDocument = gql`
    mutation removeTwoFactorAuthenticationSecret {
  removeTwoFactorAuthenticationSecret
}
    `;
export const RequestDeleteAccountDocument = gql`
    mutation requestDeleteAccount($input: RequestMagicLinkInput!) {
  requestDeleteAccount(input: $input)
}
    `;
export const RequestMagicLinkDocument = gql`
    mutation requestMagicLink($input: RequestMagicLinkInput!) {
  requestMagicLink(input: $input)
}
    `;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($input: RequestResetPasswordInput!) {
  requestResetPassword(input: $input)
}
    `;
export const RequestTicketTransferDocument = gql`
    mutation requestTicketTransfer($input: RequestTicketTransferInput!) {
  requestTicketTransfer(input: $input)
}
    `;
export const ResetPasswordDocument = gql`
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input)
}
    `;
export const ResubscribeDocument = gql`
    mutation resubscribe($userId: String!) {
  resubscribe(userId: $userId) {
    id
  }
}
    `;
export const SendOrderConfirmationDocument = gql`
    mutation sendOrderConfirmation($id: String!, $input: SendOrderConfirmationInput!) {
  sendOrderConfirmation(id: $id, input: $input) {
    email
  }
}
    `;
export const SendTwoFactorCodeViaSmsMessageDocument = gql`
    mutation sendTwoFactorCodeViaSMSMessage {
  sendTwoFactorCodeViaSMSMessage
}
    `;
export const SocialAuthDocument = gql`
    mutation socialAuth($input: SocialAuthInput!) {
  socialAuth(input: $input) {
    id
  }
}
    `;
export const SplitGroupOrderDocument = gql`
    mutation splitGroupOrder($id: String!, $input: SplitOrderInput!) {
  splitGroupOrder(id: $id, input: $input) {
    id
  }
}
    `;
export const UnlistTicketForResaleDocument = gql`
    mutation unlistTicketForResale($ticketId: String!) {
  unlistTicketForResale(ticketId: $ticketId)
}
    `;
export const UnsubscribeFromMarketingDocument = gql`
    mutation unsubscribeFromMarketing($userId: String!) {
  unsubscribeFromMarketing(userId: $userId) {
    id
  }
}
    `;
export const UpdateOrderDocument = gql`
    mutation updateOrder($id: String!, $input: UpdateOrderInput!) {
  updateOrder(id: $id, input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const UpdateOrderStatusDocument = gql`
    mutation updateOrderStatus($id: String!, $input: UpdateOrderStatusInput!) {
  updateOrderStatus(id: $id, input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordInput!) {
  updatePassword(input: $input)
}
    `;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput!) {
  updateProfile(input: $input) {
    id
  }
}
    `;
export const UpdateTicketCoverForOrderDocument = gql`
    mutation updateTicketCoverForOrder($id: String!, $input: UpdateOrderTicketCoverInput!) {
  updateTicketCoverForOrder(id: $id, input: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`;
export const UpdateTicketHolderDocument = gql`
    mutation updateTicketHolder($id: String!, $input: UpdateTicketHolderInput!) {
  updateTicketHolder(id: $id, input: $input) {
    id
  }
}
    `;
export const ValidateReleasePasswordDocument = gql`
    mutation validateReleasePassword($id: String!, $password: String!) {
  validateReleasePassword(id: $id, password: $password) {
    id
    quantityPaid
    quantityComp
    totalUsedPaid
    totalUsedComp
  }
}
    `;
export const ValidateReleasePasswordOrReleaseDocument = gql`
    mutation validateReleasePasswordOrRelease($eventId: String!, $password: String!) {
  validateReleasePasswordOrRelease(eventId: $eventId, password: $password) {
    id
    slug
    password
  }
}
    `;
export const VerifyMagicLinkDocument = gql`
    mutation verifyMagicLink($input: VerifyEmailInput!) {
  verifyMagicLink(input: $input) {
    redirectUrl
  }
}
    `;
export const VerifyPinPayment3DsDocument = gql`
    mutation verifyPinPayment3DS($input: VerifyPinPayment3DS!) {
  verifyPinPayment3DS(input: $input) {
    redirectUrl
  }
}
    `;
export const VerifyTokenDocument = gql`
    mutation verifyToken($input: VerifyEmailInput!) {
  verifyToken(input: $input) {
    redirectUrl
  }
}
    `;
export const GetPromoterSettingsDocument = gql`
    query getPromoterSettings($eventId: String!) {
  getPromoterSettings(eventId: $eventId) {
    configurations {
      platform
      actionType
      socialLinkType
    }
    socialPages {
      name
      isEnabled
      url
    }
  }
}
    `;
export const CalculateTicketCoverAmountsDocument = gql`
    query calculateTicketCoverAmounts($id: String!) {
  calculateTicketCoverAmounts(id: $id) {
    totalFeeAmount
  }
}
    `;
export const CurrentOrganizationDocument = gql`
    query currentOrganization {
  currentOrganization {
    id
    name
    slug
    showMemberships
    showMembershipRenewal
    landingPage
    privacyPolicy
    termsAndConditions
    supportInfo
    currency
    taxRate
    address {
      country
    }
    shippingLabels {
      eTicket
      printedTicketByMail
      printedTicketByCourier
      printedTicketByCourierRural
      printedTicketAtVenue
    }
    marketing {
      gtmId
      pixelId
    }
    shopify {
      shopDomain
      storefrontAccessToken
    }
    branding {
      logo {
        ...File
      }
      mobileLogo {
        ...File
      }
      favicon {
        ...File
      }
      background {
        ...File
      }
      fullBackground {
        ...File
      }
      greyScale
      P300
      P200
      P100
      S300
      S200
      S100
      N800
      N700
      N600
      N500
      N400
      N300
      N200
      N100
    }
    features
    timezone
    locale
    helpLink
    point {
      name
      isEnabled
    }
    defaultI18nLanguage
    defaultLanguage
    customTaxName
  }
}
    ${FileFragmentDoc}`;
export const EventDocument = gql`
    query event($id: String!) {
  event(id: $id) {
    ...Event
    venue {
      ...Venue
    }
    ticketTypes {
      ...TicketTypeBase
      ...TicketTypeInfo
      ...Finance
    }
    hasPublicRelease
  }
}
    ${EventFragmentDoc}
${VenueFragmentDoc}
${TicketTypeBaseFragmentDoc}
${TicketTypeInfoFragmentDoc}
${FinanceFragmentDoc}`;
export const EventWithGatesDocument = gql`
    query eventWithGates($id: String!) {
  event(id: $id) {
    title
    venue {
      id
    }
    zonesAllocatedGates {
      name
      gates
      sections {
        name
        gates
      }
    }
  }
}
    `;
export const EventWithTicketTypeFilterDocument = gql`
    query eventWithTicketTypeFilter($id: String!, $ticketTypeFilter: TicketTypeFilter = ALL) {
  event(id: $id) {
    ...Event
    venue {
      ...Venue
    }
    ticketTypes(ticketTypeFilter: $ticketTypeFilter) {
      ...TicketTypeBase
      ...TicketTypeInfo
      ...Finance
      resaleTicketTypes {
        id
      }
    }
    hasPublicRelease
  }
}
    ${EventFragmentDoc}
${VenueFragmentDoc}
${TicketTypeBaseFragmentDoc}
${TicketTypeInfoFragmentDoc}
${FinanceFragmentDoc}`;
export const EventsDocument = gql`
    query events($before: String, $after: String, $first: Int, $last: Int, $where: EventWhereInput, $orderBy: EventOrderByInput) {
  events(before: $before, after: $after, first: $first, last: $last, where: $where, orderBy: $orderBy) {
    edges {
      node {
        ...EventBase
        ...EventInfo
        venue {
          ...VenueBase
          ...VenueInfo
          ...VenueAddress
        }
        hasPublicRelease
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    ${EventBaseFragmentDoc}
${EventInfoFragmentDoc}
${VenueBaseFragmentDoc}
${VenueInfoFragmentDoc}
${VenueAddressFragmentDoc}`;
export const FindOrderIdForTicketDocument = gql`
    query findOrderIdForTicket($ticketId: String!) {
  findOrderIdForTicket(ticketId: $ticketId) {
    orderId
  }
}
    `;
export const GetCompetitionReferralReportForCustomerDocument = gql`
    query getCompetitionReferralReportForCustomer($input: CustomerCompetitionReportingInput!) {
  getCompetitionReferralReportForCustomer(input: $input) {
    ...CompetitionStatsFragment
  }
}
    ${CompetitionStatsFragmentFragmentDoc}`;
export const GetEventPurchaseRestrictionsDocument = gql`
    query getEventPurchaseRestrictions($eventId: String!) {
  getEventPurchaseRestrictions(eventId: $eventId) {
    id
    restrictedType
    restrictedId
    requiredEntities {
      requiredId
      requiredType
    }
    quantity
    isExclusive
    isAlwaysVisible
    createdAt
    updatedAt
  }
}
    `;
export const GetI18nDocument = gql`
    query getI18n {
  getI18n {
    data
  }
}
    `;
export const GetMembershipSubscriptionDocument = gql`
    query getMembershipSubscription($membershipSubscriptionId: String!) {
  getMembershipSubscription(membershipSubscriptionId: $membershipSubscriptionId) {
    billingDetails {
      emailAddress
      cardLast4
      cardBrand
      cardExpiryMonth
      cardExpiryYear
    }
  }
}
    `;
export const GetOrderJobStatusDocument = gql`
    query getOrderJobStatus($jobId: String!) {
  getOrderJobStatus(jobId: $jobId) {
    jobId
    order {
      ...Order
      event {
        title
      }
      membership {
        name
      }
    }
    createOrderError {
      ... on TicketNotAvailableError {
        __typename
        message
        shouldDisplayWaitlist
        eventId
        releaseId
      }
      ... on CreateOrderError {
        __typename
        message
      }
    }
  }
}
    ${OrderFragmentDoc}`;
export const GetOrderPlanDocument = gql`
    query getOrderPlan($orderId: String!) {
  getOrderPlan(orderId: $orderId) {
    orderId
    schedule {
      interval
      installmentCount
    }
    status
    payments {
      id
      amountDue
      dueAt
      paidAt
      refundedAt
      status
      stripeInvoiceUrl
      failureReason
      attemptCount
    }
    billingDetails {
      cardLast4
      cardBrand
      cardExpiryMonth
      cardExpiryYear
      totalFeeAmount
    }
  }
}
    `;
export const GetPaymentOptionsDocument = gql`
    query getPaymentOptions($id: String!) {
  getPaymentOptions(id: $id) {
    orderId
    options {
      id
      value
      label
      type
      paymentMethod
      description
      order
      transactionFee
      transactionFeeType
      transactionFee2
      transactionFeeType2
      bnplEnabled
      calculatedTransactionFee
      paymentPlanMetadata {
        depositAmount
        installmentAmount
        installmentCount
        paymentPlanFeePercentage
      }
    }
  }
}
    `;
export const GetRedirectUrlByCodeDocument = gql`
    query getRedirectUrlByCode($input: RedirectCodeInput!) {
  getRedirectUrlByCode(input: $input) {
    redirectURL
  }
}
    `;
export const GetReferralCampaignByEventIdDocument = gql`
    query getReferralCampaignByEventId($id: String!) {
  getReferralCampaignByEventId(id: $id) {
    id
    isActive
    referralRelease {
      id
    }
    referralOwnerRewardType
    referralOwnerRewardQuantityType
    referralOwnerRewardQuantity
    referralUserRewardType
    referralUserRewardQuantityType
    referralUserRewardQuantity
  }
}
    `;
export const GetReferralCampaignByOrderIdDocument = gql`
    query getReferralCampaignByOrderId($id: String!) {
  getReferralCampaignByOrderId(id: $id) {
    id
    isActive
    referralRelease {
      id
    }
    referralOwnerRewardType
    referralOwnerRewardQuantityType
    referralOwnerRewardQuantity
    referralUserRewardType
    referralUserRewardQuantityType
    referralUserRewardQuantity
  }
}
    `;
export const GetReferralCampaignsDocument = gql`
    query getReferralCampaigns {
  getReferralCampaigns {
    id
    isActive
    event {
      id
    }
  }
}
    `;
export const GetReferralReportForCustomerDocument = gql`
    query getReferralReportForCustomer($input: CustomerReferralReportingInput!) {
  getReferralReportForCustomer(input: $input) {
    ...ReferralStatsFragment
  }
}
    ${ReferralStatsFragmentFragmentDoc}`;
export const GetReferralRewardByOrderIdDocument = gql`
    query getReferralRewardByOrderId($id: String!) {
  getReferralRewardByOrderId(id: $id) {
    id
    referralCampaign {
      id
    }
    rewardType
    rewardQuantityType
    rewardQuantity
  }
}
    `;
export const GetReleaseByIdDocument = gql`
    query getReleaseById($id: String!) {
  release(id: $id) {
    id
    name
    type
    slug
    isActive
    status {
      value
      message
    }
    requiresAuth
    minPurchaseQuantity
    maxPurchaseQuantity
    event {
      ...Event
      venue {
        ...Venue
      }
      ticketTypes {
        ...TicketTypeBase
        ...TicketTypeInfo
      }
      hasPublicRelease
      multiBuyPromotions {
        id
        name
        getQuantity
        getTicketType {
          id
          name
          description
          restrictions
          kind
          isGrouped
          groupAmount
          dates {
            alternateEntryTime
          }
          bookingFeeType
          bookingFee
        }
        buyQuantity
        buyTicketType {
          id
          name
          description
          restrictions
          kind
          isGrouped
          groupAmount
          dates {
            alternateEntryTime
          }
          bookingFeeType
          bookingFee
        }
        price
        enabled
      }
    }
    presaleTitle
    presaleHeader {
      ...File
    }
    presaleLogo {
      ...File
    }
    presaleInformation
    releaseNotes
    allowPublicComp
    releaseZones {
      zone {
        name
        description
        displayOrder
      }
      isActive
      pricingLevel
      ticketTypes {
        price
        sections {
          name
          price
        }
        isActive
        ticketTypeId
        isSoldOut
        minPurchaseQuantity
        maxPurchaseQuantity
        ticketAvailabilityStatus
        purchaseRestrictions {
          id
          quantity
          requiredEntities {
            requiredType
            requiredId
          }
          isExclusive
          isAlwaysVisible
        }
        purchaseRestrictionsHelpText
      }
    }
    releaseEventAddons {
      eventAddon {
        id
        name
        enableNaming
        price
        description
        restrictions
        isActive
        addonAvailabilityStatus
        maxPurchaseQuantity
        addonImage {
          ...File
        }
        purchaseRestrictions {
          id
          quantity
          requiredEntities {
            requiredType
            requiredId
          }
          isExclusive
          isAlwaysVisible
        }
        purchaseRestrictionsHelpText
      }
    }
    isResaleRelease
    channels {
      id
      label
      isEnabled
    }
  }
}
    ${EventFragmentDoc}
${VenueFragmentDoc}
${TicketTypeBaseFragmentDoc}
${TicketTypeInfoFragmentDoc}
${FileFragmentDoc}`;
export const GetReleaseBySlugDocument = gql`
    query getReleaseBySlug($slug: String, $eventId: String!) {
  getReleaseBySlug(slug: $slug, eventId: $eventId) {
    id
    name
    type
    slug
    isActive
    status {
      value
      message
    }
    requiresAuth
    minPurchaseQuantity
    maxPurchaseQuantity
    event {
      ...Event
      venue {
        ...Venue
      }
      ticketTypes {
        ...TicketTypeBase
        ...TicketTypeInfo
      }
      hasPublicRelease
      multiBuyPromotions {
        id
        name
        getQuantity
        getTicketType {
          id
          name
          description
          restrictions
          kind
          isGrouped
          groupAmount
          dates {
            alternateEntryTime
          }
          bookingFeeType
          bookingFee
        }
        buyQuantity
        buyTicketType {
          id
          name
          description
          restrictions
          kind
          isGrouped
          groupAmount
          dates {
            alternateEntryTime
          }
          bookingFeeType
          bookingFee
        }
        price
        enabled
      }
    }
    presaleTitle
    presaleHeader {
      ...File
    }
    presaleLogo {
      ...File
    }
    presaleInformation
    releaseNotes
    allowPublicComp
    releaseZones {
      zone {
        name
        description
        displayOrder
      }
      isActive
      pricingLevel
      ticketTypes {
        price
        sections {
          name
          price
        }
        isActive
        ticketTypeId
        isSoldOut
        minPurchaseQuantity
        maxPurchaseQuantity
        ticketAvailabilityStatus
        purchaseRestrictions {
          id
          quantity
          requiredEntities {
            requiredType
            requiredId
          }
          isExclusive
          isAlwaysVisible
        }
        purchaseRestrictionsHelpText
      }
    }
    releaseEventAddons {
      eventAddon {
        id
        name
        enableNaming
        price
        description
        restrictions
        isActive
        addonAvailabilityStatus
        maxPurchaseQuantity
        addonImage {
          ...File
        }
        purchaseRestrictions {
          id
          quantity
          requiredEntities {
            requiredType
            requiredId
          }
          isExclusive
          isAlwaysVisible
        }
        purchaseRestrictionsHelpText
      }
    }
    isResaleRelease
    channels {
      id
      label
      isEnabled
    }
  }
}
    ${EventFragmentDoc}
${VenueFragmentDoc}
${TicketTypeBaseFragmentDoc}
${TicketTypeInfoFragmentDoc}
${FileFragmentDoc}`;
export const GetSubscriptionPortalUrlForOrderDocument = gql`
    query getSubscriptionPortalUrlForOrder($orderId: String!) {
  getSubscriptionPortalUrlForOrder(orderId: $orderId) {
    url
  }
}
    `;
export const GetSubscriptionPortalUrlForTicketDocument = gql`
    query getSubscriptionPortalUrlForTicket($ticketId: String!) {
  getSubscriptionPortalUrlForTicket(ticketId: $ticketId) {
    url
  }
}
    `;
export const GetTicketsByIdsDocument = gql`
    query getTicketsByIds($ids: String!) {
  getTicketsByIds(ids: $ids) {
    ...Ticket
    status
    addon {
      ... on MembershipAddon {
        id
        name
        enableNaming
      }
      ... on EventAddon {
        id
        name
        enableNaming
      }
    }
    lineItem {
      ...LineItem
    }
    parentTicket {
      firstName
      lastName
      id
      membershipType {
        membership {
          canRenameMembership
          name
        }
      }
    }
    ticketType {
      name
      dates {
        alternateEntryTime
      }
    }
    membershipType {
      membership {
        name
      }
    }
    event {
      title
      startDate
      endDate
      canChangeSeats
      changingSeatsFee
      canRenameTicket
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      zonesAllocatedGates {
        name
      }
    }
    shouldBeNamed
  }
}
    ${TicketFragmentDoc}
${LineItemFragmentDoc}
${VenueAddressFragmentDoc}`;
export const HandleInstagramCodeDocument = gql`
    mutation handleInstagramCode($input: HandleInstagramCodeInput!) {
  handleInstagramCode(input: $input)
}
    `;
export const HasMembershipToRenewDocument = gql`
    query hasMembershipToRenew {
  myMembershipTickets {
    hasRenewedMembership
    membershipType {
      name
      membership {
        name
        isActive
        id
        renewalMembership {
          id
        }
      }
    }
  }
}
    `;
export const IntegrationsDocument = gql`
    query integrations($input: IntegrationWhereInput) {
  integrations(input: $input) {
    ...IntegrationFragment
  }
}
    ${IntegrationFragmentFragmentDoc}`;
export const IsLoggedInDocument = gql`
    query isLoggedIn {
  isLoggedIn
}
    `;
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const MembershipDocument = gql`
    query membership($id: String!) {
  membership(id: $id) {
    ...Membership
    paymentPlanSettings {
      disabledAt
      scheduleOptions {
        id
        interval
        installmentCount
        stripeFeePercentage
        promoterFeePercentage
        flicketFeePercentage
        minimumOrderAmount
        finalPaymentDate
      }
    }
    venue {
      ...Venue
    }
    events {
      id
      eventKind
      seatsEventKey
      title
      startDate
      venue {
        name
        ...VenueAddress
      }
      printBanner1 {
        ...File
      }
      description
      hasPublicRelease
    }
    membershipZones {
      id
      zone {
        id
        name
        description
        displayOrder
      }
      pricingLevel
      isActive
      membershipTypes {
        membershipTypeId
        price
        sections {
          name
          price
        }
        isActive
        purchaseRestrictions {
          id
          quantity
          requiredEntities {
            requiredType
            requiredId
          }
          isExclusive
          isAlwaysVisible
        }
        purchaseRestrictionsHelpText
      }
    }
  }
}
    ${MembershipFragmentDoc}
${VenueFragmentDoc}
${VenueAddressFragmentDoc}
${FileFragmentDoc}`;
export const MembershipsDocument = gql`
    query memberships($before: String, $after: String, $first: Int, $last: Int, $where: MembershipWhereInput, $orderBy: MembershipOrderByInput) {
  memberships(before: $before, after: $after, first: $first, last: $last, where: $where, orderBy: $orderBy) {
    edges {
      node {
        ...MembershipBase
        ...MembershipInfo
        venue {
          ...VenueInfo
          ...VenueAddress
        }
        events {
          id
          venueAddress {
            name
            address {
              city
            }
          }
        }
        membershipZones {
          id
          zone {
            id
            name
          }
          pricingLevel
          isActive
          membershipTypes {
            price
            sections {
              name
              price
            }
            isActive
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${MembershipBaseFragmentDoc}
${MembershipInfoFragmentDoc}
${VenueInfoFragmentDoc}
${VenueAddressFragmentDoc}`;
export const MembershipsSoldForUserDocument = gql`
    query membershipsSoldForUser($membershipId: String!) {
  membershipsSoldForUser(membershipId: $membershipId) {
    membershipType {
      id
    }
    total
  }
}
    `;
export const MyEventTicketsDocument = gql`
    query myEventTickets($id: String!) {
  myEventTickets(eventId: $id) {
    ...Ticket
    status
    addon {
      ... on MembershipAddon {
        id
        name
        enableNaming
        namingFields {
          ...namingFields
        }
      }
      ... on EventAddon {
        id
        name
        enableNaming
        hideQRCode
        namingFields {
          ...namingFields
        }
      }
    }
    lineItem {
      ...LineItem
    }
    parentTicket {
      firstName
      lastName
      id
      membershipType {
        membership {
          canRenameMembership
          name
          showSchedule
          membershipKind
        }
      }
    }
    ticketType {
      id
      kind
      name
      dates {
        eventDateId
        alternateEntryTime
      }
      namingFields {
        ...namingFields
      }
    }
    membershipType {
      name
      membership {
        membershipKind
        name
        isTicketed
      }
      namingFields {
        ...namingFields
      }
    }
    event {
      id
      title
      dates {
        id
        startDate
        endDate
      }
      startDate
      endDate
      eventKind
      canChangeSeats
      changingSeatsFee
      canRenameTicket
      ticketResaleEnabled
      sellerTransferringTicketFee
      canTransferTicket
      ticketsViewableFromDate
      isR18
      backgroundImage {
        ...File
      }
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      zonesAllocatedGates {
        name
        dates {
          eventDateId
          alternateEntryTime
        }
      }
    }
    membership {
      name
      membershipKind
      startDate
      endDate
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
    }
  }
}
    ${TicketFragmentDoc}
${NamingFieldsFragmentDoc}
${LineItemFragmentDoc}
${FileFragmentDoc}
${VenueAddressFragmentDoc}`;
export const MyEventsDocument = gql`
    query myEvents($where: MyEventsWhereInput, $orderBy: MyEventsOrderByInput) {
  myEvents(where: $where, orderBy: $orderBy) {
    ...EventBase
    ...EventInfo
    venue {
      name
      ...VenueAddress
    }
  }
}
    ${EventBaseFragmentDoc}
${EventInfoFragmentDoc}
${VenueAddressFragmentDoc}`;
export const MyMembershipAddonsDocument = gql`
    query myMembershipAddons($membershipId: String!) {
  myMembershipAddons(membershipId: $membershipId) {
    id
    isNamed
    firstName
    lastName
    parentTicket {
      firstName
      lastName
      id
      membershipType {
        membership {
          name
        }
      }
    }
    membership {
      name
      startDate
      endDate
      venue {
        name
        ...VenueAddress
      }
    }
    membershipType {
      name
      membership {
        name
        membershipKind
      }
    }
    lineItem {
      ...LineItem
    }
    addon {
      ... on MembershipAddon {
        id
        enableNaming
        name
        namingFields {
          ...namingFields
        }
      }
      ... on EventAddon {
        id
        enableNaming
        name
        namingFields {
          ...namingFields
        }
      }
    }
    ...Ticket
    parentTicket {
      id
      firstName
      lastName
    }
    ticketType {
      name
      dates {
        eventDateId
        alternateEntryTime
      }
      namingFields {
        ...namingFields
      }
    }
    event {
      id
      title
      dates {
        id
        startDate
        endDate
      }
      eventKind
      startDate
      endDate
      canChangeSeats
      changingSeatsFee
      canRenameTicket
      sellerTransferringTicketFee
      canTransferTicket
      ticketsViewableFromDate
      isR18
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      zonesAllocatedGates {
        name
        dates {
          eventDateId
          alternateEntryTime
        }
      }
    }
  }
}
    ${VenueAddressFragmentDoc}
${LineItemFragmentDoc}
${NamingFieldsFragmentDoc}
${TicketFragmentDoc}`;
export const MyMembershipHoldOrdersDocument = gql`
    query myMembershipHoldOrders {
  myMembershipHoldOrders {
    id
    total
    orderNumber
    membership {
      name
    }
  }
}
    `;
export const MyMembershipTicketsDocument = gql`
    query myMembershipTickets {
  myMembershipTickets {
    ...Ticket
    status
    lineItem {
      ...LineItem
    }
    membership {
      endDate
    }
    hasRenewedMembership
    membershipType {
      name
      membership {
        membershipKind
        canRenameMembership
        showSchedule
        name
        thumbnail {
          ...File
        }
        isActive
        id
        renewalMembership {
          id
        }
      }
      namingFields {
        ...namingFields
      }
    }
    event {
      eventKind
      thumbnail {
        ...File
      }
      venue {
        name
        ...VenueAddress
      }
    }
    shouldBeNamed
  }
}
    ${TicketFragmentDoc}
${LineItemFragmentDoc}
${FileFragmentDoc}
${NamingFieldsFragmentDoc}
${VenueAddressFragmentDoc}`;
export const MyPaymentPlanTicketsDocument = gql`
    query myPaymentPlanTickets {
  myPaymentPlanTickets {
    orderId
    ticketCount
    membershipId
    eventId
  }
}
    `;
export const OrderDocument = gql`
    query order($id: String!) {
  order(id: $id) {
    ...CheckoutOrder
  }
}
    ${CheckoutOrderFragmentDoc}`;
export const MyOrdersDocument = gql`
    query myOrders($where: OrderWhereInput) {
  myOrders(where: $where) {
    edges {
      node {
        ...OrderBase
        ...OrderInfo
      }
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
  }
}
    ${OrderBaseFragmentDoc}
${OrderInfoFragmentDoc}`;
export const PinPaymentContextDocument = gql`
    query pinPaymentContext($id: String!) {
  pinPaymentContext(id: $id) {
    amount
    currency
    redirectUrl
    publicKey
    isSandbox
    orderId
  }
}
    `;
export const PollOrderDocument = gql`
    query pollOrder($id: String!) {
  pollOrder(id: $id) {
    ...CheckoutOrder
  }
}
    ${CheckoutOrderFragmentDoc}`;
export const GetPosDocument = gql`
    query getPOS($id: String!) {
  pos(id: $id) {
    id
    name
    createdAt
    updatedAt
    paymentOptions {
      cash {
        feeType
        feeAmount
      }
      eftpos {
        feeType
        feeAmount
      }
      cc {
        feeType
        feeAmount
      }
      MPAiSA {
        feeType
        feeAmount
      }
    }
    deliveryOptions
    event {
      title
      id
      startDate
      endDate
      venue {
        name
        ...VenueAddress
      }
    }
    release {
      name
      id
    }
  }
}
    ${VenueAddressFragmentDoc}`;
export const ReferralUrlByOrderIdDocument = gql`
    query referralUrlByOrderId($orderId: String!) {
  referralUrlByOrderId(orderId: $orderId)
}
    `;
export const ReferralUrlByWaitlistUserIdDocument = gql`
    query referralUrlByWaitlistUserId($eventId: String!, $waitlistUserId: String!) {
  referralUrlByWaitlistUserId(eventId: $eventId, waitlistUserId: $waitlistUserId)
}
    `;
export const ReportSpamMembershipDocument = gql`
    query reportSpamMembership($id: String!) {
  membership(id: $id) {
    id
    name
  }
}
    `;
export const ResaleTicketTypesForWaitlistDocument = gql`
    query resaleTicketTypesForWaitlist($eventId: String!, $releaseId: String) {
  resaleTicketTypesForWaitlist(eventId: $eventId, releaseId: $releaseId) {
    id
    name
  }
}
    `;
export const SeasonsDocument = gql`
    query seasons {
  seasons {
    ...SeasonFragment
  }
}
    ${SeasonFragmentFragmentDoc}`;
export const SingleEventDocument = gql`
    query singleEvent {
  currentOrganization {
    singleEvent {
      ...EventBase
      ...EventInfo
      logo {
        ...File
      }
      venue {
        ...VenueBase
        ...VenueInfo
        ...VenueAddress
      }
      hasPublicRelease
    }
  }
}
    ${EventBaseFragmentDoc}
${EventInfoFragmentDoc}
${FileFragmentDoc}
${VenueBaseFragmentDoc}
${VenueInfoFragmentDoc}
${VenueAddressFragmentDoc}`;
export const StripePaymentContextDocument = gql`
    query stripePaymentContext($id: String!) {
  stripePaymentContext(id: $id) {
    redirectUrl
    checkoutUrl
    orderId
    stripeAccountId
    publishableKey
    clientSecret
  }
}
    `;
export const TicketDocument = gql`
    query ticket($id: String!) {
  ticket(id: $id) {
    ...Ticket
    childTickets {
      ...Ticket
      ...TicketNamingField
      transferStatus
      lineItem {
        ...LineItem
      }
      event {
        title
        startDate
        endDate
        dates {
          id
          startDate
          endDate
        }
        venue {
          name
          ...VenueAddress
          gates {
            name
          }
        }
        zonesAllocatedGates {
          name
          dates {
            eventDateId
            alternateEntryTime
          }
        }
      }
    }
    resellabilityState {
      ticketResaleAllowed
      ticketTransferAllowed
      latestTransferCompletionDate
      reason
    }
    transferStatus
    shouldBeNamed
    purchaseRestrictionText
    maxTransferPrice
    lineItem {
      type
      name
    }
    addon {
      __typename
      ... on EventAddon {
        name
        id
        namingFields {
          ...namingFields
        }
      }
      ... on MembershipAddon {
        id
        name
        namingFields {
          ...namingFields
        }
      }
    }
    membershipSubscriptionId
    ...TicketNamingField
    event {
      title
      eventKind
      startDate
      endDate
      canRenameTicket
      ticketsViewableFromDate
      sellerTransferringTicketFee
      canTransferTicket
      isR18
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      zonesAllocatedGates {
        name
        dates {
          eventDateId
          alternateEntryTime
        }
      }
    }
    membership {
      name
      membershipKind
      canNameMembership
      canRenameMembership
      startDate
      backgroundImage {
        ...File
      }
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
    }
  }
}
    ${TicketFragmentDoc}
${TicketNamingFieldFragmentDoc}
${LineItemFragmentDoc}
${VenueAddressFragmentDoc}
${NamingFieldsFragmentDoc}
${FileFragmentDoc}`;
export const TicketDetailsForBackOfficeOrdersDocument = gql`
    query ticketDetailsForBackOfficeOrders($eventId: String, $membershipId: String) {
  ticketDetailsForBackOfficeOrders(eventId: $eventId, membershipId: $membershipId)
}
    `;
export const TicketGatesDocument = gql`
    query ticketGates($id: String!) {
  ticket(id: $id) {
    gates {
      id
      name
    }
  }
}
    `;
export const SendTicketDocument = gql`
    mutation sendTicket($ticketIds: [String!]!, $email: String!) {
  sendTicket(ticketIds: $ticketIds, email: $email)
}
    `;
export const GetSentTicketsByIdDocument = gql`
    query getSentTicketsById($sendId: String!) {
  getSentTicketsById(sendId: $sendId) {
    ...Ticket
    lineItem {
      ...LineItem
    }
    addon {
      ... on EventAddon {
        id
        createdAt
        updatedAt
        name
        enableNaming
        hideQRCode
      }
      ... on MembershipAddon {
        id
        createdAt
        updatedAt
        name
        enableNaming
      }
    }
    transferStatus
    purchaseRestrictionText
    event {
      title
      startDate
      endDate
      canChangeSeats
      changingSeatsFee
      canRenameTicket
      venue {
        name
        ...VenueAddress
        zones {
          name
          stand
        }
        gates {
          name
          id
        }
      }
      zonesAllocatedGates {
        name
      }
    }
    ...TicketNamingField
  }
}
    ${TicketFragmentDoc}
${LineItemFragmentDoc}
${VenueAddressFragmentDoc}
${TicketNamingFieldFragmentDoc}`;
export const TicketsSoldForUserDocument = gql`
    query ticketsSoldForUser($eventId: String!) {
  ticketsSoldForUser(eventId: $eventId) {
    ticketType {
      id
    }
    total
  }
}
    `;
export const UserDocument = gql`
    query user($id: String!) {
  user(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const VenueSeatsThumbnailDocument = gql`
    query venueSeatsThumbnail($id: String!) {
  venueSeatsThumbnail(id: $id)
}
    `;
export const VenueWithGatesDocument = gql`
    query venueWithGates($id: String!) {
  venue(id: $id) {
    name
    gates {
      id
      name
    }
  }
}
    `;
export const WindcavePaymentContextDocument = gql`
    query windcavePaymentContext($id: String!) {
  windcavePaymentContext(id: $id) {
    redirectUrl
    checkoutUrl
    orderId
  }
}
    `;
export const WindcavePaymentResultDocument = gql`
    query windcavePaymentResult($externalId: String!, $result: String!) {
  windcavePaymentResult(externalId: $externalId, result: $result) {
    redirectUrl
  }
}
    `;
export const GetXeroConfigurationQueryDocument = gql`
    query getXeroConfigurationQuery {
  getXeroConfiguration {
    accountCode
    sendFlicketInvoice
    invoiceStatus
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    activeCompetitionByType(variables: ActiveCompetitionByTypeQueryVariables): Promise<ActiveCompetitionByTypeQuery> {
      return withWrapper(() => client.request<ActiveCompetitionByTypeQuery>(print(ActiveCompetitionByTypeDocument), variables));
    },
    activeCompetitions(variables: ActiveCompetitionsQueryVariables): Promise<ActiveCompetitionsQuery> {
      return withWrapper(() => client.request<ActiveCompetitionsQuery>(print(ActiveCompetitionsDocument), variables));
    },
    competitionById(variables: CompetitionByIdQueryVariables): Promise<CompetitionByIdQuery> {
      return withWrapper(() => client.request<CompetitionByIdQuery>(print(CompetitionByIdDocument), variables));
    },
    getEventAndReleaseForCustomer(variables: GetEventAndReleaseForCustomerQueryVariables): Promise<GetEventAndReleaseForCustomerQuery> {
      return withWrapper(() => client.request<GetEventAndReleaseForCustomerQuery>(print(GetEventAndReleaseForCustomerDocument), variables));
    },
    userEntries(variables: UserEntriesQueryVariables): Promise<UserEntriesQuery> {
      return withWrapper(() => client.request<UserEntriesQuery>(print(UserEntriesDocument), variables));
    },
    grantCompetitionReward(variables: GrantCompetitionRewardMutationVariables): Promise<GrantCompetitionRewardMutation> {
      return withWrapper(() => client.request<GrantCompetitionRewardMutation>(print(GrantCompetitionRewardDocument), variables));
    },
    joinResaleWaitlist(variables: JoinResaleWaitlistMutationVariables): Promise<JoinResaleWaitlistMutation> {
      return withWrapper(() => client.request<JoinResaleWaitlistMutation>(print(JoinResaleWaitlistDocument), variables));
    },
    membershipsOverview(variables?: MembershipsOverviewQueryVariables): Promise<MembershipsOverviewQuery> {
      return withWrapper(() => client.request<MembershipsOverviewQuery>(print(MembershipsOverviewDocument), variables));
    },
    point(variables?: PointQueryVariables): Promise<PointQuery> {
      return withWrapper(() => client.request<PointQuery>(print(PointDocument), variables));
    },
    pointItem(variables: PointItemQueryVariables): Promise<PointItemQuery> {
      return withWrapper(() => client.request<PointItemQuery>(print(PointItemDocument), variables));
    },
    pointItems(variables?: PointItemsQueryVariables): Promise<PointItemsQuery> {
      return withWrapper(() => client.request<PointItemsQuery>(print(PointItemsDocument), variables));
    },
    getReferralCampaignByReferralCode(variables: GetReferralCampaignByReferralCodeQueryVariables): Promise<GetReferralCampaignByReferralCodeQuery> {
      return withWrapper(() => client.request<GetReferralCampaignByReferralCodeQuery>(print(GetReferralCampaignByReferralCodeDocument), variables));
    },
    addTransaction(variables: AddTransactionMutationVariables): Promise<AddTransactionMutation> {
      return withWrapper(() => client.request<AddTransactionMutation>(print(AddTransactionDocument), variables));
    },
    onTrackingCodeVisit(variables: OnTrackingCodeVisitMutationVariables): Promise<OnTrackingCodeVisitMutation> {
      return withWrapper(() => client.request<OnTrackingCodeVisitMutation>(print(OnTrackingCodeVisitDocument), variables));
    },
    addUnsubscribeReason(variables: AddUnsubscribeReasonMutationVariables): Promise<AddUnsubscribeReasonMutation> {
      return withWrapper(() => client.request<AddUnsubscribeReasonMutation>(print(AddUnsubscribeReasonDocument), variables));
    },
    applyPromoCode(variables: ApplyPromoCodeMutationVariables): Promise<ApplyPromoCodeMutation> {
      return withWrapper(() => client.request<ApplyPromoCodeMutation>(print(ApplyPromoCodeDocument), variables));
    },
    assignTwoFactorAuthenticationSecret(variables?: AssignTwoFactorAuthenticationSecretMutationVariables): Promise<AssignTwoFactorAuthenticationSecretMutation> {
      return withWrapper(() => client.request<AssignTwoFactorAuthenticationSecretMutation>(print(AssignTwoFactorAuthenticationSecretDocument), variables));
    },
    authenticateWithTwoFactor(variables: AuthenticateWithTwoFactorMutationVariables): Promise<AuthenticateWithTwoFactorMutation> {
      return withWrapper(() => client.request<AuthenticateWithTwoFactorMutation>(print(AuthenticateWithTwoFactorDocument), variables));
    },
    broadcastReportingLinkOpened(variables: BroadcastReportingLinkOpenedMutationVariables): Promise<BroadcastReportingLinkOpenedMutation> {
      return withWrapper(() => client.request<BroadcastReportingLinkOpenedMutation>(print(BroadcastReportingLinkOpenedDocument), variables));
    },
    broadcastReportingOrderCreated(variables: BroadcastReportingOrderCreatedMutationVariables): Promise<BroadcastReportingOrderCreatedMutation> {
      return withWrapper(() => client.request<BroadcastReportingOrderCreatedMutation>(print(BroadcastReportingOrderCreatedDocument), variables));
    },
    broadcastReportingSetAsSpamFromUser(variables: BroadcastReportingSetAsSpamFromUserMutationVariables): Promise<BroadcastReportingSetAsSpamFromUserMutation> {
      return withWrapper(() => client.request<BroadcastReportingSetAsSpamFromUserMutation>(print(BroadcastReportingSetAsSpamFromUserDocument), variables));
    },
    cancelOrder(variables: CancelOrderMutationVariables): Promise<CancelOrderMutation> {
      return withWrapper(() => client.request<CancelOrderMutation>(print(CancelOrderDocument), variables));
    },
    cancelPendingTicketTransfer(variables: CancelPendingTicketTransferMutationVariables): Promise<CancelPendingTicketTransferMutation> {
      return withWrapper(() => client.request<CancelPendingTicketTransferMutation>(print(CancelPendingTicketTransferDocument), variables));
    },
    createOrder(variables: CreateOrderMutationVariables): Promise<CreateOrderMutation> {
      return withWrapper(() => client.request<CreateOrderMutation>(print(CreateOrderDocument), variables));
    },
    createOrderToTrackingCodeAssociation(variables: CreateOrderToTrackingCodeAssociationMutationVariables): Promise<CreateOrderToTrackingCodeAssociationMutation> {
      return withWrapper(() => client.request<CreateOrderToTrackingCodeAssociationMutation>(print(CreateOrderToTrackingCodeAssociationDocument), variables));
    },
    createPayment(variables: CreatePaymentMutationVariables): Promise<CreatePaymentMutation> {
      return withWrapper(() => client.request<CreatePaymentMutation>(print(CreatePaymentDocument), variables));
    },
    createPinPaymentCharge(variables: CreatePinPaymentChargeMutationVariables): Promise<CreatePinPaymentChargeMutation> {
      return withWrapper(() => client.request<CreatePinPaymentChargeMutation>(print(CreatePinPaymentChargeDocument), variables));
    },
    createRedirectUrl(variables: CreateRedirectUrlMutationVariables): Promise<CreateRedirectUrlMutation> {
      return withWrapper(() => client.request<CreateRedirectUrlMutation>(print(CreateRedirectUrlDocument), variables));
    },
    deleteSelf(variables?: DeleteSelfMutationVariables): Promise<DeleteSelfMutation> {
      return withWrapper(() => client.request<DeleteSelfMutation>(print(DeleteSelfDocument), variables));
    },
    enableTwoFactorAuthentication(variables: EnableTwoFactorAuthenticationMutationVariables): Promise<EnableTwoFactorAuthenticationMutation> {
      return withWrapper(() => client.request<EnableTwoFactorAuthenticationMutation>(print(EnableTwoFactorAuthenticationDocument), variables));
    },
    expireOrder(variables: ExpireOrderMutationVariables): Promise<ExpireOrderMutation> {
      return withWrapper(() => client.request<ExpireOrderMutation>(print(ExpireOrderDocument), variables));
    },
    finishPosTransaction(variables: FinishPosTransactionMutationVariables): Promise<FinishPosTransactionMutation> {
      return withWrapper(() => client.request<FinishPosTransactionMutation>(print(FinishPosTransactionDocument), variables));
    },
    holdBestAvailableSeats(variables: HoldBestAvailableSeatsMutationVariables): Promise<HoldBestAvailableSeatsMutation> {
      return withWrapper(() => client.request<HoldBestAvailableSeatsMutation>(print(HoldBestAvailableSeatsDocument), variables));
    },
    holdCustomerOrder(variables: HoldCustomerOrderMutationVariables): Promise<HoldCustomerOrderMutation> {
      return withWrapper(() => client.request<HoldCustomerOrderMutation>(print(HoldCustomerOrderDocument), variables));
    },
    increaseDownloadCount(variables: IncreaseDownloadCountMutationVariables): Promise<IncreaseDownloadCountMutation> {
      return withWrapper(() => client.request<IncreaseDownloadCountMutation>(print(IncreaseDownloadCountDocument), variables));
    },
    joinWaitlist(variables: JoinWaitlistMutationVariables): Promise<JoinWaitlistMutation> {
      return withWrapper(() => client.request<JoinWaitlistMutation>(print(JoinWaitlistDocument), variables));
    },
    listTicketForResale(variables: ListTicketForResaleMutationVariables): Promise<ListTicketForResaleMutation> {
      return withWrapper(() => client.request<ListTicketForResaleMutation>(print(ListTicketForResaleDocument), variables));
    },
    login(variables: LoginMutationVariables): Promise<LoginMutation> {
      return withWrapper(() => client.request<LoginMutation>(print(LoginDocument), variables));
    },
    logout(variables?: LogoutMutationVariables): Promise<LogoutMutation> {
      return withWrapper(() => client.request<LogoutMutation>(print(LogoutDocument), variables));
    },
    register(variables: RegisterMutationVariables): Promise<RegisterMutation> {
      return withWrapper(() => client.request<RegisterMutation>(print(RegisterDocument), variables));
    },
    removePromoCode(variables: RemovePromoCodeMutationVariables): Promise<RemovePromoCodeMutation> {
      return withWrapper(() => client.request<RemovePromoCodeMutation>(print(RemovePromoCodeDocument), variables));
    },
    removeTransaction(variables: RemoveTransactionMutationVariables): Promise<RemoveTransactionMutation> {
      return withWrapper(() => client.request<RemoveTransactionMutation>(print(RemoveTransactionDocument), variables));
    },
    removeTwoFactorAuthenticationSecret(variables?: RemoveTwoFactorAuthenticationSecretMutationVariables): Promise<RemoveTwoFactorAuthenticationSecretMutation> {
      return withWrapper(() => client.request<RemoveTwoFactorAuthenticationSecretMutation>(print(RemoveTwoFactorAuthenticationSecretDocument), variables));
    },
    requestDeleteAccount(variables: RequestDeleteAccountMutationVariables): Promise<RequestDeleteAccountMutation> {
      return withWrapper(() => client.request<RequestDeleteAccountMutation>(print(RequestDeleteAccountDocument), variables));
    },
    requestMagicLink(variables: RequestMagicLinkMutationVariables): Promise<RequestMagicLinkMutation> {
      return withWrapper(() => client.request<RequestMagicLinkMutation>(print(RequestMagicLinkDocument), variables));
    },
    requestResetPassword(variables: RequestResetPasswordMutationVariables): Promise<RequestResetPasswordMutation> {
      return withWrapper(() => client.request<RequestResetPasswordMutation>(print(RequestResetPasswordDocument), variables));
    },
    requestTicketTransfer(variables: RequestTicketTransferMutationVariables): Promise<RequestTicketTransferMutation> {
      return withWrapper(() => client.request<RequestTicketTransferMutation>(print(RequestTicketTransferDocument), variables));
    },
    resetPassword(variables: ResetPasswordMutationVariables): Promise<ResetPasswordMutation> {
      return withWrapper(() => client.request<ResetPasswordMutation>(print(ResetPasswordDocument), variables));
    },
    resubscribe(variables: ResubscribeMutationVariables): Promise<ResubscribeMutation> {
      return withWrapper(() => client.request<ResubscribeMutation>(print(ResubscribeDocument), variables));
    },
    sendOrderConfirmation(variables: SendOrderConfirmationMutationVariables): Promise<SendOrderConfirmationMutation> {
      return withWrapper(() => client.request<SendOrderConfirmationMutation>(print(SendOrderConfirmationDocument), variables));
    },
    sendTwoFactorCodeViaSMSMessage(variables?: SendTwoFactorCodeViaSmsMessageMutationVariables): Promise<SendTwoFactorCodeViaSmsMessageMutation> {
      return withWrapper(() => client.request<SendTwoFactorCodeViaSmsMessageMutation>(print(SendTwoFactorCodeViaSmsMessageDocument), variables));
    },
    socialAuth(variables: SocialAuthMutationVariables): Promise<SocialAuthMutation> {
      return withWrapper(() => client.request<SocialAuthMutation>(print(SocialAuthDocument), variables));
    },
    splitGroupOrder(variables: SplitGroupOrderMutationVariables): Promise<SplitGroupOrderMutation> {
      return withWrapper(() => client.request<SplitGroupOrderMutation>(print(SplitGroupOrderDocument), variables));
    },
    unlistTicketForResale(variables: UnlistTicketForResaleMutationVariables): Promise<UnlistTicketForResaleMutation> {
      return withWrapper(() => client.request<UnlistTicketForResaleMutation>(print(UnlistTicketForResaleDocument), variables));
    },
    unsubscribeFromMarketing(variables: UnsubscribeFromMarketingMutationVariables): Promise<UnsubscribeFromMarketingMutation> {
      return withWrapper(() => client.request<UnsubscribeFromMarketingMutation>(print(UnsubscribeFromMarketingDocument), variables));
    },
    updateOrder(variables: UpdateOrderMutationVariables): Promise<UpdateOrderMutation> {
      return withWrapper(() => client.request<UpdateOrderMutation>(print(UpdateOrderDocument), variables));
    },
    updateOrderStatus(variables: UpdateOrderStatusMutationVariables): Promise<UpdateOrderStatusMutation> {
      return withWrapper(() => client.request<UpdateOrderStatusMutation>(print(UpdateOrderStatusDocument), variables));
    },
    updatePassword(variables: UpdatePasswordMutationVariables): Promise<UpdatePasswordMutation> {
      return withWrapper(() => client.request<UpdatePasswordMutation>(print(UpdatePasswordDocument), variables));
    },
    updateProfile(variables: UpdateProfileMutationVariables): Promise<UpdateProfileMutation> {
      return withWrapper(() => client.request<UpdateProfileMutation>(print(UpdateProfileDocument), variables));
    },
    updateTicketCoverForOrder(variables: UpdateTicketCoverForOrderMutationVariables): Promise<UpdateTicketCoverForOrderMutation> {
      return withWrapper(() => client.request<UpdateTicketCoverForOrderMutation>(print(UpdateTicketCoverForOrderDocument), variables));
    },
    updateTicketHolder(variables: UpdateTicketHolderMutationVariables): Promise<UpdateTicketHolderMutation> {
      return withWrapper(() => client.request<UpdateTicketHolderMutation>(print(UpdateTicketHolderDocument), variables));
    },
    validateReleasePassword(variables: ValidateReleasePasswordMutationVariables): Promise<ValidateReleasePasswordMutation> {
      return withWrapper(() => client.request<ValidateReleasePasswordMutation>(print(ValidateReleasePasswordDocument), variables));
    },
    validateReleasePasswordOrRelease(variables: ValidateReleasePasswordOrReleaseMutationVariables): Promise<ValidateReleasePasswordOrReleaseMutation> {
      return withWrapper(() => client.request<ValidateReleasePasswordOrReleaseMutation>(print(ValidateReleasePasswordOrReleaseDocument), variables));
    },
    verifyMagicLink(variables: VerifyMagicLinkMutationVariables): Promise<VerifyMagicLinkMutation> {
      return withWrapper(() => client.request<VerifyMagicLinkMutation>(print(VerifyMagicLinkDocument), variables));
    },
    verifyPinPayment3DS(variables: VerifyPinPayment3DsMutationVariables): Promise<VerifyPinPayment3DsMutation> {
      return withWrapper(() => client.request<VerifyPinPayment3DsMutation>(print(VerifyPinPayment3DsDocument), variables));
    },
    verifyToken(variables: VerifyTokenMutationVariables): Promise<VerifyTokenMutation> {
      return withWrapper(() => client.request<VerifyTokenMutation>(print(VerifyTokenDocument), variables));
    },
    getPromoterSettings(variables: GetPromoterSettingsQueryVariables): Promise<GetPromoterSettingsQuery> {
      return withWrapper(() => client.request<GetPromoterSettingsQuery>(print(GetPromoterSettingsDocument), variables));
    },
    calculateTicketCoverAmounts(variables: CalculateTicketCoverAmountsQueryVariables): Promise<CalculateTicketCoverAmountsQuery> {
      return withWrapper(() => client.request<CalculateTicketCoverAmountsQuery>(print(CalculateTicketCoverAmountsDocument), variables));
    },
    currentOrganization(variables?: CurrentOrganizationQueryVariables): Promise<CurrentOrganizationQuery> {
      return withWrapper(() => client.request<CurrentOrganizationQuery>(print(CurrentOrganizationDocument), variables));
    },
    event(variables: EventQueryVariables): Promise<EventQuery> {
      return withWrapper(() => client.request<EventQuery>(print(EventDocument), variables));
    },
    eventWithGates(variables: EventWithGatesQueryVariables): Promise<EventWithGatesQuery> {
      return withWrapper(() => client.request<EventWithGatesQuery>(print(EventWithGatesDocument), variables));
    },
    eventWithTicketTypeFilter(variables: EventWithTicketTypeFilterQueryVariables): Promise<EventWithTicketTypeFilterQuery> {
      return withWrapper(() => client.request<EventWithTicketTypeFilterQuery>(print(EventWithTicketTypeFilterDocument), variables));
    },
    events(variables?: EventsQueryVariables): Promise<EventsQuery> {
      return withWrapper(() => client.request<EventsQuery>(print(EventsDocument), variables));
    },
    findOrderIdForTicket(variables: FindOrderIdForTicketQueryVariables): Promise<FindOrderIdForTicketQuery> {
      return withWrapper(() => client.request<FindOrderIdForTicketQuery>(print(FindOrderIdForTicketDocument), variables));
    },
    getCompetitionReferralReportForCustomer(variables: GetCompetitionReferralReportForCustomerQueryVariables): Promise<GetCompetitionReferralReportForCustomerQuery> {
      return withWrapper(() => client.request<GetCompetitionReferralReportForCustomerQuery>(print(GetCompetitionReferralReportForCustomerDocument), variables));
    },
    getEventPurchaseRestrictions(variables: GetEventPurchaseRestrictionsQueryVariables): Promise<GetEventPurchaseRestrictionsQuery> {
      return withWrapper(() => client.request<GetEventPurchaseRestrictionsQuery>(print(GetEventPurchaseRestrictionsDocument), variables));
    },
    getI18n(variables?: GetI18nQueryVariables): Promise<GetI18nQuery> {
      return withWrapper(() => client.request<GetI18nQuery>(print(GetI18nDocument), variables));
    },
    getMembershipSubscription(variables: GetMembershipSubscriptionQueryVariables): Promise<GetMembershipSubscriptionQuery> {
      return withWrapper(() => client.request<GetMembershipSubscriptionQuery>(print(GetMembershipSubscriptionDocument), variables));
    },
    getOrderJobStatus(variables: GetOrderJobStatusQueryVariables): Promise<GetOrderJobStatusQuery> {
      return withWrapper(() => client.request<GetOrderJobStatusQuery>(print(GetOrderJobStatusDocument), variables));
    },
    getOrderPlan(variables: GetOrderPlanQueryVariables): Promise<GetOrderPlanQuery> {
      return withWrapper(() => client.request<GetOrderPlanQuery>(print(GetOrderPlanDocument), variables));
    },
    getPaymentOptions(variables: GetPaymentOptionsQueryVariables): Promise<GetPaymentOptionsQuery> {
      return withWrapper(() => client.request<GetPaymentOptionsQuery>(print(GetPaymentOptionsDocument), variables));
    },
    getRedirectUrlByCode(variables: GetRedirectUrlByCodeQueryVariables): Promise<GetRedirectUrlByCodeQuery> {
      return withWrapper(() => client.request<GetRedirectUrlByCodeQuery>(print(GetRedirectUrlByCodeDocument), variables));
    },
    getReferralCampaignByEventId(variables: GetReferralCampaignByEventIdQueryVariables): Promise<GetReferralCampaignByEventIdQuery> {
      return withWrapper(() => client.request<GetReferralCampaignByEventIdQuery>(print(GetReferralCampaignByEventIdDocument), variables));
    },
    getReferralCampaignByOrderId(variables: GetReferralCampaignByOrderIdQueryVariables): Promise<GetReferralCampaignByOrderIdQuery> {
      return withWrapper(() => client.request<GetReferralCampaignByOrderIdQuery>(print(GetReferralCampaignByOrderIdDocument), variables));
    },
    getReferralCampaigns(variables?: GetReferralCampaignsQueryVariables): Promise<GetReferralCampaignsQuery> {
      return withWrapper(() => client.request<GetReferralCampaignsQuery>(print(GetReferralCampaignsDocument), variables));
    },
    getReferralReportForCustomer(variables: GetReferralReportForCustomerQueryVariables): Promise<GetReferralReportForCustomerQuery> {
      return withWrapper(() => client.request<GetReferralReportForCustomerQuery>(print(GetReferralReportForCustomerDocument), variables));
    },
    getReferralRewardByOrderId(variables: GetReferralRewardByOrderIdQueryVariables): Promise<GetReferralRewardByOrderIdQuery> {
      return withWrapper(() => client.request<GetReferralRewardByOrderIdQuery>(print(GetReferralRewardByOrderIdDocument), variables));
    },
    getReleaseById(variables: GetReleaseByIdQueryVariables): Promise<GetReleaseByIdQuery> {
      return withWrapper(() => client.request<GetReleaseByIdQuery>(print(GetReleaseByIdDocument), variables));
    },
    getReleaseBySlug(variables: GetReleaseBySlugQueryVariables): Promise<GetReleaseBySlugQuery> {
      return withWrapper(() => client.request<GetReleaseBySlugQuery>(print(GetReleaseBySlugDocument), variables));
    },
    getSubscriptionPortalUrlForOrder(variables: GetSubscriptionPortalUrlForOrderQueryVariables): Promise<GetSubscriptionPortalUrlForOrderQuery> {
      return withWrapper(() => client.request<GetSubscriptionPortalUrlForOrderQuery>(print(GetSubscriptionPortalUrlForOrderDocument), variables));
    },
    getSubscriptionPortalUrlForTicket(variables: GetSubscriptionPortalUrlForTicketQueryVariables): Promise<GetSubscriptionPortalUrlForTicketQuery> {
      return withWrapper(() => client.request<GetSubscriptionPortalUrlForTicketQuery>(print(GetSubscriptionPortalUrlForTicketDocument), variables));
    },
    getTicketsByIds(variables: GetTicketsByIdsQueryVariables): Promise<GetTicketsByIdsQuery> {
      return withWrapper(() => client.request<GetTicketsByIdsQuery>(print(GetTicketsByIdsDocument), variables));
    },
    handleInstagramCode(variables: HandleInstagramCodeMutationVariables): Promise<HandleInstagramCodeMutation> {
      return withWrapper(() => client.request<HandleInstagramCodeMutation>(print(HandleInstagramCodeDocument), variables));
    },
    hasMembershipToRenew(variables?: HasMembershipToRenewQueryVariables): Promise<HasMembershipToRenewQuery> {
      return withWrapper(() => client.request<HasMembershipToRenewQuery>(print(HasMembershipToRenewDocument), variables));
    },
    integrations(variables?: IntegrationsQueryVariables): Promise<IntegrationsQuery> {
      return withWrapper(() => client.request<IntegrationsQuery>(print(IntegrationsDocument), variables));
    },
    isLoggedIn(variables?: IsLoggedInQueryVariables): Promise<IsLoggedInQuery> {
      return withWrapper(() => client.request<IsLoggedInQuery>(print(IsLoggedInDocument), variables));
    },
    me(variables?: MeQueryVariables): Promise<MeQuery> {
      return withWrapper(() => client.request<MeQuery>(print(MeDocument), variables));
    },
    membership(variables: MembershipQueryVariables): Promise<MembershipQuery> {
      return withWrapper(() => client.request<MembershipQuery>(print(MembershipDocument), variables));
    },
    memberships(variables?: MembershipsQueryVariables): Promise<MembershipsQuery> {
      return withWrapper(() => client.request<MembershipsQuery>(print(MembershipsDocument), variables));
    },
    membershipsSoldForUser(variables: MembershipsSoldForUserQueryVariables): Promise<MembershipsSoldForUserQuery> {
      return withWrapper(() => client.request<MembershipsSoldForUserQuery>(print(MembershipsSoldForUserDocument), variables));
    },
    myEventTickets(variables: MyEventTicketsQueryVariables): Promise<MyEventTicketsQuery> {
      return withWrapper(() => client.request<MyEventTicketsQuery>(print(MyEventTicketsDocument), variables));
    },
    myEvents(variables?: MyEventsQueryVariables): Promise<MyEventsQuery> {
      return withWrapper(() => client.request<MyEventsQuery>(print(MyEventsDocument), variables));
    },
    myMembershipAddons(variables: MyMembershipAddonsQueryVariables): Promise<MyMembershipAddonsQuery> {
      return withWrapper(() => client.request<MyMembershipAddonsQuery>(print(MyMembershipAddonsDocument), variables));
    },
    myMembershipHoldOrders(variables?: MyMembershipHoldOrdersQueryVariables): Promise<MyMembershipHoldOrdersQuery> {
      return withWrapper(() => client.request<MyMembershipHoldOrdersQuery>(print(MyMembershipHoldOrdersDocument), variables));
    },
    myMembershipTickets(variables?: MyMembershipTicketsQueryVariables): Promise<MyMembershipTicketsQuery> {
      return withWrapper(() => client.request<MyMembershipTicketsQuery>(print(MyMembershipTicketsDocument), variables));
    },
    myPaymentPlanTickets(variables?: MyPaymentPlanTicketsQueryVariables): Promise<MyPaymentPlanTicketsQuery> {
      return withWrapper(() => client.request<MyPaymentPlanTicketsQuery>(print(MyPaymentPlanTicketsDocument), variables));
    },
    order(variables: OrderQueryVariables): Promise<OrderQuery> {
      return withWrapper(() => client.request<OrderQuery>(print(OrderDocument), variables));
    },
    myOrders(variables?: MyOrdersQueryVariables): Promise<MyOrdersQuery> {
      return withWrapper(() => client.request<MyOrdersQuery>(print(MyOrdersDocument), variables));
    },
    pinPaymentContext(variables: PinPaymentContextQueryVariables): Promise<PinPaymentContextQuery> {
      return withWrapper(() => client.request<PinPaymentContextQuery>(print(PinPaymentContextDocument), variables));
    },
    pollOrder(variables: PollOrderQueryVariables): Promise<PollOrderQuery> {
      return withWrapper(() => client.request<PollOrderQuery>(print(PollOrderDocument), variables));
    },
    getPOS(variables: GetPosQueryVariables): Promise<GetPosQuery> {
      return withWrapper(() => client.request<GetPosQuery>(print(GetPosDocument), variables));
    },
    referralUrlByOrderId(variables: ReferralUrlByOrderIdQueryVariables): Promise<ReferralUrlByOrderIdQuery> {
      return withWrapper(() => client.request<ReferralUrlByOrderIdQuery>(print(ReferralUrlByOrderIdDocument), variables));
    },
    referralUrlByWaitlistUserId(variables: ReferralUrlByWaitlistUserIdQueryVariables): Promise<ReferralUrlByWaitlistUserIdQuery> {
      return withWrapper(() => client.request<ReferralUrlByWaitlistUserIdQuery>(print(ReferralUrlByWaitlistUserIdDocument), variables));
    },
    reportSpamMembership(variables: ReportSpamMembershipQueryVariables): Promise<ReportSpamMembershipQuery> {
      return withWrapper(() => client.request<ReportSpamMembershipQuery>(print(ReportSpamMembershipDocument), variables));
    },
    resaleTicketTypesForWaitlist(variables: ResaleTicketTypesForWaitlistQueryVariables): Promise<ResaleTicketTypesForWaitlistQuery> {
      return withWrapper(() => client.request<ResaleTicketTypesForWaitlistQuery>(print(ResaleTicketTypesForWaitlistDocument), variables));
    },
    seasons(variables?: SeasonsQueryVariables): Promise<SeasonsQuery> {
      return withWrapper(() => client.request<SeasonsQuery>(print(SeasonsDocument), variables));
    },
    singleEvent(variables?: SingleEventQueryVariables): Promise<SingleEventQuery> {
      return withWrapper(() => client.request<SingleEventQuery>(print(SingleEventDocument), variables));
    },
    stripePaymentContext(variables: StripePaymentContextQueryVariables): Promise<StripePaymentContextQuery> {
      return withWrapper(() => client.request<StripePaymentContextQuery>(print(StripePaymentContextDocument), variables));
    },
    ticket(variables: TicketQueryVariables): Promise<TicketQuery> {
      return withWrapper(() => client.request<TicketQuery>(print(TicketDocument), variables));
    },
    ticketDetailsForBackOfficeOrders(variables?: TicketDetailsForBackOfficeOrdersQueryVariables): Promise<TicketDetailsForBackOfficeOrdersQuery> {
      return withWrapper(() => client.request<TicketDetailsForBackOfficeOrdersQuery>(print(TicketDetailsForBackOfficeOrdersDocument), variables));
    },
    ticketGates(variables: TicketGatesQueryVariables): Promise<TicketGatesQuery> {
      return withWrapper(() => client.request<TicketGatesQuery>(print(TicketGatesDocument), variables));
    },
    sendTicket(variables: SendTicketMutationVariables): Promise<SendTicketMutation> {
      return withWrapper(() => client.request<SendTicketMutation>(print(SendTicketDocument), variables));
    },
    getSentTicketsById(variables: GetSentTicketsByIdQueryVariables): Promise<GetSentTicketsByIdQuery> {
      return withWrapper(() => client.request<GetSentTicketsByIdQuery>(print(GetSentTicketsByIdDocument), variables));
    },
    ticketsSoldForUser(variables: TicketsSoldForUserQueryVariables): Promise<TicketsSoldForUserQuery> {
      return withWrapper(() => client.request<TicketsSoldForUserQuery>(print(TicketsSoldForUserDocument), variables));
    },
    user(variables: UserQueryVariables): Promise<UserQuery> {
      return withWrapper(() => client.request<UserQuery>(print(UserDocument), variables));
    },
    venueSeatsThumbnail(variables: VenueSeatsThumbnailQueryVariables): Promise<VenueSeatsThumbnailQuery> {
      return withWrapper(() => client.request<VenueSeatsThumbnailQuery>(print(VenueSeatsThumbnailDocument), variables));
    },
    venueWithGates(variables: VenueWithGatesQueryVariables): Promise<VenueWithGatesQuery> {
      return withWrapper(() => client.request<VenueWithGatesQuery>(print(VenueWithGatesDocument), variables));
    },
    windcavePaymentContext(variables: WindcavePaymentContextQueryVariables): Promise<WindcavePaymentContextQuery> {
      return withWrapper(() => client.request<WindcavePaymentContextQuery>(print(WindcavePaymentContextDocument), variables));
    },
    windcavePaymentResult(variables: WindcavePaymentResultQueryVariables): Promise<WindcavePaymentResultQuery> {
      return withWrapper(() => client.request<WindcavePaymentResultQuery>(print(WindcavePaymentResultDocument), variables));
    },
    getXeroConfigurationQuery(variables?: GetXeroConfigurationQueryQueryVariables): Promise<GetXeroConfigurationQueryQuery> {
      return withWrapper(() => client.request<GetXeroConfigurationQueryQuery>(print(GetXeroConfigurationQueryDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;